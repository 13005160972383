import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DdlTypeForYesNo, Mortgage} from '../../../shared/mortgage';
import {Matter} from '../../../shared/matter';
import {Logger} from '@nsalaun/ng-logger';
import {PartialDateComponent} from '../../../../shared/partial-date';
import {Subject} from 'rxjs';
import {noYesOptions} from '../dropdown-options';
import {SelectItem} from 'primeng/api';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {MortgageReport} from '../../../shared/mortgage-report';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {InterimEnclosureModal} from '../interim-enclosure/interim-enclosure.modal.component';
import {MortgageeEnclosureService} from '../../../../admin/mortgagee-enclosure/mortgagee-enclosure.service';
import {MortgageeEnclosureConfig} from '../../../../admin/mortgagee-enclosure/mortgagee-enclosure-config';

import * as _ from 'lodash';
import {MortgagePrecedentComponent} from '../precedent/mortgage-precedent.component';
import {SESSION_STORAGE_KEYS} from '../../../../shared/session-storage-keys';
import {PrecedentTypes} from '../../../shared/mortgage-precedent-type';
import {FocusFirstElementDecorator} from '../../../../shared-main/focus-first-element-decorator';
import {GetGlobalSaveModelService} from '../../../shared/get-global-save-model.service';
import {ActivatedRoute, Params} from '@angular/router';
import {MortgageTerm} from '../../../shared/mortgage-term';
import {Utils} from '../../../shared/utils';
import {prepaymentPrivilege} from '../../../../shared-main/province-based-dropdowns';

@Component({
  selector: 'dp-mortgage-report',
  templateUrl: 'mortgage-report.component.html',
  styleUrls: [ '../mortgage.styles.scss' ]
})

@FocusFirstElementDecorator()
export class MortgageReportComponent implements OnInit {

  @Input() matter: Matter;
  @Output() onChange = new EventEmitter();
  @ViewChild('idAdjDate') partialMortgageRegistrationComponent: PartialDateComponent;
  public ngUnsubscribe: Subject<void> = new Subject<void>();
  reportingLetterTo: SelectItem[];
  deductMoniesOptions: SelectItem[];
  yesNoItems: DdlTypeForYesNo[];
  prepaymentPrivilege: SelectItem[];
  pageNumberStartingLabel: string = '';
  pageNumberEndingLabel: string = '';
  additionalClause: string;
  preselectedPrecedent: string;
  enclosureConfig: MortgageeEnclosureConfig;
  id: number = 0;
  existingDispositionRemainMortgage: Mortgage[] = [];
  selectionStart: number = 0;

  box6SelectionStart: number = 0;
  mtgDescSelectionStart: number = 0;
  loanDescSelectionStart: number = 0;
  primeSelectionStart: number = 0;

  constructor(
    public dialogService: DialogService,
    public logger: Logger,
    public globalSaveModelService: GetGlobalSaveModelService,
    public mortgageeEnclosureService: MortgageeEnclosureService,
    public activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {

    this.activatedRoute
    .params
    .subscribe((params: Params) => {

      setTimeout(() => {
        this.id = +params[ 'mortgageId' ];
        this.initMortgageReport();
      }, 100);
    });

    //DPPMP-11604, convert the existing mortgageRegistrationNumber to uppercase if applicable
    if (this.mortgage && this.mortgage.mortgageReport && this.mortgage.mortgageReport.mortgageRegistrationNumber) {
      this.mortgage.mortgageReport.mortgageRegistrationNumber = this.mortgage.mortgageReport.mortgageRegistrationNumber.toUpperCase();
    }

  }

  get mortgage(): Mortgage {
    return this.id >= this.matter.mortgages.length ? this.matter.mortgages[ 0 ] : this.matter.mortgages[ this.id ];
  }

  initMortgageReport(): void {
    this.existingDispositionRemainMortgage = this.matter.existingDispositionDischargedMortgage();
    this.reportingLetterTo = this.matter.isMortgage ? dropDowns.reportingLetterToMortgagor : dropDowns.reportingLetterToPurchaser;
    this.yesNoItems = noYesOptions;
    this.deductMoniesOptions = dropDowns.deductMoniesOptions;
    this.prepaymentPrivilege = prepaymentPrivilege[ this.matter.provinceCode ];

    this.buildMortgageReportStructure();
    this.prepaymentPrivilegeChange(null);
    let id = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    this.mortgageeEnclosureService.getMortgageeEnclosuresConfigForAccount(id, this.matter.provinceCode).subscribe((config: MortgageeEnclosureConfig) => {
      this.enclosureConfig = new MortgageeEnclosureConfig(config);
      if (this.matter.isSale && !this.mortgage.finalEnclosures) {
        this.loadFinalDefaultEnclosures();
      }
    });
    this.logger.info('mortgage-report | ngOnInit | mortgage.mortgageReport = ', this.mortgage.mortgageReport);
    this.globalSaveModelService.getItem$.takeUntil(this.ngUnsubscribe).subscribe(() => {

      setTimeout(() => {
        this.logger.info('  --  this.mortgage.mortgageReport.mortgageRegistrationDate = ', this.mortgage.mortgageReport.mortgageRegistrationDate);
        if (this.partialMortgageRegistrationComponent) {
          this.partialMortgageRegistrationComponent.setDate(this.mortgage.mortgageReport.mortgageRegistrationDate);
        }

      }, 100);
    });

  }

  onGenerateReportingLetterToChange(event) {
    this.enableSave();
    if (event !== this.mortgageReport.generateReportingLetterTo) {
      if (Array.isArray(this.mortgage.finalEnclosures)) {
        // If UI side sends finalEnclosures as undefined, null or [], server side always send back [];
        // So add finalEnclosuresDefaultsOverridden to know if finalEnclosures is undefined or null
        if (this.mortgage.finalEnclosures.length == 0 && !this.mortgage.finalEnclosuresDefaultsOverridden) {
          this.setFinalDefaultEnclosures(event);
        }
      } else {
        this.setFinalDefaultEnclosures(event);
      }
    }
  }

  setFinalDefaultEnclosures(event) {
    if (this.matter.isPurchase && event == 'PURCHASER_MORTGAGEE') {
      this.loadFinalDefaultEnclosures();
    } else if (this.matter.isMortgage && event == 'MORTGAGOR_MORTGAGEE') {
      this.loadFinalDefaultEnclosures();
    }
  }

  buildMortgageReportStructure(): void {

    if (!this.mortgageReport.generateReportingLetterTo) {
      this.setGenerateReportingLetterToDefaultValue();
    }
    if (!this.mortgageReport.includeClause) {
      this.mortgageReport.includeClause = 'N_y';
    }
    if (!this.mortgageReport.mortgagePrepaymentPrivilege) {
      this.mortgageReport.mortgagePrepaymentPrivilege = 'QUESTION';
    }

  }

  setGenerateReportingLetterToDefaultValue() {
    if (this.matter.isMatterProvinceAB || this.matter.isMatterProvinceBC) {
      if (this.matter.isPurchase) {
        this.mortgageReport.generateReportingLetterTo = 'PURCHASER_MORTGAGEE';
      } else if (this.matter.isMortgage) {
        this.mortgageReport.generateReportingLetterTo = 'MORTGAGOR_MORTGAGEE';
      }
    } else {
      this.mortgageReport.generateReportingLetterTo = 'QUESTION';
    }
  }

  get mortgageReport() {
    if (!this.mortgage.mortgageReport) {
      this.mortgage.mortgageReport = new MortgageReport();
    }
    return this.mortgage.mortgageReport;
  }

  enableSave(): void {
    this.matter.dirty = true;
    this.notifyChange();
  }

  notifyChange(): void {
    this.onChange.emit();
  };

  prepaymentPrivilegeChange(event): void {
    if (event) {
      this.enableSave();
    }
    if (this.mortgageReport.mortgagePrepaymentPrivilege && this.mortgageReport.mortgagePrepaymentPrivilege != 'NOT_APPLICABLE'
      && this.mortgageReport.mortgagePrepaymentPrivilege != 'QUESTION') {

      let selected: any = _.find(dropDowns.pageNumberLabel, {'value': this.mortgageReport.mortgagePrepaymentPrivilege});
      if (selected && selected.labelStarting) {
        this.pageNumberStartingLabel = selected.labelStarting;
      }
      if (selected && selected.labelEnding) {
        this.pageNumberEndingLabel = selected.labelEnding;
      }
    }
  }

  onDateChangeAdjDate(event): void {
    if (event && event.rawDate && this.mortgageReport.mortgageRegistrationDate !== event.rawDate) {
      this.mortgageReport.mortgageRegistrationDate = Utils.createDateValues(event.rawDate).dateValue;
      this.enableSave();

    }
  }

  getMortgageRegDateValue(): string {
    const refinedDateValue: string = Utils.createDateValues(this.mortgageReport.mortgageRegistrationDate).dateValue;
    if (this.mortgageReport.mortgageRegistrationDate != refinedDateValue) {
      this.mortgageReport.mortgageRegistrationDate = refinedDateValue;
      this.enableSave();
    }
    return this.mortgageReport.mortgageRegistrationDate;
  }

  onDateChangeRentAssignmentRegistrationDate(event): void {
    if (event && event.rawDate && this.mortgageReport.rentAssignmentRegistrationDate !== event.rawDate) {
      this.mortgageReport.rentAssignmentRegistrationDate = Utils.createDateValues(event.rawDate).dateValue;
      this.enableSave();

    }
  }

  isPageNumberAvailable(): boolean {
    return (this.mortgageReport.mortgagePrepaymentPrivilege != 'NOT_APPLICABLE'
      && this.mortgageReport.mortgagePrepaymentPrivilege != 'QUESTION');
  }

  openInterimEncModal() {
    let isCondominium: string;
    if (this.matter.matterPropertyWithCondo) {
      isCondominium = this.matter.matterPropertyWithCondo.isCondominium;
    } else {
      isCondominium = 'NO';
    }
    this.dialogService.matDialogContent({
      content: InterimEnclosureModal,
      context: {
        interimEnclosures: this.mortgage.finalEnclosures,
        isCondo: isCondominium,
        enclosureConfig: this.enclosureConfig,
        isFinal: true,
        loadDefaultEnclosures: this.getMortgageFinalEnclosures() === '' ? true : false,
        isLender: this.matter?.isMortgageBC || this.matter?.isPurchaseBC
      },
      onFulfillment: (result: any) => {
        if (result) {
          this.mortgage.finalEnclosures = result.selectedEnclosures;
          this.mortgage.finalEnclosuresDefaultsOverridden = true;
          this.enableSave();
        }
      }
    });
  }

  loadFinalDefaultEnclosures() {
    let isCondominium: string;
    if (this.matter.matterPropertyWithCondo) {
      isCondominium = this.matter.matterPropertyWithCondo.isCondominium;
    } else {
      isCondominium = 'NO';
    }
    this.mortgage.finalEnclosures = [];
    if (this.enclosureConfig && this.enclosureConfig.mortgageeEnclosureConfigItems) {
      this.enclosureConfig.mortgageeEnclosureConfigItems.forEach((enclosure) => {
        if (enclosure.finalEnclosureType === 'CONDO_AND_NON_CONDO') {
          this.mortgage.finalEnclosures.push(enclosure.id);
        } else if (enclosure.finalEnclosureType === 'CONDO') {
          if (isCondominium === 'YES') {
            this.mortgage.finalEnclosures.push(enclosure.id);
          }
        } else if (enclosure.finalEnclosureType === 'NON_CONDO') {
          if (isCondominium !== 'YES') {
            this.mortgage.finalEnclosures.push(enclosure.id);
          }
        }
      });
    }
    if (!this.matter.isSale) {
      this.mortgage.finalEnclosuresDefaultsOverridden = true;
    }

  }

  getMortgageFinalEnclosures(): string {
    let fieldNames: string[] = [];
    if (this.enclosureConfig && this.enclosureConfig.mortgageeEnclosureConfigItems) {
      if (this.mortgage.finalEnclosures) {
        this.enclosureConfig.mortgageeEnclosureConfigItems.forEach(item => {
          if (this.mortgage.finalEnclosures.indexOf(item.id) > -1) {
            fieldNames.push(item.enclosureName);
          }
        });
      }
    }
    if (fieldNames.length > 0) {
      return fieldNames.join('; ');
    }
    return '';
  }

  insertPrecedent(insertTo?: string) {
    this.precedentCoreAction('INSERT', insertTo);
  }

  saveAsPrecedent(contentFrom?: string) {
    this.precedentCoreAction('SAVE', contentFrom);
  }

  precedentCoreAction(precedentAction: string, insertTo?: string) {
    if (insertTo) {
      let additionalClause: string = '';
      if (insertTo === 'BOX6') {
        additionalClause = this.mortgageTerm.box6AdditionalProvisions;
      } else if (insertTo === 'MTG') {
        additionalClause = this.mortgageTerm.mortgageDescription;
      } else if (insertTo === 'LOAN') {
        additionalClause = this.mortgageTerm.loanDescription;
      } else if (insertTo === 'PRIME') {
        additionalClause = this.mortgageTerm.primeIsDefinedAs;
      }
      this.dialogService.matDialogContent({
        content: MortgagePrecedentComponent,
        context: {
          additionalClause: additionalClause,
          action: precedentAction,
          caller: PrecedentTypes.MORTGAGE
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.action === 'ok') {//if  modal is closed after save
              if (precedentAction === 'INSERT' && result.additionalClause) {
                this.doInsertPrecedent(null, result.additionalClause, insertTo);
                this.enableSave();
              }
            } else {
              //    For now, do nothing
            }
          }
        },

      });
    } else {
      this.dialogService.matDialogContent({
        content: MortgagePrecedentComponent,
        context: {
          additionalClause: this.mortgageReport.additionalClause,
          action: precedentAction,
          caller: PrecedentTypes.MORTGAGE
        },
        onFulfillment: (result) => {
          if (result) {
            if (result.action === 'ok') {//if  modal is closed after save
              if (precedentAction === 'INSERT' && result.additionalClause) {
                this.doInsertPrecedent(this.selectionStart, result.additionalClause);
                this.enableSave();
              }
            } else {
              //    For now, do nothing
            }
          }
        },

      });
    }
  }

  getRegistrationDateF9HelpText(): string {
    return `F9 = ${ this.matter.isClosingDateAvailable() ? this.matter.matterCloseDate : '' }`;
  }

  onRegistrationDateF9 = () => {
    this.mortgageReport.mortgageRegistrationDate = this.matter.matterCloseDate || '//';
  };

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  get mortgageIndex(): number {
    let index = this.matter.mortgages.findIndex(item => item.id == this.mortgage.id);
    return index >= 0 ? (Number(index) + 1) : index;
  }

  getMortgageIndexStr(mortgage: Mortgage): string {
    let mortgageIndexStr = 'Previous ';
    if (mortgage) {
      let index = this.matter.existingMortgageIndex(mortgage);
      mortgageIndexStr = mortgageIndexStr + (Number(index) + 1);
      if (index == 0) {
        mortgageIndexStr = mortgageIndexStr + '<sup>st</sup>';
      } else if (index == 1) {
        mortgageIndexStr = mortgageIndexStr + '<sup>st</sup>';
      } else if (index == 2) {
        mortgageIndexStr = mortgageIndexStr + '<sup>rd</sup>';
      } else if (index > 2) {
        mortgageIndexStr = mortgageIndexStr + '<sup>th</sup>';
      }
    }
    mortgageIndexStr = mortgageIndexStr + ' Mortgage';
    return mortgageIndexStr;
  }

  get isFinalReportToMortgageeEnclosuresDisabled(): boolean {
    return this.mortgageReport.generateReportingLetterTo === 'PURCHASER_MORTGAGEE' ||
      this.mortgageReport.generateReportingLetterTo === 'MORTGAGOR_MORTGAGEE' ||
      (this.matter && this.matter.isSale);
  }

  updateCursorPosition(e: MouseEvent): void {
    this.selectionStart = e.target[ 'selectionStart' ] || 0;
  }

  doInsertPrecedent(selectionStart: number, stringToInsert: string, insertTo: string = ''): void {
    if (insertTo) {
      if (insertTo === 'BOX6') {
        const box6Text = this.mortgageTerm.box6AdditionalProvisions;
        this.mortgageTerm.box6AdditionalProvisions = this.box6SelectionStart ?
          `${ box6Text.slice(0, this.box6SelectionStart) } ${ stringToInsert } ${ box6Text.slice(this.box6SelectionStart, box6Text.length) }` : stringToInsert;
      } else if (insertTo === 'MTG') {
        const mtgDesc = this.mortgageTerm.mortgageDescription;
        this.mortgageTerm.mortgageDescription = (this.mtgDescSelectionStart ?
          `${ mtgDesc.slice(0, this.mtgDescSelectionStart) } ${ stringToInsert } ${ mtgDesc.slice(this.mtgDescSelectionStart, mtgDesc.length) }` : stringToInsert).slice(0, 130);
      } else if (insertTo === 'LOAN') {
        const loanDesc = this.mortgageTerm.loanDescription;
        this.mortgageTerm.loanDescription = (this.loanDescSelectionStart ?
          `${ loanDesc.slice(0, this.loanDescSelectionStart) } ${ stringToInsert } ${ loanDesc.slice(this.loanDescSelectionStart, loanDesc.length) }` : stringToInsert).slice(0, 130);
      } else if (insertTo === 'PRIME') {
        const primeText = this.mortgageTerm.primeIsDefinedAs;
        this.mortgageTerm.primeIsDefinedAs = (this.primeSelectionStart ?
          `${ primeText.slice(0, this.primeSelectionStart) } ${ stringToInsert } ${ primeText.slice(this.primeSelectionStart, primeText.length) }` : stringToInsert).slice(0, 130);
      }
    } else {
      const clause = this.mortgageReport.additionalClause;
      this.mortgageReport.additionalClause = this.selectionStart ?
        `${ clause.slice(0, this.selectionStart) } ${ stringToInsert } ${ clause.slice(this.selectionStart, clause.length) }` : stringToInsert;
    }
  }

  get mortgageTerm() {
    if (!this.mortgage.mortgageTerm) {
      this.mortgage.mortgageTerm = new MortgageTerm();
    }
    return this.mortgage.mortgageTerm;
  }

  updateCursorPositionBox6(e: MouseEvent): void {
    this.box6SelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  updateCursorPositionMtgDesc(e: MouseEvent): void {
    this.mtgDescSelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  updateCursorPositionLoanDesc(e: MouseEvent): void {
    this.loanDescSelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  updateCursorPositionPrimeText(e: MouseEvent): void {
    this.primeSelectionStart = e.target[ 'selectionStart' ] || 0;
  }

  get isAdditionalMortgageDetailsAvailable(): boolean {
    return this.matter.isMatterProvinceMB && this.mortgage.isEmpMortgage();
  }

  ngAfterViewInit() {
  }

  setLocalInstancesForMassUpdate(matter: Matter, mortgageIndex: number) {
    this.matter = matter;
    this.id = mortgageIndex;
  }
}
