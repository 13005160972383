import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import {UUIDUtil} from '../../../../main/uuid-util';

export type TypeOfConstruction = 'NEW_CONSTRUCTION' | 'SUBSTANTIAL_RENOVATION' | 'ADDITIONAL_CONSTRUCTION' | 'CONVERSION';

export class TypeOfConstructionValue {
  public static readonly NEW_CONSTRUCTION: TypeOfConstruction = 'NEW_CONSTRUCTION';
  public static readonly SUBSTANTIAL_RENOVATION: TypeOfConstruction = 'SUBSTANTIAL_RENOVATION';
  public static readonly ADDITIONAL_CONSTRUCTION: TypeOfConstruction = 'ADDITIONAL_CONSTRUCTION';
  public static readonly CONVERSION: TypeOfConstruction = 'CONVERSION';
}

export type TypeOfHousing = 'SINGLE_UNIT' | 'CONDOMINIUM_UNIT' | 'ONE_DUPLEX'
  | 'LEASE_OF_LAND' | 'COOP_UNIT_OR_MULTIPLE' | 'MULTIPLE_ADDITION';

export class TypeOfHousingValue {
  public static readonly SINGLE_UNIT: TypeOfHousing = 'SINGLE_UNIT';
  public static readonly CONDOMINIUM_UNIT: TypeOfHousing = 'CONDOMINIUM_UNIT';
  public static readonly ONE_DUPLEX: TypeOfHousing = 'ONE_DUPLEX';
  public static readonly LEASE_OF_LAND: TypeOfHousing = 'LEASE_OF_LAND';
  public static readonly COOP_UNIT_OR_MULTIPLE: TypeOfHousing = 'COOP_UNIT_OR_MULTIPLE';
  public static readonly MULTIPLE_ADDITION: TypeOfHousing = 'MULTIPLE_ADDITION';
}

export type TypeOfRebateSituation = 'A' | 'B' | 'C';

export class TypeOfRebateSituationValue {
  public static readonly A: TypeOfRebateSituation = 'A';
  public static readonly B: TypeOfRebateSituation = 'B';
  public static readonly C: TypeOfRebateSituation = 'C';
}

export type TypeOfApplication = 'TYPE6' | 'TYPE7' | 'TYPE8' | 'TYPE9A' | 'TYPE9B';

export class TypeOfApplicationValue {
  public static readonly TYPE6: TypeOfApplication = 'TYPE6';
  public static readonly TYPE7: TypeOfApplication = 'TYPE7';
  public static readonly TYPE8: TypeOfApplication = 'TYPE8';
  public static readonly TYPE9A: TypeOfApplication = 'TYPE9A';
  public static readonly TYPE9B: TypeOfApplication = 'TYPE9B';
}

//1.57, 1.58, 1.60, 1.61, 1.71, 2.04, and 2.34
export type TypeOfRebateRate = '1.57' | '1.58' | '1.60' | '1.61' | '1.71' | '2.04' | '2.34';

export class TypeOfRebateRateValue {
  public static readonly REBATE_RATE_1_57: TypeOfRebateRate = '1.57';
  public static readonly REBATE_RATE_1_58: TypeOfRebateRate = '1.58';
  public static readonly REBATE_RATE_1_60: TypeOfRebateRate = '1.60';
  public static readonly REBATE_RATE_1_61: TypeOfRebateRate = '1.61';
  public static readonly REBATE_RATE_1_71: TypeOfRebateRate = '1.71';
  public static readonly REBATE_RATE_2_04: TypeOfRebateRate = '2.04';
  public static readonly REBATE_RATE_2_34: TypeOfRebateRate = '2.34';
}

export const ResidentialRentalRebateDropDown = {
  TypeOfConstructionOptions: [
    {label: 'New construction', value: TypeOfConstructionValue.NEW_CONSTRUCTION},
    {label: 'Substantial renovation', value: TypeOfConstructionValue.SUBSTANTIAL_RENOVATION},
    {label: 'Construction of an addition to a multiple unit residential complex', value: TypeOfConstructionValue.ADDITIONAL_CONSTRUCTION},
    {label: 'Conversion to a residential complex without substantial renovation', value: TypeOfConstructionValue.CONVERSION}
  ],
  TypeOfHousingOptions: [
    {label: 'Single Unit', value: TypeOfHousingValue.SINGLE_UNIT},
    {label: 'Condominium Unit', value: TypeOfHousingValue.CONDOMINIUM_UNIT},
    {label: 'Duplex or addition of one unit to a multiple unit residential complex', value: TypeOfHousingValue.ONE_DUPLEX},
    {label: 'Lease of Land', value: TypeOfHousingValue.LEASE_OF_LAND},
    {label: 'Co-op unit or multiple units', value: TypeOfHousingValue.COOP_UNIT_OR_MULTIPLE},
    {label: 'Addition of more than one unit to a multiple unit residential complex', value: TypeOfHousingValue.MULTIPLE_ADDITION}
  ],
  TypeOfRebateRateOptions: [
    {label: '1.57%', value: TypeOfRebateRateValue.REBATE_RATE_1_57},
    {label: '1.58%', value: TypeOfRebateRateValue.REBATE_RATE_1_58},
    {label: '1.60%', value: TypeOfRebateRateValue.REBATE_RATE_1_60},
    {label: '1.61%', value: TypeOfRebateRateValue.REBATE_RATE_1_61},
    {label: '1.71%', value: TypeOfRebateRateValue.REBATE_RATE_1_71},
    {label: '2.04%', value: TypeOfRebateRateValue.REBATE_RATE_2_04},
    {label: '2.34%', value: TypeOfRebateRateValue.REBATE_RATE_2_34}
  ],
  TypeOfRebateSituationOptions: [
    {label: 'A', value: TypeOfRebateSituationValue.A},
    {label: 'B', value: TypeOfRebateSituationValue.B},
    {label: 'C', value: TypeOfRebateSituationValue.C}
  ]
};

export const ModalAction = {
  Ok: 'Ok',
  Cancel: 'Cancel',
  Produce: 'Produce'
};

export class Unit extends BaseEntity {
  id: number;
  unitNumber: string;                        //maxLength 6
  unitFloorSpace: number;                     //Numeric - two decimal
  createdTimeStamp: number;

  constructor(unit?: Unit) {
    super(unit);
    //UI creates id
    if (!this.id) {
      this.id = UUIDUtil.getUUID();
    }
    if (!this.createdTimeStamp) {
      this.createdTimeStamp = (new Date()).getTime();
    }
  }
}

export class ResidentialRentalRebateForm extends BaseEntity {

  id: number;
  claimantsBusinessNumber: string;            //maxLength 9
  claimantsRT: string;                        //maxLength 4 no decimals
  claimantsSocialInsuranceNumber: string;     //maxLength 9 no decimals
  contactPersonFirstName: string;             //maxLength 100
  contactPersonLastName: string;              //maxLength 100
  telephoneNo: string;                        //Numeric - 10 digits, no decimals
  authorizationPersonName: string;            //maxLength 100
  dateApplicationSigned: string;              //Date
  relevantDate: string;                       //Date
  typeOfConstruction: TypeOfConstruction;
  typeOfHousing: TypeOfHousing;
  gstHstFrom: string;                         //Date
  gstHstTo: string;                           //Date
  typeOfApplication: string;
  fairMarketValue: number;                    //Standard Currency Field
  totalFloorSpaceOfUnit: number;              //Numeric - two decimal
  totalFloorSpaceOfAllUnits: number;          //Numeric - two decimal
  units: Unit[] = [];
  rebateRate: string;
  rebateSituation: string;
  salesPriceForTheBuildingOnly: number;       //Standard Currency Field
  salesPriceForShareOfCapitalStock: number;       //Standard Currency Field
  numberOfSitesForResidentialTrailerPark: number;  //no decimals

  constructor(eregistrationGstHstForm?: ResidentialRentalRebateForm) {
    super(eregistrationGstHstForm);
    this.units = [];
    if (eregistrationGstHstForm) {
      if (Array.isArray(eregistrationGstHstForm.units)) {
        eregistrationGstHstForm.units.forEach((item: Unit) => {
          this.units.push(new Unit(item));
        });
      }
    } else {
      this.typeOfConstruction = TypeOfConstructionValue.NEW_CONSTRUCTION;
      this.typeOfHousing = TypeOfHousingValue.SINGLE_UNIT;
    }
  }
}
