<div class="modal-header" >
    <div class="col-lg-12">
        <div class="col-lg-10">
            <h1>Enter Affidavit Information</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
</div>
<div class="row modal-body form-horizontal padding-left-30">

    <div class="form-group">
        <dp-checkbox fieldId="clientSelectedFlag">
            <input id="clientSelectedFlag"
                   name="clientSelectedFlag"
                   type="checkbox"
                   [checked]="isClientSelected"
                   [disabled]="true"
                   #clientSelectedFlag>
        </dp-checkbox>
        Client
    </div>
    <div class="form-group">
        <dp-checkbox fieldId="solicitorSelectedFlag">
            <input id="solicitorSelectedFlag"
                   name="clientSelectedFlag"
                   type="checkbox"
                   [checked]="isSolicitorSelected"
                   [disabled]="true"
                   #solicitorSelectedFlag>
        </dp-checkbox>
        Solicitor
    </div>
    <div class="form-group">
        <div class="col-lg-6 vertical-align-label">
            <label class="control-label">Enter the name of the debtor in the similar-name execution</label>
        </div>
        <div class="col-lg-4">
            <input class="form-control"
                   id="debtorName"
                   name="debtorName"
                   [(ngModel)]="paragraph.debtorName"
            >
        </div>
    </div>
    <div class="form-group">
        <div class="col-lg-6 vertical-align-label">
            <label class="control-label">Is more than 1 debtor named in the Writ of Execution?</label>
        </div>
        <div class="col-lg-4">
            <select class="form-control"
                    id="moreThanOneDebtor"
                    name="moreThanOneDebtor"
                    [(ngModel)]="paragraph.moreThanOneDebtor">
                <option *ngFor="let yesNoOption of yesNoOptions" [value]="yesNoOption.value">
                    {{yesNoOption.label}}
                </option>
            </select>
        </div>
    </div>
    <div class="form-group">
        <div class="col-lg-6 vertical-align-label">
            <label class="control-label">Enter the number under which the Writ of Execution was filed</label>
        </div>
        <div class="col-lg-4">
            <input class="form-control" id="executionNumber"
                   name="executionNumber"
                   maxlength="255"
                   [(ngModel)]="paragraph.executionNumber">
        </div>
    </div>
    <div class="form-group">
        <div class="col-lg-6 vertical-align-label">
            <label class="control-label">Enter the name of the plaintiff in the similar-name execution</label>
        </div>
        <div class="col-lg-4">
            <dp-autocomplete-custom-pick-list
                #pickPlaintiff
                [id]="'plaintiffName'"
                [name]="'plaintiffName'"
                [delay]="'10'"
                [maxLength]="'100'"
                [minLength]="'1'"
                [scrollHeight]="'200px'"
                [pickListType]="pickListType"
                [(parentModel)]="paragraph.plaintiffName"
                (onPickListEdit)="openPickListModal($event)">
            </dp-autocomplete-custom-pick-list>
        </div>
    </div>
    <div class="form-group">
        <div class="col-lg-6 vertical-align-label">
            <label class="control-label">Enter the amount of the judgment, excluding the dollar sign (e.g. 12,500.00)</label>
        </div>
        <div class="col-lg-4">
            <input class="form-control" id="judgmentAmount"
                   name="judgmentAmount"
                   maxlength="255"
                   [(ngModel)]="paragraph.judgmentAmount">
        </div>
    </div>
    <div class="form-group padding-bottom-15">
        <div class="col-lg-6 vertical-align-label">
            <label class="control-label">Enter amount of costs, excluding dollar sign (e.g. 200.00) or type "nil"</label>
        </div>
        <div class="col-lg-4">
            <input class="form-control" id="costAmount"
                   name="costAmount"
                   maxlength="255"
                   [(ngModel)]="paragraph.costAmount"
                   (keydown.f9)="costAmountF9()"
            >
        </div>
    </div>


</div>
<div class="modal-footer">
    <div class="col-lg-12 buttons">
        <button type="button" class="dp-btn" (click)="onOK()" #okButtonControl id="okBtn">
            <span>OK</span>
        </button>
        <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
            <span>Cancel</span>
        </button>
    </div>
</div>
