<div class="container-fluid custom-modal-container">
    <div class="row modal-header">
        <div class="col-xs-12 col-lg-10">
            <h1>{{modalHeaderText}}</h1>
        </div>
        <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
    </div>
    <div class="row modal-body view-port form-horizontal">
        <div class="form-group">

            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="solicitorName">Solicitor *</label>
            </div>

            <div class="col-lg-5"
                 id="solicitorName">
                <select class="form-control"
                        [(ngModel)]="selectedStewartUser"
                        [disabled] = "context.stewartTitleUserForReValidation"
                >
                    <option *ngFor="let solicitor of solicitorDropDownOptions" [ngValue]="solicitor">{{solicitor?.solicitorName?.surnameLastFullName}}</option>
                </select>
            </div>
        </div>

        <div class="form-group" *ngIf="isStewartTitle()">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="stewartTitleProfileId">Profile ID *</label>
            </div>
            <div class="col-lg-5">
                <input type="text"
                       name="stewartTitleProfileId"
                       id="stewartTitleProfileId"
                       class="form-control focus-first"
                       [(ngModel)]="profileId"
                       numberOnly
                       maxlength="20"
                       class="form-control"
                />
            </div>
        </div>

        <div class="form-group" *ngIf="isTitlePlus()">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label" for="stewartTitleProfileId">Law Society Number *</label>
            </div>
            <div class="col-lg-5">
                <input type="text"
                       name="lsnNumberId"
                       id="lsnNumberId"
                       class="form-control focus-first"
                       [(ngModel)]="profileId"
                       maxlength="20"
                       class="form-control"
                />
            </div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button id="okBtn" class="dp-btn" type="button" (click)="proceedToStewartTitleAuthentication()" [disabled]="!nextButtonEnabled()">
                Next
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </div>
</div>
