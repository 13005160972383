<form class="form-horizontal container-fluid custom-modal-container border-radius">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Import Confirmation</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>

    <div class="modal-body min-height-220 line-height-18">
        <div class="form-group col-lg-12 padding-left-300" *ngIf="!latestPayoutStatementStatus.isDischargeRegistered()">
            <p class="padding-bottom-10">Performing an import of the Payout Statement information will</p>
            <ul class="padding-bottom-10 padding-left-20" style="list-style-type:circle">
                <li>select the Use Mortgage Payout Calculator checkbox</li>
                <li>overwrite the</li>
                <ul class="padding-bottom-10 padding-left-20" style="list-style-type:circle">
                    <li>Amount Payable to Discharge,</li>
                    <li>Payout Date</li>
                    <li  *ngIf="!mortgage.isCollateralMortgage()">Per Diem Interest.</li>
                </ul>
            </ul>
            <p class="padding-bottom-10">Do you wish to Continue?</p>
        </div>
        <div class="form-group col-lg-12 padding-left-200" *ngIf="latestPayoutStatementStatus.isDischargeRegistered()">
            <p class="padding-bottom-5">Performing an Import of the Discharge registration information will update the Discharge is to be </p>
            <p class="padding-bottom-20">registered by and Undertaking status information.</p>
            <p>Do you wish to Continue?</p>
        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <div class="col-lg-12 buttons">
                <button type="button" id="okBtn" class="dp-btn"  (click)="parsePayoutDocument()">
                    <span>Yes</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>No</span>
                </button>
            </div>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>
</form>
