import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ErrorService} from '../../shared/error-handling/error-service';
import {Logger} from '@nsalaun/ng-logger';
import {TeranetUser} from '../../shared-main/teranet/teranet-user';
import {TeranetService} from '../../shared-main/teranet/teranet-service';
import {FocusFirstElementDecorator} from '../../shared-main/focus-first-element-decorator';
import {DialogService} from '../../shared/dialog/dialog.service';
import {ManageTeranetUserAction, ManageTeranetUserModal} from './manage-teranet-user.modal.component';
import * as _ from 'lodash';
import {TestTeranetUserCredentialModal} from './test-teranet-user-credential.modal.component';
import {TeranetChangePasswordModal} from './teranet-change-password.modal.component';
import {teranetConnectLinks} from '../../shared-main/teranet/teranet-constants';
import {StewartAssystUser} from '../../shared-main/telus/stewart-assyst-user';
import {TelusService} from '../../shared-main/telus/telus-service';
import {ApplicationError} from '../../core/application-error';
import {ModalErrorComponent} from '../../shared/error-handling/modal-error/modal-error.component';
import {AppConfig} from '../../shared-main/app-configuration';
import {StaffProfilesService} from '../../admin/staff-profiles/staff-profiles.service';
import {StaffProfiles} from '../../admin/staff-profiles/staff-profiles';
import {StewartTitleUser} from '../../shared-main/stewart-title/stewart-title-user';
import {StewartTitleService} from '../../shared-main/stewart-title/stewart-title-service';
import {StewartTitleLawyerProfileModal} from './stewart-title/stewart-title-lawyer-profile.modal.component';
import {StewartTitleAuthenticationService} from '../../shared-main/stewart-title/stewart-title-authentication-service';
import {FCTUser} from '../../shared-main/fct/fct-user';
import {FCTService} from '../../shared-main/fct/fct-service';
import {ThirdPartyType, ThirdPartyUser} from '../../shared-main/third-party/third-party-user';
import {TitlePlusUser} from '../../shared-main/title-plus/title-plus-user';
import {TitleInsuranceBaseUser} from '../../shared-main/title-insurance-base-user';
import {TitlePlusService} from '../../shared-main/title-plus/title-plus-service';
import Utils from '../../shared-main/utils';
import {titlePlusLinks} from '../../shared-main/title-plus/title-plus-constants';
import {stewartTitleLinks} from '../../shared-main/stewart-title/stewart-title-constants';
import {fctLinks} from '../../shared-main/fct/fct-constants';
import {chicagoTitleLinks} from '../../shared-main/chicago-title/chicago-title-constants';
import {ValidateUser} from './validate-user';
import {TCOLUser} from '../../shared-main/tcol/tcol-user';
import {TCOLService} from '../../shared-main/tcol/tcol-service';
import {UserStateService} from '../../shared-main/user-state/user-state.service';
import {LtsaService} from '../../shared-main/ltsa/ltsa-service';
import {PROVINCE_CODES} from '../../matters/shared/user-province';
import {LtsaUser} from '../../shared-main/ltsa/ltsa-user';
import {ManageLtsaUserModal} from './ltsa/manage-ltsa-user.modal.component';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {Permissions} from "../../core/authz/permissions";

declare var jQuery: any;

export class IntegrationSection {
  title: string;
  active: boolean;
  authOperation: Permissions;
  key: string;

  constructor(integrationSection?: IntegrationSection) {
    if (integrationSection) {
      for (let prop in integrationSection) {
        if (integrationSection.hasOwnProperty(prop)) {
          this[prop] = integrationSection[prop];
        }
      }
    }
  }

  isTeranetConnect(): boolean {
    return this.title == 'Teranet Connect';
  }

  isStewartTitle(): boolean {
    return this.title == 'Stewart Title';
  }

  isFCT(): boolean {
    return this.title == 'FCT';
  }

  isChicagoTitle(): boolean {
    return this.title == 'Chicago Title';
  }

  isStewartAssyst(): boolean {
    return this.title == 'Assyst Real Estate®';
  }

  isUnityLenderCentre(): boolean {
    return this.title == 'Unity® Lender Centre';
  }

  isTitlePlus(): boolean {
    return this.title == 'TitlePLUS';
  }

  isTaxCertificatesOnLine(): boolean {
    return this.title === 'Tax Certificates Online';
  }

  isLTSA(): boolean {
    return this.title == 'myLTSA';
  }
}

export const IntegrationSections: any[] = [
  {
    title: 'Teranet Connect',
    active: false,
    authOperation: null,
    key: 'TC'
  },
  {
    title: 'Stewart Title',
    active: false,
    authOperation: Permissions.STEWART_NEXT_STEPS_INTEGRATIONS,
    key: 'ST'
  },
  {
    title: 'FCT',
    active: false,
    authOperation: Permissions.FCT_INTEGRATIONS,
    key: 'FCT'
  },
  {
    title: 'Chicago Title',
    active: false,
    authOperation: Permissions.CHICAGO_TITLE_INTEGRATIONS,
    key: 'CT'
  },
  {
    title: 'Assyst Real Estate®',
    active: false,
    authOperation: Permissions.TELUS_ASSYST_INTEGRATIONS,
    key: 'ARE'
  },
  {
    title: 'TitlePLUS',
    active: false,
    authOperation: Permissions.TITLE_PLUS_INTEGRATIONS,
    key: 'TP'
  },
  {
    title: 'Tax Certificates Online',
    active: false,
    authOperation: Permissions.TCOL_INTEGRATIONS,
    key: 'TCOL'
  }
];

export const IntegrationSectionsLC: any[] = [
  {
    title: 'Teranet Connect',
    active: false,
    authOperation: null,
    key: 'TC'
  },
  {
    title: 'Stewart Title',
    active: false,
    authOperation: Permissions.STEWART_NEXT_STEPS_INTEGRATIONS,
    key: 'ST'
  },
  {
    title: 'FCT',
    active: false,
    authOperation: Permissions.FCT_INTEGRATIONS,
    key: 'FCT'
  },
  {
    title: 'Chicago Title',
    active: false,
    authOperation: Permissions.CHICAGO_TITLE_INTEGRATIONS,
    key: 'CT'
  },
  {
    title: 'Unity® Lender Centre',
    active: false,
    authOperation: Permissions.TELUS_ASSYST_INTEGRATIONS,
    key: 'ARE'
  },
  {
    title: 'TitlePLUS',
    active: false,
    authOperation: Permissions.TITLE_PLUS_INTEGRATIONS,
    key: 'TP'
  },
  {
    title: 'Tax Certificates Online',
    active: false,
    authOperation: Permissions.TCOL_INTEGRATIONS,
    key: 'TCOL'
  }
];

export class ManageThirdPartyCredentialsContext {
  accountId: string;
  userId: string;
  staffProfile: StaffProfiles;
  key?: string;
}

@Component({
  selector: 'dp-manage-third-party-credentials-modal',
  templateUrl: 'manage-thirdparty-credentials.modal.component.html',
  styleUrls: ['./manage-teranet-user.scss'],
  providers: [ErrorService]
})
@FocusFirstElementDecorator()
export class ManageThirdPartyCredentialsModal
  extends ModalComponent<ManageThirdPartyCredentialsContext>
  implements OnInit
{
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  @ViewChild('btnAddNew') btnAddNew: ElementRef;
  @ViewChild('btnAddNewLtsa') btnAddNewLtsa: ElementRef;
  @ViewChild('addStgUserLink') addStgUserLink: ElementRef;
  editButtonLabel: string = 'Edit';
  testLoginButtonLabel: string = 'Test Login';
  deleteButtonLabel: string = 'Delete';
  revalidateLabel: string = 'Re-Validate';
  burgerMenuItems: string[] = [this.editButtonLabel, this.testLoginButtonLabel, this.deleteButtonLabel];
  ltsaBurgerMenuItems: string[] = [this.editButtonLabel, this.deleteButtonLabel];
  burgerMenuItemsStewartTitle: string[] = [this.revalidateLabel, this.deleteButtonLabel];
  teranetUsers: TeranetUser[] = [];
  ltsaUsers: LtsaUser[] = [];
  selectedRowIndex: number = -1;
  selectedLtsaRowIndex: number = -1;
  links = teranetConnectLinks;
  integrationSections: IntegrationSection[] = [];
  stewartAssystUser: StewartAssystUser;
  fctUser: FCTUser;
  tcolUser: TCOLUser;
  reValidateStewartUser: boolean = false;

  currentStewartTitleUser: StewartTitleUser;
  solicitorStewartTitleUsers: StewartTitleUser[] = []; //all solicitors for account and their stewart users (if they exist)

  currentUserProfile: StaffProfiles;
  stewartAssystBurgerMenuItems: string[];

  currentTitlePlusUser: TitlePlusUser;
  solicitorTitlePlusUsers: TitlePlusUser[] = [];
  selectedTitlePlusUser: TitlePlusUser;
  isSelectedUserSolicitor: boolean;
  localTitlePlusLinks = titlePlusLinks;
  localStewartTitleLinks = stewartTitleLinks;
  localFctLinks = fctLinks;
  localChicagoTitleLinks = chicagoTitleLinks;
  validateUser: ValidateUser;

  constructor(
    public dialog: MatDialogRef<ManageThirdPartyCredentialsModal>,
    public logger: Logger,
    public errorService: ErrorService,
    public teranetService: TeranetService,
    public ltsaService: LtsaService,
    public telusService: TelusService,
    public fctService: FCTService,
    public tcolService: TCOLService,
    public stewartTitleService: StewartTitleService,
    public appConfig: AppConfig,
    public dialogService: DialogService,
    public staffProfilesService: StaffProfilesService,
    public stewartTitleAuthenticationService: StewartTitleAuthenticationService,
    public titlePlusService: TitlePlusService,
    public userStateService: UserStateService,
    @Inject(MAT_DIALOG_DATA) context?: ManageThirdPartyCredentialsContext
  ) {
    super(dialog, context);
  }

  isRowSelected(index: number): boolean {
    return this.selectedRowIndex === index;
  }

  updateRowIndex(index: number): void {
    this.selectedRowIndex = index;
  }

  isLtsaRowSelected(index: number): boolean {
    return this.selectedLtsaRowIndex === index;
  }

  updateLtsaRowIndex(index: number): void {
    this.selectedLtsaRowIndex = index;
  }

  ngOnInit() {
    this.validateUser = new ValidateUser();
    this.stewartAssystBurgerMenuItems = this.getStewartAssystBurgerMenuItems();
    this.integrationSections = IntegrationSectionsLC.filter((x) => x.key !== (this.userStateService.isOnlyBCEnabled() ? 'TP' : ''))
      .map((integrationSection) => {
        return new IntegrationSection(integrationSection);
      });

    this.currentUserProfile = this.context.staffProfile;
    // Test code
    // this.currentUserProfile.user.userProvinces = [];
    if (this.currentUserProfile && this.currentUserProfile.user && !this.currentUserProfile.user.isOntarioIncluded()) {
      this.integrationSections = this.integrationSections.filter((integrationSection) => {
        if (
          !integrationSection.isTeranetConnect() &&
          !integrationSection.isTaxCertificatesOnLine() &&
          !integrationSection.isTitlePlus()
        ) {
          return integrationSection;
        }
      });
    }
    this.addLtsaIntegrationSection();

    if (!!this.context.key) {
      let integrationSection = this.integrationSections.find((item) => item.key == this.context.key);
      if (integrationSection) {
        integrationSection.active = true;
      } else {
        this.integrationSections[0].active = true;
      }
    } else {
      this.integrationSections[0].active = true;
    }

    if (this.activeIntegrationSection && this.activeIntegrationSection.key == 'TC') {
      this.createTeranetUser();
    }
    if (this.activeIntegrationSection && this.activeIntegrationSection.key == 'ARE') {
      this.createTelusUser();
    }
    if (this.activeIntegrationSection && this.activeIntegrationSection.key == 'FCT') {
      this.createFCTUser();
    }
    if (this.activeIntegrationSection && this.activeIntegrationSection.key == 'ST') {
      this.createStewartTitleUser();
    }
    if (this.activeIntegrationSection && this.activeIntegrationSection.key == 'TP') {
      this.createTitlePlusUser();
    }
    if (this.activeIntegrationSection && this.activeIntegrationSection.key === 'TCOL') {
      this.createTCOLUser();
    }
    if (this.activeIntegrationSection && this.activeIntegrationSection.key === 'LTSA') {
      this.createLTSAUser();
    }
  }

  createTeranetUser() {
    this.teranetService.getTeranetUsers().subscribe((teranetUsers: TeranetUser[]) => {
      this.teranetUsers = teranetUsers;
      if (this.currentUserProfile && this.currentUserProfile.user && this.currentUserProfile.user.isOntarioIncluded()) {
        this.setFocusOnTeranetTab();
      }
    });
  }

  addLtsaIntegrationSection() {
    if (this.appConfig.isUnityBCConveyancingEnabled) {
      if (
        this.currentUserProfile &&
        this.currentUserProfile.user &&
        this.currentUserProfile.user.isProvinceIncluded(PROVINCE_CODES.BRITISH_COLOMBIA)
      ) {
        let ltsaSection = new IntegrationSection({
          title: 'myLTSA',
          active: false,
          authOperation: null,
          key: 'LTSA'
        } as IntegrationSection);
        this.integrationSections.push(ltsaSection);
      }
    }
  }

  createLTSAUser() {
    if (this.appConfig.isUnityBCConveyancingEnabled) {
      this.ltsaService.getUsers().subscribe((ltsaUsers: LtsaUser[]) => {
        this.ltsaUsers = ltsaUsers;
        if (this.currentUserProfile?.user?.isProvinceIncluded(PROVINCE_CODES.BRITISH_COLOMBIA)) {
          this.setFocusOnLtsaTab();
        }
      });
    }
  }

  createTitlePlusUser() {
    this.titlePlusService.getTitlePlusUser().subscribe(
      (titlePlusUser: TitlePlusUser) => {
        this.currentTitlePlusUser = titlePlusUser;
        if (
          this.currentUserProfile &&
          this.currentUserProfile.user &&
          !this.currentUserProfile.user.isOntarioIncluded()
        ) {
          this.focusTitlePlusAddUserLink();
        }
      },
      (error: ApplicationError) => {
        if (error && error.errorCode === 'app.titlePlusUserNotFound') {
          this.currentTitlePlusUser = new TitlePlusUser();
          if (
            this.currentUserProfile &&
            this.currentUserProfile.user &&
            !this.currentUserProfile.user.isOntarioIncluded()
          ) {
            this.focusTitlePlusAddUserLink();
          }
        }
      }
    );

    this.titlePlusService.getSolicitorTitlePlusUsers().subscribe(
      (titlePlusUsers: TitlePlusUser[]) => {
        this.solicitorTitlePlusUsers = titlePlusUsers;
      },
      (error: ApplicationError) => {
        console.log('error retrieving titleplus users');
      }
    );
  }

  createTelusUser() {
    this.telusService.getTelusUser().subscribe(
      (telusUser: StewartAssystUser) => {
        this.stewartAssystUser = telusUser;
      },
      (error: ApplicationError) => {
        if (error && error.errorCode === 'app.stewartAssystUserNotFound') {
          this.stewartAssystUser = new StewartAssystUser();
          this.stewartAssystUser.instanceType = 'STEWART_ASSYST';
        }
      }
    );
  }

  createStewartTitleUser() {
    this.stewartTitleService.getStewartTitleUser().subscribe(
      (stewartUser: StewartTitleUser) => {
        this.currentStewartTitleUser = stewartUser;
        if (
          this.currentUserProfile &&
          this.currentUserProfile.user &&
          !this.currentUserProfile.user.isOntarioIncluded()
        ) {
          this.focusStgAddUserLink();
        }
      },
      (error: ApplicationError) => {
        if (error && error.errorCode === 'app.stewartTitleUserNotFound') {
          this.currentStewartTitleUser = new StewartTitleUser();
          if (
            this.currentUserProfile &&
            this.currentUserProfile.user &&
            !this.currentUserProfile.user.isOntarioIncluded()
          ) {
            this.focusStgAddUserLink();
          }
        }
      }
    );

    this.stewartTitleService.getSolicitorStewartTitleUsers().subscribe(
      (stewartUsers: StewartTitleUser[]) => {
        this.solicitorStewartTitleUsers = stewartUsers;
      },
      (error: ApplicationError) => {
        console.log('error retrieving stg users');
      }
    );
  }

  createFCTUser() {
    this.fctService.getFCTUser().subscribe(
      (fctUser: StewartAssystUser) => {
        this.fctUser = fctUser;
      },
      (error: ApplicationError) => {
        if (error && error.errorCode === 'app.fctUserNotFound') {
          this.fctUser = new FCTUser();
          this.fctUser.instanceType = 'FCT';
        }
      }
    );
  }

  createTCOLUser() {
    this.tcolService.getTCOLUser().subscribe(
      (tcolUser: TCOLUser) => {
        this.tcolUser = tcolUser;
      },
      (error: ApplicationError) => {
        if (error && error.errorCode === 'app.tcolUserNotFound') {
          this.tcolUser = new TCOLUser();
          this.tcolUser.instanceType = 'TCOL';
        }
      }
    );
  }

  getStewartTitleUsersWithCredentials(): StewartTitleUser[] {
    return this.solicitorStewartTitleUsers.filter((user) => user.id);
  }

  isSolicitorStewartProfiles(): boolean {
    return (
      Array.isArray(this.getStewartTitleUsersWithCredentials()) && this.getStewartTitleUsersWithCredentials().length > 0
    );
  }

  getTitlePlusUsersWithCredentials(): TitlePlusUser[] {
    return this.solicitorTitlePlusUsers.filter((user) => user.id);
  }

  isTitlePlusProfilesExistForSolicitors(): boolean {
    return Array.isArray(this.getTitlePlusUsersWithCredentials()) && this.getTitlePlusUsersWithCredentials().length > 0;
  }

  getStewartAssystBurgerMenuItems(): string[] {
    let burgerMenuItems: string[] = [];
    burgerMenuItems.push('My Profile');
    burgerMenuItems.push('Messages');
    burgerMenuItems.push('Re-Validate');
    burgerMenuItems.push('Delete');
    return burgerMenuItems;
  }

  addStewartTitleUser() {
    if (this.currentUserProfile.isLawyer) {
      this.openStewartTitleLawyerProfile();
    } else {
      this.authenticateStewartTitleUser(this.currentStewartTitleUser, false);
    }
  }

  addTitlePlusUser() {
    if (this.currentUserProfile.isLawyer) {
      this.openTitlePlusLawyerProfile();
    } else {
      this.authenticateTitlePlusUser(this.currentTitlePlusUser, false);
    }
  }

  authenticateTitlePlusUser(titlePlusUser: TitlePlusUser, isSolicitor: boolean): void {
    this.titlePlusService.getTitlePlusAuthUrl().subscribe((url) => {
      this.selectedTitlePlusUser = titlePlusUser;
      this.isSelectedUserSolicitor = isSolicitor;
      window.addEventListener('storage', this.processTitlePlusResponse);
      window.open(url, '_blank');
    });
  }

  processTitlePlusResponse = (ev) => {
    if (ev.key == 'TPAuthorizationResponseUrl') {
      window.removeEventListener('storage', this.processTitlePlusResponse);
      let redirectUrl = JSON.parse(ev.newValue);
      localStorage.removeItem('TPAuthorizationResponseUrl');
      this.processTitlePlusAuthenticationResponse(redirectUrl);
    }
  };

  processTitlePlusAuthenticationResponse(redirectUrl) {
    const accessCode = Utils.extractQueryParamFromUrl(redirectUrl, 'code');
    if (accessCode) {
      this.selectedTitlePlusUser.tempCode = accessCode;
      this.titlePlusService.saveTitlePlusUser(this.selectedTitlePlusUser).subscribe((savedUser: TitlePlusUser) => {
        if (this.isSelectedUserSolicitor) {
          this.selectedTitlePlusUser.id = savedUser.id;
        } else {
          this.currentTitlePlusUser = savedUser;
        }
        this.validateUser.isTitlePlusUserValidated = true;
        this.validateUser.usersValidated.push(this.selectedTitlePlusUser.id);
      });
    } else {
      this.modalErrorComponent.createCustomDPSaveError(
        'none',
        'TitlePLUS cannot be persisted because' + ' invalid re-direction to ' + redirectUrl,
        '',
        'ERROR'
      );
    }
  }

  authenticateStewartTitleUser(stewartTitleUser: StewartTitleUser, isSolicitor: boolean): void {
    this.stewartTitleAuthenticationService.openStewartTitleAuthentication(stewartTitleUser).subscribe((result) => {
      if (result && result.refreshToken) {
        stewartTitleUser.refreshToken = result.refreshToken;
        stewartTitleUser.accessToken = result.accessToken;
        this.stewartTitleAuthenticationService
          .saveStewartTitleUser(stewartTitleUser)
          .subscribe((savedUser: StewartTitleUser) => {
            if (isSolicitor) {
              stewartTitleUser.id = savedUser.id;
            } else {
              this.currentStewartTitleUser = savedUser;
            }
          });
      }
    });
  }

  deleteTitlePlusUser(titlePlusUser: TitlePlusUser, isSolicitor: boolean): void {
    if (titlePlusUser && titlePlusUser.id) {
      this.dialogService
        .confirm(
          'Confirmation',
          'Are you sure you would like to delete your TitlePLUS' +
            ' credentials from your Unity User?<br><br>Please note you will no longer be able to communicate' +
            ' with TitlePLUS in Unity.<br><br>In order to communicate with' +
            ' TitlePLUS, you must associate your credentials.',
          false,
          'Delete'
        )
        .subscribe((res) => {
          if (res) {
            this.titlePlusService.deleteTitlePlusUser(titlePlusUser).subscribe((response: any) => {
              if (isSolicitor) {
                titlePlusUser.id = null;
                titlePlusUser.profileId = null;
              } else {
                this.currentTitlePlusUser = new TitlePlusUser();
              }

              this.focusTitlePlusAddUserLink();
            });
          }
        });
    }
  }

  deleteStewartTitleUser(stewartTitleUser: StewartTitleUser, isSolicitor: boolean): void {
    if (stewartTitleUser && stewartTitleUser.id) {
      this.dialogService
        .confirm(
          'Confirmation',
          'Are you sure you would like to delete your Stewart Title credentials from your Unity' +
            ' User?<br><br>Please note you will no longer be able to communicate with Stewart Title in Unity.<br><br>In order to communicate with' +
            ' Stewart Title, you must associate your credentials.',
          false,
          'Delete'
        )
        .subscribe((res) => {
          if (res) {
            this.stewartTitleService.deleteStewartTitleUser(stewartTitleUser).subscribe((response: any) => {
              if (isSolicitor) {
                stewartTitleUser.id = null;
                stewartTitleUser.profileId = null;
              } else {
                this.currentStewartTitleUser = new StewartTitleUser();
              }

              this.focusStgAddUserLink();
            });
          }
        });
    }
  }

  openStewartTitleLawyerProfile(stewartTitleUserForReValidation?: StewartTitleUser): void {
    let solicitorsWithoutCredentials: StewartTitleUser[] = this.solicitorStewartTitleUsers.filter((user) => !user.id);
    this.openLawyerProfileModal(
      solicitorsWithoutCredentials,
      this.stewartTitleProceedAuthFunction,
      stewartTitleUserForReValidation
    );
  }

  openTitlePlusLawyerProfile(titlePlusUserForReValidation?: TitlePlusUser): void {
    let solicitorsWithoutCredentials: TitlePlusUser[] = this.solicitorTitlePlusUsers.filter((user) => !user.id);
    this.openLawyerProfileModal(
      solicitorsWithoutCredentials,
      this.titlePlusProceedAuthFunction,
      titlePlusUserForReValidation
    );
  }

  titlePlusProceedAuthFunction = (solicitor: TitlePlusUser) => {
    this.authenticateTitlePlusUser(solicitor, true);
  };

  stewartTitleProceedAuthFunction = (solicitor: StewartTitleUser) => {
    this.authenticateStewartTitleUser(solicitor, true);
  };

  openLawyerProfileModal(
    solicitorsWithoutCredentials: TitleInsuranceBaseUser[],
    proceedAuthenticationFunction: Function,
    titleUserForReValidation?: TitleInsuranceBaseUser
  ): void {
    this.dialogService.matDialogContent({
      content: StewartTitleLawyerProfileModal,
      context: {
        stewartTitleUser: this.currentStewartTitleUser,
        stewartTitleUserForReValidation: titleUserForReValidation,
        solicitors: solicitorsWithoutCredentials,
        currentUserProfile: this.currentUserProfile,
        currentInsurer: this.activeIntegrationSection
      },
      onFulfillment: (result: any) => {
        if (result && result.action == 'proceedStewartTitleAuth' && result.solicitor) {
          proceedAuthenticationFunction(result.solicitor);
        }
      },
      modalGrid: 5
    });
  }

  close(): void {
    this.dialog.close({validateUser: this.validateUser});
  }

  get isEnabled(): boolean {
    return this.stewartAssystUser &&
      this.stewartAssystUser.password &&
      this.stewartAssystUser.userName &&
      this.stewartAssystUser.userName.trim().length > 0 &&
      this.stewartAssystUser.password.trim().length > 0
      ? true
      : false;
  }

  onKeyPress(event: KeyboardEvent) {
    if (
      event.keyCode === 40 || //Down arrow key
      event.keyCode === 38 || //Up arrow key
      event.keyCode === 13 || //Enter key
      event.keyCode == 46 //Delete Key
    ) {
      this.handleArrowKeys(event);
    }
  }

  handleArrowKeys(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.keyCode === 40) {
      //Down arrow key
      if (this.selectedRowIndex < this.teranetUsers.length) {
        this.selectedRowIndex++;
      }
    } else if (event.keyCode === 38) {
      //Up arrow key
      if (this.selectedRowIndex > 0) {
        this.selectedRowIndex--;
      }
    } else if (event.keyCode === 13) {
      this.updateTeranetUser('Edit', this.teranetUsers[this.selectedRowIndex]);
    } else if (event.keyCode === 46) {
      this.deleteTeranetUser(this.teranetUsers[this.selectedRowIndex]);
    }
  }

  moveToNextRow(): void {
    setTimeout(() => {
      if (this.selectedRowIndex < this.teranetUsers.length) {
        this.selectedRowIndex++;
      }
    }, 0);
  }

  ltsaMoveToNextRow(): void {
    setTimeout(() => {
      if (this.selectedLtsaRowIndex < this.ltsaUsers.length) {
        this.selectedLtsaRowIndex++;
      }
    }, 0);
  }

  selectFirstRow(): void {
    setTimeout(() => {
      this.selectedRowIndex = 0;
    }, 0);
  }

  burgerMenuClicked(clickedMenuOption: string, index: number, teranetUser: TeranetUser): void {
    switch (clickedMenuOption) {
      case this.editButtonLabel:
        this.updateTeranetUser('Edit', teranetUser);
        break;
      case this.deleteButtonLabel:
        this.deleteTeranetUser(teranetUser);
        break;
      case this.testLoginButtonLabel:
        this.testCredentials(teranetUser);
        break;
      default:
        return;
    }
  }

  ltsaBurgerMenuClicked(clickedMenuOption: string, index: number, ltsaUser: LtsaUser): void {
    switch (clickedMenuOption) {
      case this.editButtonLabel:
        this.updateLtsaUser('Edit', ltsaUser);
        break;
      case this.deleteButtonLabel:
        this.deleteLtsaUser(ltsaUser);
        break;
      default:
        return;
    }
  }

  burgerMenuClickedStewartTitle(clickedMenuOption: string, index: number, stewartTitleUser: StewartTitleUser): void {
    switch (clickedMenuOption) {
      case this.revalidateLabel:
        this.openStewartTitleLawyerProfile(stewartTitleUser);
        break;
      case this.deleteButtonLabel:
        this.deleteStewartTitleUser(stewartTitleUser, true);
        break;
      default:
        return;
    }
  }

  burgerMenuClickedTitlePlus(clickedMenuOption: string, index: number, titlePlusUser: TitlePlusUser): void {
    switch (clickedMenuOption) {
      case this.revalidateLabel:
        this.openTitlePlusLawyerProfile(titlePlusUser);
        break;
      case this.deleteButtonLabel:
        this.deleteTitlePlusUser(titlePlusUser, true);
        break;
      default:
        return;
    }
  }

  deleteTeranetUser(teranetUser: TeranetUser): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this credential?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          let index: number = _.findIndex(this.teranetUsers, (t) => t === teranetUser);
          this.teranetService.deleteTeranetUser(teranetUser).subscribe((response: any) => {
            this.teranetUsers.splice(index, 1);
          });
        }
      });
  }

  deleteLtsaUser(ltsaUser: LtsaUser): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this credential?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          let index: number = _.findIndex(this.ltsaUsers, (t) => t === ltsaUser);
          this.ltsaService.deleteLtsaUser(ltsaUser).subscribe((response: any) => {
            this.ltsaUsers.splice(index, 1);
          });
        }
      });
  }

  testCredentials(teranetUser: TeranetUser): void {
    this.dialogService.matDialogContent({
      content: TestTeranetUserCredentialModal,
      context: {
        teranetUser: teranetUser,
        accountId: this.context.accountId,
        userId: this.context.userId
      },
      onFulfillment: (result: any) => {
        if (result && result.action == 'PASSWORD_EXPIRED' && result.teranetUser) {
          this.openChangePasswordModal(result.teranetUser);
        } else if (result && result.action == 'Success') {
          this.dialogService.confirm(
            'Information',
            'Your Teranet Connect credentials have been successfully validated.',
            true
          );
        }
      },
      modalGrid: 5
    });
  }

  openChangePasswordModal(teranetUser: TeranetUser) {
    this.dialogService.matDialogContent({
      content: TeranetChangePasswordModal,
      context: {
        teranetUser: teranetUser
      },
      onFulfillment: (result: any) => {
        if (result && result.teranetUser) {
          this.dialogService
            .confirm('Teranet Connect &trade;: Change Password ', 'Your password was successfully updated.', true, 'OK')
            .subscribe((res) => {});
        }
      }
    });
  }

  addTeranetUser() {
    let teranetUser: TeranetUser = new TeranetUser();
    this.updateTeranetUser('Add', teranetUser);
  }

  updateTeranetUser(action: ManageTeranetUserAction, teranetUser: TeranetUser) {
    this.dialogService.matDialogContent({
      content: ManageTeranetUserModal,
      context: {
        action: action,
        teranetUser: teranetUser,
        accountId: this.context.accountId,
        userId: this.context.userId
      },
      onFulfillment: (result: any) => {
        if (result && result.action === 'OK') {
          teranetUser = result.teranetUser;
          if (action === 'Add') {
            this.teranetUsers.push(teranetUser);
          } else {
            let index = this.teranetUsers.findIndex((item) => item.id === teranetUser.id);
            if (index !== -1) {
              this.teranetUsers[index] = teranetUser;
            }
          }
        }
      },
      modalGrid: 5
    });
  }

  addLtsaUser() {
    let ltsaUser: LtsaUser = new LtsaUser();
    this.updateLtsaUser('Add', ltsaUser);
  }

  updateLtsaUser(action: ManageTeranetUserAction, ltsaUser: LtsaUser) {
    this.dialogService.matDialogContent({
      content: ManageLtsaUserModal,
      context: {
        action: action,
        ltsaUser: ltsaUser,
        userId: this.context.userId
      },
      onFulfillment: (result: any) => {
        if (result && result.action === 'OK') {
          ltsaUser = result.ltsaUser;
          if (action === 'Add') {
            this.ltsaUsers.push(ltsaUser);
          } else {
            let index = this.ltsaUsers.findIndex((item) => item.id === ltsaUser.id);
            if (index !== -1) {
              this.ltsaUsers[index] = ltsaUser;
            }
          }
        }
      },
      modalGrid: 5
    });
  }

  get activeIntegrationSection(): IntegrationSection {
    return this.integrationSections.find((item) => item.active);
  }

  openIntegrationSection(integrationSection: IntegrationSection): void {
    this.integrationSections.map((item) => {
      item.active = false;
      return item;
    });
    integrationSection.active = true;
    if (integrationSection.isTeranetConnect()) {
      this.createTeranetUser();
    } else if (integrationSection.isStewartAssyst() || integrationSection.isUnityLenderCentre()) {
      this.createTelusUser();
    } else if (integrationSection.isFCT()) {
      this.createFCTUser();
    } else if (integrationSection.isStewartTitle()) {
      this.createStewartTitleUser();
    } else if (integrationSection.isTitlePlus()) {
      this.createTitlePlusUser();
    } else if (integrationSection.isTaxCertificatesOnLine()) {
      this.createTCOLUser();
    } else if (integrationSection.isLTSA()) {
      this.createLTSAUser();
    }
  }

  focusTitlePlusAddUserLink(): void {
    setTimeout(() => {
      if (
        this.currentUserProfile &&
        !this.currentUserProfile.isLawyer &&
        this.currentTitlePlusUser &&
        !this.currentTitlePlusUser.id
      ) {
        jQuery('#addTPUserLink').focus();
      } else {
        jQuery('#btnTitlePlusAdd').focus();
      }
    }, 400);
  }

  focusStgAddUserLink(): void {
    setTimeout(() => {
      if (
        this.currentUserProfile &&
        !this.currentUserProfile.isLawyer &&
        this.currentStewartTitleUser &&
        !this.currentStewartTitleUser.id
      ) {
        jQuery('#addStgUserLink').focus();
      } else {
        jQuery('#btnStewartAdd').focus();
      }
    }, 400);
  }

  updateThirdPartyUserReference(thirdPartyUser: ThirdPartyUser, thirdPartyType: ThirdPartyType): void {
    if (thirdPartyType == 'FCT') {
      this.fctUser = thirdPartyUser;
    } else if (thirdPartyType === 'TCOL') {
      this.tcolUser = thirdPartyUser;
    } else {
      this.stewartAssystUser = thirdPartyUser;
    }
  }

  setFocusOnTeranetTab(): void {
    if (this.teranetUsers.length > 0) {
      this.selectedRowIndex = 0;
      setTimeout(() => {
        jQuery('.active.dp-teranet-row-active').focus();
      }, 400);
    } else {
      setTimeout(() => {
        this.btnAddNew.nativeElement.focus();
      }, 0);
    }
  }

  setFocusOnLtsaTab(): void {
    if (this.ltsaUsers.length > 0) {
      this.selectedLtsaRowIndex = 0;
      setTimeout(() => {
        jQuery('.active.dp-teranet-row-active').focus();
      }, 400);
    } else {
      setTimeout(() => {
        this.btnAddNewLtsa.nativeElement.focus();
      }, 0);
    }
  }

  closeKeyCommands(event: KeyboardEvent): void {
    if (!event.shiftKey && event.keyCode === 9 && this.activeIntegrationSection.isTeranetConnect()) {
      // (No Shift)-Tab on teranet tab
      event.preventDefault();
      setTimeout(() => {
        jQuery('#teranetLinkMyTeranet').focus();
      }, 0);
    }
    if (event.shiftKey && event.keyCode === 9 && this.activeIntegrationSection.isTeranetConnect()) {
      // Shift-Tab on teranet tab
      event.preventDefault();
      this.setFocusOnTeranetTab();
    }
    if (
      !event.shiftKey &&
      event.keyCode === 9 &&
      (this.activeIntegrationSection.isFCT() || this.activeIntegrationSection.isStewartAssyst())
    ) {
      // (No Shift)-Tab on FCT or Assyst/Unity® Lender Centre tab
      event.preventDefault();
      setTimeout(() => {
        jQuery('#thirdPartyUserName').focus();
      }, 0);
    }
  }

  linkMyTeranetKeyCommands(event: KeyboardEvent): void {
    if (event.shiftKey && event.keyCode === 9) {
      // Shift-Tab
      event.preventDefault();
      setTimeout(() => {
        jQuery('#btnClose').focus();
      }, 0);
    }
  }

  ngAfterViewInit() {}
}
