<!--<form class="form-horizontal" id="purchaseParcelRegistersForm" #purchaseParcelRegistersForm="ngForm">-->
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Add / Search Parcel Register</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="close()">&times;</button>
            </div>

        </div>

        <div class="row modal-body"
             [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">
            <div class="fields-group margin-top-0">
                <div class="form-group">
                    <div class="main-level fixed-header-accessGroup">
                        <span class="title font-20">Teranet Connect&trade;: Associate Matter to Teraview <sup class="font-20">&reg;</sup> Docket</span>
                    </div>
                </div>

                <div class="form-group col-lg-12 padding-bottom-20">
                    <span class="radio display-inline" (click)="toggleSearch('#docketID')">
                                  <input type="radio" [checked]="!searchDocket" >
                                  <label>Create Docket</label>
                    </span>
                    <span class="radio padding-left-20 display-inline" (click)="toggleSearch('#searchValue')" >
                                  <input type="radio" [checked]="searchDocket" >
                                  <label>Search Docket</label>
                    </span>
                </div>

                <div class="form-group col-lg-12" *ngIf="!searchDocket">
                    <label class="control-label col-lg-3 text-right padding-top-10" for="docketID" >Teraview Docket ID: </label>
                    <div class="col-lg-6">
                        <input type="text" id="docketID" name="docketID"
                               [(ngModel)]="teranetDocket.teraviewDocketIdentifier"
                               dp-error-validator
                               [fieldKey]="'teranet.createDocket.docketIdAndName'"
                               statusBarHelp
                               (keydown.f9)="handleF9DocketId()"
                               maxlength="10"
                               dp-uppercase
                               [dynamicHelpCallBackFunction] = "generateF9HelpDocketId()"
                               class="form-control "  >
                    </div>

                </div>

                <div class="form-group col-lg-12" *ngIf="!searchDocket">
                    <label class="control-label col-lg-3 text-right padding-top-10" for="docketID" >Teraview Docket Name: </label>
                    <div class="col-lg-6">
                        <input type="text" id="docketName" name="docketName"
                               [(ngModel)]="teranetDocket.teraviewDocketName"
                               dp-error-validator
                               statusBarHelp
                               [fieldKey]="'teranet.createDocket.docketIdAndName'"
                               (keydown.f9)="handleF9DocketName()"
                               [dynamicHelpCallBackFunction] = "generateF9HelpDocketName()"
                               dp-uppercase
                               maxlength="50"
                               class="form-control "  >
                    </div>

                </div>

                <div class="form-group" *ngIf="searchDocket">
                    <div class="main-level fixed-header-accessGroup">
                        <span class="title">
                            <i class="fa fa-info-circle font-20"  aria-hidden="false"></i>
                            &nbsp;Only the first 500 dockets will be listed and depending on your
                            internet connection speed,
                            this may take a period of time
                        </span>
                    </div>
                </div>

                <div class="form-group col-lg-12 padding-bottom-40 margin-bottom-100" *ngIf="searchDocket">
                    <label class="control-label col-lg-3 text-right padding-top-10" for="searchValue" >Teraview Docket ID or Name:{{searchValue}} </label>
                    <div class="col-lg-6">
                        <input type="text" id="searchValue" name="searchValue"
                               [(ngModel)]="teranetDocketRequest.searchValue"
                               dp-uppercase
                               placeholder="Partial Teraview Docket ID or Name"
                               class="form-control "  >
                    </div>
                    <div class="col-lg-2 matter-component action-buttons"  >
                        <div class="dropdown" [class.active]="actionButtonFlag"  >
                            <button class="dp-btn-white" id="searchBtn" name="searchBtn"
                                    (click)="openSearchMenu($event)"
                                    (blur)="resetActionButtonFlag()"
                                    (keyDown.arrowup)="handleSearchArrowUp($event)"
                                    (keyDown.arrowdown)="handleSearchArrowDown($event)" >
                                <i class="fa fa-bars"  aria-hidden="false"></i>
                                <span>Search</span>
                            </button>
                            <div class="dropdown-matter-type-list select-button-list searchBy" >

                                <div class="dropdown-content"
                                     (mouseenter)="actionButtonFlag = true; dropDownIndex = 0;"
                                     (mousedown)="searchTeranetDocket('DOCKET_ID',teranetDocketRequest.searchValue +'*')"
                                    >
                                    <button [ngClass]="dropDownIndex == 0 ?'selected': '' ">By Docket ID</button>
                                </div>
                                <div class="dropdown-content"
                                     (mouseenter)="actionButtonFlag = true; dropDownIndex = 1;"
                                     (mousedown)="searchTeranetDocket('DOCKET_NAME',teranetDocketRequest.searchValue +'*')">
                                    <button class="border-bottom-1" [ngClass]="dropDownIndex == 1 ?'selected': '' ">By Docket Name</button>
                                </div>
                                <div class="dropdown-content"
                                     (mouseenter)="actionButtonFlag = true; dropDownIndex = 2;"
                                     (mousedown)="searchTeranetDocket('DOCKET_NAME','*')">
                                    <button [ngClass]="dropDownIndex == 2 ?'selected': '' ">View Full Docket list by Name</button>
                                </div>
                                <div class="dropdown-content"
                                     (mouseenter)="actionButtonFlag = true; dropDownIndex = 3;"
                                     (mousedown)="searchTeranetDocket('DOCKET_ID','*')">
                                    <button [ngClass]="dropDownIndex == 3 ?'selected': '' ">View Full Docket list by ID</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group" *ngIf="searchDocket && searchDocketResult.length > 0 ">
                    <div class="main-level fixed-header-accessGroup">
                        <span class="title">
                            Search results for Teraview Docket {{teranetDocketRequest.searchType == 'DOCKET_ID' ? 'ID' : 'Name' }} {{teranetDocketRequest.searchValue}}: {{searchDocketResult.length}} Dockets found
                        </span>
                    </div>
                </div>


                <div class="view-table" *ngIf=" searchDocket && searchDocketResult.length > 0 ">
                    <div class="col-lg-12">
                        <table class="table dp-teranet-table" tabindex="0" (keydown)="onKeyPress($event)">
                            <thead>
                            <tr>
                                <th style="width:25%">ID</th>
                                <th style="width:50%">Name</th>
                                <th style="width:25%">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let row of searchDocketResult;let i=index;" [ngClass]="isRowSelected(i)? 'dp-teranet-row-active': 'dp-teranet-row'"
                                (click)="updateSelectedItem(i)"
                                (dblclick)="onSelectTeranetDocket()">
                                <td >
                                    {{row.teraviewDocketIdentifier}}
                                </td>
                                <td>
                                    {{row.teraviewDocketName}}
                                </td>
                                <td>
                                    {{row.docketStatus}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>






            </div>
        </div>
    </div>


    <div class="row modal-footer ">
        <div class="col-lg-5 text-left">
            <button class="dp-btn dp-btn-cancel" type="button"  (click)="close()"  >
                Back to Select Property(s)
            </button>
        </div>
        <div class="col-lg-7  text-left">
            <button *ngIf="!searchDocket" id="okBtn1" class="dp-btn keyboard-save-action" type="button" (click)="createDocket()"  >
                Create
            </button>
            <button *ngIf="searchDocket" id="okBtn2" class="dp-btn keyboard-save-action" type="button" (click)="onSelectTeranetDocket()"  >
                Select
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </div>

    <dp-modal-error #modalErrorComponent></dp-modal-error>
    <div class="col-lg-12" *ngIf="!searchDocket">
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </div>

<!--</form>-->


