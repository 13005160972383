import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {EFormType} from './alto-eform-request';

export class AltoEFormResponse extends BaseEntity {
  returnCode: string;
  eFormIdentifier: string;
  documentId: number;
  documentTypeDescription: string;
  documentType: string;
  lastUpdated: string;
  errors: string[];
  eFormType: EFormType;
}
