import {Injectable} from '@angular/core';

/**
 *  This service provides ability to lock screen
 **/
@Injectable()
export class LockScreenService {

  private lockCounter: number = 0;

  get lockForUpdate(): boolean {
    return (this.lockCounter > 0);
  }

  set lockForUpdate(value: boolean) {
    if (value) {
      this.lockCounter++;
    } else if (this.lockCounter > 0) {
      this.lockCounter--;
    }
  }

  /**
   * This method is used to remove the lock screen even if there are some backend calls are in progress
   */
  forceUnlock(): void {
    this.lockCounter = 0;
  }
}
