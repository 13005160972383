import * as _ from 'lodash';
import {DataPropagationCommand} from '../../../../projects/shared/data-propagation-commands';

export class UnitConnectDataPropagation {

  unitConnectImportDataPropagationCommands: DataPropagationCommand[] = [];

  constructor() {
  }

  addToDataPropagationCommands(type: string, command?: any, onlyOneNeededForSameCmdType: boolean = false, parameter?: any, deferredAfterMatterSave?: boolean): void {
    let dataPropagationCommand = new DataPropagationCommand();
    dataPropagationCommand.type = type;
    dataPropagationCommand.command = command ? command : null;
    dataPropagationCommand.parameter = parameter;
    dataPropagationCommand.deferredAfterMatterSave = deferredAfterMatterSave;
    if (onlyOneNeededForSameCmdType) {
      const sameCmdIdx: number = this.unitConnectImportDataPropagationCommands.findIndex(cmd => cmd.type == type && (!parameter || !cmd.parameter || (parameter && cmd.parameter && parameter == cmd.parameter)));
      if (sameCmdIdx > -1) {
        return;
      }
    }
    this.unitConnectImportDataPropagationCommands.push(dataPropagationCommand);
  }

  removeDataPropagationCommand(type: string, parameter?: any) {
    if (Array.isArray(this.unitConnectImportDataPropagationCommands)) {
      if (parameter) {
        _.remove(this.unitConnectImportDataPropagationCommands, (dpc: DataPropagationCommand) => dpc.type === type && dpc.parameter == parameter);

      } else {
        _.remove(this.unitConnectImportDataPropagationCommands, (dpc: DataPropagationCommand) => dpc.type === type);
      }
    }
  }
}

export const UnitConnectDataPropagationCmdType = {
  CIRF_FI_REMOVE_BROKER: 'CIRF_FI_REMOVE_BROKER',
  CIRF_MD_ON_MORTGAGE_BROKER_CHANGE: 'CIRF_MD_ON_MORTGAGE_BROKER_CHANGE',
  CIRF_MD_ADD_MORTGAGEE: 'CIRF_MD_ADD_MORTGAGEE',
  CIRF_MD_REMOVE_MORTGAGEE: 'CIRF_MD_REMOVE_MORTGAGEE',
  CIRF_REFERRAL_IMPORT_MAIN_CLIENT: 'CIRF_REFERRAL_IMPORT_MAIN_CLIENT',
  CIRF_IMPORT_PRE_CLOSING_ADDRESS: 'CIRF_IMPORT_PRE_CLOSING_ADDRESS',
  REFERRAL_IMPORT_OTHER_SIDE_CLIENT: 'REFERRAL_IMPORT_OTHER_SIDE_CLIENT',
  REFERRAL_MP_SOLICITOR_CHANGE: 'REFERRAL_MP_SOLICITOR_CHANGE',
  REFERRAL_IMPORT_CLOSING_DATE: 'REFERRAL_IMPORT_CLOSING_DATE',
  REFERRAL_IMPORT_SALE_PRICE: 'REFERRAL_IMPORT_SALE_PRICE',
  REFERRAL_IMPORT_DEPOSIT: 'REFERRAL_IMPORT_DEPOSIT',
  REFERRAL_IMPORT_MORTGAGE_TERM_PRINCIPAL: 'REFERRAL_IMPORT_MORTGAGE_TERM_PRINCIPAL',
  REFERRAL_IMPORT_COMMISSION_BEFORE_HST_CHANGE: 'REFERRAL_IMPORT_COMMISSION_BEFORE_HST_CHANGE',
  REFERRAL_IMPORT_OTHER_SIDE_SOLICITOR: 'REFERRAL_IMPORT_OTHER_SIDE_SOLICITOR',
  REFERRAL_IMPORT_OTHER_SIDE_FIRM: 'REFERRAL_IMPORT_OTHER_SIDE_FIRM',
  REFERRAL_IMPORT_REAL_ESTATE_BROKER_CHANGE: 'REFERRAL_IMPORT_REAL_ESTATE_BROKER_CHANGE',
  REFERRAL_COPY_REFERRAL_DOCUMENT: 'REFERRAL_COPY_REFERRAL_DOCUMENT'
};
