import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'forgot-credentials-form',
  templateUrl: './forgot-credentials.component.html'
})
/**
 * Component with the functionality to reset the credentials
 */
export class ForgotCredentialsComponent implements OnInit {

  // Output even which emits when user redirects to login page after resetting the credentials
  @Output() onForgotCredentials = new EventEmitter<boolean>();

  ngOnInit() {
  }

  backToLogin() {
    // Emits response:false when user wants to redirect to login page
    this.onForgotCredentials.emit(false);
  }
}
