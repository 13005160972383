<!--modal-->
<div class="custom-modal-container">
    <div class="modal-header">
        <div class="col-lg-12">
            <div class="col-lg-6">
                <h1> Save Filter</h1>
            </div>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <div class="col-lg-2 vertical-align-label">
                <label class="control-label">Filter Name</label>
            </div>
            <div class="col-lg-10">
                <input type="text"
                       id="matterListFilterName"
                       name="matterListFilterName"
                       class="form-control"
                       [(ngModel)]="matterListFilter.matterListFilterName"
                />
            </div>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons" >
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="isOkButtonDisabled"
                    class="dp-btn">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="cancel()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</div>
