import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';

export class CustomerSupportContext {
}

@Component({
  selector: 'dp-customer-support',
  templateUrl: 'customer-support.component.html',
  styleUrls: [ './customer-support.component.scss' ]

})

export class CustomerSupportComponent extends ModalComponent<CustomerSupportContext> {
  constructor(public dialog: MatDialogRef<CustomerSupportComponent>,
    @Inject(MAT_DIALOG_DATA) context?: CustomerSupportContext
  ) {
    super(dialog, context);
  }

  close() {
    this.dialog.close();
  }

  submit() {
    let url = 'https://dyedurham.ca/support/unity/';
    this.dialog.close();
    window.open(url, '_blank');
  }
}
