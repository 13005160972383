import {BaseEntity} from '../../shared/BaseEntity/base-entity';

const propertyIgnoreList = [ 'id', 'contactId' ];

export class OtherContactInformation extends BaseEntity {

  constructor(otherContactInformation?: OtherContactInformation) {
    super(otherContactInformation);
    if (otherContactInformation) {
      for (let prop in otherContactInformation) {
        if (otherContactInformation.hasOwnProperty(prop)) {
          this[ prop ] = otherContactInformation[ prop ];
        }
      }
    }
  }

  id: number;
  // The fieldName is the selected value.
  fieldName: string;
  // The value is the input context.
  value: string;
  contactId: number;

  // equals(other: OtherContactInformation): boolean {
  //     return other
  //         && other.id === this.id
  //         && other.fieldName === this.fieldName
  //         && other.value === this.value
  //         && other.contactId === this.contactId;
  // }
  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }
}

