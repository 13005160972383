import {Matter} from "./matter";
import Utils from "../../shared-main/utils";
import {MatterTax} from "./property-taxes";
import {YesNoTypes} from "../../shared-main/constants";

export class PropertyTaxesAmountFormatter {

  static readonly EMPTY: string = '';
  static readonly SPACE: string = ' ';
  static readonly SEPARATOR: string = '/';
  static readonly TOTAL_LABEL: string = 'Total ';
  static readonly PAID_LABEL: string = 'Paid ';
  static readonly TRUST_LABEL: string = 'Paid from Trust ';
  static readonly TRUST_LABEL_POSTFIX: string = ' paid from trust';
  static readonly CREDIT_LABEL: string = 'Credit ';
  static readonly SEARCH_VAL: string = 'CA';

  static formattedTrustAmount(propertyTax: MatterTax): string {
    let paidAmountFromTrustFormatted: string = PropertyTaxesAmountFormatter.EMPTY;

    if (propertyTax == null) {
      return paidAmountFromTrustFormatted;
    }
    if (propertyTax.amountPaidFromTrustAccount != null) {
      let formattedAmountPaidFromTrustAccount: string = Utils.formattedCurrencyValue(propertyTax.amountPaidFromTrustAccount);
      if (formattedAmountPaidFromTrustAccount) {
        paidAmountFromTrustFormatted = formattedAmountPaidFromTrustAccount.replace(PropertyTaxesAmountFormatter.SEARCH_VAL, PropertyTaxesAmountFormatter.EMPTY) + PropertyTaxesAmountFormatter.TRUST_LABEL_POSTFIX;
      }
    }

    return paidAmountFromTrustFormatted;
  }

  static formattedAllTaxesAmount(propertyTax: MatterTax, matter: Matter, interimTaxMultiplier: number, isProjectProportionateShare?: boolean): string {
    let allTaxesAmountFormatted: string = PropertyTaxesAmountFormatter.EMPTY;

    if (propertyTax == null) {
      return allTaxesAmountFormatted;
    }
    if (matter.isMatterProvinceBC) {
      return PropertyTaxesAmountFormatter.formattedAllTaxesAmountForBC(propertyTax)
    }
    if (propertyTax.totalTaxes != null) {
      allTaxesAmountFormatted = PropertyTaxesAmountFormatter.TOTAL_LABEL + Utils.formattedCurrencyValue(Number(propertyTax.totalTaxes));
    }
    if (propertyTax.vendorWillHavePaid != null && propertyTax.vendorWillHavePaid > 0) {
      allTaxesAmountFormatted = allTaxesAmountFormatted + PropertyTaxesAmountFormatter.getTaxPartSeparator(allTaxesAmountFormatted) + PropertyTaxesAmountFormatter.PAID_LABEL + Utils.formattedCurrencyValue(Number(propertyTax.vendorWillHavePaid));
    }
    if (matter.isPurchase || matter.isSale) {
      allTaxesAmountFormatted = allTaxesAmountFormatted +
        PropertyTaxesAmountFormatter.getTaxPartSeparator(allTaxesAmountFormatted) +
        PropertyTaxesAmountFormatter.CREDIT_LABEL +
        matter.getPayToLabel(propertyTax) +
        PropertyTaxesAmountFormatter.SPACE +
        Utils.formattedCurrencyValue(propertyTax.calculateVendorShareDifference(interimTaxMultiplier, matter.getClosingDate(), matter.isPaysForDateOfClosingVendor, isProjectProportionateShare, matter.provinceCode));
    }
    if (propertyTax.payOutOfTrustAccount == YesNoTypes.YES && propertyTax.amountPaidFromTrustAccount != null) {
      allTaxesAmountFormatted = allTaxesAmountFormatted +
        PropertyTaxesAmountFormatter.getTaxPartSeparator(allTaxesAmountFormatted) + PropertyTaxesAmountFormatter.TRUST_LABEL +
        Utils.formattedCurrencyValue(Number(propertyTax.amountPaidFromTrustAccount));
    }

    return allTaxesAmountFormatted;
  }

  static formattedAllTaxesAmountForBC(propertyTax: MatterTax): string {
    let allTaxesAmountFormatted: string = PropertyTaxesAmountFormatter.EMPTY;

    if (propertyTax == null) {
      return allTaxesAmountFormatted;
    }
    if (propertyTax.totalTaxes != null) {
      allTaxesAmountFormatted = PropertyTaxesAmountFormatter.TOTAL_LABEL + Utils.formattedCurrencyValue(Number(propertyTax.netTaxes));
    }
    if (propertyTax.vendorWillHavePaid != null) {
      allTaxesAmountFormatted = allTaxesAmountFormatted +
        PropertyTaxesAmountFormatter.getTaxPartSeparator(allTaxesAmountFormatted) +
        PropertyTaxesAmountFormatter.PAID_LABEL +
        Utils.formattedCurrencyValue(Number(propertyTax.vendorWillHavePaid));
    }

    return allTaxesAmountFormatted;
  }

  static getTaxPartSeparator(prefix: string): string {
    return (prefix) ? PropertyTaxesAmountFormatter.SEPARATOR : PropertyTaxesAmountFormatter.EMPTY;
  }
}