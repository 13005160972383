import {Component, Inject, ViewChild} from '@angular/core';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {Matter} from '../../../shared/index';
import {TeranetUser} from '../../../../shared-main/teranet/teranet-user';
import {TeranetDocket} from '../../../../shared-main/teranet/teranet-docket';
import {ApplicationError} from '../../../../core/application-error';
import {FieldError} from '../../../../core/index';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class DoplicateDocketContext {
  teranetDocket: TeranetDocket;
  modalOpenedFromCreateDocket: boolean;
  matter: Matter;
  teranetUser: TeranetUser;
}

@Component({
  selector: 'dp-create-docket-modal',
  templateUrl: 'duplicate-docket.modal.component.html',
  providers: [ErrorService]
})
export class DuplicateDocketModal extends ModalComponent<DoplicateDocketContext> {
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  actionButtonFlag: boolean = false;
  docketName: string;
  docketId: string;

  constructor(
    public dialog: MatDialogRef<DuplicateDocketModal>,
    public teranetService: TeranetService,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: DoplicateDocketContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.teranetDocket.matterId = this.matter.id;
    this.teranetDocket.teranetUser = this.teranetUser;
  }

  get teranetDocket(): TeranetDocket {
    return this.context.teranetDocket;
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get teranetUser(): TeranetUser {
    return this.context.teranetUser;
  }

  associateDocketWithMatter(): void {
    this.teranetService.associateDocketWithMatter(this.teranetDocket).subscribe(
      (createDocketResult: TeranetDocket) => {
        // this.lockScreenService.lockForUpdate = false;
        this.dialog.close({
          action: 'OK',
          teranetDocket: createDocketResult
        });
      },
      (error: ApplicationError) => {
        if (Array.isArray(error.fieldErrors) && error.fieldErrors.length > 0) {
          error.fieldErrors.forEach((fieldError: FieldError) => {
            this.modalErrorComponent.createCustomDPFieldError(
              fieldError.errorCode,
              fieldError.errorCode + ':' + fieldError.message,
              null,
              'ERROR'
            );
          });
        } else {
          this.modalErrorComponent.createCustomDPFieldError(
            error.errorCode,
            error.errorCode + ':' + error.errorMessage,
            null,
            'ERROR'
          );
        }
      }
    );
  }

  close(): void {
    this.dialog.close();
  }
}
