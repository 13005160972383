import {Component, OnInit, ViewChild} from '@angular/core';
import {Mortgage} from '../../shared/mortgage';
import {Matter} from '../../shared/matter';
import {GetGlobalSaveModelService} from '../../shared/get-global-save-model.service';
import {UserDefinedFieldService} from '../../../shared-main/user-defined-field/user-defined-field-service';
import {UserDefinedField} from '../../../shared-main/user-defined-field/user-defined-field';
import {currentMatter} from '../../shared/current-matter';
import {ActivatedRoute, Params, Router} from '@angular/router';
import * as _ from 'lodash';
import {BurgerMenuExtendedItem} from '../../shared/burger-menu-extended-item';
import {MatterTab} from '../../matter-tab';
import {TabsService} from '../../../core/tabs.service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {MortgageDetailComponent} from './mortagage-detail/mortgage-detail.component';
import {MortgageTermComponent} from './term/mortgage-term.component';
import {MortgageReportComponent} from './report/mortgage-report.component';
import {Section} from '../../shared/section';
import {EmailFieldService, EmailKeys} from '../../../shared-main/email-field/email-field-service';
import {TelusEmpMessages} from '../../../shared-main/emp-messages';
import {MortgageSoAdjService} from '../../../shared-main/mortgage-so-adj.service';
import {MatterParticipant} from '../../shared/matter-participant';
import {MatterUtil} from '../../shared/matter-util';
import {MatterUserDefinedFieldsUtil} from '../../shared/matter-utils/matter-user-defined-fields-util';
import {ContactChangesListener} from '../../../contact/contact-changes-listener';
import {DocumentProductionService} from '../../document-production/document-production.service';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {DEFAULT_LABEL, provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';

@Component({
  selector: 'dp-mortgage',
  templateUrl: 'mortgage.component.html',
  styleUrls: [ './mortgage.styles.scss' ],
  providers: [ EmailFieldService ]
})
export class MortgageComponent implements OnInit {
  navSelected: string;
  id: number = 0;
  @ViewChild('mortgagee') mortgageeComponent: MortgageDetailComponent;
  @ViewChild('mortgageeTerm') mortgageTermComponent: MortgageTermComponent;
  @ViewChild('mortgageeReport') mortgageReportComponent: MortgageReportComponent;

  constructor(public globalSaveModelService: GetGlobalSaveModelService,
              public userDefinedFieldService: UserDefinedFieldService,
              public activatedRoute: ActivatedRoute,
              public dialogService: DialogService,
              public router: Router,
              public emailFieldService: EmailFieldService,
              public mortgageSoAdjService: MortgageSoAdjService,
              public contactChangesListener: ContactChangesListener,
              public documentProductionService: DocumentProductionService,
              public contactQueryService: ContactQueryService,
              public tabService: TabsService) {
  }

  ngOnInit(): void {
    this.globalSaveModelService.getItem$.subscribe(() => {
      //currentMatter.value = matter;
      this.initiateEmailFieldService();
    });
    this.activatedRoute
    .params
    .subscribe((params: Params) => {
      this.id = +params[ 'mortgageId' ];
      if (this.activeMatterTab.childComponentSelection && this.mortgage) {
        this.navSelected = this.activeMatterTab.childComponentSelection;
      } else {
        this.navSelected = 'Mortgagee';
      }

      if (this.matter && this.matter.mortgages && this.matter.mortgages.length > 0 && this.matter.mortgages.length > this.id) {
        let mortgageId = this.matter.mortgages[ this.id ].id;
        console.log(`Mortgage changed: id=${ this.id } |  mortgageId=${ mortgageId }`);
        this.userDefinedFieldService
        .getUserDefinedFieldsForLoggedInCustomerAccount(currentMatter.value.matterType, currentMatter.value.provinceCode)
        .subscribe((customerUDFs: UserDefinedField[]) => {
          if (Array.isArray(customerUDFs)) {
            this.addMortgageUDFsTopic(mortgageId, customerUDFs, 'MORTGAGES_MORTGAGEE');
            this.addMortgageUDFsTopic(mortgageId, customerUDFs, 'MORTGAGES_TERMS');
            this.addMortgageUDFsTopic(mortgageId, customerUDFs, 'MORTGAGES_REPORT');
          }
        });
      }
    });
  }

  initiateEmailFieldService(): void {
    this.emailFieldService.matter = this.matter;
    this.emailFieldService.key = EmailKeys.mortgagee;

  }

  addMortgageUDFsTopic(mortgageId, customerUDFs: UserDefinedField[], topicName: string) {
    let udfsConfigTopic = _.filter(customerUDFs, (udf) => {
      return (udf.topicName === topicName && udf.active);
    });
    //console.log("udfsConfigTopic: ", udfsConfigTopic);

    let udfsMatterMortgageTopic = _.filter(currentMatter.value.matterUserDefinedFields, (udf) => {
      return (udf.topicName === topicName && udf.mortgageId === mortgageId && (udf.active || (!udf.active && udf.fieldValue != null && udf.fieldValue != '')));
    });
    //console.log("udfsMatterMortgageTopic: ", udfsMatterMortgageTopic);
    MatterUserDefinedFieldsUtil.addNewMortgageUDFsToMatter(currentMatter.value, udfsConfigTopic, udfsMatterMortgageTopic, mortgageId, topicName);
  }

  markMatterDirty() {
    this.matter.dirty = true;
  }

  get mortgage(): Mortgage {
    this.emailFieldService.mortgage = currentMatter.value.mortgages[ this.id ];
    return currentMatter.value.mortgages[ this.id ];
  }

  get existingMortgage(): Mortgage {
    return currentMatter.value.existingMortgages[ this.id ];
  }

  get matter(): Matter {
    return currentMatter.value;
  }

  get activeIndex(): number {
    return this.id;
  }

  burgerMenuItems(): string[] {
    let burgerMenuItems: string[] = [];
    burgerMenuItems.push('Delete');
    return burgerMenuItems;

  }

  addToBurgerMenu(burgerMenuItems: BurgerMenuExtendedItem[], key: string, text: string, action?: any, topline?: boolean): void {
    let burgerMenuItem: BurgerMenuExtendedItem;
    burgerMenuItem = new BurgerMenuExtendedItem();
    burgerMenuItem.key = key;
    burgerMenuItem.text = text;
    burgerMenuItem.topline = topline ? topline : false;
    burgerMenuItem.action = action;
    burgerMenuItems.push(burgerMenuItem);
  }

  deleteMortgage(mortgage: Mortgage): void {
    let empMortgage = this.matter.mortgages.find(item => item.isEmpMortgage());
    if (empMortgage && this.matter.isPurchase && mortgage.mortgagePriority < empMortgage.mortgagePriority) {
      this.dialogService.confirm('Information', TelusEmpMessages.EMP32, true, 'OK').subscribe(res => {
        if (res) {
          // Do Nothing
        }
      });
    } else {
      let myClient = this.matter.matterParticipants.find(mp => mp.mortgageId == this.mortgage.id && mp.myClient);
      let deleteConfirmationMessage = `Are you sure you would like to delete this Mortgage?`;
      if (myClient) {
        deleteConfirmationMessage = this.contactChangesListener.checkParticipantsEventsAndConstructDeleteMessage(deleteConfirmationMessage, this.matter, myClient, myClient.matterParticipantRole);
      }
      this.dialogService.confirm('Confirmation', deleteConfirmationMessage, false, 'Delete').subscribe(res => {
        if (res) {
          if (this.documentProductionService && this.contactQueryService) {
            this.documentProductionService.revokeAssociatedPackages(this.matter, mortgage, this.contactQueryService);
          }
          if (myClient) {
            this.handleContactChange(myClient, true);
          }
          let deletedIndex: number = this.matter.mortgages.findIndex(item => item == mortgage);
          let deletedMortgagePriority = mortgage.mortgagePriority;
          let mortgageIndex = -1;
          if (deletedIndex > -1) {
            this.matter.deleteMortgage(mortgage);
            this.removeBlanketMortgageProperties(mortgage);
            this.matter.removeEregistrationFormByMortgage(mortgage.id);
            if (this.matter.mortgages.length > 0) {
              let modifiedDeletedIndex = Number(deletedIndex) + 1;
              mortgageIndex = modifiedDeletedIndex < this.matter.mortgages.length ? deletedIndex : Number(this.matter.mortgages.length - 1);
            }
            if (this.matter.soaTrustLedgerCollection) {
              this.matter.soaTrustLedgerCollection.updateMortgageIndex(deletedIndex);
              if (this.matter.secondarySoaSheetsCollection) {
                this.matter.secondarySoaSheetsCollection.forEach(collection => {
                  collection.updateMortgageIndex(deletedIndex);
                });
              }
              this.matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
              if (this.matter.secondarySoaSheetsCollection) {
                this.matter.secondarySoaSheetsCollection.forEach(collection => {
                  collection.updateERegAndRegisterCharges();
                });
              }
              this.matter.soaTrustLedgerCollection.updateABProjectSaleReceivedFromPurchaserItemName();
            }
            if (mortgageIndex >= 0) {
              this.openMortgage(mortgageIndex);
              if (this.mortgageeComponent) {
                this.mortgageeComponent.initializeState();
              }
              if (this.mortgageTermComponent) {
                this.mortgageTermComponent.initMortgageTerm();
              }
              if (this.mortgageReportComponent) {
                this.mortgageReportComponent.initMortgageReport();
              }
            }
            this.updateMatterAfterChangingPriority(deletedMortgagePriority, mortgage);
            this.matter.recalcuateDelayedInterestDelayedAmountChange();
            this.matter.recalcuateDelayedInterestRegMortgageAmountChange();
            MatterUserDefinedFieldsUtil.deleteMortgageUserDefinedFields(this.matter, mortgage.id);
          }
        }
      });
    }
  }

  removeBlanketMortgageProperties(mortgage: Mortgage) {
    this.matter.matterProperties.slice(0).filter(property => property.mortgageId && property.mortgageId == mortgage.id)
    .forEach(blanketMortgageProperty => (<any>this.matter.matterProperties)
    .remove(this.matter.matterProperties.find(property => property.mortgageId && property.mortgageId == blanketMortgageProperty.mortgageId)));
  }

  updateMatterAfterChangingPriority(deletedMortgagePriority: number, mortgage: Mortgage): void {
    MatterUtil.updateMatterAfterChangingPriority(this.matter, deletedMortgagePriority, this.mortgageSoAdjService, mortgage);
  }

  deleteMatterMortgage(mortgage: Mortgage, clickedMenuOption: BurgerMenuExtendedItem, self: MortgageComponent): void {
    let deletedIndex: number = self.matter.mortgages.findIndex(item => item == mortgage);
    if (deletedIndex > -1) {
      self.matter.deleteMortgage(mortgage);
      if (self.matter.mortgages.length > 0) {
        let updatedDeletedIndex = Number(deletedIndex) + 1;
        let mortgageIndex = self.matter.mortgages.length > updatedDeletedIndex ? updatedDeletedIndex : Number(self.matter.mortgages.length - 1);
        self.openMortgage(mortgageIndex);
      }
      if (self.matter.soaTrustLedgerCollection) {
        self.matter.soaTrustLedgerCollection.updateMortgageIndex(deletedIndex);
        if (self.matter.secondarySoaSheetsCollection) {
          self.matter.secondarySoaSheetsCollection.forEach(collection => {
            collection.updateMortgageIndex(deletedIndex);
          });
        }
        self.matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
        if (self.matter.secondarySoaSheetsCollection) {
          self.matter.secondarySoaSheetsCollection.forEach(collection => {
            collection.updateERegAndRegisterCharges();
          });
        }
      }

    }

  }

  burgerMenuClicked(mortgage: Mortgage, clickedMenuOption: string): void {
    if (clickedMenuOption === 'Delete') {
      this.deleteMortgage(mortgage);
    }
  }

  openMortgage(mortgageIndex: number): void {
    if (this.router.url && this.router.url.indexOf('mortgages/mortgage/') > -1) {
      this.router.navigate([ this.router.url.substr(0, this.router.url.indexOf('mortgages/mortgage/')) + 'mortgages/mortgage/' + mortgageIndex ]);
      this.activeMatterTab.section = 'mortgages/mortgage/' + mortgageIndex;
    } else if (this.router.url && this.router.url.indexOf('vtbMortgages/mortgage/') > -1) {
      this.router.navigate([ this.router.url.substr(0, this.router.url.indexOf('vtbMortgages/mortgage/')) + 'vtbMortgages/mortgage/' + mortgageIndex ]);
      this.activeMatterTab.section = 'vtbMortgages/mortgage/' + mortgageIndex;
    } else {
      this.router.navigate([ this.router.url + '/mortgage/' + mortgageIndex ]);
      this.activeMatterTab.section = 'mortgages/mortgage/' + mortgageIndex;
    }
  }

  get activeMatterTab(): MatterTab {
    return this.tabService.activeTab as MatterTab;
  }

  isExistingMortgageActive(): boolean {
    return (this.activeSection.route && this.activeSection.route.indexOf('existingMortgages') > -1);

  }

  isNewMortgageActive(): boolean {
    return (this.activeSection.route && this.activeSection.route.indexOf('mortgages') > -1);
  }

  isVTBMortgageActive(): boolean {
    return (this.activeSection.route && this.activeSection.route.indexOf('vtbMortgages') > -1);

  }

  get activeSection(): Section {
    return this.applicableSections
      ? this.applicableSections.find(item => item.active) : undefined;
  }

  get applicableSections(): Section[] {
    return this.matter
      ? this.matter.applicableSections
      : [];
  }

  setNavSelection(value: string): void {
    this.navSelected = value;
    if (this.activeMatterTab) {
      this.activeMatterTab.childComponentSelection = value;
    }

  }

  handleContactChange(matterParticipant: MatterParticipant, isDeleted?: boolean, checkForChildren?: boolean): void {
    if (this.matter && matterParticipant && matterParticipant.contact) {
      this.contactChangesListener.handleContactChange(this.matter, matterParticipant, matterParticipant.matterParticipantRole, this.tabService, isDeleted, null, checkForChildren);
    }
  }

  getMortgageeTablabel(): string {
    if (!this.matter?.isMatterProvinceBC) {
      return provinceBasedFieldLabels.get('matter.mortgage.tab.label', DEFAULT_LABEL);
    }
    return provinceBasedFieldLabels.get('matter.mortgage.tab.label', this.matter?.provinceCode);
  }
}

