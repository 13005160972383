<div *ngIf="(isNewMortgageActive() || isVTBMortgageActive()) && mortgage && !mortgage.isEmpMortgage()" class="field-code-tabs">
    <div class="mortgage-nav form-group mortgage-header">
        <div class="col-lg-10 mortgage-link top-10">
            <ul>
                <li [ngClass]="navSelected === 'Mortgagee' ? 'active' : ''"><span  field-code-tab-link="mortgagee"(click)="setNavSelection('Mortgagee')">{{getMortgageeTablabel()}}</span></li>
                <li [ngClass]="navSelected === 'Term' ? 'active' : ''"><span   field-code-tab-link="term"(click)="setNavSelection('Term')">Terms</span></li>
                <li [ngClass]="navSelected === 'Report' ? 'active' : ''"><span  field-code-tab-link="report" (click)="setNavSelection('Report')">Report</span></li>
                <li *ngIf="mortgage.isMortgageLienHoldback()"  [ngClass]="navSelected === 'Advances' ? 'active' : ''">
                    <span  field-code-tab-link="advances" (click)="navSelected = 'Advances'">Advances</span>
                </li>
               </ul>
            <div class="clear"></div>
        </div>
        <div class="col-lg-2 mortgage-right-delete top-10">
            <div class="col-lg-9"></div>
            <div class="col-lg-3 margin-top-10">
            <dp-burger-menu
                    [items]="burgerMenuItems()"
                            (itemClicked)="burgerMenuClicked(mortgage , $event)"
                            >
            </dp-burger-menu>
            </div>
        </div>

        <div class="clear"></div>
    </div>
    <div class="col-lg-12">

        <div class="mortgage-item form-horizontal">
            <dp-mortgage-detail #mortgagee [hidden]="mortgage && navSelected != 'Mortgagee'" field-code-tab-id="mortgagee" (onChange)="markMatterDirty()"
                                [matter]="matter"
                                [activeIndex]="activeIndex"
            ></dp-mortgage-detail>
            <dp-mortgage-term   #mortgageeTerm [hidden]="mortgage && navSelected != 'Term'" field-code-tab-id="term"  (onChange)="markMatterDirty()"
                              [matter]="matter"></dp-mortgage-term>
            <dp-mortgage-report   #mortgageeReport [hidden]="mortgage && navSelected != 'Report'" field-code-tab-id="report"  (onChange)="markMatterDirty()"
                                [matter]="matter"></dp-mortgage-report>
            <dp-mortgage-advances   #mortgageeAdvances [hidden]="mortgage && navSelected != 'Advances'" field-code-tab-id="advances"  (onChange)="markMatterDirty()"
                                  [matter]="matter"></dp-mortgage-advances>

        </div>
    </div>
</div>
<div class="col-lg-12">
    <div class="mortgage-header form-horizontal position-static" *ngIf="isExistingMortgageActive() && existingMortgage">
        <div class="col-lg-12 margin-top-5 top-10">
            <dp-existing-mortgage [matter]="matter"
                                  (onChange)="markMatterDirty()">
            </dp-existing-mortgage>
        </div>
    </div>
</div>

<div class="col-lg-12 emp-mortgage" *ngIf="isNewMortgageActive() && mortgage && mortgage.isEmpMortgage()">
    <div class="mortgage-header form-horizontal position-static">
                <div class="col-lg-11"></div>
                <div *ngIf="!mortgage.isEmpMortgage()" class="col-lg-1 text-right padding-top-10">
                    <dp-burger-menu
                            [items]="burgerMenuItems()"
                            (itemClicked)="burgerMenuClicked(mortgage , $event)"
                    >
                    </dp-burger-menu>
                </div>
        <div class="col-lg-12 margin-top-5">
            <dp-emp-mortgage [matter]="matter" [mortgage]="mortgage"
                                  (onChange)="markMatterDirty()">
            </dp-emp-mortgage>
        </div>
    </div>
</div>

