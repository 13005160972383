//This class contains application level configurations
import {Configuration} from './reference-data/configuration';
import {FeatureService} from '../feature-service';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../shared';
import {PROVINCE_CODES} from '../matters/shared/user-province';

@Injectable()
export class AppConfig {
  private systemConfiguration: Configuration;
  //Max number of purchasers. Default value is 15
  private maxNumberOfPurchasers: number = 15;
  private maxNumberOfPrivateLenders: number = 15;
  private maxNumberOfOfferors: number = 15;
  private maxNumberOfSigningOfficer: number = 15;
  private maxNumberOfVendors: number = 15;
  private maxNumberOfMortgages: number = 8;
  private maxNumberOfSOAccountSheets: number = 8;
  private _maxNumberOfGuarantor: number = 15;
  private _maxNumberOfInstallment: number = 6;
  private _templateManagement: boolean = false;
  private maxNumberOfDeposit: number = 99;
  private _soaDefaultDocumentTemplate: string = '';
  private _trustLedgerDefaultDocumentTemplate: string = '';
  private _contactMaxPurchaseCredit: number = 1000;
  private _contactMaxCreditBalance: number = 5000;
  private _contactUserInitiatedResetsAllowed: boolean = false;
  private _maxNumberOfAmountItemsOnAdjustmentsOtherFixed: number = 99;
  private _maxUploadedFileSizeInMb: number = 25;
  private _maxSpinUploadedFileSizeInMb: number = 25;
  private _maxTprUploadedFileSizeInMB: number = 25;
  private _maxNumberOfDaysToAllowMatterNumberChange: number = 90;
  private _isFctEnabled: boolean = false; // system configuration to show/hide FCT functionality
  private _automationFeaturesAvailable: boolean = false;
  private _unityConnectBaseUrl: string = '';
  private _maxUploadedImageFileSizeInMb: number = 5;
  private _fctEnabledProvinces: string[] = [];
  private _digitalSignatureEnabledProvinces: string[] = [];
  private _matterLinkingEnabledProvinces: string[] = [];
  private _maxEntriesForStcConfiguration: number = 200; //maximum supplemental configuration item allowed at account level
  private _maxEntriesForDocumentProfile: number = 250; //maximum document profile for each province at Account Level
  private _isTestingEnabled: boolean = false; //For Testing Rollback or commit
  // Add configuration at the system level to hide/unhide google, zoom, MS Team  meeting integrations.
  private _isGoogleMeetEnabled: boolean = false;
  private _isTeamsEnabled: boolean = false;
  private _isZoomEnabled: boolean = false;
  private _isDocuSignEnabled: boolean = false;
  private _isSyngrafiiEnabled: boolean = false;
  private _isMatterNotificationsEnabled: boolean = true;
  private _titlePlusEnabledProvinces: string[] = [];
  private _teranetConnectCharges: number = 6.5;
  private _isMatterSaveStringified: boolean = true;
  private _zoomLogOutUrl: string = 'https://zoom.us/logout';
  private _isReminderNotificationEnabled: boolean = false;
  private _isAssystPayoutEnabled: boolean = false;
  private _isImportPayoutDataEnabled: boolean = true;
  private _assystEnabledProvinces: string[] = [];
  private disableMatterEditPostClosingDaysDefaultValue: number = 30;
  private _isStewartAssystSSOEnabled: boolean = false;
  private _isTCOLEnabled: boolean = false;
  private _isLenderCentrePortalRebrandingEnabled: boolean = false;
  private _isMarketingPageEnabled: boolean = false;
  private _isConveyancingDataPageEnabled: boolean = false;
  private _isCBPLiveDate: boolean = false;
  private _isEsiLawIntegrationEnabled: boolean = false;
  private _isWillMatterEnabled: boolean = false;
  private _isDropdownOptionsEnableForAssystPayout: boolean = false;
  private _isUnityBCConveyancingEnabled: boolean = false;
  private _enableGeneralEnhancementsPh1: boolean = false;
  private maxNumberOfTestatorsForWill: number = 2;
  private _isHiddenForBCProvince: boolean = true;
  private _isUPMBrandingEnabled: boolean = false;
  private _tLForCustomMattersLiveDate: string = '';

  constructor(private featureService: FeatureService) {
  }

  /**
   * This method is used by reference data service to load app configurations
   * @param systemConfiguration
   */
  public loadAppConfig(systemConfiguration: Configuration) {
    this.systemConfiguration = systemConfiguration;
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxPurchasers')) {
      this.maxNumberOfPurchasers = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxPurchasers').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxOfferors')) {
      this.maxNumberOfOfferors = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxOfferors').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxSigningOfficer')) {
      this.maxNumberOfSigningOfficer = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxSigningOfficer').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxVendors')) {
      this.maxNumberOfVendors = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxVendors').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxDeposits')) {
      this.maxNumberOfDeposit = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxDeposits').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxPrivateLenders')) {
      this.maxNumberOfDeposit = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxPrivateLenders').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxMortgages')) {
      this.maxNumberOfMortgages = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxMortgages').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxGuarantors')) {
      this._maxNumberOfGuarantor = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxGuarantors').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('TemplateManagement')) {
      this._templateManagement = this.systemConfiguration.getConfigurationValueByProperty('TemplateManagement').configurationValue.toLowerCase() == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('SOADefaultDocumentTemplate')) {
      this._soaDefaultDocumentTemplate = this.systemConfiguration.getConfigurationValueByProperty('SOADefaultDocumentTemplate').configurationValue;
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('TrustLedgerDefaultDocumentTemplate')) {
      this._trustLedgerDefaultDocumentTemplate = this.systemConfiguration.getConfigurationValueByProperty('TrustLedgerDefaultDocumentTemplate').configurationValue;
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('ContactMaxPurchaseCredit')) {
      this._contactMaxPurchaseCredit = parseInt(this.systemConfiguration.getConfigurationValueByProperty('ContactMaxPurchaseCredit').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('ContactMaxCreditBalance')) {
      this._contactMaxCreditBalance = parseInt(this.systemConfiguration.getConfigurationValueByProperty('ContactMaxCreditBalance').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('ContactUserInitiatedResetsAllowed')) {
      this._contactUserInitiatedResetsAllowed = this.systemConfiguration.getConfigurationValueByProperty('ContactUserInitiatedResetsAllowed').configurationValue == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxAdjustmentFixedAmountItems')) {
      this._maxNumberOfAmountItemsOnAdjustmentsOtherFixed = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxAdjustmentFixedAmountItems').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxUploadedFileSizeInMB')) {
      this._maxUploadedFileSizeInMb = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxUploadedFileSizeInMB').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxSpinUploadedFileSizeInMB')) {
      this._maxSpinUploadedFileSizeInMb = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxSpinUploadedFileSizeInMB').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxTprUploadedFileSizeInMB')) {
      this._maxTprUploadedFileSizeInMB = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxTprUploadedFileSizeInMB').configurationValue);
    }

    if (this.systemConfiguration.getConfigurationValueByProperty('MaxNumberOfDaysToAllowMatterNumberChange')) {
      this._maxNumberOfDaysToAllowMatterNumberChange = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxNumberOfDaysToAllowMatterNumberChange').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isFctEnabled')) {
      this._isFctEnabled = this.systemConfiguration.getConfigurationValueByProperty('isFctEnabled').configurationValue.toLowerCase() == 'true';
    }

    if (this.systemConfiguration.getConfigurationValueByProperty('automationFeaturesAvailable')) {
      this._automationFeaturesAvailable = this.systemConfiguration.getConfigurationValueByProperty('automationFeaturesAvailable').configurationValue.toLowerCase() == 'true';
    }

    if (this.systemConfiguration.getConfigurationValueByProperty('unityConnectBaseUrl')) {
      this._unityConnectBaseUrl = this.systemConfiguration.getConfigurationValueByProperty('unityConnectBaseUrl').configurationValue;
    }

    if (this.systemConfiguration.getConfigurationValueByProperty('MaxUploadedImageFileSizeInMB')) {
      this._maxUploadedImageFileSizeInMb = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxUploadedImageFileSizeInMB').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('FctEnabledProvinces')) {
      this._fctEnabledProvinces = [];
      this._fctEnabledProvinces.push(...this.systemConfiguration.getConfigurationValueByProperty('FctEnabledProvinces').configurationValue.split(','));
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MatterLinkingEnabledProvinces')) {
      this._matterLinkingEnabledProvinces = [];
      this._matterLinkingEnabledProvinces.push(...this.systemConfiguration.getConfigurationValueByProperty('MatterLinkingEnabledProvinces').configurationValue.split(','));
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('MaxEntriesForSupplementalTaskCategoryConfiguration')) {
      //current there is no entry stored in the database for this item, since same configuration value is stored in the property file
      this._maxEntriesForStcConfiguration = parseInt(this.systemConfiguration.getConfigurationValueByProperty('MaxEntriesForSupplementalTaskCategoryConfiguration').configurationValue);
    }

    // For Testing rollBack checkbox and text field
    if (this.systemConfiguration.getConfigurationValueByProperty('isTestingEnabled')) {
      this._isTestingEnabled = this.systemConfiguration.getConfigurationValueByProperty('isTestingEnabled').configurationValue.toLowerCase() == 'true';
    }

    // Add configuration at the system level to hide/unhide google, zoom, MS Team  meeting integrations.
    if (this.systemConfiguration.getConfigurationValueByProperty('isGoogleMeetEnabled')) {
      this._isGoogleMeetEnabled = this.systemConfiguration.getConfigurationValueByProperty('isGoogleMeetEnabled').configurationValue.toLowerCase() == 'true';
    }

    if (this.systemConfiguration.getConfigurationValueByProperty('isTeamsEnabled')) {
      this._isTeamsEnabled = this.systemConfiguration.getConfigurationValueByProperty('isTeamsEnabled').configurationValue.toLowerCase() == 'true';
    }

    if (this.systemConfiguration.getConfigurationValueByProperty('isZoomEnabled')) {
      this._isZoomEnabled = this.systemConfiguration.getConfigurationValueByProperty('isZoomEnabled').configurationValue.toLowerCase() == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isDocuSignEnabled')) {
      this._isDocuSignEnabled = this.systemConfiguration.getConfigurationValueByProperty('isDocuSignEnabled').configurationValue.toLowerCase() == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isUnityBCConveyancingEnabled')) {
      this._isUnityBCConveyancingEnabled = this.systemConfiguration.getConfigurationValueByProperty('isUnityBCConveyancingEnabled').configurationValue.toLowerCase() == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isSyngrafiiEnabled')) {
      this._isSyngrafiiEnabled = this.systemConfiguration.getConfigurationValueByProperty('isSyngrafiiEnabled').configurationValue.toLowerCase() == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('DigitalSignatureEnabledProvinces')) {
      this._digitalSignatureEnabledProvinces = [];
      this._digitalSignatureEnabledProvinces.push(...this.systemConfiguration.getConfigurationValueByProperty('DigitalSignatureEnabledProvinces').configurationValue.split(','));
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('DigitalSignatureEnabledProvinces')) {
      this._titlePlusEnabledProvinces = [];
      this._titlePlusEnabledProvinces.push(...this.systemConfiguration.getConfigurationValueByProperty('TitlePlusEnabledProvinces').configurationValue.split(','));
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isMatterNotificationsEnabled')) {
      this._isMatterNotificationsEnabled = this.systemConfiguration.getConfigurationValueByProperty('isMatterNotificationsEnabled').configurationValue.toLowerCase() == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('TeranetConnectCharges')) {
      this._teranetConnectCharges = Number(this.systemConfiguration.getConfigurationValueByProperty('TeranetConnectCharges').configurationValue);
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isMatterSaveStringified')) {
      this._isMatterSaveStringified = this.systemConfiguration.getConfigurationValueByProperty('isMatterSaveStringified').configurationValue.toLowerCase() == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('ZoomLogOutUrl')) {
      this._zoomLogOutUrl = this.systemConfiguration.getConfigurationValueByProperty('ZoomLogOutUrl').configurationValue;
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isReminderNotificationEnabled')) {
      this._isReminderNotificationEnabled = this.systemConfiguration.getConfigurationValueByProperty('isReminderNotificationEnabled').configurationValue == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isAssystPayoutEnabled')) {
      this._isAssystPayoutEnabled = this.systemConfiguration.getConfigurationValueByProperty('isAssystPayoutEnabled').configurationValue == 'true';
    }
    if (this.systemConfiguration.getConfigurationValueByProperty('isImportPayoutDataEnabled')) {
      this._isImportPayoutDataEnabled = this.systemConfiguration.getConfigurationValueByProperty('isImportPayoutDataEnabled').configurationValue == 'true';
    }

        if (this.systemConfiguration.getConfigurationValueByProperty("AssystEmpEnabledProvinces")) {
            this._assystEnabledProvinces = [];
            this._assystEnabledProvinces.push(...this.systemConfiguration.getConfigurationValueByProperty("AssystEmpEnabledProvinces").configurationValue.split(','));
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isStewartAssystSSOEnabled")) {
            this._isStewartAssystSSOEnabled = this.systemConfiguration.getConfigurationValueByProperty("isStewartAssystSSOEnabled").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isTCOLEnabled")) {
            this._isTCOLEnabled = this.systemConfiguration.getConfigurationValueByProperty("isTCOLEnabled").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isMarketingPageEnabled")) {
            this._isMarketingPageEnabled = this.systemConfiguration.getConfigurationValueByProperty("isMarketingPageEnabled").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isConveyancingDataPageEnabled")) {
            this._isConveyancingDataPageEnabled = this.systemConfiguration.getConfigurationValueByProperty("isConveyancingDataPageEnabled").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("CBPLiveDate")) {
            this._isCBPLiveDate = this.systemConfiguration.getConfigurationValueByProperty("CBPLiveDate").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("replayMarketingVideoDurationInHours")) {
            this._replayMarketingVideoDurationInHours = parseInt(this.systemConfiguration.getConfigurationValueByProperty("replayMarketingVideoDurationInHours").configurationValue);
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isEsiLawIntegrationEnabled")) {
            this._isEsiLawIntegrationEnabled = this.systemConfiguration.getConfigurationValueByProperty("isEsiLawIntegrationEnabled").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isWillMatterEnabled")) {
            this._isWillMatterEnabled = this.systemConfiguration.getConfigurationValueByProperty("isWillMatterEnabled").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isDropdownOptionsEnableForAssystPayout")) {
            this._isDropdownOptionsEnableForAssystPayout = this.systemConfiguration.getConfigurationValueByProperty("isDropdownOptionsEnableForAssystPayout").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("enableGeneralEnhancementsPh1")) {
            this._enableGeneralEnhancementsPh1 = this.systemConfiguration.getConfigurationValueByProperty("enableGeneralEnhancementsPh1").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("MaxTestatorsForWill")) {
            this.maxNumberOfTestatorsForWill = parseInt(this.systemConfiguration.getConfigurationValueByProperty("MaxTestatorsForWill").configurationValue);
        }
        if (this.systemConfiguration.getConfigurationValueByProperty("isHiddenForBCProvince")) {
            this._isHiddenForBCProvince = this.systemConfiguration.getConfigurationValueByProperty("isHiddenForBCProvince").configurationValue == "true";
        }
        if (this.systemConfiguration.getConfigurationValueByProperty('isUPMBrandingEnabled')) {
          this._isUPMBrandingEnabled = this.systemConfiguration.getConfigurationValueByProperty('isUPMBrandingEnabled').configurationValue == 'true';
        }
    if (this.systemConfiguration.getConfigurationValueByProperty('TLForCustomMattersLiveDate')) {
      this._tLForCustomMattersLiveDate = this.systemConfiguration.getConfigurationValueByProperty(
        'TLForCustomMattersLiveDate').configurationValue;
    }
  }

  public getSystemConfiguration(): Configuration {
    if (this.systemConfiguration === undefined) {
      console.error('******Application configuration not initialized yet*****');
    } else {
      return this.systemConfiguration;
    }
  }

  //returns the maximum number of purchasers can be added into a matter
  getMaxNumberOfPurchasers(): number {
    return this.maxNumberOfPurchasers;
  }

  //returns the default number of days to disable matter Post closing
  getDisableMattersPostClosingDaysDefaultValue(): number {
    return this.disableMatterEditPostClosingDaysDefaultValue;
  }

  //returns the maximum number of Offerers can be added into a matter
  getMaxNumberOfOfferors(): number {
    return this.maxNumberOfOfferors;
  }

  getMaxNumberOfSigningOfficer(): number {
    return this.maxNumberOfSigningOfficer;
  }

  //returns the maximum number of vendors can be added into a matter
  getMaxNumberOfOtherParties(): number {
    return this.maxNumberOfVendors;
  }

  //returns the maximum number of mortgagees can be added into a matter
  getMaxNumberOfMortgages(): number {
    return this.maxNumberOfMortgages;
  }

  getMaxNumberOfSOAccountSheets(): number {
    return this.maxNumberOfSOAccountSheets;
  }

  private _replayMarketingVideoDurationInHours: number = -1;

  get maxNumberOfGuarantor(): number {
    return this._maxNumberOfGuarantor;
  }

  get maxNumberOfInstallment(): number {
    return this._maxNumberOfInstallment;
  }

  get templateManagement(): boolean {
    return this._templateManagement;
  }

  get maxNumberOfDeposits(): number {
    return this.maxNumberOfDeposit;
  }

  get soaDefaultDocumentTemplate(): string {
    return this._soaDefaultDocumentTemplate;
  }

  get trustLedgerDefaultDocumentTemplate(): string {
    return this._trustLedgerDefaultDocumentTemplate;
  }

  get contactMaxPurchaseCredit(): number {
    return this._contactMaxPurchaseCredit;
  }

  get contactMaxCreditBalance(): number {
    return this._contactMaxCreditBalance;
  }

  get contactUserInitiatedResetsAllowed(): boolean {
    return this._contactUserInitiatedResetsAllowed;
  }

  get maxNumberOfAmountItemsOnAdjustmentsOtherFixed(): number {
    return this._maxNumberOfAmountItemsOnAdjustmentsOtherFixed;
  }

  get maxUploadedFileSizeInMb(): number {
    return this._maxUploadedFileSizeInMb;
  }

  get maxSpinUploadedFileSizeInMb(): number {
    return this._maxSpinUploadedFileSizeInMb;
  }

  get maxTprUploadedFileSizeInMB(): number {
    return this._maxTprUploadedFileSizeInMB;
  }

  get maxNumberOfDaysToAllowMatterNumberChange(): number {
    return this._maxNumberOfDaysToAllowMatterNumberChange;
  }

  // system configuration to show/hide FCT functionality
  get isFctEnabled(): boolean {
    return this._isFctEnabled;
  }

  get automationFeaturesAvailable(): boolean {
    return this._automationFeaturesAvailable;
  }

  get unityConnectBaseUrl(): string {
    return this._unityConnectBaseUrl;
  }

  get maxUploadedImageFileSizeInMb(): number {
    return this._maxUploadedImageFileSizeInMb;
  }

  get fctEnabledProvinces(): string[] {
    return this._fctEnabledProvinces;
  }

  get matterLinkingEnabledProvinces(): string[] {
    return this._matterLinkingEnabledProvinces;
  }

  get maxEntriesForStcConfiguration(): number {
    return this._maxEntriesForStcConfiguration;
  }

  //for each province
  get maxEntriesForDocumentProfile(): number {
    return this._maxEntriesForDocumentProfile;
  }

  get isTestingEnabled(): boolean {
    return this._isTestingEnabled;
  }

  // system configuration to show/hide google meeting functionality
  get isGoogleMeetEnabled(): boolean {
    return this._isGoogleMeetEnabled;
  }

  // system configuration to show/hide MS Team meeting functionality
  get isZoomEnabled(): boolean {
    return true;
  }

  // system configuration to show/hide Zoom meeting functionality
  get isTeamsEnabled(): boolean {
    return this._isTeamsEnabled;
  }

  // system configuration to show/hide Docu Sign functionality
  get isDocuSignEnabled(): boolean {
    return this._isDocuSignEnabled;
  }

  // system configuration to show/hide Syngrafii functionality
  get isSyngrafiiEnabled(): boolean {
    return this._isSyngrafiiEnabled;
  }

  get digitalSignatureEnabledProvinces(): string[] {
    return this._digitalSignatureEnabledProvinces;
  }

  get isMatterNotificationsEnabled(): boolean {
    return this._isMatterNotificationsEnabled;
  }

  get titlePlusEnabledProvinces(): string[] {
    return this._titlePlusEnabledProvinces;
  }

  get teranetConnectCharges(): number {
    return this._teranetConnectCharges;
  }

  get isMatterSaveStringified(): boolean {
    return this._isMatterSaveStringified;
  }

  get zoomLogOutUrl(): string {
    return this._zoomLogOutUrl;
  }

  get isReminderNotificationEnabled(): boolean {
    return this._isReminderNotificationEnabled;
  }

  get isAssystPayoutEnabled(): boolean {
    return this._isAssystPayoutEnabled;
  }

  get isImportPayoutDataEnabled(): boolean {
    return this._isImportPayoutDataEnabled;
  }

  get assystEnabledProvinces(): string[] {
    return this._assystEnabledProvinces;
  }

  get isStewartAssystSSOEnabled(): boolean {
    return this._isStewartAssystSSOEnabled;
  }

  get isTcolEnabled(): boolean {
    return this._isTCOLEnabled;
  }

  get isMarketingPageEnabled(): boolean {
    return this._isMarketingPageEnabled;
  }

  get isConveyancingDataPageEnabled(): boolean {
    return this._isConveyancingDataPageEnabled;
  }

  get CBPLiveDate(): boolean {
    return this._isCBPLiveDate;
  }

  get replayMarketingVideoDurationInHours(): number {
    return this._replayMarketingVideoDurationInHours;
  }

  get isEsiLawIntegrationEnabled(): boolean {
    return this._isEsiLawIntegrationEnabled;
  }

  get isDropdownOptionsEnableForAssystPayout(): boolean {
    return this._isDropdownOptionsEnableForAssystPayout;
  }

  get isWillMatterEnabled(): boolean {
    return this._isWillMatterEnabled;
  }

  get isUnityBCConveyancingEnabled(): boolean {
    return this._isUnityBCConveyancingEnabled;
  }

  get enableGeneralEnhancementsPh1(): boolean {
    return this._enableGeneralEnhancementsPh1;
  }

  getMaxNumberOfTestatorsForWill(): number {
    return this.maxNumberOfTestatorsForWill;
  }

  get isHiddenForBCProvince(): boolean {
    return sessionStorage.getItem(SESSION_STORAGE_KEYS.defaultProvinceCode) == PROVINCE_CODES.BRITISH_COLOMBIA
      && this._isHiddenForBCProvince;
  }

  get isSSOEnable(): boolean {
    return this.featureService.isSSOEnabled;
    // return true; //temporary
  }

  get isUPMBrandingEnabled(): boolean {
    return this._isUPMBrandingEnabled;
  }

  get TLForCustomMattersLiveDate(): string {
    return this._tLForCustomMattersLiveDate;
  }
}
