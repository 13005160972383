export class InterestRate {

  constructor(effectiveFrom: string, interestRate: number) {
    this.effectiveFrom = effectiveFrom;
    this.interestRate = interestRate;
  }

  id: number;

  effectiveFrom: string;

  interestRate: number;
}
