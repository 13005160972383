import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import moment from 'moment';
import {MortgagePayout} from './mortgage-payout';
import {DdlTypeForYesNo, Mortgage, MortgageCorrespondenceType} from '../../../shared/mortgage';
import {noYesOptions} from '../dropdown-options';
import {SelectItem} from 'primeng/api';
import {dropDowns} from '../../../shared/matter-drop-downs';
import {CurrencyPipe} from '@angular/common';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {MortgageDispositionType} from '../../../../shared-main/constants';
import {Matter} from '../../../shared';
import {TrustLedgerMatter} from '../../../trust-ledger/trust-ledger-matter';
import {DPError} from '../../../../shared/error-handling/dp-error';
import {MortgageUtil} from '../../../../shared-main/mortgage-utility';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

class MortgagePayoutModalContext {
  mortgagePayoutPassed: MortgagePayout;
  mortgage: Mortgage;
  matterCloseDate: string;
  lawFirmName: string;
  mortgageCorrespondenceType: MortgageCorrespondenceType;
  mortgageDispositionType: string;
  matter: Matter;
}

@Component({
  selector: 'mortgage-payout-modal-content',
  templateUrl: 'mortgage-payout.modal.component.html',
  providers: [ CurrencyPipe, ErrorService ]

})
export class MortgagePayoutModal extends ModalComponent<MortgagePayoutModalContext> implements OnInit {
  mortgagePayout: MortgagePayout;
  mortgagePayoutCp: MortgagePayout;
  calculationPeriodOptions: SelectItem[];
  yesNoItemsRegularPayments: DdlTypeForYesNo[];
  closingDate: string;
  matterCloseDateParts: any;

  pattern = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;
  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;

  constructor(public dialog: MatDialogRef<MortgagePayoutModal, {mortgagePayout: MortgagePayout, isUpdated: boolean}>,
              public errorService: ErrorService,
              public currencyPipe: CurrencyPipe,
              @Inject(MAT_DIALOG_DATA) context?: MortgagePayoutModalContext) {
    super(dialog, context);
  }

  ngOnInit(): void {
    if (this.context.mortgagePayoutPassed) {
      this.mortgagePayout = new MortgagePayout(this.context.mortgagePayoutPassed);
      this.mortgagePayoutCp = new MortgagePayout(this.context.mortgagePayoutPassed);
    } else {
      this.mortgagePayout = new MortgagePayout();
      this.mortgagePayoutCp = new MortgagePayout();
    }
    MortgageUtil.initializeMortgagePayout(this.mortgagePayout);

    if (this.context.matterCloseDate) {
      if (this.pattern.test(this.context.matterCloseDate)) {
        this.closingDate = moment(this.context.matterCloseDate, 'YYYY/MM/DD').format('MMMM DD, YYYY');
        let closeDateParts = this.context.matterCloseDate.split('/');
        this.matterCloseDateParts = {
          yy: closeDateParts[ 0 ],
          mm: closeDateParts[ 1 ],
          dd: closeDateParts[ 2 ],
          type: 'matterCloseDate'
        };
      }
    }
    this.yesNoItemsRegularPayments = noYesOptions;
    this.calculationPeriodOptions = dropDowns.calculationPeriod;
  }

  get provinceCode(): string {
    return this.context.matter.provinceCode;
  }

  get matterType(): string {
    return this.context.matter.matterType;
  }

  static setDefaultValues(mortgage: Mortgage, matter: Matter, isCalledFromMassUpdate: boolean = false): void {
    if (mortgage.mortgageDispositionType === MortgageDispositionType.BRIDGE_FINANCING
      || mortgage.mortgageDispositionType === MortgageDispositionType.DISCHARGED) {
      if (mortgage.mortgagePayout && !isCalledFromMassUpdate) {
        if (matter.isTemplateMatterForMassUpdate) {
          mortgage.mortgagePayout.useMortgagePayoutCalculator = false;
        } else {
          mortgage.mortgagePayout.useMortgagePayoutCalculator = true;
        }
        mortgage.mortgagePayout.payoutEqualsTrustBalance = false;
      }
    }
  }

  close(): void {
    this.dialog.close({mortgagePayout: this.mortgagePayoutCp, isUpdated: false});
  }

  ok(): void {
    this.validateErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.mortgagePayout.cleanUpMortgagePayout();
      this.dialog.close({mortgagePayout: this.mortgagePayout, isUpdated: true});
    }
  }

  validateErrors(): void {
    this.errorService.removeAllDpFieldError();
    // this.errorService.removeDpFieldError('mortgagePayoutModal.total');
    if (this.mortgagePayout.total < 0) {
      this.errorService.addDpFieldError(DPError.createDPError('mortgagePayoutModal.total'));
    }
    if (this.matter.isTemplateMatterForMassUpdate && !!this.mortgagePayout.inclusiveOfInterestToDate && !this.pattern.test(this.mortgagePayout.inclusiveOfInterestToDate)) {
      this.errorService.addDpFieldError(DPError.createDPError('mortgage.mortgagePayout.interestDate'));
    }
  }

  onDateChange(event, dateObject) {
    this.mortgagePayout[ dateObject ] = event.rawDate;
  }

  formattedDate(dateStr: string): string {
    return dateStr && this.pattern.test(dateStr) ? 'from ' + moment(dateStr).format('MMM DD, YYYY') + ' to' : '';
  }

  getFormattedCurrencyValue(currencyValue: number): string {
    if (currencyValue != undefined && !isNaN(currencyValue)
      && currencyValue != null && currencyValue.toString().trim() != '') {
      return this.currencyPipe.transform(currencyValue, 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    } else {
      return this.currencyPipe.transform('0.00', 'CAD', 'symbol-narrow', '1.2-2').replace('CA', '');
    }
  }

  updateMortgagePayoutCheckBox(): void {
    this.mortgagePayout.useMortgagePayoutCalculator = this.mortgagePayout.useMortgagePayoutCalculator ? false : true;
    if (this.mortgagePayout.payoutEqualsTrustBalance && this.mortgagePayout.useMortgagePayoutCalculator) {
      this.mortgagePayout.payoutEqualsTrustBalance = false;
    }
  }

  get isSameAsTrustLedgerBalanceCheckBoxDisplayed(): boolean {
    //this.matter.paymentAmountsDisplayed only has value for Sale/Mortgage matter from Province: AB/MB/SK
    return this.matter && this.matter.paymentAmountsDisplayed != null && !this.matter.paymentAmountsDisplayed;
  }

  updatePayoutEqualsTrustBalanceCheckBox(): void {
    this.mortgagePayout.payoutEqualsTrustBalance = this.mortgagePayout.payoutEqualsTrustBalance ? false : true;
    this.mortgage.mortgagePayout.payoutEqualsTrustBalance = this.mortgagePayout.payoutEqualsTrustBalance;
    if (this.mortgagePayout.payoutEqualsTrustBalance && this.mortgagePayout.useMortgagePayoutCalculator) {
      this.mortgagePayout.useMortgagePayoutCalculator = false;
    }

    if (this.isSameAsTrustLedgerBalanceCheckBoxDisplayed) {
      if (this.matter && this.matter.isMatterProvinceABorMBorSK) {
        if (this.mortgagePayout.payoutEqualsTrustBalance) {
          if (this.matter.soaTrustLedgerCollection) {
            this.matter.soaTrustLedgerCollection.updateF9ForPaidToYouAndOtherTrustLedgers();
            const mtl: TrustLedgerMatter = this.matter.soaTrustLedgerCollection.getMatterTrustLedgerToExistingMtgWithPayoutEqualsBalance();
            if (mtl) {
              this.mortgagePayout.amountPayableToDischarge = mtl.f9DefaultAmount;
            }
          }
        } else {
          if (this.matter.soaTrustLedgerCollection) {
            this.matter.soaTrustLedgerCollection.makePayToYouTLEditable();
          }
        }
      }
    } else {
      if (this.matter.isSale && this.mortgagePayout.payoutEqualsTrustBalance && this.matter.balanceOfFundsPayableTo) {
        this.mortgagePayout.amountPayableToDischarge = this.matter.balanceOfFundsPayableTo.amount;
        {//in order to to avoid the circular value update between TL, DirectionToPay and Existing Mortgage
          this.matter.autoInsertCashShortfall = false;
          if (this.matter.soaTrustLedgerCollection) {
            this.matter.soaTrustLedgerCollection.addOrRemoveCashShortFall();
          }
        }
      }
    }

  }

  isAmountPayableToDischargeFieldReadOnly(): boolean {
    return (this.mortgage && (this.mortgage.isMortgageDispositionDischarged() || this.mortgage.isMortgageDispositionBridgeFinancing())) && (this.mortgagePayout && this.mortgagePayout.payoutEqualsTrustBalance);
  }

  get matter(): Matter {
    return this.context.matter;
  }

  get mortgage(): Mortgage {
    return this.context.mortgage;
  }

  getCustomInterestAdjustmentDate(): any {
    if (!this.closingDate) {
      return '';
    }
    let closingDate = (() => {
      let matterCloseDateParts = this.closingDate.split('/');
      return {
        yy: matterCloseDateParts[ 0 ],
        mm: matterCloseDateParts[ 1 ],
        dd: matterCloseDateParts[ 2 ]
      };
    })();
    return closingDate;
  }

  generateF9HelpForChequePayableTo(): string {
    if (this.context.mortgageCorrespondenceType === 'SOLICITOR' && this.context.lawFirmName !== null) {
      return 'F9 = ' + this.context.lawFirmName;
    }
  }

  onChequePayableToF9(): void {
    if (this.context.lawFirmName) {
      this.mortgagePayout.chequePayableTo = this.context.lawFirmName;
    }
  }

  allowPayoutEnableEqualsTrustBalanceOption(mortgagePayout: MortgagePayout): boolean {
    if (this.context.matter && mortgagePayout) {
      let existingMtgsWithPayoutEquals: Mortgage[];
      if (this.matter.isSale && this.matter.isMatterProvinceON) {
        existingMtgsWithPayoutEquals = this.context.matter.getExistingMortgagesToBeDischargedBridge();
      } else {
        existingMtgsWithPayoutEquals = this.context.matter.getExistingMortgagesToBeDischarged();
      }
      if (Array.isArray(existingMtgsWithPayoutEquals) && existingMtgsWithPayoutEquals.length > 1) {
        //at most one returned
        const existingMtgsPayoutIdsWithEqualsChecked: number[] = existingMtgsWithPayoutEquals
        .filter((existingMtg: Mortgage) => existingMtg.mortgagePayout && existingMtg.mortgagePayout.payoutEqualsTrustBalance)
        .map((existingMtg: Mortgage) => existingMtg.mortgagePayout.identifier);
        if (Array.isArray(existingMtgsPayoutIdsWithEqualsChecked) && existingMtgsPayoutIdsWithEqualsChecked.length > 0) {
          return existingMtgsPayoutIdsWithEqualsChecked[ 0 ] === mortgagePayout.identifier;
        }
      }
    }
    return true;
  }

  get payoutAmountCheckBoxKey(): string {
    let defaultKey = 'matter.mortgage.existingMortgage.payoutEqualsTrustBalance';
    if (this.mortgage && this.mortgage.mortgageDispositionType === MortgageDispositionType.BRIDGE_FINANCING) {
      return defaultKey + '.bridge.financing';
    } else {
      return defaultKey;
    }
  }

  separateChequeForDischargeFeeChanged(): void {
    this.mortgagePayout.insertSeparateChequeTL = this.isInsertSeparateChequeTLVisible();
  }

  isInsertSeparateChequeTLVisible(): boolean {
    return this.mortgagePayout.isDischargeFeeApplied() && !this.matter.isProjectSale;
  }
}
