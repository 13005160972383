import {
  EmailCondoSourceType,
  EmailManagementSourceType,
  EmailMortgageBrokerSourceType,
  EmailMortgageeSourceType,
  EmailResidenceAssociationSourceType,
  EmailSurveyorSourceType,
  PhoneCondoSourceType,
  PhoneManagementSourceType,
  PhoneMortgageBrokerSourceType,
  PhoneMortgageeSourceType,
  PhoneResidenceAssociationSourceType,
  PhoneSurveyorSourceType,
  WaterHydroDeptType_IS_COMBINED,
  WaterHydroDeptType_IS_SEPARATE
} from '../shared-main/constants';

export const allCanadianProvinces = [
  {label: 'Alberta', value: 'AB'},
  {label: 'British Columbia', value: 'BC'},
  {label: 'Manitoba', value: 'MB'},
  {label: 'New Brunswick', value: 'NB'},
  {label: 'Newfoundland and Labrador', value: 'NL'},
  {label: 'Northwest Territories', value: 'NT'},
  {label: 'Nova Scotia', value: 'NS'},
  {label: 'Nunavut', value: 'NU'},
  {label: 'Ontario', value: 'ON'},
  {label: 'Prince Edward Island', value: 'PE'},
  {label: 'Quebec', value: 'QC'},
  {label: 'Saskatchewan', value: 'SK'},
  {label: 'Yukon', value: 'YT'}
];

export const contactDropDowns = {

  CONTACTLISTTYPES: [
    {value: 'CLIENT', label: 'Clients'},
    {value: 'LAW_FIRM', label: 'Solicitors & Law Firms'},
    {value: 'CONDO_CORPORATION', label: 'Condominium Corporations'},
    {value: 'JURISDICTION', label: 'Jurisdictions'},
    {value: 'MANAGEMENT_COMPANY', label: 'Management Companies'},
    {value: 'MORTGAGE_BROKER', label: 'Mortgage Brokers'},
    {value: 'RESIDENCE_ASSOCIATION', label: 'Residents\' Associations'},
    {value: 'MORTGAGEE', label: 'Mortgagees'},
    {value: 'SURVEYOR', label: 'Surveyors'},
    // {value : 'OTHER_PARTY', label : 'Other Party'},
    //{value : 'PRIVATE_LENDER', label : 'Other Lenders'},
    {value: 'INSURANCE_BROKER', label: 'Insurance Companies'},
    {value: 'REALESTATEAGENT', label: 'Real Estate Agents'},
    {value: 'REALESTATEBROKER', label: 'Real Estate Brokers'}
  ],

  BC_CONTACTLISTTYPES_OVERRIDES: {
    'LAW_FIRM': 'Lawyer/Notary Firm & Contacts',
    'CONDO_CORPORATION': 'Strata Corporations',
    'MORTGAGEE': 'Lenders'
  },

  SystemRoleContactListTypes: [
    {value: 'REALESTATEBROKER', label: 'Real Estate Brokers'},
    {value: 'LAW_FIRM', label: 'Solicitors & Law Firms'},
    {value: 'JURISDICTION', label: 'Jurisdictions'},
    {value: 'MANAGEMENT_COMPANY', label: 'Management Companies'},
    {value: 'CONDO_CORPORATION', label: 'Strata Corporations'},
    {value: 'MORTGAGE_BROKER', label: 'Mortgage Brokers'},
    {value: 'MORTGAGEE', label: 'Mortgagees'},
    {value: 'SURVEYOR', label: 'Surveyors'},
    {value: 'INSURANCE_BROKER', label: 'Insurance Companies'}
  ],

  YESNO: [
    {label: 'Y/n', value: 'Y_n'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  NOYES: [
    {label: 'N/y', value: 'N_y'},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  BLANKYESNO: [
    {label: ' ', value: ''},
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  corporateSealFlag: [
    {label: '', value: '???'},//Todo: should the value be ??? for blank
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],

  OTHERPERSONALINFOTYPES: [
    {label: 'Pager', value: 'Pager'},
    {label: 'Website', value: 'Website'},
    {label: 'Notes', value: 'Notes'},
    {label: 'Other', value: 'Other'}
  ],

  GENDER: [
    {label: ' ', value: 'QUESTION'},
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'}
  ],

  BARRISTERSSOLICITORS: [
    {label: ' ', value: 'QUESTION'},
    {label: 'Barrister & Solicitor', value: 'BARRISTER_SOLICITOR'},
    {label: 'Barristers & Solicitors', value: 'BARRISTERS_SOLICITORS'}
  ],
  BARRISTERSSOLICITORS_BC: [
    {label: ' ', value: 'QUESTION'},
    {label: 'Barrister & Solicitor', value: 'BARRISTER_SOLICITOR'},
    {label: 'Barristers & Solicitors', value: 'BARRISTERS_SOLICITORS'},
    {label: 'Lawyer', value: 'LAWYER'},
    {label: 'Lawyers', value: 'LAWYERS'},
    {label: 'Notary Public', value: 'NOTARY_PUBLIC'},
    {label: 'Notaries Public', value: 'NOTARIES_PUBLIC'}
  ],

  guarantorTypes: [
    {label: 'Guarantor', value: 'GUARANTOR'},
    {label: 'Co-coventor(s)', value: 'COVENTOR'},
    {label: 'Indemnitor', value: 'INDEMNITOR'}
  ],

  INSURANCEBROKERTYPES: [
    {label: ' ', value: 'QUESTION'},
    {label: 'Insurer', value: 'INSURER'},
    {label: 'Broker', value: 'BROKER'}
  ],

  insurerOrBrokerTypes: [
    {label: 'Insurers & Brokers', value: 'QUESTION'},
    {label: 'Insurers', value: 'INSURER'},
    {label: 'Brokers', value: 'BROKER'}
  ],

  documentationTypes: [
    {label: '', value: 'QUESTION'},
    {label: 'Policy Number', value: 'POLICY_NUMBER'},
    {label: 'Binder Number', value: 'BINDER_NUMBER'},
    {label: 'Binder Dated', value: 'BINDER_DATE'}
  ],

  accessScopeTypes: [
    {label: 'Private & Global', value: 'PRIVATE_GLOBAL'},
    {label: 'Private', value: 'PRIVATE'},
    {label: 'Global', value: 'GLOBAL'}
  ],

  mortgagesAcceptedTypes: [
    {label: 'All', value: 'ALL'},
    {label: 'Conventional Mortgages Accepted', value: 'MORTGAGES_REGISTERED'}
  ],

  clientStatusTypes: [
    {label: 'All', value: 'ALL'},
    {label: 'Active', value: 'ACTIVE'},
    {label: 'Inactive', value: 'INACTIVE'}
  ],

  statusTypes: [
    {label: 'Active', value: 'YES'},
    {label: 'Inactive', value: 'NO'},
    {label: 'Active', value: 'Yes'},
    {label: 'Inactive', value: 'No'},
    {label: 'Active', value: 'Y/n'},
    {label: 'Inactive', value: 'N/y'},
    {label: 'Active', value: 'Y_n'},
    {label: 'Inactive', value: 'N_y'}

  ],

  provinceTypes: [ {label: 'All Provinces', value: 'ALL'} ].concat(allCanadianProvinces),

  adjudicationContactTypes: [
    {value: 'ALL', label: 'All Contact Types'},
    {value: 'LAW_FIRM', label: 'Law Firm'},
    {value: 'SOLICITOR', label: 'Solicitor'},
    {value: 'MANAGEMENT_COMPANY', label: 'Management Companies'},
    {value: 'MORTGAGE_BROKER', label: 'Mortgage Brokers'},
    {value: 'MORTGAGEE', label: 'Mortgagees'},
    {value: 'SURVEYOR', label: 'Surveyors'},
    {value: 'INSURANCE_BROKER', label: 'Insurance Companies'},
    {value: 'REALESTATEBROKER', label: 'Real Estate Brokers'}
  ],

  //ToDo:Move to common place
  GENDER_ESTATE_CODE: [
    {label: '', value: 'QUESTION'},
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'}

  ],

  ESTATE_STATUS: [
    {label: '', value: 'QUESTION'},
    {label: 'Testate', value: 'TESTATE'},
    {label: 'Intestate', value: 'INTESTATE'}

  ],

  MATTER_TYPES: [
    {label: 'All Types', value: 'ALL'},
    {label: 'Purchase', value: 'PURCHASE'},
    {label: 'Sale', value: 'SALE'},
    {label: 'Mortgage', value: 'MORTGAGE'}

  ],

  SELF_MANAGED_OR_MANAGEMENT_COMPANY: [
    {label: '', value: 'QUESTION'},
    {label: 'Self Managed', value: 'SELF_MANAGED'},
    {label: 'Management Company', value: 'MANAGEMENT_COMPANY'}
  ],

  AS_OF_TYPE: [
    {label: 'the date hereof', value: 'THE_DATE_HEREOF'},
    {label: 'specify date', value: 'SPECIFY_DATE'}
  ],
  waterAndHydroDepartmentAreOptions: [
    {label: 'Separate', value: WaterHydroDeptType_IS_SEPARATE},
    {label: 'Combined', value: WaterHydroDeptType_IS_COMBINED}
  ],
  hstOptions: [
    {label: 'Yes', value: 'YES'},
    {label: 'No', value: 'NO'}
  ],
  categories: [
    {label: '', value: 'QUESTION'},
    {label: 'Client', value: 'CLIENT'},
    {label: 'Prospect', value: 'PROSPECT'},
    {label: 'Personal Contact', value: 'PERSONAL_CONTACT'},
    {label: 'Business Contact', value: 'BUSINESS_CONTACT'},
    {label: 'Other Party', value: 'OTHER_CONTACT'},
    {label: 'Other - Specify', value: 'OTHER_SPECIFY'}
  ],
  categoryFilter: [
    {label: 'All Categories', value: 'ALL'},
    {label: 'Client', value: 'CLIENT'},
    {label: 'Prospect', value: 'PROSPECT'},
    {label: 'Personal Contact', value: 'PERSONAL_CONTACT'},
    {label: 'Business Contact', value: 'BUSINESS_CONTACT'},
    {label: 'Other Party', value: 'OTHER_CONTACT'}
  ],
  supplementalTaskContactTypes: [
    {value: 'PERSON', label: 'Clients'},
    {value: 'REALESTATEBROKER', label: 'Real Estate Brokers'},
    {value: 'SOLICITOR', label: 'Solicitors & Law Firms'},
    {value: 'CONDO_CORPORATION', label: 'Condominium Corporations'},
    //{value : 'JURISDICTION', label : 'Jurisdictions'},
    {value: 'MANAGEMENT_COMPANY', label: 'Management Companies'},
    {value: 'MORTGAGE_BROKER', label: 'Mortgage Brokers'},
    {value: 'MORTGAGEE', label: 'Mortgagees'},
    {value: 'SURVEYOR', label: 'Surveyors'},
    //{value : 'PRIVATE_LENDER_PERSON', label : 'Other Lenders'},
    {value: 'INSURANCE_BROKER', label: 'Insurance Companies'},
    {value: 'REALESTATEAGENT', label: 'Real Estate Agents'}
  ],

  PhoneSourceMortgageeTypeOptions: [
    {value: PhoneMortgageeSourceType.SAME_AS_MORTGAGEE, label: 'Same as Mortgagee'},
    {value: PhoneMortgageeSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceMortgageeTypeOptions: [
    {value: EmailMortgageeSourceType.SAME_AS_MORTGAGEE, label: 'Same as Mortgagee'},
    {value: EmailMortgageeSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  PhoneSourceLenderTypeOptions: [
    {value: PhoneMortgageeSourceType.SAME_AS_MORTGAGEE, label: 'Same as Lender'},
    {value: PhoneMortgageeSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceLenderTypeOptions: [
    {value: EmailMortgageeSourceType.SAME_AS_MORTGAGEE, label: 'Same as Lender'},
    {value: EmailMortgageeSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  PhoneSourceCondoTypeOptions: [
    {value: PhoneCondoSourceType.SAME_AS_CONDO_CORPORATION, label: 'Same as Condo Corporation'},
    {value: PhoneCondoSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceCondoTypeOptions: [
    {value: EmailCondoSourceType.SAME_AS_CONDO_CORPORATION, label: 'Same as Condo Corporation'},
    {value: EmailCondoSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  PhoneSourceStrataTypeOptions: [
    {value: PhoneCondoSourceType.SAME_AS_CONDO_CORPORATION, label: 'Same as Strata Corporation'},
    {value: PhoneCondoSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceStrataTypeOptions: [
    {value: EmailCondoSourceType.SAME_AS_CONDO_CORPORATION, label: 'Same as Strata Corporation'},
    {value: EmailCondoSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  PhoneSourceManagementTypeOptions: [
    {value: PhoneManagementSourceType.SAME_AS_MANAGEMENT_COMPANY, label: 'Same as Management Company'},
    {value: PhoneManagementSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  PhoneSourceMortgageBrokerTypeOptions: [
    {value: PhoneMortgageBrokerSourceType.SAME_AS_MORTGAGE_BROKER, label: 'Same as Mortgage Broker'},
    {value: PhoneMortgageBrokerSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  PhoneSourceResidenceAssociationTypeOptions: [
    {value: PhoneResidenceAssociationSourceType.SAME_AS_RESIDENCE_ASSOCIATION, label: 'Same as Residence Association'},
    {value: PhoneResidenceAssociationSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  PhoneSourceSurveyorTypeOptions: [
    {value: PhoneSurveyorSourceType.SAME_AS_SURVEYOR, label: 'Same as Surveyor'},
    {value: PhoneSurveyorSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceManagementTypeOptions: [
    {value: EmailManagementSourceType.SAME_AS_MANAGEMENT_COMPANY, label: 'Same as Management Company'},
    {value: EmailManagementSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceMortgageBrokerTypeOptions: [
    {value: EmailMortgageBrokerSourceType.SAME_AS_MORTGAGE_BROKER, label: 'Same as Mortgage Broker'},
    {value: EmailMortgageBrokerSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceResidenceAssociationTypeOptions: [
    {value: EmailResidenceAssociationSourceType.SAME_AS_RESIDENCE_ASSOCIATION, label: 'Same as Residence Association'},
    {value: EmailResidenceAssociationSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  EmailSourceSurveyorTypeOptions: [
    {value: EmailSurveyorSourceType.SAME_AS_SURVEYOR, label: 'Same as Surveyor'},
    {value: EmailSurveyorSourceType.MANUALLY_ENTERED, label: ' Manually entered'}
  ],

  JurisdictionFormOneOptions: [
    {label: 'No', value: 'NO'},
    {label: 'Yes', value: 'YES'}
  ],

  DeceasedOptions: [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ],

  lawyerNotaryOptions: [
    {label: 'Lawyer', value: 'LAWYER'},
    {label: 'Notary', value: 'NOTARY'}
  ],

  MaritalStatusOptions: [
    {label: 'Married', value: 'CURRENT_SPOUSE'},
    {label: 'Common-Law', value: 'COMMON_LAW'},
    {label: 'Divorced', value: 'DIVORCED'},
    {label: 'Separated', value: 'SEPARATED'},
    {label: 'Widow/Widower', value: 'WIDOW'},
    {label: 'Single', value: 'SINGLE'}
  ],

  WISHES_FOR_REMAINS: [
    {label: 'Burial', value: 'BURIAL'},
    {label: 'Cremation', value: 'CREMATION'},
    {label: 'Cost-effective and economic manner', value: 'COST_EFFECTIVE'},
    {label: 'Religious traditions', value: 'RELIGIOUS_TRADITIONS'},
    {label: 'Green', value: 'GREEN'},
    {label: 'Other', value: 'OTHER'}
  ],

  YesNoBoolean: [
    {label: 'Yes', value: true},
    {label: 'No', value: false}
  ]
};



