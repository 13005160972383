import {BaseEntity} from '../../../shared/BaseEntity/base-entity';
import {LenderForms} from './lender-forms';

export class Lender extends BaseEntity {
  id: number;
  lenderName: string;
  websiteUrls: LenderWebsite[];
  lenderForms: LenderForms[] = [];

  constructor(l?: Lender) {
    super(l);
    if (l) {
      if (l.lenderForms) {
        for (let forms of l.lenderForms) {
          this.lenderForms.push(new LenderForms(forms));
        }
      }
    }
  }

  getWebsiteUrl(province: string): string {
    if (Array.isArray(this.websiteUrls) && this.websiteUrls.length > 0) {
      let lenderWebsite: LenderWebsite = this.websiteUrls.find((url: LenderWebsite) => url.province === province);
      if (lenderWebsite) {
        return lenderWebsite.websiteUrl;
      }
      return this.websiteUrls[ 0 ].websiteUrl;
    }
    return '';
  }
}

export class LenderWebsite {
  province: string;
  websiteUrl: string;
}
