<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container"   id="legendForm">
    <div class="modal-body">

        <table class="table" id="soaIconTable" >
            <thead >
            <tr>
                <th><div class="col-lg-1 font-weight-bold">Icon</div></th>
                <th><div class="col-lg-5 font-weight-bold">Description</div></th>
            </tr>
            </thead>
            <tbody>
                <tr id="interimIcon" >
                    <td id="interimIconImg">
                        <img class="width-15" src="/assets/img/icon_interim.png"/>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Interim Tab or Interim Only adjustment
                        </span>
                    </td>
                </tr>

                <tr id="finalimIcon" >
                    <td id="finalimIconImg">
                        <img class="width-15" src="/assets/img/icon_final.png"/>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Final Tab or Final only adjustment
                        </span>
                    </td>
                </tr>
                <tr id="bothIcon" >
                    <td id="bothIconImg">
                        <img class="width-15" src="/assets/img/icon_both.png"/>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Adjustment applied to both Interim and Final
                        </span>
                    </td>
                </tr>
                <tr id="notApplyIcon" >
                    <td id="notApplyIconImg">
                        <span class="glyphicon glyphicon-remove-sign red"> </span>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Added adjustment does not apply to new matter
                        </span>
                    </td>
                </tr>
                <tr id="applyIcon" >
                    <td id="applyIconImg">
                        <span class="glyphicon glyphicon-ok-sign primary"> </span>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Added adjustment to be applied to new matter
                        </span>
                    </td>
                </tr>
                <tr id="inforOnlyIcon" >
                    <td id="inforOnlyIconImg">
                        <span class="glyphicon glyphicon-info-sign info-only"> </span>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Adjustment displayed for info only (with no credit to P or V)
                        </span>
                    </td>
                </tr>
                <tr id="linkedIcon" >
                    <td id="linkedIconImg">
                        <img src="/assets/img/chain.png" class="chain-img-size"/>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Linked adjustment
                        </span>
                    </td>
                </tr>
                <tr id="questionIcon" >
                    <td id="questionIconImg">
                        <span class="glyphicon glyphicon-question-sign blue"> </span>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Don't update this adjustment (applies only to Mass Updating)
                        </span>
                    </td>
                </tr>
                <tr id="infoIcon" >
                    <td id="infoIconImg">
                        <span class="fas fa-info-circle"> </span>
                    </td>
                    <td >
                        <span class="col-lg-12">
                            Information
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
</form>
