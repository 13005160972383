<!-- modal -->
<form class="form-horizontal container-fluid custom-modal-container" id="componentSoaRetrievePredefinedForm">
  <div class="modal-header">
    <div class="col-lg-12">
      <div class="col-lg-10">
        <h1>Retrieve Predefined {{adjTitle}} Adjustment</h1>
      </div>
      <button type="button" class="btn-close pull-right" (click)="cancel()">&times;</button>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group col-lg-12">
      <div class="main col-lg-12">
        <ul #scrollable class="componentAdjustmentTemplatesList">
          <li *ngFor="let template of adjustmentTemplates; let i=index" tabindex="0"
            [ngClass]="{'active': activeListItem==i, 'focus-first': i==0}" (focus)="setActiveIndex(i, template)"
            (keydown)="onSelectionChange($event)">
            <div class="view col-lg-8">
              <span> {{template.name}}</span>
            </div>
            <div class="col-lg-4">
              <div class="width-30 margin-top-neg-10">
                <dp-burger-menu [items]="getTemplateBurgerMenuItems()"
                  (itemClicked)="clickTemplateBurgerMenu($event, template)">
                </dp-burger-menu>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row modal-footer">
    <div class="col-lg-12 buttons">
      <ng-container *ngIf="adjustmentTemplates?.length > 0">
        <button type="button" id="okBtn" class="dp-btn keyboard-save-action" (click)="select()">
          <span>Select</span>
        </button>
      </ng-container>
      <button type="button" id="cancelBtn" class="dp-btn dp-btn-cancel" (click)="cancel()">
        <span>Cancel</span>
      </button>
    </div>
  </div>
  <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>