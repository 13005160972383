import {Directive, HostListener} from '@angular/core';
import {NgModel} from '@angular/forms';

@Directive({
  selector: '[ngModel][dp-default-currency]'
})
export class DefaultCurrencyDirective {
  /*
  Directive can be used where you need to have a default value of $0.00 if currency text field is empty
  */
  constructor(private model: NgModel) {
  }

  @HostListener('blur') onBlur(): void {
    let val: string = this.model.viewModel;
    if ((!val || (val && val.toString().trim() == '') || isNaN(Number(val))) && val != null) {
      this.model.viewToModelUpdate(0);
      this.model.valueAccessor.writeValue('$0.00');
    }

  }

}
