import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {StatementOfAdjustmentHeading} from '../../model/statement-adjustment-heading';
import {DialogService} from '../../../../shared/dialog/dialog.service';
import {ErrorService} from '../../../../shared/error-handling/error-service';
import {ModalErrorComponent} from '../../../../shared/error-handling/modal-error/modal-error.component';
import {SelectItem} from 'primeng/api';
import {booleanYesNoDropDowns} from '../../../../shared-main/constants';
import Utils from '../../../../shared-main/utils';
import {SoAdjTenancyCurrent} from '../../model/so-adj-tenancy-current';
import {ModalResult} from '../../../../shared-main/enums';
import {StatementOfAdjustmentDisplayItemLine} from '../../model';
import {StatementAdjustmentDisplayBuilder} from '../../builders/statement-adjustment-display-builder';
import {tenancyDropDowns} from '../../model/so-adj-drop-downs';
import {Matter} from '../../../shared';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class TenancyCurrentModalContext {
  closingDate: string;
  adjustment: SoAdjTenancyCurrent;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder;
  provinceDefaultTaxRate: string;
  defaultHstRate: number;
  defaultPstRate: number;
  defaultGstRate: number;
  taxRateType: string;
  currentMatter: Matter;
  sourceFromProject: boolean;
}

@Component({
  selector: 'dp-tenancy-current-modal-content',
  templateUrl: 'tenancy-current.modal.component.html',
  providers: [ErrorService, DialogService]
})
export class TenancyCurrentModalComponent extends ModalComponent<TenancyCurrentModalContext> implements OnInit {
  localAdj: SoAdjTenancyCurrent;

  @ViewChild('modalErrorComponent') modalErrorComponent: ModalErrorComponent;
  taxTypes: SelectItem[];
  tenancyTypes: SelectItem[] = tenancyDropDowns.tenantOccupancyTypes;
  yesNoOptions: SelectItem[] = booleanYesNoDropDowns.BooleanYesNo;
  closingDateLabel: string = '';
  cachedDetails: StatementOfAdjustmentDisplayItemLine[] = [];
  newAdj: boolean = false;

  constructor(
    public dialog: MatDialogRef<TenancyCurrentModalComponent>,
    public dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) context?: TenancyCurrentModalContext
  ) {
    super(dialog, context);
  }

  ok() {
    this.modalErrorComponent.removeAllDpFieldError();

    this.checkForErrors();
    if (!this.modalErrorComponent.anyErrorExist()) {
      this.dialog.close(this.localAdj);
    }
  }

  checkForErrors() {
    if (this.localAdj.rentalAmount <= 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.current.rental.amount');
    }
    if (this.localAdj.isNotValidDate(this.localAdj.commencementDate)) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.current.commencement.date.empty');
    }
    if (this.localAdj.isNotValidDate(this.localAdj.endDate)) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.current.end.date.empty');
    }
    if (this.localAdj.getDateDiff(this.localAdj.commencementDate, this.context.closingDate) < 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.current.commencement.date.greater.adj');
    }
    if (this.localAdj.getDateDiff(this.context.closingDate, this.localAdj.endDate) < 0) {
      this.modalErrorComponent.createDPFieldError('matter.soadj.tenancy.current.commencement.date.greater.end');
    }
  }

  close() {
    this.dialog.close(ModalResult.Cancel);
  }

  delete(): void {
    this.dialogService
      .confirm('Confirmation', 'Do you wish to delete this item ?', false, 'Delete')
      .subscribe((res) => {
        if (res) {
          this.dialog.close(ModalResult.Delete);
        }
      });
  }

  ngOnInit() {
    this.taxTypes = Utils.loadTaxSelectItem(this.context.taxRateType, this.context.defaultPstRate);

    if (this.context.adjustment) {
      this.localAdj = new SoAdjTenancyCurrent(this.context.adjustment);
    } else {
      this.localAdj = new SoAdjTenancyCurrent();
      this.localAdj.rentalAmount = 0;
      this.localAdj.tenantOccupiesEntirePremises = true;
      this.localAdj.tenantOccupies = 'BASEMENT';
      this.localAdj.taxType = this.context.provinceDefaultTaxRate;
      this.localAdj.applyTax = false;
      this.localAdj.applyPst = false;
      this.localAdj.vendorCollectedRent = true;
      this.localAdj.hstRate = this.context.defaultHstRate;
      this.localAdj.gstRate = this.context.defaultGstRate;
      this.localAdj.pstRate = this.context.defaultPstRate;
      this.newAdj = true;
    }
    this.closingDateLabel = Utils.formatDateString(this.context.closingDate, true, '');
  }

  onDateChange(event: any, dateType: string): void {
    this.localAdj[dateType] = event.rawDate;
    this.clearCachedDetails();
  }

  /*
      getDateToSave(event: any) : string {
          let date : string;
          date = `${((event.year) ? event.year : '')}/${((event.month) ? event.month : '')}/${((event.day) ? event.day : '')}`;
          return date;
      }
  */

  clearCachedDetails(): void {
    this.cachedDetails = [];
  }

  get details(): StatementOfAdjustmentDisplayItemLine[] {
    if (this.cachedDetails.length == 0) {
      console.log('rebuilding adjustment details');
      this.cachedDetails = this.context.statementAdjustmentDisplayUtil.getDisplayItems(
        this.localAdj,
        this.context.closingDate
      );
      this.cachedDetails.pop(); // remove last one about credit (it has another formatting on main "Statement of Adjustment" screen)
    }
    return this.cachedDetails;
  }

  get vendorLabel(): string {
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  get allowDelete(): boolean {
    return !this.context.sourceFromProject && !this.newAdj;
  }
}
