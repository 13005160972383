import {Component, Input} from '@angular/core';
import {provinceBasedFieldLabels} from '../../shared-main/province-based-field-labels';
import {currentMatter} from '../shared/current-matter';
import {Router} from '@angular/router';
import {DialogService} from '../../shared/dialog/dialog.service';
import {MatterService} from '../matter.service';
import {LockScreenService} from '../../core/lock-screen.service';
import {SESSION_STORAGE_KEYS} from '../../shared';
import {StatementConfigService} from '../../admin/shared/statement-config.service';
import {StatementConfig} from '../../admin/shared/statement-config';
import {MatterBillingResult} from '../shared/matter-billing';
import {CurrencyPipe} from '@angular/common';
import {AppConfig} from '../../shared-main/app-configuration';
import {MatterUtil} from '../shared';
import {MatterTab} from '../matter-tab';
import {TabsService} from '../../core';
import {AuthZService} from '../../core/authz/auth-z.service';

@Component({
  selector: 'dp-property-teranet-main',
  styleUrls: [ './property-teranet.scss' ],
  templateUrl: 'property-teranet-main.component.html'
})
export class PropertyTeranetMainComponent {

  isTeranetTabActive: boolean;
  @Input() showWizardFields: boolean = false;

  constructor(public router: Router,
              public dialogService: DialogService,
              public lockScreenService: LockScreenService,
              public soaConfigService: StatementConfigService,
              public appConfig: AppConfig,
              public currencyPipe: CurrencyPipe,
              public tabsService: TabsService,
              public authZService: AuthZService,
              public matterService: MatterService) {
  }

  get matter() {
    return currentMatter.value;
  }

  get tabLabFromColClass(): string {
    return this.matter && provinceBasedFieldLabels.get('matter-teranet-connect-main-spin-lab', this.matter.provinceCode);
  }

  get isMatterEditingDisabledAfterPostClosingDate(): boolean {
    return MatterUtil.isMatterEditingDisabledAfterPostClosingDate(this.matter);
  }

  get isTeranetTabVisible(): boolean {
    return this.matter && !((this.matter.isMatterProvinceON || this.matter.isMatterProvinceAB) && this.matter.isTemplateMatterForMassUpdate) && !this.matter.isMatterProvinceNS && !this.matter.isMatterProvinceNB;
  }

  isHoldbackTabVisible(): boolean {
    return this.matter && !this.matter.isMatterTypeDischarge;
  }

  isUndertakingsDisabled(): boolean {
    return this.matter.isPurchase && !!this.matter.matterLink;
  }

  async teranetConnectTabClicked(): Promise<void> {
    if (this.matter && this.matter.isTeranetConnectChargesApplicable() && !this.isMatterEditingDisabledAfterPostClosingDate) {
      let teranetConnectTransaction = this.matter.billingTransactions.find(transaction => transaction.isTeranetConnectTransactionBilledOrPrebilled());
      if (teranetConnectTransaction) {
        this.navigateToTeranetConnect();
      } else {
        if (this.matter.isNewMatter()) {
          this.matter.dirty = true; //Setting the dirty flag just in case it was not dirty
          this.matterService.callOperationAfterMatterSaved(this.matter, this.checkTeranetConnectAndProceed);
        } else {
          if (!this.isMatterDisabled()) {
            this.checkTeranetConnectAndProceed();
          }

        }
      }
    } else {
      this.navigateToTeranetConnect();
    }
  }

  checkTeranetConnectAndProceed = async (isWizard?) => {
    // get the Teranet Connect charge value
    let teranetConnectCharges = await this.getTeranetConnectCharges();
    if (teranetConnectCharges) {
      let teranetConnectChargesValue: string = this.currencyPipe.transform(teranetConnectCharges, 'CAD', 'symbol', '1.2-2').replace('CA', '');
      let toProceed = await this.dialogService.confirm('INFORMATION',
        `To continue using Teranet Connect on this file an integrated search usage charge of ${ teranetConnectChargesValue } will be levied.`, false).toPromise();
      if (toProceed) {
        try {
          this.lockScreenService.lockForUpdate = true;
          //Place the charges
          let matterBillingResult: MatterBillingResult = await this.matterService.chargeTeranetConnectFees(this.matter.id).toPromise();
          if (matterBillingResult) {
            if (matterBillingResult.successful) {
              this.matter.billingTransactions.push(matterBillingResult.matterBilling);
              if (this.matter.soaTrustLedgerCollection) {
                this.matter.soaTrustLedgerCollection.addTeranetConnectChargesToSOA();
                this.matter.dirty = true;
                await this.matterService.saveMatterWithoutConfirmationDialog();
              }
              if (!isWizard) {
                this.navigateToTeranetConnect();
              }
            } else {
              this.dialogService.confirm('ERROR',
                matterBillingResult.billingIssues.errorMessage, true);
            }
          }

        } catch (error) {
          this.dialogService.confirm('ERROR',
            'We are unable to process your request.', true);
        } finally {
          this.lockScreenService.lockForUpdate = false;
        }
      }

    }
  };

  navigateToTeranetConnect(): void {
    let lastSlashIndex = this.router.url.lastIndexOf('/');
    this.router.navigate([ this.router.url.substr(0, lastSlashIndex) + '/teranetConnect' ]);
    this.isTeranetTabActive = true;
  }

  async getTeranetConnectCharges(): Promise<number> {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    let statementConfig: StatementConfig;
    if (this.matter.isMatterTyeDischargeAndProvinceDisabled) {
      statementConfig = await this.soaConfigService.getSystemStatementConfig(this.matter.provinceCode).toPromise();
    } else {
      statementConfig = await this.soaConfigService.getStatementConfig(accountId, null, this.matter.provinceCode).toPromise();
    }
    if (statementConfig && statementConfig.statementOfAccount && statementConfig.statementOfAccount.soaTemplates) {
      let defaultSoaTemplate = statementConfig.statementOfAccount.soaTemplates
      .find(template => template.applicableMatterTypeCode == this.matter.matterType && template.defaultTemplate);
      if (defaultSoaTemplate && defaultSoaTemplate.disbursementsConfig) {
        let teranetConnectCharges = defaultSoaTemplate.disbursementsConfig.find(dc => dc.isTeranetConnectCharges() && dc.matterType == this.matter.matterType);
        if (teranetConnectCharges) {
          return teranetConnectCharges.f9DefaultAmount;
        }
      }
    }
    return this.appConfig.teranetConnectCharges;
  }

  get matterTab(): MatterTab {
    return this.tabsService && this.tabsService.activeTab as MatterTab;
  }

  isMatterDisabled(): boolean {
    return MatterUtil.isMatterDisabled(this.matter, this.matterTab, this.authZService, this.tabsService);
  }

}
