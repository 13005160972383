<!-- modal -->
<div class="form-horizontal container-fluid custom-modal-container">
    <div class="modal-header margin-bottom-20">
        <div class="col-lg-12">
            <div class="col-lg-10">
                <h1>Adjustment Type: Credit {{context.provinceHstRateSlab.rateTypeLabel}} Rebate</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>
    </div>
    <div class="modal-body">

        <div class="form-group">
            <div class="col-lg-12  fields-group margin-bottom-0 margin-top-0">
                <div class="form-group main-level">
                    <span class="title min-height-30 margin-bottom-0 padding-left-15">Adjustment as at {{closingDateLabel}}</span>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-1"></div>
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Calculate using</label>
                </div>
                <div class="col-lg-5">
                    <select id="calculateUsing" name="calculateUsing" class="form-control"
                            [(ngModel)]="hstSalePriceLocal.calculateUsingType"
                            (ngModelChange)="calculateRebate();"
                    >
                        <option *ngFor="let item of calculateUsingList" [value]="item.value">
                            {{item.label}}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-1"></div>
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    >Adjustment Heading*</label>
                </div>
                <div class="col-lg-5">
                    <input type="text"  id="adjustmentHeading"  name="adjustmentHeading"  dp-uppercase  class="form-control"  maxlength="38"
                           [(ngModel)]="hstSalePriceLocal.adjustmentHeading" dp-error-validator [fieldKey]="'matter.soa.taxotherfactor.adjustheader'" />
                </div>
            </div>
        </div>

        <div class="form-group">
            <div class="col-lg-12">
                <div class="col-lg-1"></div>
                <div class="col-lg-3 vertical-align-label">
                    <label class="control-label"
                    ><span *ngIf="hstSalePriceLocal.calculateUsingType=='CREDIT_AMOUNT'">Credit to {{context.vendorLabel}}</span><span *ngIf="hstSalePriceLocal.calculateUsingType=='TAX_OUT_PRICE'">Tax out Price</span></label>
                </div>
                <div class="col-lg-5">
                    <input type="text"  id="creditAmount"  name="creditAmount"  dp-default-currency
                           dp-currency  class="form-control"
                           [(ngModel)]="hstSalePriceLocal.creditAmount" (blur)="calculateRebate();" />
                </div>
            </div>
        </div>


        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd" *ngIf="hstSalePriceLocal.calculateUsingType=='CREDIT_AMOUNT'">

            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label">{{hstSalePriceLocal.adjustmentHeading}}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"   >
                <div class="col-lg-3">
                    <label class="control-label"
                    ><span>Tax out Price</span></label>
                </div>
                <div class="col-lg-2 text-right">
                    <label class="control-label ">{{ getTaxOutPrice() | currency:CAD:'symbol' }}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"   >
                <div class="col-lg-3">
                    <label class="control-label"
                    >{{context.provinceHstRateSlab.rateTypeLabel}} calculated at {{provinceHstRate + federalHstRate |
                        number:'1.2-3' }}%</label>
                </div>
                <div class="col-lg-2 text-right">
                    <label class="control-label ">{{(salePriceAdjustmentLocal.calculateHstPortion(federalHstRate) +
                    salePriceAdjustmentLocal.calculateHstPortion(provinceHstRate)) | currency:CAD:'symbol'
                        }}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"   >
                <div class="col-lg-3">
                    <label class="control-label"
                    >Less {{context.provinceHstRateSlab.rateTypeLabel}} Rebate</label>
                </div>
                <div class="col-lg-2 text-right">
                    <label class="control-label"> {{(salePriceAdjustmentLocal.calculateProvincialRebate(provinceHstRate) +
                    salePriceAdjustmentLocal.calculateRebate(federalHstRate)) |
                        currency:CAD:'symbol' }}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    ><span> Credit  {{context.vendorLabel}}</span></label>
                </div>
                <div class="col-lg-4 text-right">
                    <label class="control-label">{{hstSalePriceLocal.creditAmount | currency:CAD:'symbol'}}</label>
                </div>
            </div>
        </div>


        <div class="padding-top-10 padding-left-5" style="border-top : 1px solid #ddd" *ngIf="hstSalePriceLocal.calculateUsingType=='TAX_OUT_PRICE'">

            <div class="col-lg-12 form-group">
                <div class="col-lg-3">
                    <label class="control-label">{{hstSalePriceLocal.adjustmentHeading}}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"   >
                <div class="col-lg-3">
                    <label class="control-label"
                    ><span>Tax out Price</span></label>
                </div>
                <div class="col-lg-2 text-right">
                    <label class="control-label ">{{hstSalePriceLocal.creditAmount | currency:CAD:'symbol'}}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"   >
                <div class="col-lg-3">
                    <label class="control-label"
                    >{{context.provinceHstRateSlab.rateTypeLabel}} calculated at {{federalHstRate + provinceHstRate |
                        number:'1.2-3' }}%</label>
                </div>
                <div class="col-lg-2 text-right">
                    <label class="control-label ">{{ salePriceAdjustmentLocal.totalTaxOutHST(federalHstRate,provinceHstRate) |
                        currency:CAD:'symbol'
                        }}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group"   >
                <div class="col-lg-3">
                    <label class="control-label"
                    >Less {{context.provinceHstRateSlab.rateTypeLabel}} Rebate</label>
                </div>
                <div class="col-lg-2 text-right">
                    <label class="control-label"> {{
                        salePriceAdjustmentLocal.totalTaxOutHSTRebate(federalHstRate,provinceHstRate) |
                        currency:CAD:'symbol' }}</label>
                </div>
            </div>
            <div class="col-lg-12  form-group">
                <div class="col-lg-3">
                    <label class="control-label"
                    ><span> Credit  {{context.vendorLabel}}</span></label>
                </div>
                <div class="col-lg-4 text-right">
                    <label class="control-label"> {{
                        salePriceAdjustmentLocal.taxOutCreditVendor(federalHstRate,provinceHstRate) |
                        currency:CAD:'symbol'}} </label>
                </div>
            </div>
        </div>

    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button type="button"
                    id="okBtn"
                    (click)="ok()"
                    [disabled]="false"
                    class="dp-btn keyboard-save-action">
                <span>OK</span>
            </button>
            <button type="button"
                    (click)="close()"
                    class="dp-btn dp-btn-cancel">
                <span>Cancel</span>
            </button>
            <button type="button" *ngIf="allowDelete"
                    (click)="delete()"
                    class="secondary-button">
                <span>Delete</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent ></dp-modal-error>
</div>
