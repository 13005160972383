import {Injectable} from '@angular/core';
import {GlobalLogger, LogLevelTypes} from '../../core/global-logger';
import {Document} from './document';
import {MasterDocument} from './document-production-data';

export type ProductionType = 'SingleDocumentProduction' | 'MultipleDocumentProduction' | 'ProduceMerge';
export const ProductionTypes = {
  singleProduce: 'SingleDocumentProduction',
  multipleProduce: 'MultipleDocumentProduction',
  produceMerge: 'ProduceMerge'
};

export const clientSideDocumentProduction = 'CLIENT_SIDE_DOCUMENT_PRODUCTION: ';

export class DocumentProductionInfo {
  matterId: number;
  documentId: number;
  numberOfDocuments: number;
  produceDocumentRequestStartTime: number;
  produceDocumentRequestReturnTime: number;
  productionType: ProductionType;
  producedFileType: string;
}

// This service is used to log the client side duration for document production

@Injectable()
export class DocumentProductionLoggerService {
  constructor(private globalLogger: GlobalLogger) {
  }

  documentProductionInfo: DocumentProductionInfo[];

  createDocumentProductionLogger(matterId: number, numberOfDocuments: number, producedFileType: string): DocumentProductionInfo {
    if (!this.documentProductionInfo) {
      this.resetTemplateLogger();
    }
    let produceDocumentStarTime: number = Date.now();
    let productionType = ProductionTypes.singleProduce;
    if (numberOfDocuments > 1) {
      productionType = ProductionTypes.multipleProduce;
    }
    let documentLogger = new DocumentProductionInfo();
    documentLogger.matterId = matterId;
    documentLogger.produceDocumentRequestStartTime = produceDocumentStarTime;
    documentLogger.productionType = <ProductionType>productionType;
    documentLogger.numberOfDocuments = numberOfDocuments;
    documentLogger.producedFileType = producedFileType;
    return documentLogger;
  }

  setProduceDocumentRequestReturnTime(sourceDocumentLogger: DocumentProductionInfo, documentIds: number[]) {
    let produceDocumentRequestReturnTime: number = Date.now();
    for (let id of documentIds) {
      let newDocumentLogger = new DocumentProductionInfo();
      this.copyDocumentProductionInfo(sourceDocumentLogger, newDocumentLogger);
      newDocumentLogger.documentId = id;
      newDocumentLogger.produceDocumentRequestReturnTime = produceDocumentRequestReturnTime;
      this.documentProductionInfo.push(newDocumentLogger);
    }

  }

  logAndRemoveFromDocumentLogger(matterId: number, producedDocuments: Document[]) {
    if (this.documentProductionInfo && this.documentProductionInfo.length) {
      for (let document of producedDocuments) {
        let existingDocumentLogger: DocumentProductionInfo = this.documentProductionInfo.find(item => item.documentId == document.id && item.matterId == matterId);
        if (existingDocumentLogger && existingDocumentLogger.produceDocumentRequestStartTime && existingDocumentLogger.produceDocumentRequestReturnTime) {
          let now = Date.now();
          let initializeRequestTime = existingDocumentLogger.produceDocumentRequestReturnTime - existingDocumentLogger.produceDocumentRequestStartTime;
          let productionTime = now - existingDocumentLogger.produceDocumentRequestReturnTime;
          let logMessage = clientSideDocumentProduction
            + ';matterId=' + existingDocumentLogger.matterId
            + ';documentId=' + document.id + ';documentType=' + document.documentType + ';documentName=' + document.documentName
            + ';numberOfDocuments=' + existingDocumentLogger.numberOfDocuments + ';requestedProducedFileType=' + existingDocumentLogger.producedFileType
            + ';productionType=' + existingDocumentLogger.productionType
            + ';initializeRequestTime=' + initializeRequestTime + ';productionTime=' + productionTime;
          this.globalLogger.log(LogLevelTypes.INFO, logMessage);
          (<any>this.documentProductionInfo).remove(existingDocumentLogger);
        }
      }

    }
  }

  resetTemplateLogger() {
    this.documentProductionInfo = [];
  }

  createDocumentMergeLogger(matterId: number, producedFileType: string, numberOfDocuments: number): DocumentProductionInfo {
    let documentLogger: DocumentProductionInfo = new DocumentProductionInfo();
    if (!this.documentProductionInfo) {
      this.resetTemplateLogger();
    }
    documentLogger.matterId = matterId;
    documentLogger.numberOfDocuments = numberOfDocuments;
    documentLogger.productionType = <ProductionType>ProductionTypes.produceMerge;
    documentLogger.produceDocumentRequestStartTime = Date.now();
    documentLogger.producedFileType = producedFileType;
    return documentLogger;
  }

  setMergeDocumentRequestReturnTime(sourceDocumentLogger: DocumentProductionInfo, masterDocuments: MasterDocument[]) {
    let produceDocumentRequestReturnTime = Date.now();
    for (let masterDocument of masterDocuments) {
      let docLogger = new DocumentProductionInfo();
      this.copyDocumentProductionInfo(sourceDocumentLogger, docLogger);
      docLogger.documentId = masterDocument.masterDocumentId;
      docLogger.produceDocumentRequestReturnTime = produceDocumentRequestReturnTime;
      this.documentProductionInfo.push(docLogger);
    }

  }

  copyDocumentProductionInfo(sourceDocumentLogger: DocumentProductionInfo, targetDocumentLogger: DocumentProductionInfo) {
    targetDocumentLogger.matterId = sourceDocumentLogger.matterId;
    targetDocumentLogger.numberOfDocuments = sourceDocumentLogger.numberOfDocuments;
    targetDocumentLogger.productionType = sourceDocumentLogger.productionType;
    targetDocumentLogger.produceDocumentRequestStartTime = sourceDocumentLogger.produceDocumentRequestStartTime;
    targetDocumentLogger.producedFileType = sourceDocumentLogger.producedFileType;
  }
}
