import {Component, Inject} from '@angular/core';
import {DocumentTemplateCategory} from '../../document-production/document-template-category';
import {
  DocumentTemplate,
  DocumentTemplateUtil,
  preProduceValidationFlags
} from '../../document-production/document-template';
import {UserConfig} from '../../shared/user-config';
import {DocumentProductionService} from '../../document-production/document-production.service';
import {UserConfigurationService} from '../../../shared-main/user-configuration.service';
import {DocumentProductionUtilityService} from '../../document-production/document-production-utility.service';
import {DocumentProductionData} from '../../document-production/document-production-data';
import {Document, DocumentTypeType} from '../../document-production/document';
import {ContentDialogConfig, DialogConfigParams, DialogService} from '../../../shared/dialog/dialog.service';
import {DocumentUtilityService} from '../../document-production/document-utility.service';
import {Observable} from 'rxjs/Observable';
import {ApplicationError} from '../../../core/application-error';
import {MatterService} from '../../matter.service';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {Matter} from '../../shared';
import {
  GST_HST_RESIDENTIAL_RENTAL_REBATE,
  GST_HST_RESIDENTIAL_RENTAL_REBATE_FILE_NAME,
  RemovedFormTemplate
} from '../../../shared-main/constants';
import {MatterStopCode, MultipleMattersDocumentCall} from '../../document-production/multiple-matters-document-call';
import {FocusFirstElementModalDecorator} from '../../../shared-main/focus-first-element-modal-decorator';
import {StopCodesMultipleMatterModalComponent} from '../../document-production/stop-codes/stop-codes-multiple-matter-modal.component';
import {LockScreenService} from '../../../core/lock-screen.service';
import {TemplateStopCode} from '../../document-production/template-stop-code';
import {Logger} from '@nsalaun/ng-logger';
import * as _ from 'lodash';
import {HOLDBACK_TYPE} from '../../shared/advance-holdback/matter-holdback';
import {ERegistrationService} from '../../forms/eregistration/eregistration.service';
import {FormTemplate} from '../../forms/eregistration/form-template';
import {StatementAdjustmentComponent} from '../../statement-adjustment/statement-adjustment.component';
import {Subject} from 'rxjs/Subject';
import {HttpClient} from '../../../core';
import {DirectionReFundsComponent} from '../../direction-re-funds/direction-re-funds.component';
import {provinceBasedFieldLabels} from '../../../shared-main/province-based-field-labels';
import {ProjectService} from '../../../projects/project.service';
import {Contact} from '../../shared/contact';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {MatterParticipant} from '../../shared/matter-participant';
import {ShareDocumentsModalComponent} from './share-documents-modal.component';
import Utils from '../../../shared-main/utils';
import {EmailFieldService, EmailKeys} from '../../../shared-main/email-field/email-field-service';
import {
  BulkShareMatterData,
  bulkShareResultStatus,
  BulkSharing,
  BulkSharingResponse,
  sharingPreviews
} from './bulk-sharing';
import {StatementAdjustmentPreview} from '../../statement-adjustment/model/statement-adjustment-preview';
import {DirectionReFundsPreview} from '../../direction-re-funds/model/direction-re-funds-preview';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class Recipient {
  contact: Contact;
  role: string;
}

class DocumentTemplateModalContext {
  selectedMatterId: number;
  applicableMatterTypeCode: string;
  provinceCode: string;
  matterIds: number[] = [];
  isMergeDocuments: boolean;
  isDocumentProfileProccessorTypeWordPerfect: boolean;
  statementAdjustmentComponent: StatementAdjustmentComponent;
  directionReFundsComponent: DirectionReFundsComponent;
  shareResultNotificationHandler: Function;
}

interface TemplateRow {
  template: DocumentTemplate;
  index: number | null;
}

/**
 * @param templates
 * @param overrideIndex can be null, which is expected to cause backend (existing logic) to generate a
 *          document for every mortgage or affidavit of a given matter.
 */
function mapTemplatesToTemplateRows(templates: DocumentTemplate[], overrideIndex?: number): TemplateRow[] {
  return templates.map((t, index) => {
    return {template: t, index: overrideIndex !== undefined ? overrideIndex : index};
  });
}

/**
 * Extracts template info for creating a {@link DocumentProductionData} instance. The output format is compatible with requirements of
 * {@link DocumentProductionData#of}.
 *
 * @param templateRows
 */
function extractTemplateIdAndIndex(...templateRows: TemplateRow[]): string[] {
  return templateRows.map((row) => {
    let id = '' + row.template.docGenTemplateId;

    // assuming we can only have either one of MortgageIndex, AffidavitIndex or holdbackIndex
    let mortgageIndex = _.isFinite(row.template.mortgageIndex) ? '-' + row.template.mortgageIndex : '';
    let affidavitIndex = _.isFinite(row.template.affidavitIndex) ? '-' + row.template.affidavitIndex + '-A' : '';
    let holdbackIndex = _.isFinite(row.template.holdbackIndex) ? '-' + row.template.holdbackIndex + '-HB' : '';

    return id + mortgageIndex + affidavitIndex + holdbackIndex;
  });
}

@FocusFirstElementModalDecorator()
@Component({
  selector: 'document-template-modal-content',
  templateUrl: './document-template-modal.component.html'
})
export class DocumentTemplateModalComponent extends ModalComponent<DocumentTemplateModalContext> {
  categories: DocumentTemplateCategory[] = [];
  categoryFilter: number;
  documentTemplates: DocumentTemplate[];

  unfilteredRows: TemplateRow[] = []; // keep all template list rows
  rows: TemplateRow[] = []; // used in view list, as result of filtering
  selectedTemplates: TemplateRow[] = [];
  checkBoxAllTemplate: boolean = false;

  documentRows: TemplateRow[] = [];
  selectedDocumentTemplates: TemplateRow[] = [];
  checkBoxAllDocumentTemplates: boolean = false;

  formRows: TemplateRow[] = [];
  selectedFormTemplates: TemplateRow[] = [];
  checkBoxAllFormTemplates: boolean = false;

  selectedTab: string;
  soaPreviewText = 'Statement of Adjustment Preview (Print)';
  directionRefundPreviewText;

  sortStateFileNumber: number = 0;
  sortStateDescriptionNumber: number = 0;
  matters: Matter[] = [];
  bulkSharing: BulkSharing;
  mattersWithNoSharingRecipients: Matter[];
  produceAndShareMessage =
    'Selected documents are being produced.  Once produced, documents will be available in their respective matter and will be shared with appropriate recipients';

  constructor(
    public dialog: MatDialogRef<DocumentTemplateModalComponent>,
    public documentProductionService: DocumentProductionService,
    public eRegistrationService: ERegistrationService,
    public userConfigurationService: UserConfigurationService,
    public documentProductionUtilityService: DocumentProductionUtilityService,
    public dialogService: DialogService,
    public documentUtility: DocumentUtilityService,
    public matterService: MatterService,
    public lockScreenService: LockScreenService,
    public httpClient: HttpClient,
    public projectService: ProjectService,
    public contactQueryService: ContactQueryService,
    public emailFieldService: EmailFieldService,
    private logger: Logger,
    @Inject(MAT_DIALOG_DATA) context?: DocumentTemplateModalContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.directionRefundPreviewText = provinceBasedFieldLabels.get(
      'directionRefundPreviewText',
      this.context.provinceCode
    );

    this.matterService
      .getMattersWithDetail(this.context.matterIds)
      .finally(() => {
        this.logger.debug('calling loadCategoriesAndTemplates()');
        this.loadCategoriesAndTemplates();
        if (!this.isDocumentMergeActive) {
          this.loadFormTemplates();
        }
      })
      .subscribe((matters: Matter[]) => {
        this.logger.debug('received matters', matters);
        this.matters = matters;
        this.loadMattersProjects();
      });
  }

  initializeDocumentTab() {
    this.selectedTab = 'Documents';
    this.checkBoxAllTemplate = this.checkBoxAllDocumentTemplates;
    this.rows = this.documentRows;
    this.selectedTemplates = this.selectedDocumentTemplates;
  }

  get isDocumentMergeActive(): boolean {
    return this.context && this.context.isMergeDocuments;
  }

  produceF2(event): void {
    if (event.keyCode === 113 && this.selectedTemplates.length > 0) {
      this.produceDocuments();
    }
  }

  loadCategoriesAndTemplates() {
    this.categories = [];
    this.documentProductionService
      .getDocGenTemplates(this.context.selectedMatterId, 'ALL', false)
      .subscribe((documentTemplates: DocumentTemplate[]) => {
        if (documentTemplates && documentTemplates.length > 0) {
          this.documentTemplates = documentTemplates;

          if (this.context.isMergeDocuments) {
            this.unfilteredRows = this.prepareTemplatesForMerge(documentTemplates);
          } else {
            this.unfilteredRows = mapTemplatesToTemplateRows(documentTemplates, null);
          }
          this.documentRows = this.unfilteredRows;

          this.categories = this.documentProductionUtilityService.getDocumentProductionCategoryByMatterId(
            documentTemplates,
            this.context.applicableMatterTypeCode
          );
          this.userConfigurationService.getUserConfiguration().subscribe((userConfig: UserConfig) => {
            this.categoryFilter = this.documentProductionUtilityService.getDefaultDocumentProductionCategory(
              userConfig,
              this.context.applicableMatterTypeCode,
              this.categories
            );
            this.onCategoryChange();
          });

          this.initializeDocumentTab();
        }
      });
  }

  //Loads the form Templates
  loadFormTemplates() {
    let documentTemplates = [];
    documentTemplates.push(this.createPreviewDocumentTemplate(this.soaPreviewText));
    if (this.context.applicableMatterTypeCode == 'SALE' && !this.matters[0].isMatterProvinceNS) {
      documentTemplates.push(this.createPreviewDocumentTemplate(this.directionRefundPreviewText));
    }
    this.eRegistrationService
      .getFormTemplates('GST_HST_APPLICATION', this.matters[0].matterType, this.matters[0].provinceCode)
      .subscribe((res: FormTemplate[]) => {
        res.forEach((formTemplate) => {
          if (formTemplate.documentTemplate && formTemplate.formName != RemovedFormTemplate) {
            //Remove GST524 Ontario Rebate Schedule in Ontario
            formTemplate.documentTemplate.description = formTemplate.formName;
            if (this.isOntarioGstHstResidentialRentalRebateForm(formTemplate.formName)) {
              formTemplate.documentTemplate.description = formTemplate.formName + ' and Ontario Schedule';
            }
            documentTemplates.push(formTemplate.documentTemplate);
          }
        });
        this.formRows = mapTemplatesToTemplateRows(documentTemplates, null);
        this.formRows[0].index = 1; //SOA Preview
        if (this.context.applicableMatterTypeCode == 'SALE') {
          this.formRows[1].index = 2; //Direction Re Fund Preview
        }
      });
  }

  isOntarioGstHstResidentialRentalRebateForm(formName: string): boolean {
    return this.matters[0].isMatterProvinceON && formName.startsWith(GST_HST_RESIDENTIAL_RENTAL_REBATE);
  }

  createPreviewDocumentTemplate(name: string): DocumentTemplate {
    let documentTemplate = new DocumentTemplate();
    documentTemplate.description = name;
    return documentTemplate;
  }

  private prepareTemplatesForMerge(templates: DocumentTemplate[]): TemplateRow[] {
    let allTemplates: TemplateRow[] = [];

    templates.forEach((template) => {
      let templateInstances: DocumentTemplate[] = [template];

      if (DocumentTemplateUtil.isMultiPriorityTemplate(template.fileName)) {
        templateInstances = DocumentTemplateUtil.replicateMultiPriorityTemplatesForNewMortgage(this.matters, template);
      } else if (DocumentTemplateUtil.isMultiExistingPriorityTemplate(template.fileName)) {
        templateInstances = DocumentTemplateUtil.replicateMultiPriorityTemplatesForExistingMortgages(
          this.matters,
          template
        );
      } else if (DocumentTemplateUtil.isMultiAffidavitPriorityTemplate(template.fileName)) {
        // affidavits
        templateInstances = DocumentTemplateUtil.replicateAffidavitTemplatesBasedOnAffidavitCount(
          this.matters,
          template
        );
      } else if (DocumentTemplateUtil.isMultiAdvanceHoldbackTemplate(template.fileName)) {
        // Advance Holdback
        templateInstances = DocumentTemplateUtil.replicateHoldbackTemplatesBasedOnHoldbackCount(
          this.matters,
          template,
          HOLDBACK_TYPE.advance
        );
      } else if (DocumentTemplateUtil.isMultiOtherHoldbackTemplate(template.fileName)) {
        // Other Holdback
        templateInstances = DocumentTemplateUtil.replicateHoldbackTemplatesBasedOnHoldbackCount(
          this.matters,
          template,
          HOLDBACK_TYPE.other
        );
      }
      if (templateInstances) {
        allTemplates.push(...mapTemplatesToTemplateRows(templateInstances));
      }
    });
    return allTemplates;
  }

  cancel() {
    this.dialog.close();
  }

  onCategoryChange() {
    this.rows = this.unfilteredRows.filter((item) =>
      item.template.documentTemplateCategories.some((dt) => dt.id == this.categoryFilter)
    );
  }

  selectAllTemplate(): void {
    if (this.checkBoxAllTemplate) {
      this.emptySelectedTemplates();

      this.rows.forEach((row) => {
        if (row.template && !row.template.isInfected && !row.template.isVirusScanPending) {
          this.selectedTemplates.push(row);
        }
      });
    } else {
      this.emptySelectedTemplates();
    }
  }

  emptySelectedTemplates() {
    if (this.isSelectedTabDocuments()) {
      this.selectedDocumentTemplates = [];
      this.selectedTemplates = this.selectedDocumentTemplates;
    } else {
      this.selectedFormTemplates = [];
      this.selectedTemplates = this.selectedFormTemplates;
    }
  }

  selectTemplate(row: TemplateRow, event): void {
    if (this.isDocumentMergeActive) {
      this.selectedTemplates = [];
    }
    if (!this.isTemplateSelected(row)) {
      this.selectedTemplates.push(row);
    } else {
      if (this.isDocumentMergeActive) {
        this.selectedTemplates = this.selectedTemplates.filter((item) => !this.isSameTemplateRow(item, row));
      } else {
        if (this.isSelectedTabDocuments()) {
          this.selectedDocumentTemplates = this.selectedDocumentTemplates.filter(
            (item) => !this.isSameTemplateRow(item, row)
          );
          this.selectedTemplates = this.selectedDocumentTemplates;
        } else {
          this.selectedFormTemplates = this.selectedFormTemplates.filter((item) => !this.isSameTemplateRow(item, row));
          this.selectedTemplates = this.selectedFormTemplates;
        }
      }
    }
    event.stopPropagation();
    event.preventDefault();
  }

  isTemplateSelected(row: TemplateRow): boolean {
    return this.selectedTemplates.some((item) => this.isSameTemplateRow(item, row));
  }

  isSameTemplateRow(t1: TemplateRow, t2: TemplateRow): boolean {
    return t1.template.docGenTemplateId == t2.template.docGenTemplateId && t1.index == t2.index;
  }

  produceDocuments(): void {
    this.lockScreenService.lockForUpdate = true;
    this.checkMatterLocking()
      .finally(() => {
        this.lockScreenService.lockForUpdate = false;
      })
      .subscribe((lockedMatterIds: number[]) => {
        if (lockedMatterIds && lockedMatterIds.length > 0) {
          this.showLockedMatterErrorMessage(lockedMatterIds);
        } else {
          if (this.isMatterValidForProduction()) {
            this.checkForDocumentLockingErrorsAndProduceDocs();
          }
        }
      });
  }

  showLockedMatterErrorMessage(lockedMatterIds: number[]) {
    let messageString =
      '<div>Following matters are locked by another user.</div><div class="padding-top-10 padding-bottom-10"><span class="inline-block width-200 semi-bold">Matter Number</span><span class="inline-block width-200 semi-bold">User</span></div>';

    let lockedMatterIdsWithNoLockedByUser: number[] = [];
    lockedMatterIds.forEach((lockedMatterId) => {
      let item = this.matters.find((matter) => matter.id == lockedMatterId);
      if (item) {
        if (item.lockedByUser) {
          let matterLockedByUserName =
            (item.lockedByUser && item.lockedByUser.firstName ? item.lockedByUser.firstName : '') +
            ' ' +
            (item.lockedByUser && item.lockedByUser.lastName ? item.lockedByUser.lastName : '');
          messageString =
            messageString +
            ' <div class="padding-bottom-5"><span class="inline-block width-200">' +
            item.matterRecordNumber +
            '</span><span class="inline-block width-200">' +
            matterLockedByUserName +
            '</span></div>';
        } else {
          // Matters may have been locked after opening the modal
          lockedMatterIdsWithNoLockedByUser.push(item.id);
        }
      }
    });

    if (lockedMatterIdsWithNoLockedByUser && lockedMatterIdsWithNoLockedByUser.length) {
      let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
      this.matterService.getMattersById(lockedMatterIdsWithNoLockedByUser, accountId).subscribe((matters: Matter[]) => {
        matters.forEach((item) => {
          let matterLockedByUserName =
            (item.lockedByUser && item.lockedByUser.firstName ? item.lockedByUser.firstName : '') +
            ' ' +
            (item.lockedByUser && item.lockedByUser.lastName ? item.lockedByUser.lastName : '');
          messageString =
            messageString +
            ' <div class="padding-bottom-5"><span class="inline-block width-200">' +
            item.matterRecordNumber +
            '</span><span class="inline-block width-200">' +
            matterLockedByUserName +
            '</span></div>';
        });
        this.showErrorDialog(messageString);
      });
    } else {
      this.showErrorDialog(messageString);
    }
  }

  showErrorDialog(messageString: string): void {
    const contentModalConfig: DialogConfigParams = {
      title: 'ERROR',
      message: messageString,
      hideCancelBtn: true,
      alignContentLeft: true
    };
    this.dialogService.confirmCustomDialog(contentModalConfig).subscribe();
  }

  get fileType(): DocumentTypeType {
    return this.context.isDocumentProfileProccessorTypeWordPerfect ? 'WORDPERFECT' : 'WORD';
  }

  private checkForDocumentLockingErrorsAndProduceDocs(): void {
    let checkDocumentProductionSubscription: any[] = [];
    let templateIds = extractTemplateIdAndIndex(...this.selectedTemplates);
    this.context.matterIds.forEach((matterId) => {
      checkDocumentProductionSubscription.push(
        this.checkDocumentProductionErrors(
          matterId,
          DocumentProductionData.of(matterId, templateIds, this.fileType),
          templateIds
        )
      );
    });

    this.lockScreenService.lockForUpdate = true;
    Observable.forkJoin(checkDocumentProductionSubscription)
      .finally(() => {
        this.lockScreenService.lockForUpdate = false;
      })
      .subscribe((documentProductionErrors: boolean[]) => {
        if (documentProductionErrors.some((isError) => isError)) {
          this.dialogService.confirm(
            'ERROR',
            'Document production cannot proceed as one or more selected templates are locked.',
            true
          );
        } else {
          if (this.isDocumentMergeActive) {
            this.produceMergeDocuments();
          } else {
            this.produceDocumentsAndForms();
          }
        }
      });
  }

  async produceDocumentsAndForms(): Promise<void> {
    let documentTemplateIds = [];
    if (this.selectedDocumentTemplates && this.selectedDocumentTemplates.length) {
      documentTemplateIds = extractTemplateIdAndIndex(...this.selectedDocumentTemplates);
    }
    //Handle Form Documents
    let formTemplateIds = [];
    let formTemplates;
    if (this.selectedFormTemplates && this.selectedFormTemplates.length) {
      formTemplates = this.selectedFormTemplates.map((item) => item);
      formTemplateIds = this.getFormTemplateIdsFromSelectedTemplates(formTemplateIds, formTemplates);
    }

    let docProdSubscription: any[] = [];
    let mattersWithSOAErrors = [];
    let mattersWithDirectionRefundsErrors = [];
    for (let matter of this.matters) {
      if (documentTemplateIds.length) {
        docProdSubscription.push(
          this.documentProductionService
            .produceDocument(DocumentProductionData.of(matter.id, documentTemplateIds, this.fileType))
            .catch((error) => Observable.of(error))
        );
      }
      if (formTemplateIds.length) {
        let customTemplateNameMap: any[] = this.createFormsCustomTemplateNameMap(formTemplateIds, formTemplates);
        docProdSubscription.push(
          this.documentProductionService
            .produceDocument(DocumentProductionData.of(matter.id, formTemplateIds, 'PDF', true, customTemplateNameMap))
            .catch((error) => Observable.of(error))
        );
      }
      if (this.isSoaPreviewSelected()) {
        //Generate SOA Preview
        let sap: StatementAdjustmentPreview = await this.generateSoaPreview(matter, mattersWithSOAErrors);
        if (sap) {
          docProdSubscription.push(
            this.documentProductionService.generateStatementOfAdjustmentPdfPreviewMatterDocument(matter.id, sap)
          );
        }
      }
      if (this.isDirectionRefundPreviewSelected()) {
        //Generate Direction Refunds Preview
        let directionReFundsPreview: DirectionReFundsPreview = await this.generateDirectionReFundsPreview(
          matter,
          mattersWithDirectionRefundsErrors
        );
        if (directionReFundsPreview) {
          docProdSubscription.push(
            this.documentProductionService.generateDirectionReFundsPreviewMatterDocument(
              matter.id,
              directionReFundsPreview
            )
          );
        }
      }
    }
    if (mattersWithSOAErrors.length || mattersWithDirectionRefundsErrors.length) {
      let toProceed = await this.showMattersError(mattersWithSOAErrors, mattersWithDirectionRefundsErrors);
      if (toProceed && docProdSubscription.length) {
        this.produce(docProdSubscription);
      }
    } else {
      if (docProdSubscription.length) {
        this.produce(docProdSubscription);
      }
    }
  }

  async showMattersError(mattersWithSOAErrors: Matter[], mattersWithDirectionRefundsErrors: Matter[]) {
    let message = '';
    if (mattersWithSOAErrors.length) {
      message += 'Statement of adjustment documents will not be generated for the following matters:<br><br>';
      mattersWithSOAErrors.forEach((matter) => {
        message += matter.matterRecordNumber + '<br>';
      });
      message += '<br>';
    }
    if (mattersWithDirectionRefundsErrors.length) {
      message += 'Direction re Funds documents will not be generated for the following matters:<br><br>';
      mattersWithDirectionRefundsErrors.forEach((matter) => {
        message += matter.matterRecordNumber + '<br>';
      });
      message += '<br>';
    }
    return await this.dialogService.confirm('WARNING', message, false).toPromise();
  }

  getFormTemplateIdsFromSelectedTemplates(formTemplateIds: string[], formTemplates: TemplateRow[]): String[] {
    let soaPreviewIndex = this.getPreviewIndex(formTemplates, this.soaPreviewText);
    if (soaPreviewIndex != -1) {
      //Remove SoaPreview from the formTemplates
      formTemplates.splice(soaPreviewIndex, 1);
    }
    let directionRefundPreviewIndex = this.getPreviewIndex(formTemplates, this.directionRefundPreviewText);
    if (directionRefundPreviewIndex != -1) {
      //Remove Direction Refunds from the formTemplates
      formTemplates.splice(directionRefundPreviewIndex, 1);
    }
    if (formTemplates && formTemplates.length) {
      formTemplateIds = extractTemplateIdAndIndex(...formTemplates);
    }

    return formTemplateIds;
  }

  createFormsCustomTemplateNameMap(formTemplateIds: string[], formTemplates: TemplateRow[]): any[] {
    let customTemplateNameMap: any[] = [];
    formTemplateIds.forEach((templateId) => {
      let formTemplate = formTemplates.find(
        (formTemplate) => formTemplate.template.docGenTemplateId == Number(templateId)
      );
      if (formTemplate) {
        let customTemplateName = formTemplate.template.fileName;
        if (this.isOntarioGstHstResidentialRentalRebateForm(formTemplate.template.description)) {
          customTemplateName = GST_HST_RESIDENTIAL_RENTAL_REBATE_FILE_NAME;
        }
        customTemplateNameMap.push({
          customTemplateName: customTemplateName,
          templateId: templateId,
          isForm: true,
          mortgageIndex: null
        });
      }
    });
    return customTemplateNameMap;
  }

  async loadMattersProjects(): Promise<void> {
    this.lockScreenService.lockForUpdate = true;
    let projects = [];
    let matterProject;
    for (let matter of this.matters) {
      if (matter.isProjectSale) {
        matterProject = projects.find((project) => project.id == matter.unityProjectId);
        if (matterProject) {
          matter.project = matterProject;
        } else {
          //TODO It's better to get all projects at once with one API similar to getMattersWithDetail API
          matterProject = await this.projectService.getProject(matter.unityProjectId, false).toPromise();
          matter.project = matterProject;
        }
      }
    }
    this.lockScreenService.lockForUpdate = false;
  }

  /**
   * When producing single merge doc using a "m#" template, we need to filter out matters that do not have any mortgage that match selected priority.
   * @param {Matter[]} matters
   * @param {number} mortgagePriority
   * @returns {number[]}
   */
  private filterMatterIdsForMergeNewMortgageDocs(matters: Matter[], mortgagePriority: number): number[] {
    return matters.filter((matter) => matter.hasNewMortgageOfPriority(mortgagePriority)).map((matter) => matter.id);
  }

  /**
   * When producing single merge doc using a "x#" template, we need to filter out matters that do not have any Existing Mortgage that match the
   * <i>selected sequence number</i> (not priority).
   * @param {Matter[]} matters
   * @param {number} seqNo
   * @returns {number[]}
   */
  private filterMatterIdsForMergeExistingMortgageDocs(matters: Matter[], seqNo: number): number[] {
    return matters.filter((matter) => matter.hasExistingMortgageOfSequenceNo(seqNo)).map((matter) => matter.id);
  }

  /**
   * When producing single merge doc using a "H#" template, we need to filter out matters that do not have any Existing Mortgage that match the
   * <i>selected sequence number</i> (not priority).
   * @param {Matter[]} matters
   * @param {number} seqNo
   * @returns {number[]}
   */
  private filterMatterIdsForMergeAdvanceHoldbackDocs(matters: Matter[], seqNo: number): number[] {
    return matters.filter((matter) => matter.hasAdvanceHoldbackOfSequenceNo(seqNo)).map((matter) => matter.id);
  }

  /**
   * When producing single merge doc using a "O#" template, we need to filter out matters that do not have any Existing Mortgage that match the
   * <i>selected sequence number</i> (not priority).
   * @param {Matter[]} matters
   * @param {number} seqNo
   * @returns {number[]}
   */
  private filterMatterIdsForMergeOtherHoldbackDocs(matters: Matter[], seqNo: number): number[] {
    return matters.filter((matter) => matter.hasOtherHoldbackOfSequenceNo(seqNo)).map((matter) => matter.id);
  }

  produceMergeDocuments(matterStopCodeList?: MatterStopCode[]): void {
    if (this.selectedTemplates && this.selectedTemplates.length > 0) {
      let selectedTemplateRow = this.selectedTemplates[0];
      let documentTemplate = this.documentTemplates.find(
        (t) => t.docGenTemplateId == selectedTemplateRow.template.docGenTemplateId
      );
      let templateIds = extractTemplateIdAndIndex(selectedTemplateRow);

      let multipleMattersDocumentRequest;
      if (this.isDocumentMergeActive && DocumentTemplateUtil.isMultiPriorityTemplate(documentTemplate.fileName)) {
        multipleMattersDocumentRequest = MultipleMattersDocumentCall.of(
          this.filterMatterIdsForMergeNewMortgageDocs(this.matters, selectedTemplateRow.template.mortgageIndex),
          templateIds[0],
          this.fileType,
          null,
          null,
          matterStopCodeList
        );
      } else if (
        this.isDocumentMergeActive &&
        DocumentTemplateUtil.isMultiExistingPriorityTemplate(documentTemplate.fileName)
      ) {
        multipleMattersDocumentRequest = MultipleMattersDocumentCall.of(
          this.filterMatterIdsForMergeExistingMortgageDocs(this.matters, 1 + selectedTemplateRow.index),
          templateIds[0],
          this.fileType,
          null,
          null,
          matterStopCodeList
        );
      } else if (
        this.isDocumentMergeActive &&
        DocumentTemplateUtil.isMultiAdvanceHoldbackTemplate(documentTemplate.fileName)
      ) {
        multipleMattersDocumentRequest = MultipleMattersDocumentCall.of(
          this.filterMatterIdsForMergeAdvanceHoldbackDocs(this.matters, 1 + selectedTemplateRow.index),
          templateIds[0],
          this.fileType,
          null,
          null,
          matterStopCodeList
        );
      } else if (
        this.isDocumentMergeActive &&
        DocumentTemplateUtil.isMultiOtherHoldbackTemplate(documentTemplate.fileName)
      ) {
        multipleMattersDocumentRequest = MultipleMattersDocumentCall.of(
          this.filterMatterIdsForMergeOtherHoldbackDocs(this.matters, 1 + selectedTemplateRow.index),
          templateIds[0],
          this.fileType,
          null,
          null,
          matterStopCodeList
        );
      } else {
        multipleMattersDocumentRequest = MultipleMattersDocumentCall.of(
          this.context.matterIds,
          templateIds[0],
          this.fileType,
          null,
          null,
          matterStopCodeList
        );
      }

      this.documentProductionService.mergeMultipleDocuments(multipleMattersDocumentRequest).subscribe(
        (multipleMattersDocumentCall: MultipleMattersDocumentCall) => {
          if (
            multipleMattersDocumentCall &&
            multipleMattersDocumentCall.matterStopCodes &&
            multipleMattersDocumentCall.matterStopCodes.length > 0 &&
            !multipleMattersDocumentCall.masterMatterDocumentId
          ) {
            let stopCodeList: any[] = this.buildStopCodeList(multipleMattersDocumentCall.matterStopCodes);
            this.openStopCodesPopup(stopCodeList);
          } else if (multipleMattersDocumentCall.masterMatterDocumentId) {
            this.dialog.close(multipleMattersDocumentCall.masterMatterDocumentId);
          } else {
            this.dialogService
              .confirm('ERROR', 'Document production Failed. Please try again.', true)
              .subscribe(() => {});
          }
        },
        (error: ApplicationError) => {
          this.dialogService
            .confirm('ERROR', 'Document production Failed. Please try again.', true)
            .subscribe(() => {});
        }
      );
    }
  }

  checkMatterLocking(): Observable<any[]> {
    let matterLockSubscriptions: any[] = [];
    let lockedMatterIds: any[] = [];
    this.context.matterIds.forEach((matterId) => {
      matterLockSubscriptions.push(this.matterService.getMatterLockStatus(matterId));
    });
    return Observable.forkJoin(matterLockSubscriptions).flatMap((documentProductionErrors: boolean[]) => {
      documentProductionErrors.forEach((status: any, index) => {
        if (status && status.MatterLockStatus) {
          let matterId = this.context.matterIds[index];
          lockedMatterIds.push(matterId);
        }
      });
      return Observable.of(lockedMatterIds);
    });
  }

  openStopCodesPopup(stopCodeDataList: any[], isBulkProduceShare?: boolean) {
    const contentModalConfig: ContentDialogConfig = {
      content: StopCodesMultipleMatterModalComponent,
      context: {
        stopCodeData: stopCodeDataList,
        matters: this.matters
      },
      onFulfillment: (result: any) => {
        if (result) {
          if (this.isDocumentMergeActive || isBulkProduceShare) {
            let matterStopCodeList: MatterStopCode[] = [];
            stopCodeDataList.forEach((item) => {
              let matterStopCode = new MatterStopCode();
              if (item.documentProductionData) {
                matterStopCode.matterId = item.documentProductionData.matterId;
                if (
                  item.documentProductionData.templates &&
                  item.documentProductionData.templates.length &&
                  item.documentProductionData.templates[0]
                ) {
                  matterStopCode.stopCodesList = item.documentProductionData.templates[0].stopCodes;
                  if (isBulkProduceShare) {
                    matterStopCode.templateId = item.documentProductionData.templates[0].templateId;
                  }
                }
              }
              matterStopCodeList.push(matterStopCode);
            });
            if (isBulkProduceShare) {
              this.updateBulkShareStopCodes(matterStopCodeList);
              this.callBulkShareAndCloseModal(this.bulkSharing, true);
            } else {
              this.produceMergeDocuments(matterStopCodeList);
            }
          } else {
            let docProdSubscription: any[] = [];
            stopCodeDataList.forEach((item) => {
              docProdSubscription.push(
                this.documentProductionService
                  .produceDocument(item.documentProductionData)
                  .catch((error) => Observable.of(error))
              );
            });
            this.produce(docProdSubscription);
          }
        }
      }
    };
    this.dialogService.matDialogContent(contentModalConfig);
  }

  produce(docProdSubscription: any[]): void {
    let errorIndex: any[] = [];
    let stopCodeResponse: any[] = [];

    Observable.forkJoin(docProdSubscription)
      .finally(() => {
        if (errorIndex && errorIndex.length > 0) {
          this.dialogService.confirm('ERROR', 'Document production Failed For Some Matter', true).subscribe(() => {
            this.dialog.close();
          });
        } else if (stopCodeResponse && stopCodeResponse.length > 0) {
          this.openStopCodesPopup(stopCodeResponse);
        } else {
          this.dialogService
            .confirm(
              'INFORMATION',
              'Selected documents are being produced. Once produced documents will be available in their respective matters',
              true
            )
            .subscribe(() => {
              this.dialog.close();
            });
        }
      })
      .subscribe(
        (docProdSubscriptionData: any[]) => {
          if (docProdSubscriptionData) {
            docProdSubscriptionData.forEach((item, index) => {
              if (item instanceof DocumentProductionData) {
                if (item.templates && item.templates.length > 0) {
                  stopCodeResponse.push({documentProductionData: item});
                }
              } else if (item instanceof ApplicationError) {
                errorIndex.push(index);
              }
            });
          }
        },
        (error: ApplicationError) => {
          this.dialogService
            .confirm('ERROR', 'Document production Failed. Please try again.', true)
            .subscribe(() => {});
        }
      );
  }

  checkDocumentProductionErrors(
    matterId: number,
    documentProductionData: DocumentProductionData,
    docTemplateIds?: any[]
  ): Observable<boolean> {
    let errors: string[] = [];
    return this.documentProductionService.getDocuments(matterId).flatMap((data) => {
      data.forEach((document: Document) => {
        (docTemplateIds && docTemplateIds.length > 0 ? docTemplateIds : this.selectedTemplates).forEach((id) => {
          let templateIdArr: string[] = id.toString().split('-');
          let currentAffidavitIdx: number = null;
          let currentHoldbackIdx: number = null;
          let currentMortgageIdx: number = null;
          if (templateIdArr && templateIdArr.length > 1) {
            if (document.currentAffidavitIndex != null) {
              currentAffidavitIdx = parseInt(templateIdArr[1]);
            } else if (document.currentHoldbackIndex != null) {
              currentHoldbackIdx = parseInt(templateIdArr[1]);
            } else {
              currentMortgageIdx = parseInt(templateIdArr[1]);
            }
          }

          let templateId = parseInt(templateIdArr[0]);
          if (
            this.isDocumentGeneratedWithGivenTemplate(
              document,
              templateId,
              currentMortgageIdx,
              currentAffidavitIdx,
              currentHoldbackIdx
            )
          ) {
            if (!this.isDocumentProduceReady(document)) {
              if (this.isDocumentSubmitted(document)) {
                errors.push(
                  'Document production of ' +
                    this.getTemplateName(templateId, currentMortgageIdx, currentAffidavitIdx, currentHoldbackIdx) +
                    ' still in progress'
                );
              } else if (
                document.isWordOrWordPerfect &&
                templateIdArr &&
                templateIdArr.length > 0 &&
                documentProductionData.isTemplateRequestedWordOrWordPerfect(templateId) &&
                document.isDocumentOpened
              ) {
                errors.push(
                  'The document ' +
                    document.documentName +
                    ' is currently opened by ' +
                    document.lockedByUser.loginId +
                    ' and cannot be overwritten. Please ask ' +
                    document.lockedByUser.loginId +
                    ' to close document and try again.'
                );
              }
            }
          }
        });
      });
      return Observable.of(errors.length > 0);
    });
  }

  // This method checks that document is generated with given template id. In case of multi-priority template or affidavit execution template,
  // the template id will be same but mortgageIndex/affidavitIndex will be different therefore it uses that too for comparision if given.
  isDocumentGeneratedWithGivenTemplate(
    document: Document,
    templateId: number,
    mortgageIndex: number,
    affidavitIndex: number,
    holdbackIndex: number
  ): boolean {
    if (mortgageIndex != null) {
      return document.templateId === templateId && document.currentMortgageIndex === mortgageIndex;
    } else if (affidavitIndex != null) {
      return document.templateId === templateId && document.currentAffidavitIndex === affidavitIndex;
    } else if (holdbackIndex != null) {
      return document.templateId === templateId && document.currentHoldbackIndex === holdbackIndex;
    } else {
      return document.templateId === templateId;
    }
  }

  isDocumentProduceReady(document: Document) {
    let status = this.documentUtility.mapStatus(document.status, false);
    if (
      (status === 'Available' || status === '' || status === 'Failed' || status === 'Self-referencing-include-file') &&
      !document.lockedByUser
    ) {
      return true;
    }
    return false;
  }

  isDocumentSubmitted(document: Document): boolean {
    let status = this.documentUtility.mapStatus(document.status, false);
    if (status === 'Submitted') {
      return true;
    }
    return false;
  }

  getTemplateName(
    templateId: number,
    currentMortgageIdx: number | null,
    currentAffidavitIdx: number | null,
    currentHoldbackIdx: number | null
  ): string {
    let templateName = '';
    this.rows.forEach((row) => {
      if (
        row.template.docGenTemplateId == templateId &&
        ((row.template.mortgageIndex !== null && row.template.mortgageIndex == currentMortgageIdx) ||
          (row.template.affidavitIndex !== null && row.template.affidavitIndex == currentAffidavitIdx) ||
          (row.template.holdbackIndex !== null && row.template.holdbackIndex == currentHoldbackIdx))
      ) {
        templateName = row.template.fileName;
      }
    });
    return templateName;
  }

  sortTemplateList(sortStateOptions: any[], sortType: string, sortStateSequenceNumber: string): void {
    this[sortStateSequenceNumber] = this[sortStateSequenceNumber] + 1;
    if (sortStateOptions.indexOf(this[sortStateSequenceNumber]) < 0) {
      this.sortStateFileNumber = 0;
    }
    switch (this[sortStateSequenceNumber]) {
      case 1:
        this.rows = this.rows.sort((a: TemplateRow, b: TemplateRow) => {
          return a.template[sortType] &&
            b.template[sortType] &&
            a.template[sortType].toLowerCase() < b.template[sortType].toLocaleLowerCase()
            ? -1
            : 1;
        });
        break;

      case 2:
        this.rows = this.rows.sort((a: TemplateRow, b: TemplateRow) => {
          return a.template[sortType] &&
            b.template[sortType] &&
            a.template[sortType].toLowerCase() < b.template[sortType].toLocaleLowerCase()
            ? 1
            : -1;
        });
        break;

      default:
        this.rows = this.unfilteredRows.filter((item) =>
          item.template.documentTemplateCategories.some((dt) => dt.id == this.categoryFilter)
        );
    }
  }

  checkForTrustLedgeMatterErrors(): boolean {
    let trustLedgerMatterErrors: any = [];
    if (this.matters && this.matters.length) {
      this.matters.forEach((matter) => {
        if (!matter.isTrustLedgerTopicsBalanced() || matter.checkTrustLedgerSoaLegalFeeBalanced().length > 0) {
          trustLedgerMatterErrors.push({
            matterRecordNumber: matter.matterRecordNumber,
            isTrustLedgerTopicsBalanced: !matter.isTrustLedgerTopicsBalanced(),
            isBalanced: matter.checkTrustLedgerSoaLegalFeeBalanced().length > 0
          });
        }
      });
    }
    if (trustLedgerMatterErrors && trustLedgerMatterErrors.length) {
      let messageString = '<div class="text-center"><div class="padding-top-30">No Documents have been produced</div>';
      let trustLedgerTopicsNotBalancedItems = trustLedgerMatterErrors.filter(
        (item) => !!item.isTrustLedgerTopicsBalanced
      );
      messageString =
        messageString +
        this.generateMessageStringForTLBalanceError(
          'The Trust Ledger columns do not balance for the following matter(s):',
          trustLedgerTopicsNotBalancedItems
        );
      let trustLedgerNotBalancedItems = trustLedgerMatterErrors.filter((item) => !!item.isBalanced);
      messageString =
        messageString +
        this.generateMessageStringForTLBalanceError(
          'The total of the fees & disbursements in the Statement of Account is not equal to Paid Legal Fess & Disbursements in the Trust Ledger for the following matter(s):',
          trustLedgerNotBalancedItems
        );
      messageString = messageString + '</div>';
      this.showErrorDialog(messageString);
    }
    return trustLedgerMatterErrors && trustLedgerMatterErrors.length > 0;
  }

  private generateMessageStringForTLBalanceError(header: string, trustLedgerTopicsNotBalancedItems: any[]): string {
    let messageString = '';
    if (trustLedgerTopicsNotBalancedItems && trustLedgerTopicsNotBalancedItems.length) {
      messageString = messageString + '<div class="padding-bottom-30 padding-top-20">' + header + '</div>';
      messageString =
        messageString +
        '<table class="width-200 border-bottom-0 border-solid  margin-auto"><tr><th class="border-bottom-solid padding-10 text-center semi-bold">Matter #</th></tr>';
      trustLedgerTopicsNotBalancedItems.forEach((trustLedgerMatterError) => {
        messageString =
          messageString +
          '<tr><td class="border-bottom-solid padding-10 text-left">' +
          trustLedgerMatterError.matterRecordNumber +
          '</td></tr>';
      });
      messageString = messageString + '</table>';
    }
    return messageString;
  }

  isSelectedTempletaRequireTLBalanceValidation(): boolean {
    return (
      this.selectedTemplates &&
      this.selectedTemplates.some((item) => item.template && !!item.template.trustLedgerBalancedValidationRequired)
    );
  }

  isSelectedTemplatesHaveAmortizationScheduleValidationFlag(): boolean {
    return (
      this.selectedTemplates &&
      this.selectedTemplates.some(
        (item) =>
          item.template &&
          DocumentTemplateUtil.hasValidationFlag(item.template, preProduceValidationFlags.amortizationSschedule)
      )
    );
  }

  isAnySelectedMultiPriorityTemplate(): boolean {
    return (
      this.selectedTemplates &&
      this.selectedTemplates.some((templateRow) =>
        DocumentTemplateUtil.isMultiPriorityTemplate(templateRow.template && templateRow.template.fileName)
      )
    );
  }

  ngAfterViewInit() {}

  selectTab(type: string) {
    this.selectedTab = type;
    if (type == 'Documents') {
      this.checkBoxAllFormTemplates = this.checkBoxAllTemplate;
      this.checkBoxAllTemplate = this.checkBoxAllDocumentTemplates;
      this.rows = this.documentRows;
      this.selectedTemplates = this.selectedDocumentTemplates;
    } else {
      this.checkBoxAllDocumentTemplates = this.checkBoxAllTemplate;
      this.checkBoxAllTemplate = this.checkBoxAllFormTemplates;
      this.rows = this.formRows;
      this.selectedTemplates = this.selectedFormTemplates;
    }
  }

  isSelectedTabDocuments() {
    return this.selectedTab == 'Documents';
  }

  isSelectedTabForms() {
    return this.selectedTab == 'Forms';
  }

  getPreviewIndex(templateRows: TemplateRow[], previewText: string): number {
    let idx = -1;
    if (templateRows && templateRows.length) {
      templateRows.forEach((row, index) => {
        if (row.template.description == previewText) {
          idx = index;
        }
      });
    }

    return idx;
  }

  isSoaPreviewSelected(): boolean {
    let formTemplates = this.selectedFormTemplates.map((item) => item);
    return this.getPreviewIndex(formTemplates, this.soaPreviewText) != -1;
  }

  isDirectionRefundPreviewSelected(): boolean {
    let formTemplates = this.selectedFormTemplates.map((item) => item);
    return this.getPreviewIndex(formTemplates, this.directionRefundPreviewText) != -1;
  }

  async callAsynchronously(callback: Function): Promise<boolean> {
    let returnSubject: Subject<boolean> = new Subject<boolean>();
    callback();
    let serviceInstance = this;
    let scheduler = setInterval(() => {
      if (serviceInstance.httpClient.linkedMatterBackEndCallsCounter == 0) {
        clearInterval(scheduler);
        returnSubject.next(true);
        returnSubject.complete();
      }
    }, 500);
    return returnSubject.toPromise();
  }

  async initStatementAdjustmentComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.context.statementAdjustmentComponent) {
        this.context.statementAdjustmentComponent.setLocalInstanceMatter(matter);
        await this.context.statementAdjustmentComponent.initSoAdjComponent();
        await this.context.statementAdjustmentComponent.setUpStatementOfAdjustment();
      }
    });
  }

  generateSoaPreview = async (matter: Matter, mattersWithSOAErrors: Matter[]): Promise<StatementAdjustmentPreview> => {
    this.lockScreenService.lockForUpdate = true;
    await this.initStatementAdjustmentComponent(matter);
    if (
      !(
        this.context.statementAdjustmentComponent.soaUtils.isAdjustDateNotValid() ||
        this.context.statementAdjustmentComponent.soaUtils.soaDisplayItemsContainsOutOfRangeItem()
      )
    ) {
      this.lockScreenService.lockForUpdate = false;
      return this.context.statementAdjustmentComponent.soaUtils.generateStatementAdjustmentPreviewObject('PDF');
    } else {
      mattersWithSOAErrors.push(matter);
    }
    this.lockScreenService.lockForUpdate = false;
    return null;
  };

  async initDirectionReFundsComponent(matter: Matter): Promise<boolean> {
    return await this.callAsynchronously(async () => {
      if (this.context.directionReFundsComponent) {
        this.context.directionReFundsComponent.setLocalInstanceMatter(matter);
        await this.context.directionReFundsComponent.ngOnInit();
      }
    });
  }

  generateDirectionReFundsPreview = async (
    matter: Matter,
    mattersWithDirectionRefundsErrors: Matter[]
  ): Promise<DirectionReFundsPreview> => {
    this.lockScreenService.lockForUpdate = true;
    await this.initDirectionReFundsComponent(matter);
    if (
      !this.context.directionReFundsComponent.isClosingDateNotValid() &&
      matter &&
      matter.directionReFunds &&
      matter.directionReFunds.length > 0
    ) {
      this.lockScreenService.lockForUpdate = false;
      return this.context.directionReFundsComponent.generateDirectionReFundsPreviewObject();
    } else {
      mattersWithDirectionRefundsErrors.push(matter);
    }
    this.lockScreenService.lockForUpdate = false;
    return null;
  };

  async share(produceDocumentBeforeSharing?: boolean) {
    if (
      (this.selectedDocumentTemplates && this.selectedDocumentTemplates.length) ||
      (this.selectedFormTemplates && this.selectedFormTemplates.length)
    ) {
      this.lockScreenService.lockForUpdate = true;
      let lockedMatterIds = await this.checkMatterLocking().toPromise();
      this.lockScreenService.lockForUpdate = false;
      if (lockedMatterIds && lockedMatterIds.length > 0) {
        this.showLockedMatterErrorMessage(lockedMatterIds);
      } else {
        if (produceDocumentBeforeSharing && !this.isMatterValidForProduction()) {
          return;
        }
        let toProceed = await this.prepareSharedDocumentPackages(produceDocumentBeforeSharing);
        if (toProceed) {
          if (this.mattersWithNoSharingRecipients.length) {
            //Showing warning message
            let message =
              'The following matters do not have an Other Side Lawyer or Law Clerk who can received shared documents:<br><br>';
            this.mattersWithNoSharingRecipients.forEach((matter) => {
              message += `- ${matter.matterRecordNumber}<br>`;
            });
            if (this.bulkSharing && this.bulkSharing.selectedMatters && this.bulkSharing.selectedMatters.length) {
              message += '<br>Would you like to proceed with the other matters?';
            }

            let toProceed = await this.dialogService
              .confirm('WARNING', message, !this.bulkSharing.selectedMatters.length, null, null, true)
              .toPromise();
            if (toProceed && this.bulkSharing.selectedMatters && this.bulkSharing.selectedMatters.length) {
              this.initializeSharingModal(produceDocumentBeforeSharing);
            }
          } else {
            this.initializeSharingModal(produceDocumentBeforeSharing);
          }
        }
      }
    }
  }

  initializeSharingModal(produceDocumentBeforeSharing: boolean) {
    let waitForStopCodes = false;
    if (produceDocumentBeforeSharing) {
      waitForStopCodes = this.someTemplatesWithStopCodesExist();
    }

    this.dialogService.matDialogContent({
      content: ShareDocumentsModalComponent,
      context: {
        bulkSharing: this.bulkSharing,
        documentShareMode: 'BULK_SHARING'
      },
      onFulfillment: (result) => {
        if (result && result.bulkSharing) {
          if (produceDocumentBeforeSharing) {
            if (waitForStopCodes) {
              this.callBulkShareRequest(result.bulkSharing, produceDocumentBeforeSharing, waitForStopCodes);
            } else {
              //Show confirmation message and close the modal after calling the API
              this.callBulkShareAndCloseModal(result.bulkSharing, produceDocumentBeforeSharing);
            }
          } else {
            //Share without produce
            this.callBulkShareRequest(result.bulkSharing);
          }
        }
      }
    });
  }

  callBulkShareRequest(
    bulkSharing: BulkSharing,
    produceDocumentBeforeSharing?: boolean,
    waitForStopCodes?: boolean
  ): void {
    this.documentProductionService.bulkShare(bulkSharing).subscribe(
      (result: BulkSharingResponse) => {
        if (result) {
          if (produceDocumentBeforeSharing && this.context.shareResultNotificationHandler) {
            //If the result contains stop codes, then open the stop code modal
            if (waitForStopCodes) {
              if (result.matterStopCodes && result.matterStopCodes.length) {
                let stopCodeList: any[] = this.buildStopCodeList(result.matterStopCodes);
                this.openStopCodesPopup(stopCodeList, true);
              } else {
                //Templates has conditional stop codes but the condition does not apply
                this.showBulkProduceAndShareResult(result);
              }
            } else {
              //No Stop Codes
              this.context.shareResultNotificationHandler(result);
            }
          } else {
            //Share only without produce
            let message = this.createbulkSharingResponseMessage(result);
            this.dialogService.confirm('INFORMATION', message, true, null, null, true).subscribe(() => {
              this.dialog.close();
            });
          }
        }
      },
      (error) => {
        this.dialogService.confirm('ERROR', 'ERROR', true);
      }
    );
  }

  createbulkSharingResponseMessage = (result: BulkSharingResponse): string => {
    let message = '';
    if (result.bulkSharingResult == bulkShareResultStatus.COMPLETED) {
      message = 'All selected documents have been shared successfully.';
    }
    if (result.bulkSharingResult == bulkShareResultStatus.FAILED) {
      message = result.errorMessage;
    }
    if (result.bulkSharingResult == bulkShareResultStatus.PARTIAL) {
      message = 'There were unexpected errors sharing some of the specified documents.<br><br>';
      if (result.sharedDocuments && result.sharedDocuments) {
        let nonSharedDocMessage = '';
        let sharedDocMessage = '';
        result.sharedDocuments.forEach((sharedDocument) => {
          if (sharedDocument.documentName) {
            if (!sharedDocMessage) {
              sharedDocMessage = 'The following documents are available and will be shared<br><br>';
            }
            sharedDocMessage += `${sharedDocument.matterRecordNumber} - ${sharedDocument.templateName}<br>`;
          } else {
            if (!nonSharedDocMessage) {
              nonSharedDocMessage = 'The following documents are not available and will not be shared<br><br>';
            }
            nonSharedDocMessage += `${sharedDocument.matterRecordNumber} - ${sharedDocument.templateName}<br>`;
          }
        });
        message += nonSharedDocMessage + '<br>' + sharedDocMessage;
      }
    }

    return message;
  };

  async prepareSharedDocumentPackages(produceDocumentBeforeSharing: boolean): Promise<boolean> {
    this.mattersWithNoSharingRecipients = [];
    this.bulkSharing = this.createBulkSharing();
    this.bulkSharing.produceDocumentBeforeSharing = produceDocumentBeforeSharing;
    this.lockScreenService.lockForUpdate = true;
    for (let matter of this.matters) {
      let matterDataPackages: BulkShareMatterData[] = await this.prepareBulkShareMatterData(matter);
      if (matterDataPackages && matterDataPackages.length) {
        this.createEmailSubjectsForEachMatter(matterDataPackages, matter);
        this.bulkSharing.selectedMatters.push(...matterDataPackages);
      } else {
        this.mattersWithNoSharingRecipients.push(matter);
      }
    }
    this.lockScreenService.lockForUpdate = false;

    let mattersWithSOAErrors = [];
    let mattersWithDirectionRefundsErrors = [];
    if (this.isSoaPreviewSelected()) {
      await this.prepareSoaBulkShareMatterData(mattersWithSOAErrors);
    }
    if (this.isDirectionRefundPreviewSelected()) {
      await this.prepareDirectionRefundsBulkShareMatterData(mattersWithDirectionRefundsErrors);
    }
    if ((mattersWithSOAErrors.length || mattersWithDirectionRefundsErrors.length) && produceDocumentBeforeSharing) {
      return await this.showMattersError(mattersWithSOAErrors, mattersWithDirectionRefundsErrors);
    } else {
      return true;
    }
  }

  async prepareSoaBulkShareMatterData(mattersWithSOAErrors: Matter[]) {
    for (let matter of this.matters) {
      let bulkShareMatterData: BulkShareMatterData = this.bulkSharing.selectedMatters.find(
        (obj) => obj.matterId == matter.id
      );
      let sap: StatementAdjustmentPreview = await this.generateSoaPreview(matter, mattersWithSOAErrors);
      if (sap && bulkShareMatterData) {
        bulkShareMatterData.statementAdjustmentPreview = sap;
      }
    }
  }

  async prepareDirectionRefundsBulkShareMatterData(mattersWithDirectionRefundsErrors: Matter[]) {
    for (let matter of this.matters) {
      let bulkShareMatterData: BulkShareMatterData = this.bulkSharing.selectedMatters.find(
        (obj) => obj.matterId == matter.id
      );
      let directionReFundsPreview: DirectionReFundsPreview = await this.generateDirectionReFundsPreview(
        matter,
        mattersWithDirectionRefundsErrors
      );
      if (directionReFundsPreview && bulkShareMatterData) {
        bulkShareMatterData.directionReFundsPreview = directionReFundsPreview;
      }
    }
  }

  async prepareBulkShareMatterData(matter: Matter): Promise<BulkShareMatterData[]> {
    let selectedBulkShareMatterData: BulkShareMatterData[] = [];
    let unityContacts: Contact[] = [];
    let nonUnityContacts: Recipient[] = [];

    let recipients: Recipient[] = await this.getOtherPartyContacts(matter);
    if (recipients && recipients.length) {
      let contactIds = recipients
        .filter((item) => item.contact.sourceContactId)
        .map((item) => item.contact.sourceContactId);
      if (contactIds && contactIds.length) {
        let unityContactIds = await this.contactQueryService.getContactIdsHavingActiveUser(contactIds).toPromise();
        for (let recipient of recipients) {
          if (unityContactIds.find((id) => id == recipient.contact.sourceContactId)) {
            unityContacts.push(recipient.contact);
          } else {
            if (recipient.contact.email && Utils.validateEmail(recipient.contact.email)) {
              nonUnityContacts.push(recipient);
            }
          }
        }
      }

      if (unityContacts && unityContacts.length) {
        let unityRecipientIds = unityContacts.map((contact) => contact.sourceContactId);
        let bulkShareMatterData = new BulkShareMatterData();
        bulkShareMatterData.matterId = matter.id;
        bulkShareMatterData.unityRecipientIds = unityRecipientIds;
        selectedBulkShareMatterData.push(bulkShareMatterData);
      }
      for (let connectRecipient of nonUnityContacts) {
        let bulkShareMatterData = this.createSharedDocumentPackage(
          matter,
          connectRecipient.contact,
          connectRecipient.role
        );
        selectedBulkShareMatterData.push(bulkShareMatterData);
      }
    }

    return selectedBulkShareMatterData;
  }

  createBulkSharing(): BulkSharing {
    let bulkSharing: BulkSharing = new BulkSharing();
    let documentTemplateIds = [];
    if (this.selectedDocumentTemplates && this.selectedDocumentTemplates.length) {
      documentTemplateIds = extractTemplateIdAndIndex(...this.selectedDocumentTemplates);
      documentTemplateIds.forEach((documentTemplateId) => {
        bulkSharing.selectedTemplates.push(
          DocumentProductionData.createDocGenRequestForSelectedTemplate(documentTemplateId, this.fileType)
        );
      });
    }
    //Handle Form Documents
    let formTemplateIds = [];
    let formTemplates;
    if (this.selectedFormTemplates && this.selectedFormTemplates.length) {
      formTemplates = this.selectedFormTemplates.map((item) => item);
      formTemplateIds = this.getFormTemplateIdsFromSelectedTemplates(formTemplateIds, formTemplates);
      let customTemplateNameMap: any[] = this.createFormsCustomTemplateNameMap(formTemplateIds, formTemplates);
      formTemplateIds.forEach((formTemplateId) => {
        bulkSharing.selectedTemplates.push(
          DocumentProductionData.createDocGenRequestForSelectedTemplate(
            formTemplateId,
            'PDF',
            true,
            customTemplateNameMap
          )
        );
      });
    }

    bulkSharing.selectedPreviews = [];
    if (this.isSoaPreviewSelected()) {
      bulkSharing.selectedPreviews.push(sharingPreviews.statementOfAdjustment);
    }
    if (this.isDirectionRefundPreviewSelected()) {
      bulkSharing.selectedPreviews.push(sharingPreviews.directionRefunds);
    }
    bulkSharing.sharingMessageSubject = this.emailFieldService.getMailSubject(EmailKeys.vendorsSolicitor);
    bulkSharing.selectedMatters = [];
    return bulkSharing;
  }

  createSharedDocumentPackage(matter: Matter, contact: Contact, connectRecipientRole: string): BulkShareMatterData {
    let bulkShareMatterData = new BulkShareMatterData();
    bulkShareMatterData.matterId = matter.id;
    //sharedDocumentsPackage.recipientDearName = contact.dear;
    bulkShareMatterData.recipientName = contact.getOrganizationNameOrFullName();
    bulkShareMatterData.connectRecipientEmail = contact.email;
    bulkShareMatterData.connectRecipientRole = connectRecipientRole;
    return bulkShareMatterData;
  }

  createRecipient(contact: Contact, role: string): Recipient {
    let recipient = new Recipient();
    recipient.contact = contact;
    recipient.role = role;
    return recipient;
  }

  async getOtherPartyContacts(matter: Matter): Promise<Recipient[]> {
    let recipients: Recipient[] = [];
    let contacts: Contact[] = await this.getSolicitorsList(matter);
    if (contacts && contacts.length) {
      contacts.forEach((contact) => {
        recipients.push(this.createRecipient(contact, 'OTHERPARTY_SOLICITOR'));
      });
    }
    let otherPartyLawClerkContact: Contact = await this.getOtherPartyLawClerk(matter);
    if (otherPartyLawClerkContact) {
      recipients.push(this.createRecipient(otherPartyLawClerkContact, 'LAWCLERK'));
    }
    return recipients;
  }

  async getOtherPartyLawClerk(matter: Matter): Promise<Contact> {
    let otherPartyLawClerkContact: Contact;
    if (matter.otherPartyContactInfo) {
      if (matter.otherPartyContactInfo.lawClerkId) {
        otherPartyLawClerkContact = await this.contactQueryService
          .getOtherSideLawClerkById(matter.otherPartyContactInfo.lawClerkId)
          .toPromise();
      } else {
        otherPartyLawClerkContact = new Contact();
        otherPartyLawClerkContact.email = matter.otherPartyContactInfo.lawClerkEmail;
        otherPartyLawClerkContact.organizationName = matter.otherPartyContactInfo.lawClerkName;
      }
    }
    return otherPartyLawClerkContact;
  }

  async getSolicitorsList(matter: Matter): Promise<Contact[]> {
    let solicitorMPList: MatterParticipant[] = [];
    let solicitorSourceContactList: Contact[] = [];
    let solicitorMP: MatterParticipant = matter.otherPartySolicitor;
    if (solicitorMP && solicitorMP.contact && solicitorMP.contact.sourceContactId) {
      solicitorMPList.push(solicitorMP);
    }

    let getContactObservables: Observable<any>[];
    let solicitorsList: number[] = solicitorMPList
      .filter((item) => item.contact.sourceContactId)
      .map((item) => item.contact.sourceContactId);

    if (solicitorsList && solicitorsList.length > 0) {
      getContactObservables = solicitorsList.map((contactId: number) =>
        this.contactQueryService.getContactForMatter(contactId)
      );
      if (getContactObservables) {
        let sourceContacts: Contact[] = await Observable.forkJoin(getContactObservables).toPromise();
        if (sourceContacts && sourceContacts.length) {
          sourceContacts.forEach((res: any) => {
            solicitorSourceContactList.push(res);
          });
        }
      }
    }
    return solicitorSourceContactList;
  }

  produceAndShare() {
    this.share(true);
  }

  isFormTabVisible(): boolean {
    if (this.matters && this.matters.length) {
      return !(this.matters[0].isMortgage || this.matters[0].isCustomMatter() || this.matters[0].isWillMatter());
    } else {
      return false;
    }
  }

  getTitle(): string {
    return this.isDocumentMergeActive ? 'Document Production' : 'Document Production and Sharing';
  }

  someTemplatesWithStopCodesExist(): boolean {
    if (this.selectedDocumentTemplates && this.selectedDocumentTemplates.length) {
      let templates = this.selectedDocumentTemplates.map((templateRow) => templateRow.template);
      return templates && templates.some((template) => template.templateHasStopCodes);
    } else {
      return false;
    }
  }

  buildStopCodeList(matterStopCodes: MatterStopCode[]): any[] {
    let stopCodeList: any[] = [];
    matterStopCodes.forEach((item) => {
      let documentProductionData = new DocumentProductionData();
      documentProductionData.matterId = item.matterId;
      let templateStopCode = new TemplateStopCode();
      templateStopCode.stopCodes = item.stopCodesList;
      documentProductionData.templates = [];
      documentProductionData.templates.push(templateStopCode);
      templateStopCode.templateName = item.templateName;
      templateStopCode.templateId = item.templateId;
      templateStopCode.mortgageIndex = item.mortgageIndex;
      templateStopCode.holdbackIndex = item.holdbackIndex;
      stopCodeList.push({documentProductionData: documentProductionData});
    });
    return stopCodeList;
  }

  updateBulkShareStopCodes(matterStopCodeList: MatterStopCode[]): void {
    if (this.bulkSharing && this.bulkSharing.selectedTemplates && this.bulkSharing.selectedTemplates.length) {
      matterStopCodeList.forEach((matterStopCode) => {
        let templateWithStopCode = this.bulkSharing.selectedTemplates.find(
          (template) => template.templateId == matterStopCode.templateId
        );
        if (templateWithStopCode) {
          templateWithStopCode.stopCodes = matterStopCode.stopCodesList;
        }
      });
    }
  }

  callBulkShareAndCloseModal(bulkSharing: BulkSharing, produceDocumentBeforeSharing: boolean): void {
    //Show confirmation message and close the modal after calling the API
    this.dialogService.confirm('INFORMATION', this.produceAndShareMessage, true).subscribe(() => {
      this.callBulkShareRequest(bulkSharing, produceDocumentBeforeSharing);
      this.dialog.close();
    });
  }

  showBulkProduceAndShareResult(result: BulkSharingResponse): void {
    let message = 'All the specified documents have been produced and shared successfully. ';
    let messageType = 'INFORMATION';
    if (result.bulkSharingResult != bulkShareResultStatus.COMPLETED) {
      message = 'There were unexpected errors producing and/or sharing some of the specified documents. ';
      messageType = 'ERROR';
    }
    this.dialogService.confirm(messageType, message, true).subscribe(() => {
      this.dialog.close();
    });
  }

  isMatterValidForProduction(): boolean {
    // Check if trust ledger balanced
    let trustLedgerBalanceValidationCheck = this.isSelectedTempletaRequireTLBalanceValidation();
    if (
      !trustLedgerBalanceValidationCheck ||
      (trustLedgerBalanceValidationCheck && !this.checkForTrustLedgeMatterErrors())
    ) {
      // Check if Interest Only Mortgage matters and amortization schedule validation templates
      let amortizationScheduleValidationFlag = this.isSelectedTemplatesHaveAmortizationScheduleValidationFlag();
      if (
        !amortizationScheduleValidationFlag ||
        (amortizationScheduleValidationFlag && !this.checkForInterestOnlyMortgageMatters())
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  checkForInterestOnlyMortgageMatters(): boolean {
    let interestOnlyMortgageMatterErrors: any = [];
    if (this.matters && this.matters.length) {
      this.matters.forEach((matter) => {
        if (this.isDocumentMergeActive && this.selectedTemplates && this.selectedTemplates.length) {
          // For document mail merge
          let selectedTemplate = this.selectedTemplates[0].template;
          let mortgageIndex = selectedTemplate.mortgageIndex ? selectedTemplate.mortgageIndex - 1 : 0;
          if (matter.isMortgageHasInterestOnlyTerm(mortgageIndex)) {
            interestOnlyMortgageMatterErrors.push({matterRecordNumber: matter.matterRecordNumber});
          }
        } else {
          if (
            (this.isAnySelectedMultiPriorityTemplate() && matter.someMortgageWithInterestOnlyTerm()) || //For multi-priority templates we have to check all mortgages
            (!this.isAnySelectedMultiPriorityTemplate() && matter.isMortgageHasInterestOnlyTerm(0))
          ) {
            //For non multipriority templates, we check only the first mortgage
            interestOnlyMortgageMatterErrors.push({matterRecordNumber: matter.matterRecordNumber});
          }
        }
      });
    }
    if (interestOnlyMortgageMatterErrors && interestOnlyMortgageMatterErrors.length) {
      let messageString = '<div class="text-center"><div class="padding-top-30">No Documents have been produced</div>';
      messageString =
        messageString +
        this.generateMessageStringForTLBalanceError(
          'The Amortization Schedule cannot be produced as a Mortgage is set to Interest Only in the following matter(s):',
          interestOnlyMortgageMatterErrors
        );
      messageString = messageString + '</div>';
      this.showErrorDialog(messageString);
    }
    return interestOnlyMortgageMatterErrors && interestOnlyMortgageMatterErrors.length > 0;
  }

  private createEmailSubjectsForEachMatter(matterDataPackages: BulkShareMatterData[], matter: Matter) {
    // Here I don't know what matter in the service now and where it will be used after this call
    // that why I save this matter there we can discuss it
    let matterInEmailService = this.emailFieldService.matter;

    matterDataPackages.forEach((shareMatterData) => {
      this.emailFieldService.matter = matter;
      shareMatterData.sharingMessageSubject = this.emailFieldService.getMailSubject(EmailKeys.vendorsSolicitor);
    });

    this.emailFieldService.matter = matterInEmailService;
  }
}
