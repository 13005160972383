<form class="form-horizontal" id="depositForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-lg-10">
                <h1>My Personal Details</h1>
            </div>
            <button type="button" class="btn-close pull-right" (click)="close()">&times;</button>
        </div>

        <div class="row modal-body" *ngIf="contact" [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">
            <div class="col-lg-12 fields-group margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">Global Information</span>
                </div>
            </div>
            <div class="form-group">
                <!-- Business Role -->
                <label class="control-label col-lg-2">Business Role</label>
                <div class="col-lg-4">
                    <input type="text"  id="contactType" name="contactType" [value]="contact.contactBusinessRole"
                           [dp-read-only]="true" class="form-control"/>
                </div>
                <!-- Last Updated -->
                <label class="control-label col-lg-2" for="lastUpdated">Last Updated</label>
                <div class="col-lg-4">
                    <input class="form-control" id="lastUpdated" value="{{contact.lastUpdatedTimeStamp | dpDateFormatPipe}}"
                           readonly/>
                </div>
            </div>

            <div class="form-group">
                <!-- Last Name -->
                <label class="control-label col-lg-2" for="lastName"
                       [ngClass]="{error: !lastName.valid && isFormDirty}">Surname*</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="lastName" name="lastName" #lastName="ngModel" [readonly]="isSsoEnabled()"
                           [(ngModel)]="contact.contactName.lastName"
                           fieldKey="admin.staffProfile.lastName"
                           dp-error-validator

                           class="form-control focus-first" maxlength="60" trimmed/>
                    <div *ngIf="!lastName.valid && isFormDirty">
                        <div class="error-wrapper" style="position:relative;"><i class="fa fa-exclamation-triangle"
                                                                                 aria-hidden="true"></i>
                            <p class="error">{{surNameLabel}} must be completed.</p>
                            <a class="btn-close"><b class="fa fa-times" aria-hidden="true"></b></a>
                        </div>
                    </div>
                </div>
                <!-- Last Updated By -->
                <label class="control-label col-lg-2" for="lastUpdatedBy">Last Updated By</label>
                <div class="col-lg-4">
                    <input class="form-control" id="lastUpdatedBy" value="{{contact.updatedByUser.fullName}}" readonly/>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-lg-2" for="firstName"
                       [ngClass]="{error: !firstName.valid && isFormDirty}">First Name*</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="firstName" name="firstName"  #firstName="ngModel" [readonly]="isSsoEnabled()"
                           [(ngModel)]="contact.contactName.firstName"
                           fieldKey="admin.staffProfile.firstName"
                           dp-error-validator
                           maxlength="40" class="form-control" trimmed/>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-lg-2">Middle Name</label>
                <div class="col-lg-4">
                    <input type="text" trimmed id="middleName" name="middleName" [(ngModel)]="contact.contactName.middleName" [readonly]="isSsoEnabled()"
                           maxlength="40" class="form-control"/>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-lg-2">Firm Name</label>
                <div class="col-lg-10">
                    <input type="text" trimmed id="firmName" name="firmName"
                           maxlength="50" class="form-control"
                           [ngModel]="contact?.firmName"
                           readonly="true"/>
                </div>
            </div>

            <div>
                <dp-address-form [address]="contact.mailingAddress"
                                 [addressSummaryLabel]="'Mailing Address'"
                                 [showDropdown]="true"
                                 [fieldPrefix]="'admin.staffProfile.address'"
                                 [addressDd]="staffProfileAddressCopyOptions"
                ></dp-address-form>
            </div>

            <div class="form-group">
                <div>
                    <label class="control-label col-lg-2">Work Phone</label>
                    <div class="col-lg-4">
                        <input type="text" trimmed name="workPhone" class="form-control" [(ngModel)]="contact.workPhone"
                               maxlength="30"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <label i18n class="control-label col-lg-2">Cell Phone</label>
                    <div class="col-lg-4">
                        <input type="text" trimmed name="mobilePhone" class="form-control" [(ngModel)]="contact.cellPhone"
                               maxlength="30"/>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <label class="control-label col-lg-2">Fax</label>
                    <div class="col-lg-4">
                        <input type="text" trimmed name="fax" class="form-control" [(ngModel)]="contact.faxPhone"
                               maxlength="30"/>
                    </div>
                </div>
            </div>
            <div *ngIf="isSsoEnabled()" class="form-group">
                <div class="col-lg-2"></div>
                <div class="col-lg-4">
                    <label *ngIf="!hasSystemRole()"> <a href="{{keycloakEditProfileLink}}" target="_blank">Click this link to update your Dye & Durham login information  </a></label>
                </div>
            </div>
            <div class="form-group">
                <div>
                    <label *ngIf="isSsoEnabled()" class="control-label col-lg-2" for="email" [ngClass]="{error: !email.valid}">Communication Email Address</label>
                    <label *ngIf="!isSsoEnabled()" class="control-label col-lg-2" for="email" [ngClass]="{error: !email.valid}">Email Address*</label>
                    <div class="col-lg-4">

                        <input type="text" trimmed id="email" name="email" #email="ngModel" class="form-control"
                               [(ngModel)]="contact.email"
                               [readonly]="true"
                               maxlength="50"
                               fieldKey="admin.staffProfile.email"
                               [dp-error-validator]="{isEmail : true}"
                               multi-email-validator
                        />
                    </div>
                    <div *ngIf="!isSsoEnabled" class="col-lg-6 padding-top-10">Go to My Unity Credentials to edit email address</div>

                </div>
            </div>
            <div class="form-group">
                <div>
                    <label class="control-label col-lg-2">Dear</label>
                    <div class="col-lg-4">
                        <input type="text" trimmed name="dear" class="form-control" [(ngModel)]="contact.dear"
                               maxlength="30"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 fields-group padding-top-20 margin-bottom-0">
                <div class="form-group main-level">
                    <span class="title min-height-30">Private Information</span>
                </div>
            </div>
            <div>
                <div class="form-group">
                    <label class="control-label col-lg-2">Gender</label>
                    <div class="col-lg-4">
                        <select class="form-control" id="gender"
                                name="gender" [(ngModel)]="contact.gender">
                            <option *ngFor="let gender of genders" [value]="gender.value">{{gender.label}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="form-group">

                <label class="control-label col-lg-2">Initials<span *ngIf="isInitialMandatory()">*</span></label>

                <div class="col-lg-4">
                    <input type="text" trimmed id="initials" name="initials" #initials="ngModel"
                           fieldKey="admin.staffProfile.initials"
                           dp-error-validator
                           [(ngModel)]="contact.contactName.initials"
                           dp-alpha-only
                           maxlength="4"
                           (blur)="formatInitials()"
                           class="form-control"
                           trimmed/>
                </div>
            </div>
            <div class="form-group" *ngIf="user.getEnabledUserProvinceCodes().length > 1">
                <label class="control-label col-lg-2">Accessible Provinces</label>
                <div class="col-lg-4">
                    <!--<input type="text" trimmed id="userProvince" name="userProvince" #initials="ngModel"
                           [(ngModel)]="contact.getEnabledUserProvinceCodes()" readonly = "true"
                           class="form-control"/>-->
                    <input type="text"  id="userProvince" name="userProvince" [value]="userAccessibleProvinceCodes()"
                           [dp-read-only]="true" class="form-control"/>
                    <!--<dp-multi-select [multiSelectData]="userAccessibleProvincesOptions()" [inputName]="'userProvince'"
                                     [title]="'No Province Selected'"
                                     [attr.disabled] = "true"
                                     [multiSelectDataSelected]="contact.getEnabledUserProvinceCodes()"
                                     [showLabelInTitle]="true"
                    ></dp-multi-select>-->
                </div>
            </div>

            <div class="form-group"  statusBarHelp tabindex="0">
                <div class="col-lg-2 vertical-align-label">
                    <label class="control-label text-right">Unity Messaging Signature Block</label>
                </div>
                <div class="col-lg-6" >
                    <textarea class="form-control"
                              name="unitySignatureBlock"
                              id="unitySignatureBlock"
                              trimmed
                              [(ngModel)]="user.unitySignatureBlock">
                    </textarea>
                </div>
            </div>

            <div class="form-group col-lg-12">
                <div class="col-lg-6">
                    <label class="control-label col-lg-4">Messaging Delegates</label>
                    <div class="col-lg-8">
                        <dp-multi-select [multiSelectData]="messageDelegateUsers"
                                         [inputName]="'Messaging Delegate Users '"
                                         [title]="'No Delegates Selected'"
                                         [multiSelectedTitle]="'Multiple Messaging Delegates'"
                                         [showLabelInTitle]="true"
                                         [splitLabelLawyersAndClerks]="true"
                                         [multiSelectDataSelected]="user.messagingDelegateUserIds"
                                         (updateMultiSelect)="updateMultiSelectFilters($event)"
                                         class="clerk-multi-select"></dp-multi-select>
                    </div>
                </div>

            </div>

            <div class="form-group" *ngIf="user.getEnabledUserProvinceCodes().length > 1">
                <label class="control-label col-lg-2">Default Matter Province*</label>
                <div class="col-lg-4">
                    <select class="form-control" id="province"  [fieldKey]="'admin.staffProfile.contact.defaultProvinceCode'" [(ngModel)]="user.defaultProvinceCode"
                            dp-error-validator name="province" #province="ngModel">
                        <option *ngFor="let defaultProvince of defaultProvinceOptions" [value]="defaultProvince.value">{{defaultProvince.label}}</option>
                    </select>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-lg-2">Correspondence Destination(s)</label>
                <div class="col-lg-4">
                    <select class="form-control" id="sharingMessage" name="sharingMessage"
                            [(ngModel)]="user.sharingMessageNotificationMode">
                        <option *ngFor="let sharingMessage of sharingMessageOptions" [value]="sharingMessage.value">{{sharingMessage.label}}</option>
                    </select>
                </div>
            </div>
            <dp-responsible-staff
                [readOnly]= "false"
                [user]="user"
            >
            </dp-responsible-staff>
            <div class="form-group">
                <label class="control-label col-lg-2">Jurisdiction</label>
                <div class="col-lg-10">
                    <p-autoComplete id="jurisdiction" name="jurisdiction"
                                    field="jurisdictionName" [minLength]="0" [delay]="1000" maxlength="75"
                                    scrollHeight="350px"
                                    [(ngModel)]="selectedJurisdiction"
                                    [class.autocomplete-searching]="jurisdictionsLoading"
                                    [suggestions]="jurisdictions"
                                    placeholder="Search by Name"
                                    (completeMethod)="searchJurisdiction($event)"
                                    (onSelect)="jurisdictionOnSelect(selectedJurisdiction)"
                                    (keyup)="checkIfJurisdictionEmpty(selectedJurisdiction)"
                                    class="autocomplete-search-icon"
                                    tabindex="0">

                        <ng-template let-jurisdiction pTemplate="item">
                            <li class="ui-autocomplete-list-item ui-helper-clearfix" [disableItem]="jurisdiction">
                                                    <span class="omnibarLi100 col-lg-4" *ngIf="jurisdictionAutoCompleteEmptyFlag == true">
                                                        {{jurisdiction.jurisdictionName}}</span>
                                <span class="omnibarLi40 col-lg-4" *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                                        {{jurisdiction.jurisdictionName}}</span>
                                <span class="omnibarLi40 col-lg-4" *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                                        {{jurisdiction?.city?.name}}</span>
                                <span class="omnibarLi40 col-lg-4" *ngIf="jurisdictionAutoCompleteEmptyFlag == false">
                                                        {{jurisdiction?.city?.municipality?.name}}</span>
                            </li>
                        </ng-template>
                    </p-autoComplete>
                </div>
            </div>

            <div class="form-group">
                <label class="control-label col-lg-2">
                    City
                </label>
                <div class="col-lg-4">
                    <input type="text"
                           name="city"
                           class="form-control"
                           maxlength="50"
                           trimmed
                           [(ngModel)]="contact.city"

                    />
                </div>
                <label class="control-label col-lg-2">
                    Municipality
                </label>
                <div class="col-lg-4">
                    <input type="text"
                           name="Municipality"
                           class="form-control"
                           maxlength="50"
                           trimmed
                           [(ngModel)]="contact.municipality"
                    />
                </div>

            </div>
            <div class="form-group">
                <label class="control-label col-lg-2">
                    Notes
                </label>
                <div class="col-lg-10">
                    <input type="text"
                           name="notes"
                           class="form-control"
                           maxlength="255"
                           trimmed
                           [(ngModel)]="contact.note"

                    />
                </div>
            </div>
            <dp-other-contact-info-fields [otherContactInfoFields]="contact && contact.otherContactInformation"
            >
            </dp-other-contact-info-fields>
            <div class="form-group">
                <label for="optIn" class="control-label col-lg-2 padding-top-10">CASL Email Opt-in</label>
                <div class="col-lg-1">
                    <span class="radio display-block padding-top-10"  (click)="handleCaslInfoChange()">
                        <input id="optIn"
                               name="optIn"
                               type="radio"
                               [value]="false"
                               [(ngModel)]="caslInfo.caslEmailOptOut"
                        />
                        <label for="optIn"  dp-radio-label-event-directive></label>
                    </span>
                </div>
                <div class="col-lg-9">
                    <label for="optIn" style="margin-left: -50px;">
                        {{commonLabels.caslOptIn}}
                    </label>
                </div>
            </div>
            <div class="form-group">
                <label for="optOut" class="control-label col-lg-2 padding-top-5">CASL Email Opt-out</label>
                <div class="col-lg-1">
                    <span class="radio display-block padding-top-5" (click)="handleCaslInfoChange()">
                        <input id="optOut"
                               name="optOut"
                               type="radio"
                               [value]="true"
                               [(ngModel)]="caslInfo.caslEmailOptOut"
                        />
                        <label for="optOut"  dp-radio-label-event-directive></label>
                    </span>
                </div>
                <div class="col-lg-9">
                    <label for="optOut" style="margin-left: -50px;">
                        {{commonLabels.caslOptOut}}
                    </label>
                </div>
            </div>

            <div class="form-group" *ngIf="isCaslInfoAvailable">
                    <label class="control-label col-lg-2">CASL Requested By</label>
                    <div class="col-lg-4">
                        <select class="form-control"
                                name="caslRequestedById" [(ngModel)]="caslInfo.caslRequestedById"
                                (ngModelChange)="onContactActiveFlagChange()"
                                [dp-read-only]="isCaslRequestedByDisabled">
                            <option *ngFor="let profile of staffProfilesOptions" [value]="profile.id">{{profile.surnameLastFullName}}</option>
                        </select>
                    </div>
                </div>

            <div class="form-group" *ngIf="isCaslInfoAvailable">
                    <label class="control-label col-lg-2">
                        CASL Last Modified By
                    </label>
                    <div class="col-lg-4">
                        <input type="text"
                               name="caslLastModifiedBy"
                               class="form-control"
                               [dp-read-only]="true"
                               [value]="caslInfo.caslLastModifiedBy||''"
                        />
                    </div>
                    <label class="control-label col-lg-2">
                        CASL Last Modified Date
                    </label>
                    <div class="col-lg-4">
                        <input class="form-control"
                                [dp-read-only]="true"
                               value="{{caslInfo.caslLastModifiedDate|dpDateFormatPipe}}"
                        />
                    </div>
                </div>
                <div class="form-group" *ngFor="let cpc of contact.contactProvinceCapacities; let i = index;">
                    <label class="control-label col-lg-2">
                        {{cpc.provinceName}} Notary or Commissioner
                    </label>
                    <div class="col-lg-2">
                        <select name="notaryOrCommissioner_{{i}}" class="form-control" [disabled]="contact.isSolicitor"  [(ngModel)]="cpc.provinceCapacity">
                            <option *ngFor="let nc of notaryOrCommissionerSelect" [value]="nc.value">
                                {{nc.label}}
                            </option>
                        </select>
                    </div>


                    <div *ngIf="cpc.provinceCapacity=='NOTARY'">
                             <div class="col-lg-1"></div>
                            <label class="control-label col-lg-3">
                               Insert Name and Address into registration documents?
                            </label>

                            <div class="col-lg-2">
                              <select name="insertNameAddressIntoReg_{{i}}" class="form-control" [(ngModel)]="cpc.insertNameAddressToRegistrationDocument">
                                    <option *ngFor="let yesNo of insertNameIntoRegDocumentsSelect"  [value]="yesNo.value">
                                        {{yesNo.label}}
                                    </option>
                              </select>
                            </div>
                    </div>

                    <div *ngIf="cpc.provinceCapacity=='COMMISSIONER'">
                        <div class="col-lg-2"></div>
                        <label class="control-label col-lg-2">
                            Commission Expiry
                        </label>
                        <div class="col-lg-4">
                            <dp-partial-date [fullDateRequired]="true" [inputDate]="cpc.commissionExpiry" id="commissionerDate_{{i}}"
                                             (dateChange)="onDateChangeCommissioner($event,i)"
                                             [fieldKey]="fieldKey? fieldKey : 'personaldetails.commissionerDate'"
                                             [identifierId]="'commissionerDate_'+(i)"
                                             dp-error-validator>
                            </dp-partial-date>
                        </div>
                    </div>
                </div>

        </div>
    </div>
    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button id="okBtn" class="dp-btn keyboard-save-action" type="button" (click)="ok()">
                <span>Save</span>
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>
