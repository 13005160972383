import {Component, Inject} from '@angular/core';
import {Contact} from '../../../matters/shared/contact';
import {MatterParticipant} from '../../shared/matter-participant';
import {PrintIdAction, PrintIdInfo} from './print-id-info';
import {IdentificationRecord} from '../../shared/identification-record';
import {IdentificationDocument} from '../../shared/identification-document';
import moment from 'moment';
import * as _ from 'lodash';
import {ContactType} from '../../shared/contact-type.';
import {Matter} from '../../shared/matter';
import {matterParticipantRoleLabels} from '../../shared/matter-participant-role-types';
import {SelectItem} from 'primeng/api';
import {DocumentProductionUtil} from '../../shared/document-production/document-production-util';
import {MatterService} from '../../matter.service';
import {FamilyLawAct} from '../../shared/fla-data';
import {ProducibleDocType} from '../../document-production/document-production-data';
import Utils from '../../../shared-main/utils';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

class PrintIdModalContext {
  public purchasers: Array<MatterParticipant>;
  public selectedRecords: Array<PrintIdInfo>;
  public action: string;
  public matter?: Matter;
  public outputType: string;
  public isDocumentProfileTypeWord: boolean;
}

@Component({
  selector: 'dp-print-id-modal-content',
  templateUrl: './print-id.modal.component.html',
  styleUrls: ['./print-id.modal.component.scss']
})
export class PrintIdModal extends ModalComponent<PrintIdModalContext> {
  rows: PrintIdInfo[] = [];
  outputTypeOptions: SelectItem[];

  constructor(
    public dialog: MatDialogRef<PrintIdModal>,
    public matterService: MatterService,
    @Inject(MAT_DIALOG_DATA) context?: PrintIdModalContext
  ) {
    super(dialog, context);
    for (let mp of this.context.purchasers) {
      if (!mp) {
        continue;
      }
      let contact: Contact = mp.contact;
      // Printing is supposed to be a readonly operation.
      // initializeContactIdDetails() changes and may also overwrite the original data.
      // The following change passes DPPMP-7315 test
      // contact.initializeContactIdDetails();
      let gender: string = contact.gender;
      let signers = this.context.matter ? this.context.matter.getChildSigners(mp) : [];
      switch (gender) {
        case 'QUESTION':
        case 'MALE':
        case 'FEMALE': {
          let name = contact.fullNameStartWithFirstName;
          this.addPrintIdInfo(name, name, mp, contact, 0);
          break;
        }
        case 'OTHERENTITY':
        case 'CORPORATION': {
          signers.forEach((singer) => {
            let name: string =
              'Signing Officer ' +
              (singer.contact.fullName ? singer.contact.fullName : '????') +
              ' for ' +
              contact.organizationName;
            this.addPrintIdInfo(name, singer.contact.fullNameStartWithFirstName, mp, singer.contact, 0, contact);
          });

          break;
        }
        case 'MALEPOA':
        case 'FEMALEPOA': {
          signers.forEach((singer) => {
            let forName = contact.fullNameStartWithFirstName;
            let name: string =
              'Attorney ' + (singer.contact ? singer.contact.fullNameStartWithFirstName : '????') + ' for ' + forName;
            this.addPrintIdInfo(name, singer.contact.fullNameStartWithFirstName, mp, singer.contact, 0, contact);
          });
          break;
        }
        case 'ESTATE': {
          signers.forEach((singer) => {
            let deceased: string = contact.fullNameStartWithFirstName;
            let name: string =
              'Estate Trustee ' +
              (singer.contact ? singer.contact.fullNameStartWithFirstName : '????') +
              ' for ' +
              deceased;
            this.addPrintIdInfo(name, singer.contact.fullNameStartWithFirstName, mp, singer.contact, 0, contact);
          });
          break;
        }
      }
      let familyLawAct: FamilyLawAct = mp.getConsentedSpouseFamilyLawAct();
      if (familyLawAct && familyLawAct.consentedSpouseParticipant && familyLawAct.consentedSpouseParticipant.contact) {
        let consentedSpouse: Contact = familyLawAct.consentedSpouseParticipant.contact;
        let spouseName = consentedSpouse.fullNameStartWithFirstName;
        let nameForId: string = spouseName;
        spouseName = spouseName ? spouseName : '<Unnamed Spouse>';

        this.addPrintIdInfo(spouseName, nameForId, mp, consentedSpouse, 0);
      }
    }
  }

  ngOnInit() {
    this.matterService.getMatterDocumentProfile(this.context.matter).subscribe((matterDocumentProfile) => {
      if (matterDocumentProfile && matterDocumentProfile.accountFileFolder) {
        this.context.outputType = matterDocumentProfile.accountFileFolder.producibleDocType;
        this.context.isDocumentProfileTypeWord = matterDocumentProfile.accountFileFolder.isWordProcessorType;
        this.outputTypeOptions = DocumentProductionUtil.getOutputMenuItems(<ProducibleDocType>this.context.outputType);
      }
    });
  }

  printId(): void {
    //This is where the print Id is clicked.
    this.sortSelectedRecords();
    this.context.action = PrintIdAction.PRINT_ID;
    this.dialog.close(this.context);
  }

  printDeclarations(): void {
    this.sortSelectedRecords();
    this.context.action = PrintIdAction.PRINT_DECLARATION;
    this.dialog.close(this.context);
  }

  close(): void {
    this.dialog.close();
  }

  public hasDifferentName(nameForId: string, identificationRecord: IdentificationRecord): boolean {
    if (identificationRecord) {
      return !!identificationRecord.identificationDocuments.find((idDocument) => {
        let nameOnId = idDocument.nameOnId;
        if (!nameOnId) {
          nameOnId = '';
        }
        if (!nameForId && nameOnId === '??????') {
          return false;
        }
        return nameOnId !== nameForId;
      });
    }
    return false;
  }

  public getPartyIs(mp: MatterParticipant): string {
    if (mp.matterParticipantRole === 'GUARANTOR' && this.context.matter) {
      return 'Guarantor for ' + this.getMortgageOrder(mp.mortgageId) + ' Mortgage';
    } else if (mp.matterParticipantRole === 'PRIVATE_LENDER' && this.context.matter) {
      return 'Private Lender for ' + this.getMortgageOrder(mp.mortgageId) + ' Mortgage';
    } else if (this.isMatterProvinceBC) {
      if (this.matter?.isPurchase) {
        return 'Buyer';
      }
      if (this.matter?.isMortgage) {
        return 'Borrower';
      }
      return 'Seller';
    }
    return matterParticipantRoleLabels[mp.matterParticipantRole];
  }

  private getMortgageOrder(mortgageId: number) {
    const priority = this.context?.matter?.mortgages?.find((mortgage) => mortgage.id === mortgageId)?.mortgagePriority;
    if (priority) {
      return Utils.getOrdinal(priority);
    }
    return '';
  }

  public getVerifiedBy(contact: Contact): string {
    if (contact.contactType === ContactType.CONSENTED_SPOUSE) {
      if (
        contact.idInfoEnteredBy &&
        contact.enteredOn &&
        this.hasAtLeastOneDocumentFilled(
          Array.isArray(contact.identificationRecords) && contact.identificationRecords.length > 0
            ? contact.identificationRecords[0].identificationDocuments
            : null
        )
      ) {
        return contact.idInfoEnteredBy + ' - ' + this.formatDate(contact.enteredOn);
      }
    } else {
      if (contact.idInfoEnteredBy && contact.enteredOn) {
        return contact.idInfoEnteredBy + ' - ' + this.formatDate(contact.enteredOn);
      }
    }
    return 'ID Not Verified';
  }

  public hasAtLeastOneDocumentFilled(identificationDocuments: IdentificationDocument[]): boolean {
    if (identificationDocuments) {
      for (let doc of identificationDocuments) {
        if (doc.nameOnId && doc.identificationTypeCode && doc.identificationNumber) {
          return true;
        }
      }
    }
    return false;
  }

  public formatDate(date: string): string {
    return moment(date, 'YYYY/MM/DD').format('MMMM DD, YYYY');
  }

  /*    multiSelectDocuments(isCheckBoxSelected : boolean) : void {
          this.context.selectedRecords = [];
          if(isCheckBoxSelected) {
              for(let i = 0; i < this.rows.length; i++) {
                  this.context.selectedRecords.push(this.rows[i]);
              }
          }
      }*/

  /*    isSelected(row : PrintIdInfo) : boolean {
          return !!this.context.selectedRecords.find((printIdInfo : PrintIdInfo) => printIdInfo == row);
      }*/

  selectToProduce(printIdInfo: PrintIdInfo, value: boolean): void {
    // if checked, add id if not present
    // if un-checked remove id
    if (value) {
      let index: number = this.context.selectedRecords.indexOf(printIdInfo);
      if (index < 0) {
        this.context.selectedRecords[this.context.selectedRecords.length] = printIdInfo;
      }
    } else {
      _.remove(this.context.selectedRecords, (info: PrintIdInfo) => info == printIdInfo);
    }
  }

  sortSelectedRecords(): void {
    let sortedRecords: PrintIdInfo[] = [];
    let sortedRecordsIndexes: number[] = [];

    this.context.selectedRecords.forEach((printIdInfo: PrintIdInfo) => {
      let index: number = this.rows.indexOf(printIdInfo);
      sortedRecordsIndexes.push(index);
    });
    sortedRecordsIndexes.sort(function (a, b) {
      return a - b;
    });
    sortedRecordsIndexes.forEach((index: number) => {
      sortedRecords.push(this.rows[index]);
    });
    this.context.selectedRecords = sortedRecords;
  }

  enablePrintIdBtn(): boolean {
    return this.context.selectedRecords.length > 0;
  }

  enablePrintDeclarations(): boolean {
    for (let row of this.context.selectedRecords) {
      if (row.similarNameDeclared === 'Yes') {
        return true;
      }
    }
    return false;
  }

  public addPrintIdInfo(
    name: string,
    nameForId: string,
    mp: MatterParticipant,
    contact: Contact,
    idRecordIndex: number,
    parentContact?: Contact
  ) {
    let printIdInfo: PrintIdInfo = {
      contact: contact,
      identificationRecord: contact.identificationRecords ? contact.identificationRecords[idRecordIndex] : null,
      name: name,
      similarNameDeclared: this.hasDifferentName(
        nameForId,
        contact.identificationRecords ? contact.identificationRecords[idRecordIndex] : null
      )
        ? 'Yes'
        : '',
      partyIs: contact.contactType === ContactType.CONSENTED_SPOUSE ? 'Consenting Spouse' : this.getPartyIs(mp),
      verifiedBy: parentContact ? this.getVerifiedBy(parentContact) : this.getVerifiedBy(contact)
    };
    this.rows.push(printIdInfo);
    this.context.selectedRecords.push(printIdInfo);
    // if(printIdInfo.verifiedBy !== 'ID Not Verified') {
    //     this.context.selectedRecords.push(printIdInfo);
    // }
  }

  get isMatterProvinceBC(): boolean {
    return this.context?.matter?.isMatterProvinceBC;
  }

  get matter(): Matter {
    return this.context.matter;
  }
}
