import {Component, Inject, OnInit} from '@angular/core';
import {ParcelRegister} from '../../../../shared-main/teranet/parcel-register';
import {ParcelAdjacentAnalysis, ParcelAdjacentAnalysisInstrument} from '../../model/parcel-adjacent-analysis';
import {ContactNameUtil} from '../../../shared/contact-name-util';
import {Instrument} from '../../../../shared-main/teranet/instrument';
import {TeranetService} from '../../../../shared-main/teranet/teranet-service';
import {TeranetDocket} from '../../../../shared-main/teranet/teranet-docket';
import {FocusFirstElementModalDecorator} from '../../../../shared-main/focus-first-element-modal-decorator';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../../shared/dialog/modal-dialog.service';

export class AnalysisAdjacentParcelModalFocus {
  primary: number;
  adjacent: number;
}

export class AnalysisAdjacentParcelContext {
  teranetDocket: TeranetDocket;
  matterId: number;
  focusFirst: AnalysisAdjacentParcelModalFocus;
  adjacentParcelAnalysisAvailable: boolean;
}

@Component({
  selector: 'dp-analysis-adjacent-parcel-modal-content',
  templateUrl: 'analysis-adjacent-parcel.modal.component.html',
  styleUrls: ['./analysis-adjacent-parcel.modal.component.scss']
})
@FocusFirstElementModalDecorator()
export class AnalysisAdjacentParcelComponent extends ModalComponent<AnalysisAdjacentParcelContext> implements OnInit {
  infoTextArea: string[] = [];
  parcelAdjacentAnalysis: ParcelAdjacentAnalysis[] = [];
  anyInstrumentSelected: boolean;
  focusFirst: AnalysisAdjacentParcelModalFocus = {primary: 0, adjacent: -1};

  constructor(
    public dialog: MatDialogRef<AnalysisAdjacentParcelComponent>,
    public teranetService: TeranetService,
    @Inject(MAT_DIALOG_DATA) context?: AnalysisAdjacentParcelContext
  ) {
    super(dialog, context);
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    if (this.context.focusFirst) {
      this.focusFirst = this.context.focusFirst;
    }
    this.SetInfoArea();
    this.SetComparisonArea();
  }

  public SetInfoArea() {
    this.context.teranetDocket.parcelRegisters.forEach((pr) => {
      if (pr.hasMap) {
        let totalAdjParcels: number = pr.adjacentParcels ? pr.adjacentParcels.length : 0;
        let plural: string = totalAdjParcels > 1 ? 's' : '';
        let orderedAdjParcels: number = pr.adjacentParcels.filter((adj) => adj.ordered).length;

        //this.infoTextArea.push(`Adjacent Parcel Analysis for primary PIN ${pr.pin}, comparing against ${orderedAdjParcels} of ${totalAdjParcels} Adjacent Parcel Registers purchased.`);

        //PIN xxxxx-xxxx - Adjacent Parcel Analysis comparing PIN against + number of adjacent parcel registers ordered for the primary pin + ' of ' + total number of adjacent parcels against the primary pin + ' purchased Adjacent Parcel Register(s).
        this.infoTextArea.push(
          `PIN ${pr.pin} - Adjacent Parcel Analysis comparing PIN against ${orderedAdjParcels} of ${totalAdjParcels} purchased Adjacent Parcel Register${plural}.`
        );
      } else {
        this.infoTextArea.push(`PIN ${pr.pin}, Map not ordered.  Adjacent lands not searched.`);
      }
    });
  }

  public SetComparisonArea() {
    this.context.teranetDocket.parcelRegisters.forEach((pr) => {
      this.compileAdjacentAnalysis(pr);
    });
  }

  compileAdjacentAnalysis(pr: ParcelRegister): void {
    if (pr.adjacentParcels && pr.adjacentParcels != null && pr.instruments && pr.instruments != null) {
      for (let adjP of pr.adjacentParcels) {
        if (adjP.instruments) {
          adjP.instruments
            .filter((adjInstr) => adjInstr.isInstrumentOfTypeTransfer)
            .forEach((adjInstr) => {
              pr.instruments
                .filter((instr) => instr.isInstrumentOfTypeTransfer)
                .forEach((instr) => {
                  let surnameMatches: string[] = ContactNameUtil.getSurnameMatches(
                    instr.partiesFrom,
                    adjInstr.partiesFrom
                  );
                  if (surnameMatches.length > 0) {
                    surnameMatches.forEach((sr) =>
                      this.addParcelAdjacentAnalysis(sr, pr, instr, '(Party From)', adjP, adjInstr, '(Party From)')
                    );
                  } else {
                    surnameMatches = ContactNameUtil.getSurnameMatches(instr.partiesTo, adjInstr.partiesFrom);
                    if (surnameMatches.length > 0) {
                      surnameMatches.forEach((sr) =>
                        this.addParcelAdjacentAnalysis(sr, pr, instr, '(Party To)', adjP, adjInstr, '(Party From)')
                      );
                    } else {
                      surnameMatches = ContactNameUtil.getSurnameMatches(instr.partiesFrom, adjInstr.partiesTo);
                      if (surnameMatches.length > 0) {
                        surnameMatches.forEach((sr) =>
                          this.addParcelAdjacentAnalysis(sr, pr, instr, '(Party From)', adjP, adjInstr, '(Party To)')
                        );
                      } else {
                        surnameMatches = ContactNameUtil.getSurnameMatches(instr.partiesTo, adjInstr.partiesTo);
                        if (surnameMatches.length > 0) {
                          surnameMatches.forEach((sr) =>
                            this.addParcelAdjacentAnalysis(sr, pr, instr, '(Party To)', adjP, adjInstr, '(Party To)')
                          );
                        }
                      }
                    }
                  }
                });
            });
        }
      }
    }
  }

  addParcelAdjacentAnalysis(
    surname: string,
    primaryParcel: ParcelRegister,
    primaryInstrument: Instrument,
    primarySurnameFrom: string,
    adjacentParcel: ParcelRegister,
    adjacentInstrument: Instrument,
    adjacentSurnameFrom: string
  ) {
    let newPaa: ParcelAdjacentAnalysis = new ParcelAdjacentAnalysis();

    newPaa.surname = surname;
    newPaa.primaryParcelData = this.getNewParcelAnalysisInstrument(
      primaryParcel,
      primaryInstrument,
      primarySurnameFrom
    );
    newPaa.adjacentParcelData = this.getNewParcelAnalysisInstrument(
      adjacentParcel,
      adjacentInstrument,
      adjacentSurnameFrom
    );
    if (newPaa.surname != '' && newPaa.surname != '*** COMPLETELY DELETED ***') {
      this.parcelAdjacentAnalysis.push(newPaa);
    }
  }

  public getNewParcelAnalysisInstrument(
    parcel: ParcelRegister,
    instrument: Instrument,
    surnameFrom: string
  ): ParcelAdjacentAnalysisInstrument {
    let paai: ParcelAdjacentAnalysisInstrument = new ParcelAdjacentAnalysisInstrument();

    paai.pin = parcel.pin;
    paai.lro = parcel.lro;
    paai.parcelRegisterId = parcel.id;
    paai.instrument = instrument;
    paai.instrument.parcelRegisterId = parcel.id;
    paai.instrument.analysisStillNeeded = true;
    paai.surnameFrom = surnameFrom;

    return paai;
  }

  viewInstrument(instrument: Instrument): void {
    let doc = this.context.teranetDocket.getPrimaryDocumentForInstrument(instrument);
    this.teranetService.downloadTeranetDocumentAndDisplay(this.context.matterId, doc.id);
  }

  orderInstruments() {
    let instrumentToOrder: ParcelAdjacentAnalysisInstrument;
    this.parcelAdjacentAnalysis.forEach((paa) => {
      if (paa.primaryParcelData.selected) {
        instrumentToOrder = paa.primaryParcelData;
      }
      if (paa.adjacentParcelData.selected) {
        instrumentToOrder = paa.adjacentParcelData;
      }
    });

    this.dialog.close({
      action: 'ORDER',
      instrument: instrumentToOrder.instrument,
      focusFirst: this.focusFirst
    });
  }

  updateSelection(isPrimary: boolean, index: number) {
    setTimeout(() => {
      for (let i = 0; i < this.parcelAdjacentAnalysis.length; i++) {
        if (index == i) {
          if (isPrimary) {
            this.parcelAdjacentAnalysis[i].primaryParcelData.selected =
              !this.parcelAdjacentAnalysis[i].primaryParcelData.selected;
            this.focusFirst.primary = index;
            this.focusFirst.adjacent = -1;
            //User should only be able to order one instrument at a time.
            if (this.parcelAdjacentAnalysis[i].primaryParcelData.selected) {
              this.parcelAdjacentAnalysis[i].adjacentParcelData.selected = false;
            }
          } else {
            this.parcelAdjacentAnalysis[i].adjacentParcelData.selected =
              !this.parcelAdjacentAnalysis[i].adjacentParcelData.selected;
            this.focusFirst.adjacent = index;
            this.focusFirst.primary = -1;
            //User should only be able to order one instrument at a time.
            if (this.parcelAdjacentAnalysis[i].adjacentParcelData.selected) {
              this.parcelAdjacentAnalysis[i].primaryParcelData.selected = false;
            }
          }
        } else {
          this.parcelAdjacentAnalysis[i].primaryParcelData.selected = false;
          this.parcelAdjacentAnalysis[i].adjacentParcelData.selected = false;
        }
      }

      this.anyInstrumentSelected = this.parcelAdjacentAnalysis.some(
        (paa) => paa.primaryParcelData.selected || paa.adjacentParcelData.selected
      );
    }, 100);
  }

  get isAdjacentParcelAnalysisAvailable(): boolean {
    return this.context.adjacentParcelAnalysisAvailable && this.parcelAdjacentAnalysis.length > 0;
  }

  ngAfterViewInit() {}
}
