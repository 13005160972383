import {api} from '../../common/api';

export const checklistTemplateAPI = {
  getAllChecklistTemplates(accountId: string): string {
    return `${ api }/accounts/${ accountId }/checklistTemplates`;
  },
  getChecklistTemplateById(accountId: string, checklistTemplateId: string): string {
    return `${ api }/accounts/${ accountId }/checklistTemplates/${ checklistTemplateId }`;
  },
  createChecklistTemplate(accountId: string): string {
    return `${ api }/accounts/${ accountId }/checklistTemplates`;
  },
  updateChecklistTemplate(accountId: string, checklistTemplateId: string): string {
    return `${ api }/accounts/${ accountId }/checklistTemplates/${ checklistTemplateId }`;
  },
  getDefaultChecklistTemplate(accountId: string): string {
    return `${ api }/accounts/${ accountId }/checklistTemplates/default`;
  }
};
