import {OtherFixedCreditTypes, SoAdjOtherFixed} from '../model/so-adj-other-fixed';
import {StatementOfAdjustmentDisplayItemLine} from '../model/statement-adjustment-display-item';
import {StatementAdjustmentDisplayBuilder} from './statement-adjustment-display-builder';
import {StatementAdjustmentKey} from '../statement-adjustment-constants';
import {ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD, ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT} from '../../../shared-main/constants';

//Other Fixed Amount  | Other Fixed Amount(Payable on Occupancy)
export class StatementAdjustmentDisplayOtherFixedBuilder {
  static getDisplayItems(soAdjOtherFixed: SoAdjOtherFixed, mainBuilder: StatementAdjustmentDisplayBuilder, taxRateType: string, statementAdjustmentKey?: string, toBeUsedOnModal: boolean = false): StatementOfAdjustmentDisplayItemLine[] {
    let sadItemDetails: StatementOfAdjustmentDisplayItemLine[] = [];
    let amntHeadingAdded: boolean = false;

    if (soAdjOtherFixed.items && !toBeUsedOnModal) {

      //mimic the implementation of deposit adjustments for handling too many items
      if (soAdjOtherFixed.items.length >= ADJUSTMENT_SUBITEMS_FULL_DISPLAY_THRESTHOLD) {
        //display the first 8 items
        soAdjOtherFixed.items.slice(0, ADJUSTMENT_SUBITEMS_PARTIAL_TOP_DISPLAY_CNT).forEach(itm => {
          sadItemDetails.push(mainBuilder.getNewItemLine(itm.heading,
            ((statementAdjustmentKey === StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST) ? '' : mainBuilder.getCurrency(itm.amount, 2, true))
          ));
        });
        //display three lines of dot
        [ 1, 2, 3 ].forEach(_ => sadItemDetails.push(mainBuilder.getNewItemLine(`.`, ``)));
        //display the last item
        const lastItem = soAdjOtherFixed.items[ soAdjOtherFixed.items.length - 1 ];
        sadItemDetails.push(mainBuilder.getNewItemLine(lastItem.heading,
          ((statementAdjustmentKey === StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST) ? '' : mainBuilder.getCurrency(lastItem.amount, 2, true))
        ));
      } else {
        soAdjOtherFixed.items.forEach(itm => {
          sadItemDetails.push(mainBuilder.getNewItemLine(itm.heading,
            ((statementAdjustmentKey === StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST) ? '' : mainBuilder.getCurrency(itm.amount, 2, true))
          ));
        });
      }
    }

    if (soAdjOtherFixed.creditType == OtherFixedCreditTypes.NO_ADJUSTMENT) {
      sadItemDetails.push(mainBuilder.getNewItemLine(`No Adjustment`, ``));
    } else if (soAdjOtherFixed.creditType == OtherFixedCreditTypes.SILENT) {
      // well, silent !
    } else {

      let amntHeading: string = soAdjOtherFixed.amountHeading;
      let amnt: number = soAdjOtherFixed.amount;
      let totalAmount: number = soAdjOtherFixed.totalAmount;
      let hstAmount: number = soAdjOtherFixed.hstAmount;
      let gstAmount: number = soAdjOtherFixed.gstAmount;
      let pstAmount: number = soAdjOtherFixed.pstAmount;

      if (statementAdjustmentKey === StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST) {
        let ctsp: number = mainBuilder.matter.isMatterProvinceAB
          ? (Number(mainBuilder.matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes))
          : Number(mainBuilder.matter.matterPropertyWithCondo.condominiumTotalSharePercentage);
        soAdjOtherFixed.condominiumTotalSharePercentage = ctsp;
        if (toBeUsedOnModal) {
          amntHeading = 'Purchaser\'s share';
        } else {
          amntHeading = `Purchaser's share (${ mainBuilder.getPercentage(ctsp) } of ${ mainBuilder.getCurrency(soAdjOtherFixed.amount) })`;
        }
        amnt = soAdjOtherFixed.getAmountForPercentageInterest(ctsp);
        totalAmount = soAdjOtherFixed.getTotalAmountPurchaserForPercentageInterest(ctsp);
        hstAmount = soAdjOtherFixed.getPercentageInterestHstAmount(ctsp);
        pstAmount = soAdjOtherFixed.getPercentageInterestPstAmount(ctsp);
        gstAmount = soAdjOtherFixed.getPercentageInterestGstAmount(ctsp);
      }

      if (soAdjOtherFixed.applyTaxBoolean || soAdjOtherFixed.applyPstBoolean) {

        if (soAdjOtherFixed.adjustmentHeading && amntHeading && soAdjOtherFixed.adjustmentHeading != amntHeading) {
          sadItemDetails.push(mainBuilder.getNewItemLine(amntHeading, mainBuilder.getCurrency(amnt)));
          amntHeadingAdded = true;
        }

        if (soAdjOtherFixed.taxType == taxRateType) {
          sadItemDetails.push(mainBuilder.getNewItemLine(
            `${ taxRateType } calculated at ${ mainBuilder.getPercentage(soAdjOtherFixed.hstRate) }`,
            mainBuilder.getCurrency(hstAmount)));
        }

        if (mainBuilder.matter && !mainBuilder.matter.isMatterProvinceAB) {
          if (soAdjOtherFixed.taxType == 'GST' && soAdjOtherFixed.applyTaxBoolean) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
              `GST calculated at ${ mainBuilder.getPercentage(soAdjOtherFixed.gstRate) }`,
              mainBuilder.getCurrency(gstAmount)));
          }
        }

        if (soAdjOtherFixed.applyPstBoolean) {
          sadItemDetails.push(mainBuilder.getNewItemLine(
            `PST calculated at ${ mainBuilder.getPercentage(soAdjOtherFixed.pstRate) }`,
            mainBuilder.getCurrency(pstAmount)));
        }

        if (toBeUsedOnModal || soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'NO' || mainBuilder.isSalePriceFormatINCLUSIVE) {
          let creditLabel = soAdjOtherFixed.creditType == OtherFixedCreditTypes.VENDOR ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel;
          sadItemDetails.push(mainBuilder.getNewItemLine(
            `Credit ${ creditLabel }`,
            toBeUsedOnModal ? mainBuilder.getCurrency(totalAmount) : '',
            soAdjOtherFixed.creditType === OtherFixedCreditTypes.VENDOR
          ));
        }
      } else {
        if (statementAdjustmentKey === StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST && !amntHeadingAdded) {
          sadItemDetails.push(mainBuilder.getNewItemLine(amntHeading, mainBuilder.getCurrency(amnt)));
          amntHeadingAdded = true;
        }
        if (soAdjOtherFixed.creditType == OtherFixedCreditTypes.VENDOR) {
          if (soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'NO' || mainBuilder.isSalePriceFormatINCLUSIVE || (statementAdjustmentKey === StatementAdjustmentKey.OTHER_PRORATED_ON_PERCENTAGE_INTEREST && toBeUsedOnModal)) {
            sadItemDetails.push(mainBuilder.getNewItemLine(
              `Credit ${ mainBuilder.vendorLabel }`,
              toBeUsedOnModal ? mainBuilder.getCurrency(totalAmount) : '',
              true
            ));
          } else {
            if (!amntHeadingAdded && amntHeading && soAdjOtherFixed.adjustmentHeading
              && soAdjOtherFixed.adjustmentHeading != amntHeading) {
              sadItemDetails.push(mainBuilder.getNewItemLine(
                amntHeading,
                mainBuilder.getCurrency(amnt),
                true
              ));
              amntHeadingAdded = true;
            }
          }
        } else {
          let creditLabel = soAdjOtherFixed.creditType == OtherFixedCreditTypes.VENDOR ? mainBuilder.vendorLabel : mainBuilder.purchaserLabel;
          sadItemDetails.push(mainBuilder.getNewItemLine(
            `Credit ${ creditLabel }`,
            toBeUsedOnModal ? mainBuilder.getCurrency(totalAmount) : ''
          ));
        }
      }
    }
    return sadItemDetails;
  }

  static getNoteReferenceMark(soAdjOtherFixed: SoAdjOtherFixed): string {
    let nrm: string = '';
    if (soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_ELIGIBLE_FOR_REBATE') {
      nrm = '1';
    } else if (soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE') {
      nrm = '2';
    } else if (soAdjOtherFixed.amountAdditionalConsiderationToVendor == 'YES_NOT_ELIGIBLE_FOR_REBATE_PLUS_TAX') {
      nrm = '3';
    }
    return nrm;
  }

}
