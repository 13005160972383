<form class="form-horizontal" id="purchaseParcelRegistersForm" #purchaseParcelRegistersForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-sm-11 ">
                <h1>Teranet Connect&trade;: Teraview&reg; Docket ID</h1>
            </div>
            <div class="col-sm-1">
                <button type="button" class="btn-close pull-right padding-top-5" (click)="close()">&times;</button>
            </div>

        </div>

        <div class="row modal-body" >
            <div class="fields-group">
                <div class="form-group">
                    <div class="col-lg-10 offset-lg-1 bordered-section padding-20">
                        <div class="col-lg-12 padding-bottom-20" *ngIf="context.modalOpenedFromCreateDocket">
                            The specified Docket ID {{teranetDocket.teraviewDocketIdentifier}}, already exists.
                        </div>
                        <div class="col-lg-12 padding-bottom-20">
                            Do you wish to use the following Teraview&reg; Docket information for the transaction?
                        </div>

                        <div class="col-lg-12 padding-bottom-10">
                            <div class="col-lg-2 offset-lg-1">
                                Docket ID:
                            </div>
                            <div class="col-lg-9">
                                {{teranetDocket.teraviewDocketIdentifier}}
                            </div>

                        </div>
                        <div class="col-lg-12 padding-bottom-10">
                            <div class="col-lg-2 offset-lg-1">
                                Docket Name:
                            </div>
                            <div class="col-lg-9 ">
                                {{teranetDocket.teraviewDocketName}}
                            </div>
                        </div>


                    </div>
                </div>


            </div>

        </div>
    </div>


    <div class="row modal-footer ">

        <div class="col-lg-12  text-center">
            <button id="okBtn" class="dp-btn focus-first" type="button" (click)="associateDocketWithMatter()"  >
                Proceed
            </button>

            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()">
                Cancel
            </button>
        </div>
    </div>

    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>


