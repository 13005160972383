import {Component, OnInit} from '@angular/core';
import {TrustedPhoneNumberService} from './trusted-phone-number.service';
import {AuthChallenge, AuthChallengePhone} from './trusted-phone-number';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {SESSION_STORAGE_KEYS} from '../../../shared/session-storage-keys';
import {Logger} from '@nsalaun/ng-logger';
import {AuthZService} from '../../../core/authz/auth-z.service';
import {AcceptTermsConditions} from '../accept-terms-conditions/accept-terms-conditions';
import {LockScreenService} from '../../../core/lock-screen.service';
import {FocusFirstElementDecorator} from '../../../shared-main/focus-first-element-decorator';
import {DppmCookieService} from '../../../main/dppm-cookie.service';
import {appRoutesUrlParts} from '../../../app.routes';
import {AcceptTermsConditionsService} from '../accept-terms-conditions/accept-terms-conditions.service';
import {User} from '../../../matters/shared/user';
import {RegisterService} from '../register/register.service';
import {ApplicationError, HttpClient} from '../../../core';
import {ReferenceDataService} from '../../../shared-main/reference-data/reference-data.service';
import {UUIDService} from '../../../main/uuid.service';
import {messages} from '../../../common';
import {UtilsService} from '../../../main/utils.service';
import {UserStateService} from "../../../shared-main/user-state/user-state.service";

declare var jQuery: any;

const sendPasscodeTimeOut = 15000; //15 seconds

@Component({
  selector: 'dp-trusted-phone-number',
  templateUrl: './trusted-phone-number.component.html',
  styleUrls: [
    '../../unity-login.styles.scss'
  ]
})

@FocusFirstElementDecorator()

export class TrustedPhoneNumberComponent implements OnInit {

  trustedBrowser: boolean;
  selectedPhoneNumber: string;
  typedPhoneNumber: string;
  phoneNumbers: AuthChallengePhone[];
  passcode: string;
  errorFlag: boolean;
  errorMessage: string;
  passcodeText: string;
  phoneAdded: boolean;
  agentId: string = '';
  passcodeTitle: string;
  sessionUser: any;
  user: User;
  token: string;
  validToken: boolean;
  registerTokenErrorMsg: string;
  lockUserFlag: boolean = false;
  contactAdminstrator: boolean;
  isSendPasscodeTimeout: boolean = false;

  constructor(public trustedPhoneNumberService: TrustedPhoneNumberService,
              public authZService: AuthZService,
              public route: ActivatedRoute,
              public router: Router,
              public registerService: RegisterService,
              public dialogService: DialogService,
              public activatedRoute: ActivatedRoute,
              public lockScreenService: LockScreenService,
              public cookieService: DppmCookieService,
              public acceptTermsConditionsService: AcceptTermsConditionsService,
              public httpClient: HttpClient,
              public referenceDataService: ReferenceDataService,
              public uuidService: UUIDService, public utilsService: UtilsService,
              public userStateService: UserStateService,
              public logger: Logger) {
  }

  ngOnInit() {
    this.lockScreenService.lockForUpdate = false;
    this.route.params.subscribe(params => {
      this.token = params['token'];
      if (this.token) {
        this.handlePasswordReset();
      } else {
        this.lockUserFlag = false;
        this.sessionUser = sessionStorage.getItem(SESSION_STORAGE_KEYS.user);
        if (this.sessionUser) {
          this.user = new User(JSON.parse(this.sessionUser));
          this.phoneNumbers = this.user.authChallengePhones;
        }
      }
    });

    this.resetFlags();
    if (!this.isPhoneNumberRequired()) {
      this.passcodeTitle = 'You must enter a Unity® Practice Management provided passcode to complete login. Please select the phone number to receive the passcode.';
    }

  }

  handlePasswordReset(): void {

    this.registerService.getResetPasswordUser(this.token)
      .subscribe(
        (respondUser: any) => {
          this.userStateService.setUserRegion(respondUser.defaultProvinceCode, respondUser.userProvinces);
          this.validToken = true;
          this.user = new User(respondUser);
          this.passcodeTitle = 'You must enter a Unity® Practice Management provided passcode to change your password. Please select the phone number to' +
            ' receive the  passcode.';
          this.phoneNumbers = this.user.authChallengePhones;

          if (this.user.userStatus != 'ACTIVE') {
            this.lockUserFlag = true;
            this.registerTokenErrorMsg = 'Your account is either locked or suspended. Please contact your Administrator.';
          }
        },
        (error: any) => {
          this.validToken = false;
          this.lockUserFlag = true;
          this.registerTokenErrorMsg = error && error.message ? error.message : 'Server error';
        }
      );

  }

  isPhoneNumberRequired(): boolean {
    return this.isFirstLogin() || !(this.phoneNumbers && this.phoneNumbers.length);
  }

  isFirstLogin(): boolean {
    return (this.user && !this.user.caslAccepted && !this.user.trustedPhoneNumberProvided);
  }

  isPasscodeVisible(): boolean {
    return (this.isPhoneNumberRequired() && this.selectedPhoneNumber && this.selectedPhoneNumber.length == 10 && this.typedPhoneNumber == this.selectedPhoneNumber)
      || (!this.isPhoneNumberRequired() && this.selectedPhoneNumber && this.selectedPhoneNumber.length != 0)
      || this.contactAdminstrator;
  }

  next() {
    if (this.token && this.validToken) {
      this.router.navigate([`./login/${appRoutesUrlParts.resetPassword}`]);
    } else {
      if (this.user && !this.user.caslAccepted) {
        let redirect = `./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.acceptCasl}`;
        this.logger.info('TrustedPhoneNumberComponent | next() | redirect : ', redirect);
        this.router.navigate([redirect]);
      } else {
        this.navigateToNextStep();
      }
    }

  }

  resetFlags() {
    this.passcodeText = '';
    this.errorMessage = '';
    this.errorFlag = false;
    this.passcode = '';
    this.phoneAdded = false;
    this.contactAdminstrator = false;
  }

  selectPhoneNumber(phone: string): void {
    this.selectedPhoneNumber = phone;
    this.submitPhoneNumber();
  }

  submitPhoneNumber() {
    if (this.typedPhoneNumber) {
      this.selectedPhoneNumber = this.typedPhoneNumber;
    }

    this.resetFlags();
    if (!this.isPhoneNumberValid()) {
      this.errorFlag = true;
      this.errorMessage = 'Invalid Phone Number - must be 10 digits including area code.';
    } else {
      this.requestAuthChallenge();
    }

  }

  isPhoneNumberValid(): boolean {
    return this.isPhoneNumberRequired() ? this.selectedPhoneNumber && this.selectedPhoneNumber.length == 10 : true;
  }

  requestAuthChallenge(): void {
    let authChallenge = new AuthChallenge();
    authChallenge.phoneNumber = this.selectedPhoneNumber;
    this.trustedPhoneNumberService.requestAuthChallenge(authChallenge)
      .subscribe((res) => {
        if (res) {
          this.logger.info('TrustedPhoneNumberComponent | getPasscode() | res = ', res);
          this.errorFlag = false;
          this.isSendPasscodeTimeout = true;
          setTimeout(() => {
            this.isSendPasscodeTimeout = false;
          }, sendPasscodeTimeOut);
          if (this.isPhoneNumberRequired()) {
            this.passcodeText = 'Your passcode has been sent via text to the number entered. ' +
              'Please enter it below and select Submit Passcode. ' +
              'If you did not receive the text, the Send Passcode option will be re-enabled shortly, and you will be able to select it to request another passcode.';
          } else {
            this.passcodeTitle = 'A passcode has been sent via text to the phone number ending in ' + this.selectedPhoneNumber + '. ' +
              'Please enter it below and select Submit Passcode. ' +
              'If you did not receive the text, the Send Passcode option will be re-enabled shortly, and you will be able to select it to request another passcode.';
          }

          jQuery('#code').focus();
          setTimeout(() => {
            let objDiv = jQuery('.trusted-phone-box');
            if (objDiv && objDiv.length) {
              objDiv[0].scrollTop = objDiv[0].scrollHeight;
            }
          }, 50);

        }
      });
  }

  verifyAuthChallenge(): void {
    if (this.user) {
      if (this.trustedBrowser) {

        this.httpClient.getToken().subscribe((token: string) => {
          this.agentId = token;
          this.cookieService.saveUserAgentId(this.user.publicAccountId, this.user.username, token);
          this.doVerifyAuthChallenge();
        });
      } else {
        this.agentId = '';
        this.doVerifyAuthChallenge();
      }

    }
  }

  doVerifyAuthChallenge(): void {
    let authChallenge = new AuthChallenge();
    authChallenge.agentId = this.agentId;
    authChallenge.authChallengeCode = this.passcode;
    authChallenge.phoneNumber = this.selectedPhoneNumber;
    this.lockScreenService.lockForUpdate = true;
    this.trustedPhoneNumberService.verifyAuthChallenge(authChallenge)
      .subscribe((res) => {
        if (res) {
          this.logger.info('TrustedPhoneNumberComponent | submitPasscode() | res = ', res);
          if (res) {
            this.errorFlag = false;
            this.errorMessage = '';
            this.passcode = null;
            if (this.isPhoneNumberRequired()) {
              this.passcodeText = 'Your phone number has been successfully added. Click Next to continue.';
              this.phoneAdded = true;
              this.next();
            } else {
              this.navigateToNextStep();
            }
          }
        }
      }, (err: ApplicationError) => {
        this.lockScreenService.lockForUpdate = false;
        this.passcode = '';
        if (err.errorCode == 'app.authChallenge.codeExpired') {
          this.errorFlag = true;
          this.errorMessage = 'Passcode has expired please request another.';
          this.logger.info('TrustedPhoneNumberComponent | submitPasscode() |  Expired Passcode On Trusted Phone ');
        }

        if (err.errorCode == 'app.authChallenge.incorrectCode') {
          this.errorFlag = true;
          this.errorMessage = 'The entered Passcode is incorrect, please try again or select Get Passcode to request another';
          this.logger.info('TrustedPhoneNumberComponent | submitPasscode() |  Incorrect Passcode On Trusted Phone ');
        }

      });

  }

  navigateToNextStep() {
    if (this.token && this.validToken) {
      this.router.navigate([`./login/${appRoutesUrlParts.resetPassword}`]);
    } else {
      if (this.user.passwordChangeRequired) {
        this.router.navigate(['first-login/setup-password']);
      } else if (this.user.caslAccepted) {
        this.authZService.initializationAfterLogin(this.referenceDataService, this.uuidService);
        this.checkTermsAndConditionsVersionUptoDate();
      } else {
        this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.acceptCasl}`]);
      }
    }

  }

  checkTermsAndConditionsVersionUptoDate() {

    this.acceptTermsConditionsService.getTermsAndConditions().subscribe(
      (res: AcceptTermsConditions) => {
        if (res) {
          let termsAcceptedDate = res.createdDate;
          if (termsAcceptedDate <= this.user.termsAcceptedDate) {
            this.authZService.navigateToGetRedirectUrl();
          } else {
            this.router.navigate([`./${appRoutesUrlParts.firstLogin}/${appRoutesUrlParts.termsAndConditions}`]);
          }
        }

      },
      (error) => {
        this.dialogService.confirm('ERROR', messages.loginPage.internal, true);
      }
    );

  }

  contactAdmin(): void {
    this.selectedPhoneNumber = null;
    this.contactAdminstrator = true;
    this.passcodeText = 'Please contact your administrator. After verifying your identity, they can provide a temporary passcode that you will enter below.';
  }

  ngAfterViewInit() {
  }
}