import {MatterProperty} from '../matter-property';
import {Matter} from '../matter';
import {CondominiumExpense} from '../../property-teranet/unit-level-plan/condominium-expense';
import {UnitLevelPlanUtil} from '../../property-teranet/unit-level-plan/unit-level-plan-util';
import {ParcelLegalDescription} from '../parcel-legal-description';
import {ProvinceCode} from '../../../admin/accounts/shared/base-province';
import {BurgerMenuPropertyTeranetParcel} from '../../../shared-main/constants';
import {UUIDUtil} from '../../../main/uuid-util';
import {ErrorService} from '../../../shared/error-handling/error-service';
import {ParcelTitle} from '../parcel-title';
import {DpBooleanValueTypes} from '../dp-boolean';
import {StatementAdjustment} from '../../statement-adjustment/statement-adjustment';
import {StatementAdjustmentExpenseTypes} from '../../statement-adjustment/statement-adjustment-constants';
import {ConsiderationTaxes} from '../../consideration-ltt/consideration-taxes';
import {ManitobaLttTierUtil} from '../../consideration-ltt/manitoba-ltt-tier-util';
import {Tax_RATE} from '../../../shared-main/province-based-dropdowns';
import {ManitobaLTTTier} from '../../consideration-ltt/manitoba-ltt-tier';
import {StatementAdjustmentDisplayBuilder} from '../../statement-adjustment/builders/statement-adjustment-display-builder';
import {BrokerCommissionComponent} from '../../broker-commission/broker-commission.component';
import {MatterService} from '../../matter.service';
import {TaxRateService} from '../../consideration-ltt/tax-rate.service';
import {StatementAdjustmentUtil} from '../../statement-adjustment/statement-adjustment-util';

export class MatterPropertyUtil {

  static updateTeraviewLegalDescs(matter: Matter, condominiumExpense?: CondominiumExpense, matterProperty?: MatterProperty): void {
    if (matter && matter.isMatterProvinceON && matter.isProjectSale) {
      if (matter.isPropertyCondo() && matter.matterPropertyWithCondo) {
        let matterPropertyWithCondo = matter.matterPropertyWithCondo;
        if (matterProperty) {
          matterPropertyWithCondo = matterProperty;
        }
        if (condominiumExpense) {
          matter.calculateTeraviewLegalDesc(condominiumExpense, matterPropertyWithCondo);
        } else {
          matterPropertyWithCondo.condominiumExpenses.forEach((condominiumExpense) => {
            matter.calculateTeraviewLegalDesc(condominiumExpense, matterPropertyWithCondo);
          });
        }

      } else {
        if (matter.matterProperties && matter.matterProperties.length) {
          matter.matterProperties.forEach((matterProperty) => {
            matter.calculateTeraviewLegalDescForMatterProperty(matterProperty);
          });
        }
      }
    }
  }

  static calculateTeraviewLegalDesc(matter: Matter, condominiumExpense: CondominiumExpense, matterProperty: MatterProperty): void {
    if (matter && condominiumExpense.isLegalDescriptionApplyLegalDescEasement()) {
      condominiumExpense.teraviewLegalDesc = '';
      let text = '';
      if (matterProperty && matterProperty.parcel) {
        text = matterProperty.parcel + '\n';
      }
      if (matterProperty && matterProperty.parcel && matterProperty.section) {
        text = matterProperty.parcel + ', ' + matterProperty.section + '\n';
      }
      condominiumExpense.teraviewLegalDesc += text;

      matterProperty.unitLevelPlan = UnitLevelPlanUtil.generateUnitLevelPlanForCondominiumExpense(condominiumExpense, matterProperty, matter.provinceCode);
      condominiumExpense.teraviewLegalDesc += matterProperty.unitLevelPlan;
      if (condominiumExpense.teraviewLegalDesc) {
        condominiumExpense.teraviewLegalDesc += '\n';
      }
      if (matterProperty.easementRightOfWay) {
        condominiumExpense.teraviewLegalDesc += matterProperty.easementRightOfWay + '\n';
      }

      if (matterProperty.city) {
        // Remove " of " from the city. For example "Regional Municipality of " or "city of ".
        let city = matterProperty.city.split(/ of /i);
        condominiumExpense.teraviewLegalDesc += city[ city.length - 1 ];

      }
    }
  }

  static calculateTeraviewLegalDescForMatterProperty(matter: Matter, matterProperty: MatterProperty): void {
    if (matter && matterProperty && matterProperty.isLegalDescriptionApplyLegalDescEasement() && matter.matterProperties && matter.matterProperties.length && matter.matterProperties[ 0 ]) {
      matterProperty.fullLegalDescription = '';
      let text = '';
      if (matter.matterProperties[ 0 ].parcel) {
        text = matter.matterProperties[ 0 ].parcel + '\n';
      }
      if (matter.matterProperties[ 0 ].parcel && matter.matterProperties[ 0 ].section) {
        text = matter.matterProperties[ 0 ].parcel + ', ' + matter.matterProperties[ 0 ].section + '\n';
      }
      matterProperty.fullLegalDescription += text;
      text = '';

      if (matter.matterProperties[ 0 ].partLot) {
        text = matter.matterProperties[ 0 ].partLot + '\n';
      }
      if (matter.matterProperties[ 0 ].partLot && matter.matterProperties[ 0 ].plan) {
        text = matter.matterProperties[ 0 ].partLot + ', ' + matter.matterProperties[ 0 ].plan + '\n';
      }
      matterProperty.fullLegalDescription += text;
      text = '';

      if (matter.matterProperties[ 0 ].beingPart) {
        text = matter.matterProperties[ 0 ].beingPart + '\n';
      }
      if (matter.matterProperties[ 0 ].beingPart && matter.matterProperties[ 0 ].onPlan) {
        text = matter.matterProperties[ 0 ].beingPart + ', ' + matter.matterProperties[ 0 ].onPlan + '\n';
      }

      matterProperty.fullLegalDescription += text;
      text = '';

      if (matter.matterProperties[ 0 ].easementRightOfWay) {
        matterProperty.fullLegalDescription += matter.matterProperties[ 0 ].easementRightOfWay + '\n';
      }

      if (matter.matterProperties[ 0 ].city) {
        // Remove " of " from the city. For example "Regional Municipality of " or "city of ".
        let city = matter.matterProperties[ 0 ].city.split(/ of /i);
        matterProperty.fullLegalDescription += city[ city.length - 1 ];
      }
    }
  }

  static getMatterPropertyAddress(matter: Matter): string {
    let mp: MatterProperty = matter.matterPropertyWithCondo;
    let primaryAddress = '';
    if (mp && mp.address && mp.address.addressHash != null) {
      let address = mp.address;
      primaryAddress = (address.addressLine1 === null ? ' ' : address.addressLine1) + ' ' +
        (address.addressLine2 === null ? ' ' : address.addressLine2) + ' ' +
        (address.city === null ? ' ' : address.city) + ' ' +
        (address.postalCode === null ? ' ' : address.postalCode) + ' ' +
        (address.provinceName === null ? ' ' : address.provinceName);

      return primaryAddress;

    } else {
      return ' ';
    }
  }

  static getMatterDocketValue(matter: Matter): string {
    if (matter) {
      if (matter.isProjectSale) {
        return matter.project && matter.project.insertFileNoIntoTeraviewDocket && matter.fileNumber ?
          (matter.fileNumber.length <= 10 ? matter.fileNumber : matter.fileNumber.substring(0, 10)) : '';
      }
      if (!matter.teraviewConfig || matter.teraviewConfig.docketIdType == 'BLANK') {
        return '';
      } else if (matter.matterRecordNumber && matter.matterRecordNumber.length <= 10 && matter.teraviewConfig.docketIdType == 'MATTER_NO') {
        return matter.matterRecordNumber;
      } else if (matter.fileNumber && matter.fileNumber.length <= 10 && matter.teraviewConfig.docketIdType == 'FILE_NO') {
        return matter.fileNumber;
      } else if (matter.matterRecordNumber && matter.matterType && matter.matterRecordNumber.length <= 9 && matter.teraviewConfig.docketIdType == 'PSM_WITH_MATTER_NO') {
        return matter.matterType.charAt(0) + matter.matterRecordNumber;
      } else if (matter.fileNumber && matter.matterType && matter.fileNumber.length <= 9 && matter.teraviewConfig.docketIdType == 'PSM_WITH_FILE_NO') {
        return matter.matterType.charAt(0) + matter.fileNumber;
      } else {
        return '';
      }
    }
  }

  static removeAdditionalMatterPropertyWithoutPin(matter: Matter): void {
    if (matter && matter.isMatterProvinceON) {
      let re = new RegExp('^[0-9]{9}$');
      for (let i = 0; i < matter.matterProperties.length; i++) {
        if (matter.matterProperties[ i ].pin) {
          let pins = matter.matterProperties[ i ].pin.split(',');
          for (let j = 0; j < pins.length; j++) {
            if (!re.test(pins[ j ].trim()) && !matter.isPartialPinAllowed) {
              matter.matterProperties[ i ].pin = '';
              break;
            }
          }
        }
        if (matter.matterProperties[ i ].isEmptyAdditionalMatterProperty()) {
          matter.deleteMatterProperty(matter.matterProperties[ i ]);
        }

      }
    }

  }

  static deleteParcel(parcelLegalDescription: ParcelLegalDescription, matter: Matter, errorService: ErrorService, provinceHstRateSlab: ConsiderationTaxes,
                      soaConsiderationTaxes: ConsiderationTaxes, considerationTaxes: ConsiderationTaxes[], manitobaLTTTiers: ManitobaLTTTier[],
                      statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder, brokerCommissionComponent: BrokerCommissionComponent, matterService: MatterService): void {
    if (matter.matterPropertyWithCondo.parcelLegalDescriptions.length > 1) {
      (<any>matter.matterPropertyWithCondo.parcelLegalDescriptions).remove(parcelLegalDescription);
    } else if (matter.matterPropertyWithCondo.parcelLegalDescriptions.length == 1) {
      matter.matterPropertyWithCondo.parcelLegalDescriptions = [];
      this.addParcel(matter);
    }
    this.updateParcelValues(matter, provinceHstRateSlab, soaConsiderationTaxes, considerationTaxes, manitobaLTTTiers, statementAdjustmentDisplayUtil, brokerCommissionComponent, matterService);
    if (matter.isMatterProvinceSK) {
      matter.checkHSTRebateToDisplayWarningMessage(errorService);
    }
  }

  static updateCommissionPayableToVendorBroker(matter: Matter, brokerCommissionComponent: BrokerCommissionComponent): void {
    if (matter.isSale && brokerCommissionComponent) {
      // TODO : Need to clean up this and move updateCommissionPayableToVendorBroker to matter.ts
      brokerCommissionComponent.updateCommissionPayableToVendorBroker();
    }
  }

  static ontarioTaxRateSlab(considerationTaxes: ConsiderationTaxes[]): ConsiderationTaxes {
    const OntarioTaxRates = considerationTaxes.filter(
      item => {
        return item.instanceType == Tax_RATE.ONTARIO_LTT_RATE &&
          new Date(item.effectiveDate).getTime() < new Date().getTime();
      });
    return TaxRateService.findLatestRateBasedOnEffectiveDate(OntarioTaxRates);
  }

  static torontoTaxRateSlab(considerationTaxes: ConsiderationTaxes[]): ConsiderationTaxes {
    const TorontoTaxRates = considerationTaxes.filter(
      item => {
        return item.instanceType == Tax_RATE.TORONTO_LTT_RATE &&
          new Date(item.effectiveDate).getTime() < new Date().getTime();
      });
    return TaxRateService.findLatestRateBasedOnEffectiveDate(TorontoTaxRates);
  }

  static updateParcelValues(matter: Matter, provinceHstRateSlab: ConsiderationTaxes, soaConsiderationTaxes: ConsiderationTaxes,
                            considerationTaxes: ConsiderationTaxes[], manitobaLTTTiers: ManitobaLTTTier[],
                            statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder, brokerCommissionComponent: BrokerCommissionComponent, matterService: MatterService): void {
    const totalValueOfParcel: number = this.addTotalValueOfParcels(matter);
    const parcelValuesChanged: boolean = (matter.matterPropertyWithCondo.totalValueOfParcels != totalValueOfParcel);
    matter.matterPropertyWithCondo.totalValueOfParcels = totalValueOfParcel;
    matter.matterPropertyWithCondo.condominiumFees = this.calculateCondoFeesFromParcels(matter);
    matter.updateMortgageRegistrationFee();
    this.updateCondoFeeInSoa(matter);
    if (parcelValuesChanged) {
      this.updateSalePriceOnParcelUpdate(matter, provinceHstRateSlab, soaConsiderationTaxes, considerationTaxes, manitobaLTTTiers, statementAdjustmentDisplayUtil, brokerCommissionComponent, matterService);
    }
  }

  static updateSalePriceOnParcelUpdate(matter: Matter, provinceHstRateSlab: ConsiderationTaxes, soaConsiderationTaxes: ConsiderationTaxes,
                                       considerationTaxes: ConsiderationTaxes[], manitobaLTTTiers: ManitobaLTTTier[],
                                       statementAdjustmentDisplayUtil: StatementAdjustmentDisplayBuilder, brokerCommissionComponent: BrokerCommissionComponent, matterService: MatterService): void {
    if (matter.considerationLtt && matter.considerationLtt.salePriceAdjustment) {
      // Initializing purchasePriceType if it is not defined , Since it is defaulted to Sale Price in Soa on Creation
      if (!matter.matterPropertyWithCondo.purchasePriceType) {
        matter.matterPropertyWithCondo.purchasePriceType = 'SALE_PRICE_AS_IN_SOA';
      }
      matter.considerationLtt.salePriceAdjustment.agreementSalePrice = matter.matterPropertyWithCondo.totalValueOfParcels;
      let newSalePrice = matter.considerationLtt.salePriceAdjustment.getSalePrice(provinceHstRateSlab);
      this.onSalePriceUpdate({
        salePrice: matter.matterPropertyWithCondo.purchasePrice,
        salePriceSoa: newSalePrice,
        priceType: matter.matterPropertyWithCondo.purchasePriceType,
        salePriceAdjustment: matter.considerationLtt.salePriceAdjustment,
        autoInsertHst: matter.autoInsertHst,
        affidavitTobeSignedBy: matter.matterPropertyWithCondo.affidavitTobeSignedBy,
        statementAdjustmentDisplayUtil: statementAdjustmentDisplayUtil,
        overrideValueOfParcels: matter.matterPropertyWithCondo.overrideValueOfParcels
      }, matter, considerationTaxes, soaConsiderationTaxes, provinceHstRateSlab, manitobaLTTTiers, brokerCommissionComponent, matterService);
    }
  }

  static onSalePriceUpdate(result: any, matter: Matter, considerationTaxes: ConsiderationTaxes[], soaConsiderationTaxes: ConsiderationTaxes, provinceHstRateSlab: ConsiderationTaxes,
                           manitobaLTTTiers: ManitobaLTTTier[], brokerCommissionComponent: BrokerCommissionComponent, matterService: MatterService): void {
    matter.onSalePriceUpdate(result, considerationTaxes);
    if (matter.isMatterProvinceMB && matter.isPurchase) {
      if (matter.matterPropertyWithCondo) {
        matter.matterPropertyWithCondo.landTransferTax = ManitobaLttTierUtil.calculateLttTax(matter.matterPropertyWithCondo.purchasePrice, manitobaLTTTiers);
      }
    }
    if (result.statementAdjustmentDisplayUtil) {
      // apply any changes on SalePrice to TarionWarranty adjustments if exists
      if (StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(result.salePriceAdjustment, matter.statementOfAdjustments, result.statementAdjustmentDisplayUtil, matter.projectAdjustmentConfig)) {
        // now update in sale price adjustments TarionWarranty considerations
        StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(result.salePriceAdjustment, matter.statementOfAdjustments);
        // now make sure net sale price has not changed that much that we`re on another set of TarionWarranty threshold
        StatementAdjustmentUtil.updateTarionWarrantyAndHCRA(result.salePriceAdjustment, matter.statementOfAdjustments, result.statementAdjustmentDisplayUtil, matter.projectAdjustmentConfig);
        // maybe there's an updated consideration out of new TarionWarranty changes
        StatementAdjustmentUtil.updateSalePriceAdditionalConsiderations(result.salePriceAdjustment, matter.statementOfAdjustments);
      }

      result.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayItems();
      result.statementAdjustmentDisplayUtil.updateStatementOfAdjustmentDisplayBalanceItems();
      matter.statementOfAdjustmentDisplay.updateData(result.statementAdjustmentDisplayUtil);
    }
    this.updateCommissionPayableToVendorBroker(matter, brokerCommissionComponent);
    matter.updateBalanceOfFundsPayableTo();
    if (matter.isMatterProvinceON && (matter.isProjectOrProjectSale || matter.isSale)) {
      matter.recalculateConsiderationLTTBasedOnSalePrice(soaConsiderationTaxes, this.ontarioTaxRateSlab(considerationTaxes), this.torontoTaxRateSlab(considerationTaxes));
    }
    if (matter.isMatterProvinceNB || matter.isMatterProvinceNS) {
      //this.calculateLandTransferTax();
      matter.updateLTTOnStatementOfAccount();
    }
    matterService.verifyPurchasePriceWithInstructionPurchasePrice(matter);
  }

  static isParcelCancel(parcelLegalDescription: ParcelLegalDescription): boolean {
    return (!!parcelLegalDescription.unitType || !!parcelLegalDescription.parcelNumber || !!parcelLegalDescription.unitNumber || !!parcelLegalDescription.extensionNumber
      || parcelLegalDescription.commonElementFees > 0 || parcelLegalDescription.parcelTitles.length > 0);
  }

  static updateCondoFeeInSoa(matter: Matter): void {
    if (matter.isMatterProvinceSK) {

      if (matter.matterPropertyWithCondo.isCondominium == DpBooleanValueTypes.YES) {
        if (matter.matterPropertyWithCondo.condominiumFees != undefined && matter.matterPropertyWithCondo.condominiumFees != null) {
          let statementAdjustment: StatementAdjustment = matter.statementOfAdjustments.find(item => item.itemKey == StatementAdjustmentExpenseTypes.COMMON_EXPENSE && !item.addedFlag);
          if (!statementAdjustment) {
            matter.createUpdateAdjustmentCommonExp(undefined, this.calculateCondoFeesFromParcels(matter));
            statementAdjustment = matter.commonExpenseAdjustmentFromProperty;
          }
          if (statementAdjustment && statementAdjustment.soaCommonExpense) {
            statementAdjustment.soaCommonExpense.commonExpenseAmount = this.calculateCondoFeesFromParcels(matter);
            statementAdjustment.amount = statementAdjustment.soaCommonExpense.calculateVendorShareDifference(matter.getClosingDate(), matter.isPaysForDateOfClosingVendor);
          }
        }
      }
    }
  }

  static calculateCondoFeesFromParcels(matter: Matter): number {
    let total: number = 0;
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.parcelLegalDescriptions && matter.matterPropertyWithCondo.parcelLegalDescriptions.length > 0) {
      matter.matterPropertyWithCondo.parcelLegalDescriptions.forEach((item: ParcelLegalDescription) => {
        total = total + Number(item.commonElementFees);
      });
    }
    return total;
  }

  static addTotalValueOfParcels(matter: Matter): number {
    let total: number = 0;
    if (matter.matterPropertyWithCondo && matter.matterPropertyWithCondo.parcelLegalDescriptions && matter.matterPropertyWithCondo.parcelLegalDescriptions.length > 0) {
      matter.matterPropertyWithCondo.parcelLegalDescriptions.forEach((item: ParcelLegalDescription) => {
        if (!(item.mineral && item.excludeMineralParcels) && item.parcelTitles && item.parcelTitles.length > 0) {
          item.parcelTitles.forEach((pt: ParcelTitle) => {
            if (pt.parcelTitleValue) {
              total = total + Number(pt.parcelTitleValue);
            }
          });
        }
      });
    }
    return total;
  }

  static addParcel(matter: Matter): void {
    if (!matter.matterPropertyWithCondo.parcelLegalDescriptions) {
      matter.matterPropertyWithCondo.parcelLegalDescriptions = [];
    }
    let parcelLegalDescription: ParcelLegalDescription = new ParcelLegalDescription();
    parcelLegalDescription.id = UUIDUtil.getUUID();
    matter.matterPropertyWithCondo.parcelLegalDescriptions.push(parcelLegalDescription);
    this.updateParcelFieldDescription(parcelLegalDescription, matter.matterPropertyWithCondo, matter.provinceCode);
  }

  static isAddAnotherParcelLinkVisible(matter: Matter): boolean {
    return (matter && (matter.matterPropertyWithCondo.parcelLegalDescriptions.length > 0 && !this.isParcelEmpty(matter.matterPropertyWithCondo)));
  }

  static getBurgerMenuItemsParcel(): string[] {
    let menuItems: string[] = [];
    menuItems.push(BurgerMenuPropertyTeranetParcel.EDIT);
    menuItems.push(BurgerMenuPropertyTeranetParcel.DELETE);
    return menuItems;
  }

  static updateParcelFieldDescription(parcelLegalDescription: ParcelLegalDescription, matterProperty: MatterProperty, provinceCode: ProvinceCode): string {
    if (parcelLegalDescription !== undefined) {
      if (this.isNotCondo(matterProperty)) {
        // let parcelType =parcelLegalDescription && parcelLegalDescription.mineral ? 'Mineral Parcel': 'Surface Parcel';
        if (matterProperty.purchaseIsOfCode === 'FARM_LAND') {
          return parcelLegalDescription.parcelFieldDescNonCondoWithPurchaseFarmLand;
        } else {
          return parcelLegalDescription.parcelFieldDescNonCondo;
        }
      } else {
        let legalDesc = this.isParcelEmpty(matterProperty, parcelLegalDescription) ? '' : matterProperty.parcelTextDescription + parcelLegalDescription.generateParcelFieldValue(provinceCode);
        parcelLegalDescription.parcelFieldDesc = legalDesc;
        return legalDesc;
      }
    }
    return '';
  }

  static isNotCondo(matterProperty: MatterProperty): boolean {
    return matterProperty && matterProperty.isCondominium !== 'YES';
  }

  static isParcelEmpty(matterProperty: MatterProperty, parcelLegalDescription?: ParcelLegalDescription): boolean {
    if (parcelLegalDescription) {
      return !this.validateParcelData(parcelLegalDescription, matterProperty);
    } else {
      if (matterProperty.parcelLegalDescriptions.length > 0) {
        return !this.validateParcelData(matterProperty.parcelLegalDescriptions[ 0 ], matterProperty);
      } else {
        return (matterProperty.parcelLegalDescriptions.length == 0);
      }
    }
  }

  static validateParcelData(parcelLegalDescription: ParcelLegalDescription, matterProperty: MatterProperty): boolean {
    return (!!parcelLegalDescription.unitType || !!parcelLegalDescription.parcelNumber || !!parcelLegalDescription.unitNumber || !!parcelLegalDescription.extensionNumber
      || !!matterProperty.condominiumPlanNumber || !!matterProperty.condominiumType || parcelLegalDescription.commonElementFees > 0 || parcelLegalDescription.parcelTitles.length > 0);
  }

  static updateNBStatementOfAccount(matter: Matter): void {
    if (matter && matter.isMatterProvinceNB && matter.soaTrustLedgerCollection) {
      matter.soaTrustLedgerCollection.updateERegAndRegisterCharges();
      if (matter.secondarySoaSheetsCollection) {
        matter.secondarySoaSheetsCollection.forEach(collection => {
          collection.updateERegAndRegisterCharges();
        });
      }
    }
  }
}
