<form class="form-horizontal" id="teranetConnectForm" #teranetConnectForm="ngForm">
    <div class="container-fluid custom-modal-container">
        <div class="row modal-header">
            <div class="col-xs-12 col-lg-7">
                <h1>Teranet Connect &trade;: Login</h1>
            </div>
            <div class="col-xs-12 col-lg-5"  id="headerLink" tabindex="0">
                <div class="col-xs-12 col-lg-5">
                    <a class="noUnderline" (click)="faq()" (keyup.enter)="faq()" tabindex="0" >Teranet Connect &trade; FAQ</a></div>
                <div class="col-xs-12 col-lg-7">
                    <a class="noUnderline" (click)="rateSheet()" (keyup.enter)="rateSheet()" tabindex="0">Teranet Connect &trade; Rate Sheet</a>
                    <button type="button" class="btn-close pull-right padding-top-5" (click)="close()">&times;</button>
                </div>
            </div>

        </div>

        <div class="row modal-body margin-top-40" [ngClass]="{'error-modal-padding':  (modalErrorComponent && modalErrorComponent.errorList && modalErrorComponent.errorList.length > 0)}">

            <div class="form-group">
                <label class="control-label col-xs-12 col-lg-4" for="credential" >Which Credential are you using?*</label>
                <div class="col-xs-12 col-lg-4">
                    <input *ngIf="tcCredentials.length <= 1" type="text"
                           [dp-read-only]="true"
                           tabindex="0"
                           class="form-control "
                           dp-error-validator
                           [fieldKey]="'matter.propertyTeranet.noCredentials'"
                           [value]="tcCredentials.length == 0 ? '': tcCredentials[0].teranetAccountName + '/' + tcCredentials[0].teranetLoginName"
                           required>

                    <select *ngIf="tcCredentials.length > 1" id="credential" name="credential" class="form-control"
                            [dp-read-only]="formDisabled"
                            tabindex="0"
                            [(ngModel)]="tcCredentialID" >
                        <option *ngFor="let cred of tcCredentials;"
                                [value]="cred.id" >{{cred.teranetAccountName}}/{{cred.teranetLoginName}}</option>
                    </select>

                </div>
                <div class="col-xs-12 col-lg-4 padding-top-10"><a (click)="addCredential()" tabindex="0" (keyup.enter)="addCredential()">Add Credential</a></div>


            </div>
            <div class="form-group">
                <label class="control-label col-xs-12 col-lg-4">Password*</label>
                <div class="col-xs-12 col-lg-4">
                    <input type="password" id="tcPassword" name="tcPassword" [dp-read-only]="formDisabled"
                           [(ngModel)]="tcPassword"
                           class="form-control focus-first"
                           #password="ngModel"
                           required>
                </div>
                <div class="col-xs-12 col-lg-4 padding-top-10"><a (click)="forgetPassword()" (keyup.enter)="forgetPassword()" tabindex="0">Forgot Password</a></div>
            </div>


        </div>
    </div>


    <div class="row modal-footer">
        <div class="col-lg-12 text-center">
            <button id="okBtn" class="dp-btn" type="button" (click)="ok()" [disabled]="teranetConnectForm.form.invalid" tabindex="0">
                OK
            </button>
            <button class="dp-btn dp-btn-cancel" type="button" (click)="close()" (keydown.tab)="tabToHeaderLink();$event.stopPropagation();" tabindex="0">
                Cancel
            </button>
        </div>
    </div>
    <dp-modal-error #modalErrorComponent></dp-modal-error>

</form>

<div *ngIf="lockScreenService && lockScreenService.lockForUpdate" class="update-wait-spinner">
    <div id="spinner">
        <span>Loading...</span>
    </div>
</div>
