import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ThirdPartyType, ThirdPartyUser} from '../../shared-main/third-party/third-party-user';
import {StewartAssystUser} from '../../shared-main/telus/stewart-assyst-user';
import {FieldError} from '../../core';
import {ApplicationError} from '../../core/application-error';
import {TelusService} from '../../shared-main/telus/telus-service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {FCTService} from '../../shared-main/fct/fct-service';
import {AppConfig} from '../../shared-main/app-configuration';
import {LockScreenService} from '../../core/lock-screen.service';
import {TCOLService} from '../../shared-main/tcol/tcol-service';
import {TCOLUser} from '../../shared-main/tcol/tcol-user';

declare var jQuery: any;

@Component({
  selector: 'dp-third-party-credentials',
  templateUrl: 'third-party-credentials.component.html'
})
export class ThirdPartyCredentialsComponent implements OnInit {
  @Input() thirdPartyUser: ThirdPartyUser;
  @Input() thirdPartyType: ThirdPartyType;
  @Output() thirdPartyUserCreated = new EventEmitter<ThirdPartyUser>();

  reValidateUser: boolean = false;
  burgerMenuItems: string[];

  ngOnInit() {
    this.setUpThirdPartyCredentialPage();
    this.burgerMenuItems = this.getBurgerMenuItems();
  }

  constructor(
    public telusService: TelusService,
    public fctService: FCTService,
    public tcolService: TCOLService,
    public dialogService: DialogService,
    public appConfig: AppConfig,
    public lockScreenService: LockScreenService
  ) {

  }

  get isValidateButtonEnabled(): boolean {
    return (this.thirdPartyUser && this.thirdPartyUser.password && this.thirdPartyUser.userName && (this.thirdPartyUser.userName).trim().length > 0 && (this.thirdPartyUser.password).trim().length > 0) ? true : false;
  }

  getBurgerMenuItems(): string[] {
    let burgerMenuItems: string[] = [];
    if (this.thirdPartyType !== 'TCOL') {
      burgerMenuItems.push('My Profile');
    }
    if (this.thirdPartyType == 'STEWART_ASSYST') {
      burgerMenuItems.push('Messages');
    }
    burgerMenuItems.push('Re-Validate');
    burgerMenuItems.push('Delete');
    return burgerMenuItems;
  }

  burgerMenuClicked(clickedMenuOption: string): void {
    switch (clickedMenuOption) {
      case 'My Profile':
        console.log('My Profile is clicked..stewartAssystUser.id:' + this.thirdPartyUser.id);
        this.getWebUrl(this.thirdPartyUser.id);
        break;
      case 'Messages':
        this.getWebUrl(this.thirdPartyUser.id, 'Messages');
        break;
      case 'Re-Validate':
        this.reValidateUser = true;
        this.reValidateStewartAssystPage();
        break;
      case 'Delete':
        if (this.thirdPartyType === 'TCOL') {
          this.deleteTCOLCredentialsFromUnity(this.thirdPartyUser);
        }
        if (this.thirdPartyType == 'STEWART_ASSYST') {
          this.deleteMyStewartAssystCredentialsFromUnity(this.thirdPartyUser);
        } else if (this.thirdPartyType == 'FCT') {
          this.deleteFCTCredentialsFromUnity(this.thirdPartyUser);
        }

        break;
      default:
        return;
    }
  }

  getWebUrl(userId: number, actionType: string = 'Profile') {
    if (this.thirdPartyType == 'STEWART_ASSYST') {
      this.getTelusWebUrl(userId, actionType);
    } else if (this.thirdPartyType == 'FCT') {
      this.getFCTWebUrl(this.thirdPartyUser);
    }
  }

    getTelusWebUrl(userId: number, actionType: string = 'Profile') {
        this.telusService.getTelusWebUrl(userId, actionType).subscribe(url => {
                console.log("Returned url:" + url);
                window.open(url);
            },
            (error: ApplicationError) => {
                const isAssyst = "Assyst Real Estate";
                (error && error.message.includes(isAssyst)) ? error.message = 'The Unity® Lender Centre credentials provided are invalid.<br><br> Please check to make sure you entered your credentials correctly.' : error.errorMessage;
                if (error && error.errorCode) {
                    if (error.errorCode === 'app.invalidFieldsDataError' && error.fieldErrors.length > 0) {
                        let errorMessage: string = '';
                        error.fieldErrors.forEach((fieldError: FieldError) => {
                            errorMessage = errorMessage + fieldError.message;
                        });

            this.dialogService.confirm('Error', errorMessage, true, 'OK').subscribe(res => {
              if (res) {
              }
            });
          } else {
            this.dialogService.confirm('Error', error.message + ' (' + error.errorCode + ').', true, 'OK').subscribe(res => {
              if (res) {
              }
            });
          }

        }
      }
    );
  }

  getFCTWebUrl(fctUser: StewartAssystUser) {
    this.fctService.getFCTMyProfile(fctUser).subscribe(url => {
        console.log('Returned url:' + url);
        window.open(url);
      },
      (error: ApplicationError) => {
        if (error && error.errorCode) {
          if (error.errorCode === 'app.invalidFieldsDataError' && error.fieldErrors.length > 0) {
            let errorMessage: string = '';
            error.fieldErrors.forEach((fieldError: FieldError) => {
              errorMessage = errorMessage + fieldError.message;
            });

            this.dialogService.confirm('Error', errorMessage, true, 'OK').subscribe(res => {
              if (res) {
              }
            });
          } else {
            this.dialogService.confirm('Error', error.message + ' (' + error.errorCode + ').', true, 'OK').subscribe(res => {
              if (res) {
              }
            });
          }

        }
      }
    );
  }

    public deleteMyStewartAssystCredentialsFromUnity(stewartAssystUser: StewartAssystUser): void {
        let message = 'Are you sure you would like to delete your Unity® Lender Centre credentials from your' +
        ' Unity User?<br><br>Please note you will no longer be able to communicate with Unity® Lender Centre in Unity.<br><br>In order to' +
        ' communicate with Unity® Lender Centre, you must associate your credentials.';

    this.dialogService.confirm('Confirmation', message, false, 'Delete', '', true).subscribe(res => {
      if (res) {
        this.telusService.deleteStewartAssystUnityUser(stewartAssystUser).subscribe(
          (response: any) => {
            stewartAssystUser.id = null;
            stewartAssystUser.userName = null;
            this.setUpThirdPartyCredentialPage();
          }
        );
      }
    });
  }

  public deleteFCTCredentialsFromUnity(fctUser: StewartAssystUser): void {
    this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete your FCT credentials from your' +
      ' Unity user profile?<br><br>Please note you will no longer be able to communicate with FCT in Unity.<br><br>In order to' +
      ' communicate with FCT, you must associate your credentials.', false, 'Delete', '', true).subscribe(res => {
      if (res) {
        this.fctService.deleteFCTUnityUser(fctUser).subscribe(
          (response: any) => {
            fctUser.id = null;
            fctUser.userName = null;
            this.setUpThirdPartyCredentialPage();
          }
        );
      }
    });
  }

  public deleteTCOLCredentialsFromUnity(tcolUser: TCOLUser): void {
    this.dialogService.confirm('Confirmation', 'Are you sure you would like to delete your Tax Certificates Online credentials from your' +
      ' Unity user profile?<br><br>Please note you will no longer be able to communicate with Tax Certificates Online in Unity.<br><br>In order to' +
      ' communicate with Tax Certificates Online, you must associate your credentials.', false, 'Delete', '', true).subscribe(res => {
      if (res) {
        this.tcolService.deleteTCOLUnityUser(tcolUser).subscribe((response: any) => {
          tcolUser.id = null;
          tcolUser.userName = null;
          this.setUpThirdPartyCredentialPage();
        });
      }
    });
  }

  validateThirdPartyUser() {
    if (this.thirdPartyType === 'TCOL') {
      this.validateTCOLUser();
    } else if (this.thirdPartyType === 'STEWART_ASSYST') {
      this.validateStewartAssystUser();
    } else {
      this.validateFCTUser();
    }
  }

  validateFCTUser() {
    this.fctService.validateUser(this.thirdPartyUser).subscribe(
      (fctUser: ThirdPartyUser) => {

        this.dialogService.confirm('Success', ThirdPartyCredentialsComponent.getSuccessMessage('FCT'), true, 'OK').subscribe(res => {

          if (res) {
            this.reValidateUser = false;
            this.thirdPartyUser = fctUser;
            this.thirdPartyUserCreated.emit(fctUser);
          }

        });
      },
      (error: ApplicationError) => {
        if (error && error.errorCode) {
          this.thirdPartyUser.password = '';
          this.dialogService.confirm('Error', error.message, true, 'OK').subscribe(res => {
            if (res) {
              this.reValidateStewartAssystPage();
            }
          });
        }

      }
    );

  }

  validateTCOLUser() {
    this.tcolService.validateTCOLUser(this.thirdPartyUser).subscribe((tcolUser: ThirdPartyUser) => {
      this.dialogService.confirm('Success', ThirdPartyCredentialsComponent.getSuccessMessage('Tax Certificates Online'), true, 'OK').subscribe((res) => {
        if (res) {
          this.reValidateUser = false;
          this.thirdPartyUser = tcolUser;
          this.thirdPartyUserCreated.emit(tcolUser);
        }
      });
    }, (error: ApplicationError) => {
      if (error && error.errorCode) {
        this.thirdPartyUser.password = '';
        this.dialogService.confirm('Error', error.message, true, 'OK').subscribe((res) => {
          if (res) {
            this.reValidateStewartAssystPage();
          }
        });
      }
    });
  }

  public static getSuccessMessage(thirdPartyName: string): string {
    return `The ${ thirdPartyName } credentials were successfully validated and saved to your Unity user profile.`;
  }

  validateStewartAssystUser() {
    this.telusService.validateUser(this.thirdPartyUser).subscribe(
      (telusUser: ThirdPartyUser) => {

                this.dialogService.confirm('Success', ThirdPartyCredentialsComponent.getSuccessMessage('Unity® Lender Centre'), true, 'OK').subscribe(res => {
                    if (res) {
                        this.reValidateUser = false;
                        this.thirdPartyUser = telusUser;
                        this.thirdPartyUserCreated.emit(telusUser);
                    }


                });
            },
            (error: ApplicationError) => {
                const isAssyst = "Assyst Real Estate";
                (error && error.message.includes(isAssyst)) ? error.message = 'The Unity® Lender Centre credentials provided are invalid.<br><br> Please check to make sure you entered your credentials correctly.' : error.errorMessage;
                if (error && error.errorCode) {
                    this.thirdPartyUser.password = '';
                    if (error.errorCode === 'app.invalidFieldsDataError' && error.fieldErrors.length > 0) {
                        let errorMessage: string = '';
                        error.fieldErrors.forEach((fieldError: FieldError) => {
                            errorMessage = errorMessage + fieldError.message;
                        });

            this.dialogService.confirm('Error', errorMessage, true, 'OK').subscribe(res => {
              if (res) {
                this.reValidateStewartAssystPage();
              }
            });
          } else {
            this.dialogService.confirm('Error', error.message + ' (' + error.errorCode + ').', true, 'OK').subscribe(res => {
              if (res) {
                this.reValidateStewartAssystPage();
              }
            });
          }

        }

      }
    );

  }

  reValidateStewartAssystPage(): void {
    setTimeout(() => {
      jQuery('#thirdPartyPassword').attr('type', 'password');
      jQuery('#thirdPartyPassword').focus();
    }, 400);
  }

  setUpThirdPartyCredentialPage(): void {
    setTimeout(() => {
      jQuery('#thirdPartyPassword').attr('type', 'password');
      jQuery('#thirdPartyUserName').focus();
    }, 400);
  }

  getPasswordMaxLength(): number {
    return this.thirdPartyType == 'STEWART_ASSYST' ? 8 : 50;
  }

  getUsernameMaxLength(): number {
    return this.thirdPartyType == 'STEWART_ASSYST' ? 20 : 50;
  }

  openForgotPassword(): void {
    if (this.thirdPartyType === 'TCOL') {
      this.openTcolForgotPasswordUrl();
    }
    if (this.thirdPartyType == 'STEWART_ASSYST') {
      this.openStewartAssystForgotPasswordUrl();
    } else if (this.thirdPartyType == 'FCT') {
      this.openFctForgotPasswordUrl();
    }
  }

  openStewartAssystForgotPasswordUrl() {
    this.lockScreenService.lockForUpdate = true;
    this.telusService.getForgotPasswordUrl().finally(() => {
      this.lockScreenService.lockForUpdate = false;
    }).subscribe(
      (forgotUrl: string) => {
        window.open(forgotUrl);
      },
      (error: ApplicationError) => {
        if (error) {
          this.dialogService.confirm('Error', error.message, true, 'OK').subscribe(res => {
          });
        }
      }
    );
  }

  openFctForgotPasswordUrl() {
    this.lockScreenService.lockForUpdate = true;
    this.fctService.getFCTForgotPswdUrl().finally(() => {
      this.lockScreenService.lockForUpdate = false;
    }).subscribe(
      (forgotUrl: string) => {
        window.open(forgotUrl);
      },
      (error: ApplicationError) => {
        if (error) {
          this.dialogService.confirm('Error', error.message, true, 'OK').subscribe(res => {
          });
        }

      }
    );
  }

  openTcolForgotPasswordUrl() {
    this.lockScreenService.lockForUpdate = true;
    this.tcolService.getTCOLForgotPasswordUrl().finally(() => {
      this.lockScreenService.lockForUpdate = false;
    }).subscribe((forgotUrl: string) => {
        window.open(forgotUrl);
      },
      (error: ApplicationError) => {
        if (error) {
          this.dialogService.confirm('Error', error.message, true, 'OK').subscribe(res => {
          });
        }
      }
    );
  }

  firstFieldKeyCommands(): void {
    event.preventDefault();
    jQuery('#btnClose').focus();
  }

  cancelRevalidate() {
    this.reValidateUser = false;
  }
}
