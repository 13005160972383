import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'dp-toBeDefined',
  templateUrl: './toBeDefined.component.html',
  styleUrls: [
    '../../unity-login.styles.scss'
  ]
})

export class ToBeDefinedComponent implements OnInit {

  ngOnInit(): void {

  }
}
