<!-- modal -->
<form class="form-horizontal">
    <div class="modal-header">
        <div class=" col-lg-12">
            <div class="col-lg-10"><h1>Parcel Legal Description</h1></div>
            <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group col-lg-12">
            <label class="control-label col-lg-2" for="parcelNumber">Parcel Number</label>
            <div class="col-lg-4">
                <input type="text" id="parcelNumber" name="parcelNumber" class="form-control" maxlength="25" dp-uppercase
                       [(ngModel)]="localParcelLegalDescription.parcelNumber" />
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="!isCondo() && isPurchaseOfFarmLand()">
            <label class="control-label col-lg-2" for="qtrSec">QtrSec</label>
            <div class="col-lg-4">
                <input type="text" id="qtrSec" name="qtrSec" class="form-control" maxlength="25" dp-uppercase
                       [(ngModel)]="localParcelLegalDescription.qtrsec" />
            </div>
        </div>
        <div class="form-group col-lg-12"  *ngIf="!isCondo() && isPurchaseOfFarmLand()">
            <div class="control-label col-lg-3" style="padding-left: 80px">
                <label class="control-label col-lg-6" for="sec">Sec</label>
                <div class="col-lg-6">
                    <input type="text" id="sec" name="sec" class="form-control" maxlength="2" dp-uppercase
                           [(ngModel)]="localParcelLegalDescription.sec" />
                </div>
            </div>

            <div class="control-label col-lg-3">
                <label class="control-label col-lg-6" for="sec">Twp</label>
                <div class="col-lg-6">
                    <input type="text" id="twp" name="twp" class="form-control" maxlength="10" dp-uppercase dp-alphanumeric-only
                           [(ngModel)]="localParcelLegalDescription.twp" />
                </div>
            </div>
            <div class="control-label col-lg-3">
                <label class="control-label col-lg-6" for="rng">Rng</label>
                <div class="col-lg-4">
                    <input type="text" id="rng" name="rng" class="form-control" maxlength="2" dp-uppercase
                           [(ngModel)]="localParcelLegalDescription.rng" />
                </div>
            </div>
            <div class="control-label col-lg-3">
                <label class="control-label col-lg-6" for="mdn">Mdn</label>
                <div class="col-lg-4">
                    <input type="text" id="mdn" name="mdn" class="form-control" maxlength="2" dp-uppercase
                           [(ngModel)]="localParcelLegalDescription.mdn" />
                </div>
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="!isCondo() && !isPurchaseOfFarmLand()">
            <label class="control-label col-lg-2" for="lotNumber">Lot Number</label>
            <div class="col-lg-4">
                <input type="text" id="lotNumber" name="lotNumber" class="form-control" maxlength="10" dp-uppercase
                       [(ngModel)]="localParcelLegalDescription.lotNumber" />
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="!isCondo() && !isPurchaseOfFarmLand()">
            <label class="control-label col-lg-2" for="blockNumber">Block Number</label>
            <div class="col-lg-4">
                <input type="text" id="blockNumber" name="blockNumber" class="form-control" maxlength="10" dp-uppercase
                       [(ngModel)]="localParcelLegalDescription.blockNumber" />
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="!isCondo() && !isPurchaseOfFarmLand()">
            <label class="control-label col-lg-2" for="planNumber">Plan Number</label>
            <div class="col-lg-4">
                <input type="text" id="planNumber" name="planNumber" class="form-control" maxlength="100" dp-uppercase
                       [(ngModel)]="localParcelLegalDescription.planNumber" />
            </div>
        </div>


        <div class="form-group col-lg-12" *ngIf="isCondo()">
            <label class="control-label col-lg-2" for="unitNumber">Unit Number</label>
            <div class="col-lg-2">
                <input type="text" id="unitNumber" name="unitNumber" class="form-control" dp-uppercase maxlength="5"
                       [(ngModel)]="localParcelLegalDescription.unitNumber" />
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="!isCondo() && isPurchaseOfFarmLand()">
            <label class="control-label col-lg-2" for="municipality">Municipality</label>
            <div class="col-lg-2">
                <input type="text" id="municipality" name="municipality" class="form-control" maxlength="2" dp-uppercase
                       [(ngModel)]="localParcelLegalDescription.municipality" />
            </div>
        </div>
        <div class="form-group col-lg-12">
            <label class="control-label col-lg-2" for="extensionNumber">Extension Number</label>
            <div class="col-lg-2">
                <input type="text" id="extensionNumber" name="extensionNumber" class="form-control"  dp-uppercase maxlength="5"
                       [(ngModel)]="localParcelLegalDescription.extensionNumber" />
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="!isCondo()">
            <label class="control-label col-lg-2" for="mineral">Mineral</label>
            <div class="col-lg-2" style="padding-top: 9px">
                <dp-checkbox fieldId="mineral">
                    <input type="checkbox"
                           id="mineral"
                           name="mineral"
                           [(ngModel)]="localParcelLegalDescription.mineral"
                    />
                </dp-checkbox>

            </div>
            <div class="col-lg-6 padding-top-10" *ngIf="localParcelLegalDescription.mineral">
                <dp-checkbox fieldId="excludeMineralParcels">
                    <input type="checkbox"
                           id="excludeMineralParcels"
                           name="excludeMineralParcels"
                           [(ngModel)]="localParcelLegalDescription.excludeMineralParcels"
                    />
                </dp-checkbox>
                <label> Exclude from sale price value</label>
            </div>

        </div>
        <div class="form-group col-lg-12" *ngIf="!isCondo()">
            <label class="control-label col-lg-2" for="mineralRights">Mineral Rights</label>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    id="mineralRights" name="mineralRights" [(ngModel)]="localParcelLegalDescription.mineralRights" >
                    <option *ngFor="let mineralRights of mineralRightsOptions"
                            [value]="mineralRights.value">{{mineralRights.label}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="isCondo()">
            <label class="control-label col-lg-2" for="condominuimFees">Condominium Fees</label>
            <div class="col-lg-3">
                <input type="text" id="condominuimFees" name="condominiumFees" class="form-control"
                       dp-default-currency dp-currency  [(ngModel)]="localParcelLegalDescription.commonElementFees" />
            </div>
        </div>
        <div class="form-group col-lg-12" *ngIf="isCondo()">
            <label class="control-label col-lg-2" for="unitType">Unit Type</label>
            <div class="col-lg-4">
                <select
                    class="form-control"
                    id="unitType" name="unitType" [(ngModel)]="localParcelLegalDescription.unitType" >
                    <option *ngFor="let unitType of condoUnitType"
                            [value]="unitType.value">{{unitType.label}}
                    </option>
                </select>
            </div>
        </div>
        <div class="clear"></div>
        <div class="form-group main-level"><span class="title min-height-30">Titles</span></div>
        <div class="form-group" *ngFor="let parcelTitle of localParcelLegalDescription.parcelTitles;  let i=index">
            <label class="control-label col-lg-2" for="titleNumber_{{i}}">Title Number</label>
            <div class="col-lg-3">
                <input type="text" id="titleNumber_{{i}}" name="titleNumber_{{i}}" class="form-control"
                       dp-uppercase maxlength="12"   [(ngModel)]="parcelTitle.titleNumber" />
                <div class="actionbtns margin-top-5" *ngIf="i>0">
                    <div class="width-20">
                                        <span>
                                            <i class="fa fa-trash"  (click)="removeParcelTitle(parcelTitle)"
                                               aria-hidden="true"></i>
                                        </span>
                    </div>
                </div>
            </div>
            <label class="control-label col-lg-1" for="value_{{i}}">Value</label>
            <div class="col-lg-2">
                <input type="text" id="value_{{i}}" name="value_{{i}}" class="form-control"
                       dp-default-currency dp-currency  [(ngModel)]="parcelTitle.parcelTitleValue" />
            </div>
            <div *ngIf="isCondo()">
                <label class="control-label col-lg-2" for="numberOfShares_{{i}}">Number of Individual Shares</label>
                <div class="col-lg-2">
                    <input  id="numberOfShares_{{i}}" name="numberOfShares_{{i}}" class="form-control padding-right-3"
                            [(ngModel)]="parcelTitle.numberOfIndividualShares"  [max-int] = 99999.999999
                            dp-percentage
                            [max-decimals] = 6 />
                </div>

            </div>

        </div>
        <div class="form-group">
            <div class="col-lg-2"></div>
            <div class="col-lg-4"><a href="javascript:void(0);" (click)="addAnotherTitle()">
                Add Another Title
                <span class="selector fa fa-plus-square-o"></span>
            </a></div>
        </div>
    </div>

    <div class="row modal-footer">
        <div class="col-lg-12 buttons">
            <button id="okBtn" type="button" class="dp-btn" (click)="ok()">
                <span>Ok</span>
            </button>
            <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                <span>Cancel</span>
            </button>
        </div>
    </div>

</form>
