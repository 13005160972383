import {MatterTitleInsurance} from '../../matters/shared/matter-title-insurance';
import {ChicagoBaseResponse} from './chicago-base-response';

export class ChicagoGetAllStatusDetailsResponse extends ChicagoBaseResponse {
  matterTitleInsurance: MatterTitleInsurance;

  constructor(c?: ChicagoGetAllStatusDetailsResponse) {
    super(c);

    if (c.matterTitleInsurance) {
      this.matterTitleInsurance = new MatterTitleInsurance(c.matterTitleInsurance);
    }

  }
}
