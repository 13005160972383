import {Component, Inject, OnInit} from '@angular/core';
import {SalePriceAdjustmentHeading, SalePriceAdjustmentHeadingItem} from './sale-price-adjustment-heading';
import {ErrorService} from '../../shared/error-handling/error-service';
import {DPError} from '../../shared/error-handling/dp-error';
import {StatementOfAdjustmentHeading} from '../statement-adjustment/model/statement-adjustment-heading';
import {PROVINCE_CODES} from '../shared/user-province';
import {MatterType} from '..';
import {ModalComponent} from '../../shared/dialog/modal-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export class SalePriceAdjustmentHeadingContext {
  salePriceAdjustmentHeading: SalePriceAdjustmentHeading;
  statementAdjustmentHeading: StatementOfAdjustmentHeading;
  taxType: string;
  provinceCode: string;
  matterType?: MatterType;
}

@Component({
  selector: 'dp-sale-price-adjustmentheading-modal-content',
  templateUrl: 'sale-price-adjustment-heading.modal.html',
  providers: [ErrorService]
})
export class SalePriceAdjustmentHeadingModal
  extends ModalComponent<SalePriceAdjustmentHeadingContext>
  implements OnInit
{
  salesPriceAdjustmentHeading: SalePriceAdjustmentHeading;

  constructor(
    public dialog: MatDialogRef<SalePriceAdjustmentHeadingModal>,
    public errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) context?: SalePriceAdjustmentHeadingContext
  ) {
    super(dialog, context);
  }

  ngOnInit() {
    this.salesPriceAdjustmentHeading = new SalePriceAdjustmentHeading(this.context.salePriceAdjustmentHeading);
    if (
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems &&
      !this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length
    ) {
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.push(new SalePriceAdjustmentHeadingItem());
    }
  }

  ok() {
    this.errorService.clearAllSaveErrors();
    if (!this.salesPriceAdjustmentHeading.name) {
      this.errorService.addDpSaveError(DPError.createDPError('matter.soa.modal.salePrice.adjustmentHeading.name'));
    }
    if (!this.salesPriceAdjustmentHeading.cost && !this.isPrairieProvince()) {
      this.errorService.addDpSaveError(DPError.createDPError('matter.soa.modal.salePrice.adjustmentHeading.cost'));
    }
    let emptyRows: number[] = [];
    if (
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems &&
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length
    ) {
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.forEach((item, index) => {
        if (!item.description) {
          if (Number(item.cost)) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                'matter.salesPriceAdjustmentHeading.modal.item' + index,
                'Description is' + ' Required.',
                'Sales Price Adjustment Heading',
                'ERROR'
              )
            );
          } else {
            emptyRows.push(index);
          }
        } else {
          if (!Number(item.cost) && !this.isPrairieProvince()) {
            this.errorService.addDpSaveError(
              DPError.createCustomDPError(
                'matter.salesPriceAdjustmentHeading.modal.itemCost' + index,
                'Item Cost' + ' is Required.',
                'Sales Price Adjustment Heading',
                'ERROR'
              )
            );
          }
        }
      });
    }

    if (this.errorService.hasNoErrors()) {
      if (emptyRows && emptyRows.length) {
        for (let i = emptyRows.length - 1; i >= 0; i--) {
          this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.splice(emptyRows[i], 1);
        }
      }
      this.dialog.close(this.salesPriceAdjustmentHeading);
    }
  }

  close() {
    this.dialog.close();
  }

  addAnotherItem(): void {
    let salePriceAdjustmentHeadingItem = new SalePriceAdjustmentHeadingItem();
    this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.push(salePriceAdjustmentHeadingItem);
  }

  calculateHeadingCost(): void {
    if (
      this.salesPriceAdjustmentHeading.autoCalculate &&
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems &&
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length
    ) {
      this.salesPriceAdjustmentHeading.cost = 0;
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.forEach((item) => {
        if (Number(item.cost)) {
          this.salesPriceAdjustmentHeading.cost += Number(item.cost);
        }
      });
    }
  }

  removeItem(index: number): void {
    this.errorService.removeDpSaveError('matter.salesPriceAdjustmentHeading.modal.itemCost' + index);
    this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.splice(index, 1);
    this.calculateHeadingCost();
  }

  get vendorLabel(): string {
    if (this.context.provinceCode === 'BC') {
      return 'Seller';
    }
    return this.context.statementAdjustmentHeading
      ? this.context.statementAdjustmentHeading.rightColumnListsCreditsTo
      : '';
  }

  showWarningMessage(): boolean {
    return (
      !this.salesPriceAdjustmentHeading.autoCalculate &&
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems &&
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length &&
      this.anyItemCostNotZero()
    );
  }

  anyItemCostNotZero(): boolean {
    if (
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems &&
      this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.length
    ) {
      let item = this.salesPriceAdjustmentHeading.salePriceAdjustmentHeadingItems.find((item) => !!item.cost);
      return !!item;
    } else {
      return false;
    }
  }

  isPrairieProvince(): boolean {
    return (
      this.context.provinceCode === PROVINCE_CODES.ALBERTA ||
      this.context.provinceCode === PROVINCE_CODES.SASKATCHEWAN ||
      this.context.provinceCode === PROVINCE_CODES.MANITOBA
    );
  }
}
