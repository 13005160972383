<div class="container-fluid custom-modal-container border-radius">
        <div class="row modal-header">
            <div class="col-lg-12">
                <div class="col-lg-10"><h1>{{header}}</h1></div>
                <button type="button" class="btn-close pull-right" (click)="cancelDialog()" >&times;</button>
            </div>
        </div>
        <div class="row modal-body">
            <div class="todoapp">
                <div class="form-group">
                        <div class="inp col-lg-10">
                            <input
                                tabindex="0"
                                maxlength="100"
                                class="new-todo form-control"
                                placeholder="  "
                                autofocus
                                #newComment
                                [(ngModel)]="newCommentText"
                                (keyup.enter)="addNewComment(newComment.value)"
                                (keydown.shift.tab)="cancelEvent($event)"
                                (click)="removeFocus()">
                        </div>
                        <div class="addNew col-lg-2">
                            <input
                                tabindex="0"
                                type="button"
                                class="secondary-button"
                                [class.nonEditable]="!newComment.value"
                                (click)="addNewComment(newComment.value)"
                                [disabled]="!newComment.value"
                                value="Add new">
                        </div>

                </div>
                <div class="main col-lg-12" *ngIf="specialCommentsToDo">
                    <ul #scrollable class="todo-list">
                        <li *ngFor="let comment of specialCommentsToDo; trackBy:trackByFunc; let i=index;"
                            tabindex="0"
                            [ngClass]="{'tododeleted': comment.deleted, 'active': comment.completed }"
                            (focus)="toggleCompletion(comment)"
                            (dblclick)="selectComment()"
                            (keydown)="onSelectionChange($event, comment)">
                            <div class="view">
                                <label *ngIf="!comment.editing">{{comment.customPickListItemValue}}</label>
                                <input
                                    *ngIf="comment.editing"
                                    #commentEdit
                                    maxlength="100"
                                    class="edit"
                                    [value]="comment.customPickListItemValue"
                                    (keydown)="cancelComment($event, comment)"
                                    (keyup.enter)="updateComment($event, commentEdit.value, i, comment)"
                                    (blur)="updateComment($event, commentEdit.value, i, comment)">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    <div class="btn2 col-lg-12 modal-footer">
        <div class="col-lg-8 offset-lg-2">
            <input
                type="button"
                id="okBtn2"
                class="dp-btn keyboard-save-action"
                [disabled]="isSelectDisabled"
                (click)="selectComment()"
                value="Select">
            <input
                type="button"
                class="secondary-button"
                [disabled]="isMoveUpDisabled"
                (click)="moveUp(getComment())"
                value="Move Up">
            <input
                type="button"
                class="secondary-button"
                [disabled]="isMoveDownDisabled"
                (click)="moveDown(getComment())"
                value="Move Down">
            <input
                type="button"
                class="secondary-button"
                [disabled]="isEditDisabled"
                (click)="editComment(getComment())"
                value="Edit">
            <input
                type="button"
                (keydown.tab)="cancelEvent($event)"
                class="secondary-button"
                [disabled]="isDeleteDisabled"
                (click)="removeComment(getComment())"
                value="Delete">
        </div>
    </div>
</div>

