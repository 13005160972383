import {LowerCasePipe} from '@angular/common';
import {BaseEntity} from '../../shared/BaseEntity/base-entity';
import {AddressUtil} from './address-util';
import {UUIDUtil} from '../../main/uuid-util';
import {Address as EMPAddress} from '../../shared-main/telus/stewart-assyst-mortgage-instruction-data';
import {dropDowns} from '../../shared-main/address-Form/drop-downs';
import {Ignore} from '../../admin/shared/dp-ignore-decorator';
import Utils from '../../shared-main/utils';
import {AddressTypes} from './address-types';

const propertyIgnoreList = [ 'id', 'addressHash', 'parsedAddressLine', 'errorMessage', 'isAddressValid', 'parsedUnitNo', 'parsedStreetNumber', 'parsedStreetName', 'parsedStreetAddress' ];

export interface SameAsAddressOption {
  description: string,
  srcAddress: Address,
}

export class Address extends BaseEntity {

  static readonly ADDRESS_LINE_DEFAULT_MAX_LENGTH = 49; //default address length for most addresses (including Ontario Subject Property)
  static readonly ADDRESS_LINE_MORTGAGEE_MAX_LENGTH = 100; //used only for mortgagee addresses for all provinces
  static readonly ADDRESS_LINE_INCREASED_MAX_LENGTH = 256; //used for subject property in all provinces except Ontario

  constructor(address?: Address) {
    super(address);
    if (!this.id || this.id < 0) {
      this.id = UUIDUtil.getUUID();
    }
  }

  @Ignore
  id: number;
  static clientAssignedIdentifierEntity: boolean = true;//Marker property for indicating client side assigns the ID to this entity
  addressLine1: any; //Changed the type of addressLine1 from String to any as same field is bound to auto-complete
  unitNumber: string;// this field is populated from address parsed line.
  addressLine2: string;
  addressTypeCode: string;
  city: string;
  country: string;
  postalCode: string;
  provinceName: string;
  provinceCode: string;
  primaryAddress: boolean;
  sameAsImportedAddress: boolean;
  @Ignore
  errorMessage?: string;
  @Ignore
  isAddressValid?: boolean;
  @Ignore
  addressHash: string;
  sameAsAddressTypeCode: string; //"SUBJECT"| "PRE_CLOSING_ADDRESS" | "POST_CLOSING_ADDRESS" | "SOLICITOR"
  parsedAddressLine: any;

  parsedUnitNo: string;
  parsedStreetNumber: string;
  parsedStreetName: string;
  parsedStreetAddress: string;
  startedLivingDate: string;

  // equals(source : Address) : boolean {
  //
  //     return this.addressLine1 == source.addressLine1
  //         && this.addressLine2 == source.addressLine2
  //         && this.city == source.city
  //         && this.postalCode == source.postalCode
  //         && this.provinceCode == source.provinceCode
  //         && this.provinceName == source.provinceName
  //         && this.country == source.country
  //         && this.sameAsAddressTypeCode == source.sameAsAddressTypeCode;
  // }

  // updateFrom(source: Address): void {

  //     this.addressLine1 = source.addressLine1;
  //     this.addressLine2 = source.addressLine2;
  //     this.city = source.city;
  //     this.postalCode = source.postalCode;
  //     this.provinceCode = source.provinceCode;
  //     this.provinceName = source.provinceName;
  // }
  private _wrapperSameAsAddressTypeCode: string;

  get wrapperSameAsAddressTypeCode(): string {
    return this.sameAsAddressTypeCode ? this.sameAsAddressTypeCode : '';
  }

  set wrapperSameAsAddressTypeCode(value: string) {
    this.sameAsAddressTypeCode = value;
  }

  update(source: Address, skipPrimaryAddress?: boolean): void {
    this.addressLine1 = source.addressLine1;
    this.addressLine2 = source.addressLine2;
    this.city = source.city;
    this.postalCode = source.postalCode;
    this.provinceCode = source.provinceCode;
    this.provinceName = source.provinceName;
    this.country = source.country;
    this.sameAsAddressTypeCode = source.sameAsAddressTypeCode;
    if (!skipPrimaryAddress) {
      this.primaryAddress = source.primaryAddress;
    }
    this.sameAsImportedAddress = source.sameAsImportedAddress;
    this.addressTypeCode = source.addressTypeCode;
    this.parsedUnitNo = source.parsedUnitNo;
    this.parsedStreetNumber = source.parsedStreetNumber;
    this.parsedStreetName = source.parsedStreetName;
    this.parsedStreetAddress = source.parsedStreetAddress;
    this.startedLivingDate = source.startedLivingDate;
  }

  get addressText(): string {
    return this.getAddressText(true, false, true, true);
  }

  get addressTextWithProvinceName(): string {
    return this.getAddressText(true, true, true, true);
  }

  get addressTextWithoutCityAndProvinceName(): string {
    return this.getAddressText(false, false, true, true);
  }

  get addressTextWithoutCountryAndPostalCode(): string {
    return this.getAddressText(true, true, false, false);
  }

  get addressTextWithoutCountry(): string {
    return this.getAddressText(true, true, false, true);
  }

  get addressTextWithoutProvinceAndPostalCode(): string {
    return (typeof this.addressLine1 == 'object' || !this.addressLine1 ? '' : this.addressLine1 || '') +
      (this.addressLine2 ? ', ' + this.addressLine2 : '') +
      (this.city ? ', ' + this.city : '');
  }

  private getAddressText(useCity: boolean, useProvinceName: boolean, useCountryName: boolean, usePostalCode: boolean): string {
    return (typeof this.addressLine1 == 'object' || !this.addressLine1 ? '' : this.addressLine1 || '') +
      (this.addressLine2 ? ', ' + this.addressLine2 : '') +
      (useCity && this.city ? ', ' + this.city : '') +
      (useProvinceName && this.provinceName ? ', ' + this.provinceName : '') +
      (useCountryName && !this.isCountryCanada && this.country ? ', ' + this.country : '') +
      (usePostalCode && this.postalCode ? ' ' + this.postalCode : '');
  }

  get isCountryCanada(): boolean {
    return this.country && (this.country.toUpperCase() === 'CANADA' || this.country.toUpperCase() === 'CA');
  }

  get isCountryUsa(): boolean {
    return this.country && (this.country.toUpperCase() === 'USA' || this.country.toUpperCase() === 'US');
  }

  private getAddressHashString(): string {
    return (this.addressLine1 || '') +
      (this.addressLine2 ? ', ' + this.addressLine2 : '') +
      (this.city ? ', ' + this.city : '') +
      (this.provinceName ? ', ' + this.provinceName : '') +
      (this.country ? ', ' + this.country : '') +
      (this.postalCode ? ' ' + this.postalCode : '');
  }

  resetValue(): void {
    this.addressLine1 = null;
    this.addressLine2 = null;
    this.city = null;
    this.provinceCode = null;
    this.provinceName = null;
    this.country = 'CANADA';
    this.postalCode = null;
  }

  get isEmpty(): boolean {
    return !this.addressLine1
      && !this.addressLine2
      && !this.city
      // && !this.provinceCode
      // && !this.provinceName
      && !this.postalCode;
  }

  addressHasNoManuallyEnteredData(strictMode ?: boolean): boolean {
    let noManuallyEnteredData: boolean = true;
    noManuallyEnteredData = !this.addressLine1 && !this.addressLine2 && !this.city && !this.postalCode;
    if (strictMode) {
      //the initial address has provinceName set to Ontario (case sensitive), but province code set to ON
      //if user manually remove provinceName field value, then provinceCode is null and provinceName is ''
      noManuallyEnteredData = noManuallyEnteredData && ((!this.provinceCode && !this.provinceName) || (this.provinceCode == 'ON' && this.provinceName == 'Ontario'));
      //country is default to CANADA
      noManuallyEnteredData = noManuallyEnteredData && (this.country && this.country.toUpperCase() == 'CANADA');
    }
    return noManuallyEnteredData;
  }

  setAddressHash(): void {
    this.addressHash = new LowerCasePipe().transform(this.addressTypeCode + ':' + this.getAddressHashString().replace(/[^A-Za-z0-9!?]/g, ''));
  }

  get unitNo(): string {
    if (!this.parsedAddressLine) {
      this.parsedAddressLine = AddressUtil.extractParsedAddress(this.addressLine1, ' ', this.addressLine2, this.provinceCode);
    }
    return this.parsedAddressLine && this.parsedAddressLine[ 'unitNumber' ];
  }

  get streetNumber(): string {
    if (!this.parsedAddressLine) {
      this.parsedAddressLine = AddressUtil.extractParsedAddress(this.addressLine1, ' ', this.addressLine2, this.provinceCode);
    }
    return this.parsedAddressLine && this.parsedAddressLine[ 'streetNumber' ];
  }

  get streetName(): string {
    if (!this.parsedAddressLine) {
      this.parsedAddressLine = AddressUtil.extractParsedAddress(this.addressLine1, ' ', this.addressLine2, this.provinceCode);
    }
    return this.parsedAddressLine && this.parsedAddressLine[ 'streetName' ];
  }

  shouldKeyBeChecked(key): boolean {
    // We only check if it is a normal data property
    return this.isNonBlackedKey(key, propertyIgnoreList) && super.shouldKeyBeChecked(key);
  }

  mapEMPAddress(address: EMPAddress): void {
    //We do not need show civicNumber only show apartment
    if (address.addressLine1 && address.addressLine1.length > 50) {
      // this.addressLine1 = address.addressLine1.replace(/^(.{49}[^\s]*).*/, "$1");
      // this.addressLine2 = address.addressLine1.substr(this.addressLine1.length,address.addressLine1.length).trim();
      // this.addressLine2 = this.addressLine2 + ' '+(address.apartment ?  address.apartment : '');
      let addressLine1 = address.addressLine1.substr(0, 48);
      if (addressLine1.indexOf(',') > -1 || addressLine1.indexOf(' ') > -1) {
        let lastIndex = addressLine1.lastIndexOf(',');
        if (lastIndex < 0) {
          lastIndex = addressLine1.lastIndexOf(' ');
        }
        if (lastIndex > -1) {
          this.addressLine1 = address.addressLine1.substr(0, lastIndex);
          this.addressLine2 = address.addressLine1.substr(lastIndex + 1, address.addressLine1.length);
        } else {
          this.addressLine1 = addressLine1;
          this.addressLine2 = address.addressLine1.substr(48, address.addressLine1.length);
        }

      } else {
        this.addressLine1 = addressLine1;
        this.addressLine2 = address.addressLine1.substr(48, address.addressLine1.length);
      }

    } else {
      this.addressLine1 = address.addressLine1;
      this.addressLine2 = (address.apartment ? address.apartment : '');
    }

    this.city = address.city;
    if (address.province) {
      if (address.province.length == 2) {
        this.provinceCode = address.province;
        let province = dropDowns.provinceCodeValidator.find(item => item.value == address.province.toUpperCase());
        if (province) {
          this.provinceName = province.label;
        }
      } else if (address.province == 'Other') {
        this.provinceName = address.provinceOther;
      } else {
        let province = dropDowns.provinceCodeValidator.find(item => item.label.toUpperCase() == address.province.toUpperCase());
        if (province) {
          this.provinceName = province.label;
          this.provinceCode = province.value;
        }
        this.provinceName = address.province;
      }

    }
    if (address.country && address.country == 'Other') {
      this.country = address.countryOther;
    } else {
      this.country = address.country;
    }
    this.postalCode = address.postalCode;
  }

  get isProvinceValid(): boolean {
    return this.provinceName && dropDowns.provinceCodeValidator.some(item => item.label && item.label.toUpperCase() == this.provinceName.trim().toUpperCase());
  }

  /**
   * Returns the same Address object with normalized address lines, other address data are kept same.
   * The normalization ensures both addressLine1 and addressLine2 are within given length limit by truncating at the specified length.
   * (as per clarification by Anne on July 30)
   *
   * @param address
   * @param addrLineWidth default is ADDRESS_LINE_DEFAULT_MAX_LENGTH
   *
   */
  normalizeAddressLines(addrLineWidth = Address.ADDRESS_LINE_DEFAULT_MAX_LENGTH): Address {
    let l1 = (this.addressLine1 ? ('' + this.addressLine1) : '');
    let l2 = (this.addressLine2 ? ('' + this.addressLine2) : '');
    this.addressLine1 = l1.substr(0, addrLineWidth);
    this.addressLine2 = l2.substr(0, addrLineWidth);
    return this;
  }

  isStreetContinueCityPostalCodeEmpty(): boolean {
    return !this.addressLine1 && !this.addressLine2 && !this.city && !this.postalCode;
  }

  changeToMixedCase(): void {
    this.addressLine1 = Utils.toMixedCase(this.addressLine1);
    this.addressLine2 = Utils.toMixedCase(this.addressLine2);
    this.city = Utils.toMixedCase(this.city);
    this.provinceName = Utils.toMixedCase(this.provinceName);
    this.setAddressHash();
  }

  copyAddress(): Address {
    let targetAddress: Address = new Address(this);
    targetAddress.id = UUIDUtil.getUUID();
    targetAddress.identifier = UUIDUtil.getUUID();
    targetAddress.setAddressHash();
    return targetAddress;
  }

  isSameAsPreClosingAddress(): boolean {
    return this.sameAsAddressTypeCode == 'PRE_CLOSING_ADDRESS';
  }

  isSameAsSubjectProperty(): boolean {
    return this.sameAsAddressTypeCode == 'SUBJECT';
  }

  isOtherPreviousAddress(): boolean {
    return this.addressTypeCode == AddressTypes.otherPreviousAddress;
  }

  static convertAddressToDisplayAddress(address: Address): string {
    let addressToDisplay: string = '';
    if (address.addressLine1) {
      addressToDisplay = address.addressLine1;
    }

    if (address.addressLine2) {
      if (addressToDisplay) {
        addressToDisplay = `${ addressToDisplay }, ${ address.addressLine2 }`;
      } else {
        addressToDisplay = address.addressLine2;
      }
    }
    if (address.city) {
      if (addressToDisplay) {
        addressToDisplay = `${ addressToDisplay }, ${ address.city }`;
      } else {
        addressToDisplay = address.city;
      }
    }
    if (address.provinceName) {
      if (addressToDisplay) {
        addressToDisplay = `${ addressToDisplay }, ${ address.provinceName }`;
      } else {
        addressToDisplay = address.provinceName;
      }
    }
    return addressToDisplay;
  }
}
