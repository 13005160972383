import {Component, Inject, OnInit} from '@angular/core';
import {Matter} from '../../shared/matter';
import {Subject} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {Contact} from '../../shared/contact';
import {ContactService} from '../../../shared-main/contact.service';
import {dropDowns} from '../../shared/matter-drop-downs';
import {Utils} from '../../shared/utils';
import {PurchaserService} from '../../purchaser/purchaser.service';
import {CustomPickListService} from '../../../shared/modal/custom-pick-list.service';
import {ContactQueryService} from '../../../contact/contact-query.service';
import {Logger} from '@nsalaun/ng-logger';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {ReferredByInfo, ReferredByType} from '../../shared/referred-by-info';
import {ReferredByWrapper} from '../../shared/referred-by-wrapper';
import {ReferredByTypeValues} from '../../../shared-main/constants';
import * as _ from 'lodash';
import {LockScreenService} from '../../../core/lock-screen.service';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ModalComponent} from '../../../shared/dialog/modal-dialog.service';

export class ReferralModalContext {
  public matter: Matter;
}

@Component({
  selector: 'dp-referred-by-modal',
  templateUrl: 'referred-by-modal.component.html',
  providers: [PurchaserService]
})
export class ReferredByModalComponent extends ModalComponent<ReferralModalContext> implements OnInit {
  referredBy: SelectItem[] = [];
  utils;
  isDirty: boolean = false;
  referredByWrapperList: ReferredByWrapper[] = [];
  manualReferral: ReferredByWrapper;

  constructor(
    public dialog: MatDialogRef<ReferredByModalComponent>,
    public dialogService: DialogService,
    public contactService: ContactService,
    public purchaserService: PurchaserService,
    public customPickListService: CustomPickListService,
    public contactQueryService: ContactQueryService,
    public logger: Logger,
    public lockScreenService: LockScreenService,
    @Inject(MAT_DIALOG_DATA) context?: ReferralModalContext
  ) {
    super(dialog, context);
    this.utils = new Utils();
  }

  ngOnInit(): void {
    this.isDirty = false;
    this.initManualReferralWrapper();
    let referredByWrapper;
    if (!Array.isArray(this.matter.referredByList) || this.matter.referredByList.length == 0) {
      this.referredByWrapperList = [];
      referredByWrapper = new ReferredByWrapper();
      referredByWrapper.selectedReferredByInfo.referredByType = ReferredByTypeValues.MANUAL_ENTRY;
      referredByWrapper.refferedByDataOptions = this.manualReferral ? this.manualReferral.refferedByDataOptions : [];
      this.initReferredByModalWrapper(referredByWrapper);
      this.referredByWrapperList.push(referredByWrapper);
      this.enableSave();
    } else {
      this.matter.referredByList.forEach((referredByInfo: ReferredByInfo) => {
        referredByWrapper = new ReferredByWrapper();
        referredByWrapper.selectedReferredByInfo = new ReferredByInfo(referredByInfo);
        referredByWrapper.selectedReferredBy = referredByInfo.referredBy;
        if (referredByWrapper.isReferredByManual()) {
          referredByWrapper.refferedByDataOptions = this.manualReferral
            ? this.manualReferral.refferedByDataOptions
            : [];
        }
        this.initReferredByModalWrapper(referredByWrapper);
        this.referredByWrapperList.push(referredByWrapper);
      });
    }
    this.referredBy = this.getReferdByOptions();
  }

  enableSave() {
    this.isDirty = true;
  }

  async initManualReferralWrapper(): Promise<void> {
    this.manualReferral = new ReferredByWrapper();
    this.manualReferral.selectedReferredByInfo.referredByType = ReferredByTypeValues.MANUAL_ENTRY as ReferredByType;
    this.manualReferral.pageNo = 1;
    this.manualReferral.refferedByDataOptions = [];
    this.manualReferral.ignoreEditReferredByListItem = false;
    try {
      this.lockScreenService.lockForUpdate = true;
      await this.customPickListService.getAllReferralsForReferredByWrapper(this.manualReferral);
    } finally {
      this.lockScreenService.lockForUpdate = false;
    }
    if (
      Array.isArray(this.manualReferral.refferedByDataOptions) &&
      this.manualReferral.refferedByDataOptions.length > 0
    ) {
      let tmp = this.manualReferral.refferedByDataOptions.shift();
      this.manualReferral.refferedByDataOptions = _.sortBy(this.manualReferral.refferedByDataOptions, [
        'customPickListItemSequence'
      ]);
      this.manualReferral.refferedByDataOptions.unshift(tmp);
    }
  }

  initReferredByModalWrapper(referredByWrapper: ReferredByWrapper): void {
    if (referredByWrapper) {
      referredByWrapper.initReferredByModalWrapper(
        this.contactService,
        this.purchaserService,
        this.customPickListService
      );
      if (referredByWrapper.getShowRefferedByAttention()) {
        this.getAttentionPickList(referredByWrapper, this.contactQueryService);
      }
    }
  }

  get matter(): Matter {
    return this.context.matter;
  }

  refreshSubject(subject: Subject<string>): Subject<string> {
    return Utils.refreshSubject(subject);
  }

  dataSelectedReferredByTimeout(referredByWrapper: ReferredByWrapper): void {
    if (referredByWrapper) {
      setTimeout(() => {
        referredByWrapper.dataSelectedReferredBy(
          this.dialogService,
          this.contactQueryService,
          this.getAttentionPickList,
          this.manualReferral
        );
      }, 10);
    }
  }

  // getAttentionDropdownArrowShow(referredByWrapper : ReferredByWrapper): boolean {
  //     return referredByWrapper.attentionList && referredByWrapper.attentionList.length > 0;
  // }

  handleDropdownClickAttention = (event) => {
    //use arrow function syntax to preserve 'this' binding
    if (event && event.referredByWrapper) {
      event.referredByWrapper.attentionList = event.referredByWrapper.cachedAttentionList.map((item) => item);
    }
  };

  handleDropdownClickAttentionNew(referredByWrapper: ReferredByWrapper) {
    if (referredByWrapper) {
      referredByWrapper.attentionList = referredByWrapper.cachedAttentionList.map((item) => item);
    }
  }

  getContactNameForAttention(contact: Contact): string {
    return contact.subContactDisplayName;
  }

  onAttentionSelect(event: Contact, referredByWrapper: ReferredByWrapper): void {
    this.enableSave();
    if (event) {
      setTimeout(() => {
        // Fix the isuuse which it shows [object Object]
        referredByWrapper.referredByAttention = event.subContactDisplayName;
      }, 1);
    }
  }

  getAttentionPickList(referredByWrapper: ReferredByWrapper, contactQueryService: ContactQueryService): void {
    if (referredByWrapper && referredByWrapper.referredBySourceContactId) {
      contactQueryService.getContactForMatter(referredByWrapper.referredBySourceContactId).subscribe(
        (sourceContact: Contact) => {
          if (sourceContact.privateContacts) {
            // this.attentionList = sourceContact.privateContacts && sourceContact.privateContacts.persons;
            referredByWrapper.cachedAttentionList =
              sourceContact.privateContacts && sourceContact.privateContacts.persons;
            referredByWrapper.attentionList = referredByWrapper.cachedAttentionList;
          }
        },
        (err) => this.logger.warn(err)
      );
    }
  }

  addNewReferredBy() {
    let referredByWrapper = new ReferredByWrapper();
    referredByWrapper.selectedReferredByInfo.referredByType = <ReferredByType>ReferredByTypeValues.MANUAL_ENTRY;
    this.initReferredByModalWrapper(referredByWrapper);
    this.referredByWrapperList.push(referredByWrapper);
    this.enableSave();
  }

  removeReferredBy(referredByWrapper: ReferredByWrapper) {
    let index: number = this.referredByWrapperList.findIndex((item) => item == referredByWrapper);
    if (index > -1) {
      this.referredByWrapperList.splice(index, 1);
      if (index == 0) {
        //empty the first line
        let referredByWrapper = new ReferredByWrapper();
        this.initReferredByModalWrapper(referredByWrapper);
        referredByWrapper.selectedReferredByInfo.referredByType = <ReferredByType>ReferredByTypeValues.MANUAL_ENTRY;
        this.referredByWrapperList.unshift(referredByWrapper);
      }
      this.enableSave();
    }
  }

  isTrashVisible(index: number): boolean {
    return (
      index != 0 ||
      this.referredByWrapperList[0].referredByType != ReferredByTypeValues.MANUAL_ENTRY ||
      !!this.referredByWrapperList[0].referredBy ||
      !!this.referredByWrapperList[0].referredByAttention
    );
  }

  close() {
    this.dialog.close();
  }

  ok() {
    let referredByList: ReferredByInfo[] = [];
    this.referredByWrapperList.forEach((referredByWrapper: ReferredByWrapper) => {
      referredByWrapper.selectedReferredByInfo.referredBy = referredByWrapper.selectedReferredBy;
      referredByList.push(new ReferredByInfo(referredByWrapper.selectedReferredByInfo));
    });
    this.dialog.close({
      action: 'OK',
      referredByList: referredByList,
      isDirty: this.isDirty || this.referredByWrapperList.some((item) => item.isDirty)
    });
  }

  private getReferdByOptions() {
    return this.matter.isMatterProvinceBC ? dropDowns.ReferredByForBC : dropDowns.ReferredBy;
  }
}
