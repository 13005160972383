import {Matter} from './matter';
import {MatterListFilter} from '../matter-list/matter-list-filter';
import {PROJECT_SALE_MATTER_OPTION_VALUE} from '../../shared-main/constants';

export class MatterListState {

  isNew: boolean = true;
  rows: Matter[] = [];
  selectedStatus: string = 'ACTIVE';
  selectedMatters: Matter[] = [];
  searchText: string;
  lastSearchText: string;
  sortQuery: string = 'lastUpdatedTimeStamp';
  sortType: string = 'DESC';
  selectedMatterTypes: string[] = [];
  selectedProvinceCode: string[] = [];
  matterTypeRowData: any[] = [];
  lawyersList: any[] = [];
  clerksList: any[] = [];
  projectList: any[] = [];
  selectedLawyers: string[] = [];
  selectedClerks: string[] = [];
  selectedProjects: string[] = [];
  pageNo: number = 1;
  listEndReached: boolean = false;
  scrollY: number;
  currentSearchView: MatterListFilter;
  radioStatus: string = 'ACTIVE';
  showFilters: boolean = false;
  selectedMatterStatuses: string[] = [ 'ACTIVE' ];
  selectedActionRequiredFilters: string[] = [];
  taskStatusFilter: string;
  taskDescription: string;
  closingDateFrom: string;
  closingDateTo: string;
  closingDayFilter: number;

  excludeDraft: boolean;

  hasAccessToProjectSaleMatter: boolean; //read Or Full : true
  hasAccessToConveyancingMatter: boolean; //read Or Full : true

  get startDate(): Date {
    let startDate: Date = null;
    if (this.closingDateFrom) {
      startDate = new Date(this.closingDateFrom);
    }
    return startDate;
  }

  get endDate(): Date {
    let endDate: Date = null;
    if (this.closingDateTo) {
      endDate = new Date(this.closingDateTo);
    }
    return endDate;
  }

  get isProjectSaleMatterTypeFilterSelected(): boolean {
    return this.selectedMatterTypes && this.selectedMatterTypes.some(item => item == PROJECT_SALE_MATTER_OPTION_VALUE);
  }

  get isSaleMatterTypeFilterSelected(): boolean {
    return this.selectedMatterTypes && this.selectedMatterTypes.some(item => item == 'SALE');
  }

  sortSelectedMatterListAsRowsDisplay(): void {
    this.selectedMatters.sort((a, b) => {
      if (this.rows.indexOf(a) < this.rows.indexOf(b)) {
        return -1;
      }
      if (this.rows.indexOf(a) > this.rows.indexOf(b)) {
        return 1;
      }
      return 0;
    });
  }

  copyOfMatterListStateObject(old: MatterListState) {
    this.isNew = old.isNew;
    this.selectedStatus = old.selectedStatus;
    this.searchText = old.searchText;
    this.lastSearchText = old.lastSearchText;
    this.sortQuery = old.sortQuery;
    this.sortType = old.sortType;
    this.selectedMatterTypes = old.selectedMatterTypes;
    this.selectedProvinceCode = old.selectedProvinceCode;
    this.matterTypeRowData = old.matterTypeRowData;
    this.lawyersList = old.lawyersList;
    this.clerksList = old.clerksList;
    this.projectList = old.projectList;
    this.selectedLawyers = old.selectedLawyers;
    this.selectedClerks = old.selectedClerks;
    this.selectedProjects = old.selectedProjects;
    this.pageNo = old.pageNo;
    this.listEndReached = old.listEndReached;
    this.scrollY = old.scrollY;
    this.currentSearchView = new MatterListFilter(old.currentSearchView);
    this.radioStatus = old.radioStatus;
    this.showFilters = old.showFilters;
    this.selectedMatterStatuses = old.selectedMatterStatuses;
    this.selectedActionRequiredFilters = old.selectedActionRequiredFilters;
    this.taskStatusFilter = old.taskStatusFilter;
    this.taskDescription = old.taskDescription;
    this.closingDateFrom = old.closingDateFrom;
    this.closingDateTo = old.closingDateTo;
    this.closingDayFilter = old.closingDayFilter;
    this.excludeDraft = old.excludeDraft;
    this.hasAccessToProjectSaleMatter = old.hasAccessToProjectSaleMatter;
    this.hasAccessToConveyancingMatter = old.hasAccessToConveyancingMatter;
  }
}
