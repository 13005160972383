<!-- modal -->
<div class="container-fluid custom-modal-container">
    <form class="form-horizontal">
        <div #ReqEditModal>
        <div class="row modal-header">
            <div class=" col-lg-12">
                <div class="col-lg-10"><h1>Requisition Editor</h1></div>
                <button type="button" class="btn-close pull-right" (click)="close()" >&times;</button>
            </div>
        </div>
        <div class="modal-body">
            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label col-lg-2">Require: undertaking to</label>
                    <div class="col-lg-10">
                        <input class="form-control" id="require"
                               name="require"
                               maxlength="100"
                               [(ngModel)]="context.selectedTemplate.require" [readonly]="!fieldsEditable" (keydown)="enterForOK($event)">
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label col-lg-2">Type of encumbrance</label>
                    <div class="col-lg-10">
                        <input class="form-control" id="encumbrance"
                               name="encumbrance"
                               maxlength="100"
                               [(ngModel)]="context.selectedTemplate.encumbranceType" [readonly]="!fieldsEditable" (keydown)="enterForOK($event)">
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label col-lg-2">Registration number</label>
                    <div class="col-lg-10">
                        <input class="form-control" id="registration-number"
                               name="registration-number"
                               maxlength="50"
                               [(ngModel)]="context.selectedTemplate.registrationNumber" [readonly]="!fieldsEditable || !isInstrumentNumberEditable()" (keydown)="enterForOK($event)">
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="form-group">
                    <label class="control-label col-lg-2">in favour of</label>
                    <div class="col-lg-10">
                        <input class="form-control" id="in-favour-of"
                               name="in-favour-of"
                               [(ngModel)]="context.selectedTemplate.inFavourOf" [readonly]="!fieldsEditable" (keydown)="enterForOK($event)">
                    </div>
                </div>
            </div>

            <div class="row padding-top-20">
                <div class="col-lg-12 hide-tool-bar">
                    <textarea name="rteditorModal" id="rteditorModal" rows="10" cols="80"></textarea>
                </div>
            </div>

            <ng-container *ngIf="showNext">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label style="text-align: left" class="control-label col-lg-12">{{prompt}}</label>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <div class="col-lg-12">
                                <input class="form-control focus-first" id="stopCodeValue"
                                       name="stopCodeValue" #stopCodeValueControl (keydown)="enterForNext($event)"
                                       (keydown.f9)="handleF9StopCode()"
                                       statusBarHelp
                                       [dynamicHelpCallBackFunction] = "generateF9StopCode()"
                                       [maxlength]="matchedMaxLength"
                                       [(ngModel)]="stopCodeValue" autofocus>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="col-lg-8 margin-top-10">
                <div class="form-group">
                    <label class="control-label col-lg-3">Requisition Status: </label>
                    <div class="col-lg-2">
                        <select class="form-control" id="requisitionStatus" name="requisitionStatus"  #optionControl
                                [(ngModel)]="context.selectedTemplate.requisitionStatus">
                            <option *ngFor="let requisitionStatusOption of requisitionStatusOptions" [value]="requisitionStatusOption.value">{{requisitionStatusOption.label}}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 margin-top-10"  *ngIf="isCategoryVisible()">
                <div class="form-group margin-left-170" >
                    <label class="control-label col-lg-3 col-lg-3-75" >Group ID: </label>
                    <div class="col-lg-2" >
                        <select class="form-control col-lg-3-75"  id="requisitionCategory" name="requisitionCategory"
                                [(ngModel)]="context.selectedTemplate.requisitionCategory"   (change)="onRequisitionCategoryChange(context.selectedTemplate)" >
                            <option *ngFor="let requisitionCategoryOption of requisitionCategoryOptions" [value]="requisitionCategoryOption.value">{{requisitionCategoryOption.label}}</option>
                        </select>
                    </div>
                </div>
            </div>

        </div>

        <div class="modal-footer">
            <div class="col-lg-12 buttons">
                <!--Replace dp-btn class with dp-btn-green to avoid disabling the btn when clicked -->
                <button type="button" class="dp-btn-green margin-right-10" *ngIf="showNext" (click)="onNext()">
                    <span>Next</span>
                </button>
                <button type="button" class="dp-btn keyboard-save-action" *ngIf="!showNext" [disabled]="disableOK" (click)="onOK()" #okButtonControl>
                    <span>OK</span>
                </button>
                <button type="button" class="secondary-button" *ngIf="showDelete" (click)="onDelete()">
                    <span>Delete</span>
                </button>
                <button type="button" class="dp-btn dp-btn-cancel" (click)="close()">
                    <span>Cancel</span>
                </button>
            </div>
        </div>

        <div *ngIf="isStopCodeF9Visible()">
            <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
        </div>

        </div>
    </form>
</div>
