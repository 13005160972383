import moment from 'moment';
import {BaseEntity} from '../../../../shared/BaseEntity/base-entity';
import Utils from '../../../../shared-main/utils';
import {StatementAdjustmentAmountTypes} from '../../statement-adjustment-constants';
import {StatementAdjustment} from '../../statement-adjustment';
import Moment = moment.Moment;

export const COMMON_EXPENSES = 'COMMON EXPENSES';
export const CONDOMINIUM_FEES = 'CONDOMINIUM FEES';
export const STRATA_FEES = 'STRATA FEES';
export type SoAdjExpenseOccupancyPeriodPaidType = 'YES' | 'NO';
export const TotalEffectiveTypes = {NO_START_DATE: 'NO_START_DATE', ENTER_OWN_DATE: 'ENTER_OWN_DATE', NO_END_DATE: 'NO_END_DATE'};

export class SoAdjExpenseOccupancyPeriod extends BaseEntity {
  id: number;
  overrideGrossAnnualExpense: boolean;
  totalExpense: number;
  share: number;
  effectiveFromType: string;
  totalEffectiveFrom: string;
  effectiveTillType: string;
  totalEffectiveTill: string;
  useAmountFromPropertyTab: boolean;
  chargePurchaser: boolean;
  expensesAmount: number;
  adjustmentHeading: string;
  adjustedFor: string;
  isExpenseOccupancyAdjusted: SoAdjExpenseOccupancyPeriodPaidType;
  adjustPercentage: number;

  constructor(soAdjExpenseOccupancyPeriod?: SoAdjExpenseOccupancyPeriod) {
    super(soAdjExpenseOccupancyPeriod);
    if (!soAdjExpenseOccupancyPeriod) {
      this.overrideGrossAnnualExpense = false;
      this.effectiveFromType = TotalEffectiveTypes.NO_START_DATE;
      this.effectiveTillType = TotalEffectiveTypes.NO_END_DATE;
      this.chargePurchaser = true;
    }
  }

  getUnitLevelPlanCondominiumTotalShareExpenses(condominiumTotalSharePercentage: Number): Number {
    // const unitLevelPlanPercentageShareOfTotalRealtyTaxes: Number = matter.getUnitLevelPlanCondominiumTotalSharePercentage();
    //After confirm with BA, if condominiumTotalSharePercentage is null, it will be as 0
    const shareNumber: Number = condominiumTotalSharePercentage != null
      ? Number(Math.round(this.totalExpense * condominiumTotalSharePercentage.valueOf()) / 100)
      : Number(0);
    this.share = shareNumber && shareNumber.valueOf();
    return shareNumber;
  }

  getMonthlyCommonExpensesAsNumber(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number): Number {
    return this.overrideGrossAnnualExpense
      ? this.getUnitLevelPlanCondominiumTotalShareExpenses(condominiumTotalSharePercentage)
      : (this.useAmountFromPropertyTabConvertToBoolean
        ? condominiumTotalExpenses
        : Number(this.expensesAmount));
  }

  getMonthlyCommonExpenses(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number): string {
    const monthlyCommonExpenses: Number = this.getMonthlyCommonExpensesAsNumber(condominiumTotalExpenses, condominiumTotalSharePercentage);
    return monthlyCommonExpenses != null
      ? Utils.formattedCurrencyValue(monthlyCommonExpenses.valueOf())
      : '';
  }

  getSoAdjExpenseOccupancyPeriodTaxAsNumber(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number): Number {
    let expenseOccupancyPeriodTax: Number = null;
    if (this.isExpenseOccupancyAdjusted === 'YES') {
      const monthlyCommonExpenses: Number = this.getMonthlyCommonExpensesAsNumber(condominiumTotalExpenses, condominiumTotalSharePercentage);
      if (monthlyCommonExpenses != null) {
        expenseOccupancyPeriodTax = Number(monthlyCommonExpenses.valueOf() * Number(this.adjustPercentage).valueOf() / 100);
      }
    }

    return expenseOccupancyPeriodTax;
  }

  getSoAdjExpenseOccupancyPeriodTax(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number): string {
    const expenseOccupancyPeriodTax: Number = this.getSoAdjExpenseOccupancyPeriodTaxAsNumber(condominiumTotalExpenses, condominiumTotalSharePercentage);
    return expenseOccupancyPeriodTax != null
      ? Utils.formattedCurrencyValue(expenseOccupancyPeriodTax.valueOf())
      : '';
  }

  getStartDate(soAdjExpenseOccupancyPeriodOccupancyDate: string): string {
    // In project sale matter, occupancyDate is the requisition date.
    const occupancyDate: Moment = moment(soAdjExpenseOccupancyPeriodOccupancyDate, 'YYYY/MM/DD');
    let startDate: Moment = occupancyDate;
    if (this.overrideGrossAnnualExpense && this.effectiveFromType === TotalEffectiveTypes.ENTER_OWN_DATE) {
      const enterOwnDate = moment(this.totalEffectiveFrom, 'YYYY/MM/DD');
      if (enterOwnDate.isValid() && (!startDate.isValid() || enterOwnDate > startDate)) {
        startDate = enterOwnDate;
      }
    }

    return startDate.isValid() ? startDate.format('YYYY/MM/DD') : null;
  }

  getEndDate(matterClosingDate: string): string {
    // TODO: confirm with BA: B7 (Final Calculated as at Date) or B8 (Closing Date)
    // const closingDate: number = moment(matter.matterCloseDate, "YYYY/MM/DD").date();
    const closingDate: Moment = moment(matterClosingDate, 'YYYY/MM/DD');

    let endDate: Moment = closingDate;
    if (endDate.isValid()) {

      if (this.overrideGrossAnnualExpense && this.effectiveTillType === TotalEffectiveTypes.ENTER_OWN_DATE) {
        const enterOwnDate: Moment = moment(this.totalEffectiveTill, 'YYYY/MM/DD');
        if (enterOwnDate.isValid() && enterOwnDate < endDate) {
          endDate = enterOwnDate;
        }
      }

      return endDate.format('YYYY/MM/DD');
    } else {
      return '';
    }
  }

  getAmountToBeProrated(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number): Number {
    const expenseOccupancyPeriodTax: Number = this.getSoAdjExpenseOccupancyPeriodTaxAsNumber(condominiumTotalExpenses, condominiumTotalSharePercentage);
    const tax: Number = expenseOccupancyPeriodTax != null
      ? Number(Math.round(expenseOccupancyPeriodTax.valueOf() * 100) / 100)
      : Number(0);
    const monthlyCommonExpenses: Number = this.getMonthlyCommonExpensesAsNumber(condominiumTotalExpenses, condominiumTotalSharePercentage);
    return monthlyCommonExpenses != null
      ? monthlyCommonExpenses.valueOf() + tax.valueOf()
      : null;
  }

  getMonths(matterClosingDate: string, soAdjExpenseOccupancyPeriodOccupancyDate: string): Number {
    let months: number = null;
    const closingDate: Moment = moment(matterClosingDate, 'YYYY/MM/DD');
    if (closingDate.isValid()) {
      let tempEndDate: Moment = moment(closingDate.endOf('month').format('YYYY/MM/DD'), 'YYYY/MM/DD');
      const effectiveTill = moment(this.totalEffectiveTill, 'YYYY/MM/DD');

      if (this.overrideGrossAnnualExpense
        && this.effectiveTillType === TotalEffectiveTypes.ENTER_OWN_DATE
        && effectiveTill.isValid() && effectiveTill < closingDate) {
        tempEndDate = effectiveTill;
      } else if (!this.chargePurchaserConvertToBoolean) {
        tempEndDate.subtract(1, 'months');
      }

      let tempStartDate: Moment = moment(this.getStartDate(soAdjExpenseOccupancyPeriodOccupancyDate), 'YYYY/MM/DD');

      if (tempStartDate.isValid()) {
        const start: Moment = moment(tempStartDate.endOf('month').add(1, 'days').format('YYYY/MM/DD'), 'YYYY/MM/DD');
        const end: Moment = moment(tempEndDate.add(1, 'days').format('YYYY/MM/DD'), 'YYYY/MM/DD');
        if (start > end) {
          months = null;
        } else {
          months = end.diff(start, 'months');
          if (months < 0) {
            months = null;
          }
        }

        console.log('start:', start.format('YYYY/MM/DD'), ', end:', end.format('YYYY/MM/DD'));
      }
    }
    return months === null ? null : Number(months);
  }

  isOutOfRange(matterClosingDate: string, soAdjExpenseOccupancyPeriodOccupancyDate: string): boolean {
    return this.getMonths(matterClosingDate, soAdjExpenseOccupancyPeriodOccupancyDate) === null;
  }

  getValueForMonths(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number, matterClosingDate: string, soAdjExpenseOccupancyPeriodOccupancyDate: string): Number {
    let valueForMonths: Number = null;
    const amountToBeProrated: Number = this.getAmountToBeProrated(condominiumTotalExpenses, condominiumTotalSharePercentage);
    if (amountToBeProrated != null) {
      const months: Number = this.getMonths(matterClosingDate, soAdjExpenseOccupancyPeriodOccupancyDate);
      valueForMonths = months != null ? Number(amountToBeProrated.valueOf() * months.valueOf()) : null;
    }
    return valueForMonths;
  }

  getValueForDays(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number, soAdjExpenseOccupancyPeriodOccupancyDate: string): Number {
    let valueForDays: Number = null;
    const amountToBeProrated: Number = this.getAmountToBeProrated(condominiumTotalExpenses, condominiumTotalSharePercentage);
    if (amountToBeProrated != null) {
      let startDate: Moment = moment(this.getStartDate(soAdjExpenseOccupancyPeriodOccupancyDate), 'YYYY/MM/DD');
      if (startDate.isValid()) {
        // endOf('month') doesn't get back correct value(the last day ot the month). Only after using format, it gives correct value
        let elapsedDays = moment(startDate, 'YYYY/MM/DD').date();
        let lastDayOfTheMonthFormat = startDate.endOf('month').format('YYYY/MM/DD');
        let lastDayOfTheMonth = moment(lastDayOfTheMonthFormat, 'YYYY/MM/DD');

        let daysInMonth: number = startDate.daysInMonth();

        const dailyRate: number = amountToBeProrated.valueOf() / daysInMonth;
        const days: number = daysInMonth - elapsedDays + 1;
        valueForDays = Number(Math.round(dailyRate * days * 100) / 100);
      }
    }

    return valueForDays;
  }

  getSoAdjExpenseOccupancyPeriodTotalAsNumber(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number, closingDate: string, soAdjExpenseOccupancyPeriodOccupancyDate: string): number {
    const valueForMonths: Number = this.getValueForMonths(condominiumTotalExpenses, condominiumTotalSharePercentage, closingDate, soAdjExpenseOccupancyPeriodOccupancyDate);
    const valueForDays: Number = this.getValueForDays(condominiumTotalExpenses, condominiumTotalSharePercentage, soAdjExpenseOccupancyPeriodOccupancyDate);

    return (valueForMonths != null && valueForDays != null)
      ? valueForMonths.valueOf() + valueForDays.valueOf()
      : null;
  }

  getSoAdjExpenseOccupancyPeriodTotal(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number, closingDate: string, soAdjExpenseOccupancyPeriodOccupancyDate: string): string {
    const expenseOccupancyPeriodTotal: number = this.getSoAdjExpenseOccupancyPeriodTotalAsNumber(condominiumTotalExpenses, condominiumTotalSharePercentage, closingDate, soAdjExpenseOccupancyPeriodOccupancyDate);
    return expenseOccupancyPeriodTotal != null
      ? Utils.formattedCurrencyValue(expenseOccupancyPeriodTotal)
      : '$???????????.??';
  }

  getTotalExpensePeriod(soAdjExpenseOccupancyPeriodOccupancyDate: string, matterClosingDate: string): string {
    const startDate: Moment = moment(this.getStartDate(soAdjExpenseOccupancyPeriodOccupancyDate), 'YYYY/MM/DD');

    const from: string = startDate.isValid()
      ? (!this.overrideGrossAnnualExpense || this.effectiveFromType === TotalEffectiveTypes.NO_START_DATE
        ? 'occupancy'
        : startDate.format('MMMM DD, YYYY'))
      : '???????????';

    const closingDate: Moment = moment(matterClosingDate, 'YYYY/MM/DD');
    let endDate: Moment = moment(this.getEndDate(matterClosingDate), 'YYYY/MM/DD');
    const enterOwnDate: Moment = moment(this.totalEffectiveTill, 'YYYY/MM/DD');
    let to: string = '???????????';
    if (closingDate.isValid() && endDate.isValid()) {
      if (this.overrideGrossAnnualExpense && this.effectiveTillType === TotalEffectiveTypes.ENTER_OWN_DATE
        && enterOwnDate.isValid() && enterOwnDate < closingDate) {
        to = enterOwnDate.format('MMMM DD, YYYY');
      } else {
        if (!this.chargePurchaserConvertToBoolean) {
          endDate = endDate.subtract(1, 'months');
        }
        to = endDate.endOf('month').format('MMMM DD, YYYY');
      }
    }

    return 'from ' + from + ' to ' + to;
  }

  // Total Common Expenses(matter.matterPropertyWithCondo.condominiumTotalExpenses)
  // Total % Share(matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes)
  // Occupancy Date(matter.soAdjExpenseOccupancyPeriodOccupancyDate)
  // Final Calculated as at Date(matter.adjustAsAtClosingDate)
  // Closing Date()
  //If the above 5 outsiade fields update, only Total Common Expenses and Total % Share will be updated
  updateSoAdjExpenseOccupancyPeriod(condominiumTotalExpenses: Number, condominiumTotalSharePercentage: Number, closingDate: string, soAdjExpenseOccupancyPeriodOccupancyDate: string, statementAdjustment?: StatementAdjustment) {
    if (this.overrideGrossAnnualExpense) {
      //Update "share" when Total Common Expenses(matter.matterPropertyWithCondo.condominiumTotalExpenses) or
      // Total % Share(matter.matterPropertyWithCondo.percentageShareOfTotalRealtyTaxes) with overrideGrossAnnualExpense is true.
      let shareExpenses: Number = this.getUnitLevelPlanCondominiumTotalShareExpenses(condominiumTotalSharePercentage);
      this.share = shareExpenses && shareExpenses.valueOf();
    } else {
      if (this.useAmountFromPropertyTabConvertToBoolean) {
        this.expensesAmount = condominiumTotalExpenses && condominiumTotalExpenses.valueOf();
      }

    }

    if (statementAdjustment) {
      statementAdjustment.amount = this.getSoAdjExpenseOccupancyPeriodTotalAsNumber(condominiumTotalExpenses,
        condominiumTotalSharePercentage,
        closingDate,
        soAdjExpenseOccupancyPeriodOccupancyDate);
      statementAdjustment.description = this.adjustmentHeading;
      statementAdjustment.statementOfAdjustmentCreditType = StatementAdjustmentAmountTypes.VENDOR;

    }
  }

  cleanupSoAdjExpenseOccupancyPeriod() {
    if (this.overrideGrossAnnualExpense) {
      //Clean useAmountFromPropertyTab
      this.useAmountFromPropertyTab = undefined;
      this.expensesAmount = 0;
      if (this.effectiveFromType == TotalEffectiveTypes.NO_START_DATE) {
        this.totalEffectiveFrom = null;
      }
      if (this.effectiveTillType == TotalEffectiveTypes.NO_END_DATE) {
        this.totalEffectiveTill = null;
      }
    } else {
      this.totalExpense = null;
      this.share = null;
      this.effectiveFromType = TotalEffectiveTypes.NO_START_DATE;
      this.totalEffectiveFrom = null;
      this.effectiveTillType = TotalEffectiveTypes.NO_END_DATE;
      this.totalEffectiveTill = null;
    }
  }

  get useAmountFromPropertyTabConvertToBoolean(): boolean {
    return Utils.convertToBoolean(this.useAmountFromPropertyTab);
  }

  get chargePurchaserConvertToBoolean(): boolean {
    return Utils.convertToBoolean(this.chargePurchaser);
  }

  // adjustmentCreditType(condominiumTotalExpenses : Number, condominiumTotalSharePercentage: Number, matterClosingDate : string, soAdjExpenseOccupancyPeriodOccupancyDate : string): string {
  //     const value: number = this.getSoAdjExpenseOccupancyPeriodTotalAsNumber(condominiumTotalExpenses, condominiumTotalSharePercentage, matterClosingDate, soAdjExpenseOccupancyPeriodOccupancyDate);
  //
  //     if (value === 0){
  //         return StatementAdjustmentAmountTypes.NO_ADJUSTMENT;
  //     }else{
  //         return StatementAdjustmentAmountTypes.VENDOR;
  //     }
  // }

}
