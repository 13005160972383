import {accountApi} from '../admin/accounts/shared/account-api';
import {configApi} from '../main/config-api';
import {HttpClient} from '../core';
import {Injectable} from '@angular/core';
import {SESSION_STORAGE_KEYS} from '../shared';

/** Account service defines all api calls for account modules
 */
@Injectable()
export class DefaultFieldsService {

  constructor(private http: HttpClient) {
  }

  // Other Contact Information List AKA List (DPPMP-2060)
  getLabels() {
    let accountId = sessionStorage.getItem(SESSION_STORAGE_KEYS.accountId);
    return this.http.get(accountApi.ConfigurationOptionLabels.replace('{accountId}', accountId))
    .map((res) => {
      return res[ 'ConfigurationOptionLabels' ];
    });
  }

  getValues(accountId: string) {
    let url = accountApi.ConfigurationOptionValues.replace('{accountId}', accountId);
    return this.http.get(url)
    .map((res) => {
      return res;
    });
  }

  setValues(values, accountId: string) {
    let url = configApi.customerAccountConfiguration.replace('{accountId}', accountId);
    return this.http.put(url, values)
    .map((res) => {
      return res;
    });
  }
}
