<form class="form-horizontal">
    <div class="container-fluid custom-modal-container">
        <div class="modal-header margin-bottom-20">
            <div class="col-lg-12">
                <div class="col-lg-10">
                    <h1>{{context.isForm4BankAccount ? 'Form 4 Bank Account' : 'Firm Trust Account'}}</h1>
                </div>
                <button type="button" class="btn-close pull-right" (click)="cancelDialog()">&times;</button>
            </div>
            <div class="clear"></div>
        </div>
        <div class="modal-body">
            <form class="form-horizontal">
                <div class="form-group" *ngIf="!context.isForm4BankAccount">
                    <label class="control-label col-lg-2">Preferred method to receive mortgage funds</label>
                    <div class="col-lg-2">
                        <span class="radio">
                            <input name="receiveMortgageFundsCode" type="radio" value="INTO_TRUST_ACCOUNT"
                                id="receiveMortgageFundsCode-0" (ngModelChange)="onChange($event)"
                                [ngModel]="trustAccount.receiveMortgageFundsCode"
                                (click)="trustAccount.receiveMortgageFundsCode = 'INTO_TRUST_ACCOUNT'"
                                [checked]="trustAccount.receiveMortgageFundsCode == 'INTO_TRUST_ACCOUNT'">

                            <label class="label-text focus-first" tabindex="0" for="receiveMortgageFundsCode-0">Into
                                Trust Account</label>
                        </span>
                    </div>
                    <div class="col-lg-4">
                        <span class="radio">
                            <input name="receiveMortgageFundsCode" type="radio" value="BY_CHEQUE"
                                id="receiveMortgageFundsCode-1" (ngModelChange)="onChange($event)"
                                [ngModel]="trustAccount.receiveMortgageFundsCode"
                                (click)="trustAccount.receiveMortgageFundsCode = 'BY_CHEQUE'"
                                [checked]="trustAccount.receiveMortgageFundsCode == 'BY_CHEQUE'">

                            <label class="label-text" tabindex="0" for="receiveMortgageFundsCode-1">By Cheque</label>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-lg-2">Institution No.</label>
                    <div class="col-lg-1">
                        <input type="text" trimmed id="institutionNo" name="institutionNo" numberOnly maxlength="3"
                            [fieldKey]="searchErrorElementKey" dp-error-validator
                            (ngModelChange)="onInstitutionNoChange()" (blur)="padInstitutionNo()" class="form-control"
                            [(ngModel)]="trustAccount.institutionNumber" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-lg-2">Transit No.</label>
                    <div class="col-lg-1">
                        <input type="text" trimmed id="transitNo" name="transitNo" numberOnly maxlength="5"
                            [fieldKey]="searchErrorElementKey" dp-error-validator (ngModelChange)="onTransitNoChange()"
                            (blur)="padTransitNo()" class="form-control" [(ngModel)]="trustAccount.transitNumber" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-lg-2">Trust Account No.</label>
                    <div class="col-lg-3">
                        <input type="text" trimmed id="trustAccountNumber" name="trustAccountNumber"
                            (ngModelChange)="onChange($event)" maxlength="20" class="form-control"
                            [(ngModel)]="trustAccount.trustAccountNumber" />
                    </div>
                </div>
                <div class="form-group">
                    <label class="control-label col-lg-2">Name of Bank/Trust Co.</label>
                    <div class="col-lg-9">
                        <input type="text" id="bankTrustCoLink" name="bankTrustCoLink" maxlength="15"
                            class="form-control" [dp-read-only]="true" (ngModelChange)="onChange($event)"
                            [(ngModel)]="trustAccount.mortgageeName" />
                    </div>
                </div>
                <div class="col-lg-12 padding-right-96">
                    <dp-address-form [address]="trustAccount.mortgageeMailingAddress" [addressSummaryLabel]="'Address'"
                        [disabled]="true">
                    </dp-address-form>
                </div>
                <ng-container *ngIf="context.isForm4BankAccount">
                    <div class="form-group">
                        <label class="control-label col-lg-2">Phone</label>
                        <div class="col-lg-9">
                            <input type="text" trimmed id="phone" name="phone" (ngModelChange)="onChange($event)"
                                maxlength="30" class="form-control" [(ngModel)]="trustAccount.phoneNumber" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-lg-2">Fax</label>
                        <div class="col-lg-9">
                            <input type="text" trimmed id="faxphone" name="faxphone" (ngModelChange)="onChange($event)"
                                maxlength="30" class="form-control" [(ngModel)]="trustAccount.faxNumber" />
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-lg-2">Contact</label>
                        <div class="col-lg-9">
                            <input type="text" trimmed id="contactName" name="contactName"
                                (ngModelChange)="onChange($event)" maxlength="200" class="form-control"
                                [(ngModel)]="trustAccount.contactName" />
                        </div>
                    </div>
                </ng-container>
                <div class="form-group">
                    <label class="control-label col-lg-2">{{context.isForm4BankAccount ? '' : 'Trust'}} Account
                        Name</label>
                    <div class="col-lg-9">
                        <input type="text" trimmed id="trustAccountName" name="trustAccountName" maxlength="200"
                            (ngModelChange)="onChange($event)" class="form-control"
                            [(ngModel)]="trustAccount.trustAccountName"
                            [dynamicHelpCallBackFunction]="dynamicF9HelpTrustAccountName()"
                            fieldKey="admin.trustAccount.trustAccountName" statusBarHelp
                            (keydown.f9)="handleF9OnTrustAccountName()" />
                    </div>
                    <div class="col-lg-1">"in trust"</div>
                </div>
                <div class="form-group" *ngIf="!context.isForm4BankAccount">
                    <label class="control-label col-lg-2">Or by Cheque Payable to</label>
                    <div class="col-lg-9">
                        <input type="text" trimmed id="chequePayableTo" name="chequePayableTo" maxlength="200"
                            (ngModelChange)="onChange($event)" class="form-control"
                            [(ngModel)]="trustAccount.chequePayableTo" fieldKey="admin.trustAccount.chequePayableTo"
                            statusBarHelp [dynamicHelpCallBackFunction]="dynamicF9HelpChequePayableTo()"
                            (keydown.f9)="handleF9OnChequePayableTo()" />
                    </div>
                    <div class="col-lg-1">"in trust"</div>
                </div>
                <div class="form-group">
                    <label class="control-label col-lg-2">Comments</label>
                    <div class="col-lg-9">
                        <input type="text" id="comments" name="comments" maxlength="250"
                            (ngModelChange)="onChange($event)" class="form-control"
                            [(ngModel)]="trustAccount.comments" />
                    </div>
                </div>
            </form>
        </div>
        <div class="row modal-footer">
            <div class="col-lg-12 buttons">
                <button type="button" id="okBtn" (click)="ok()" class="dp-btn">
                    <span>Save</span>
                </button>
                <button type="button" (click)="cancelDialog()" class="dp-btn dp-btn-cancel">
                    <span>Cancel</span>
                </button>
            </div>
        </div>
        <dp-modal-error #modalErrorComponent></dp-modal-error>
        <dp-modal-f9-help #modalF9HelpComponent></dp-modal-f9-help>
    </div>
</form>