import {AccountingItemKeys} from '../../admin/soa/soa-constants';
import {SoaTrustLedgerConfigKeys} from '../shared/soa-trustledger-collection';
import {Matter} from '../shared/matter';
import {MatterType, MatterTypesValue} from '../shared/index';
import {ProgressionStatus} from '../statement-adjustment/statement-adjustment';
import {AccountingMatter} from '../accounting/accounting-matter';
import {ProvinceCode} from '../../admin/accounts/shared/base-province';
import {PROVINCE_CODES} from '../shared/user-province';

export class SoaMatter extends AccountingMatter {

  id: number;
  itemName: string;
  itemValue: number;
  itemType: string;
  readOnly: boolean;
  isSelected: boolean;
  f9DefaultAmount: number;
  feesId: number;
  disbursementConfigId: number;
  statementConfigurationId: number;
  fromMortgageIndex: number;
  departmentPriority: number;
  accountCode: string;
  // Property added only for UI to display dynamic options
  accountCodeArray: any[] = [];
  progressionStatus: string;
  userOverriddenValue: boolean;

  constructor(soaMatter?: any) {
    super(soaMatter);

    if (isNaN(this.fromMortgageIndex)) {
      this.fromMortgageIndex = -1;
    }

    if (!soaMatter || soaMatter && !soaMatter.progressionStatus) {
      this.progressionStatus = ProgressionStatus.FINAL;
    }

  }

  isSearchCost(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.SEARCH_COSTS ]);
  }

  isExecutionsCertificate(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.EXECUTIONS_CERTIFICATE ]);
  }

  isOntarioTorontoLTTAvailable(): boolean {
    return (this.itemKey == SoaTrustLedgerConfigKeys.L1_OT);
  }

  isLTT(itemKey: string, configId: number): boolean {
    return (this.statementConfigurationId == configId && this.itemKey == itemKey);
  }

  isMortgage(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_ARRANGED_MORTGAGE ]);
  }

  isAdditionalMortgage(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_ADDITIONAL_MORTGAGE ]);
  }

  isParalegal(): boolean {
    return (this.itemKey === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_PURCHASE ] ||
      this.itemKey === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_SALE ] ||
      this.itemKey === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_DISCHARGE ] ||
      this.itemKey === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_MORTGAGE ]
    );
  }

  isTransactionLevySurcharge(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.TRANSACTION_LEVY_SURCHARGE ]);
  }

  isNsOurFeesForTransaction(matterType: MatterType, customMatterTypeName ?: string): boolean {
    if (customMatterTypeName) {
      return this.itemKey == 'FEE_' + customMatterTypeName + '_TRANSACTION_KEY';
    } else {
      switch (matterType) {
        case 'PURCHASE' :
          return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_PURCHASE_TRANSACTION_KEY ];
        case 'SALE' :
          return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_SALE_TRANSACTION_KEY ];
        case 'MORTGAGE' :
          return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_MORTGAGE_TRANSACTION_KEY ];
        default:
          return false;
      }
    }
  }

  isTransactionFees(matterType: MatterType, provinceCode: ProvinceCode, customMatterTypeName ?: string): boolean {

    if (customMatterTypeName) {
      if (provinceCode === PROVINCE_CODES.NOVA_SCOTIA) {
        return this.itemKey == 'PARALEGAL_FEE_FOR_ACTING_ON_' + customMatterTypeName;
      } else {
        return this.itemKey == 'FEE_' + customMatterTypeName + '_TRANSACTION_KEY';
      }
    } else {
      if (provinceCode === PROVINCE_CODES.NOVA_SCOTIA) {
        switch (matterType) {
          case MatterTypesValue.PURCHASE :
            return this.itemKey === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_PURCHASE ];
          case MatterTypesValue.SALE :
            return this.itemKey === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_SALE ];
          case MatterTypesValue.MORTGAGE :
            return this.itemKey === AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_MORTGAGE ];
          case 'DISCHARGE' :
            return this.itemKey == AccountingItemKeys[ AccountingItemKeys.PARALEGAL_FEE_FOR_ACTING_ON_DISCHARGE ];
          default:
            return false;
        }
      } else {
        switch (matterType) {
          case 'PURCHASE' :
            return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_PURCHASE_TRANSACTION_KEY ];
          case 'SALE' :
            return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_SALE_TRANSACTION_KEY ];
          case 'MORTGAGE' :
            return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_MORTGAGE_TRANSACTION_KEY ];
          case 'DISCHARGE' :
            return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_DISCHARGE_TRANSACTION_KEY ];
          case 'WILL' :
            return this.itemKey == AccountingItemKeys[ AccountingItemKeys.FEE_WILL_TRANSACTION_KEY ];
          default:
            return false;
        }
      }

    }

  }

  isMLTTConfiguration(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.HST_MLTT_ADMIN_FEE ]);
  }

  isCondoFeeAdded(matter: Matter): boolean {
    return this.provinceBasedCondoFeeAdded[ matter.provinceCode ] ? this.provinceBasedCondoFeeAdded[ matter.provinceCode ]() : this.provinceBasedCondoFeeAdded[ 'DEFAULT' ]();
  }

  isCondoCertificateFeeAdded = (): boolean => {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.CONDO_STATUS_CERTIFICATE_FEE ]);
  };

  isCondoEstoppelCertificateFeeAdded = (): boolean => {
    return (
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.CONDO_STATUS_CERTIFICATE_FEE ] ||
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.ESTOPPEL_CERTIFICATE ]
    );
  };

  isCondoDocumentFeeAdded = (): boolean => {
    return (
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.CONDOMINIUM_DOCUMENTS ] ||
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.STATUS_CERTIFICATE ] ||
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.ESTOPPEL_CERTIFICATE ]);
  };

  provinceBasedCondoFeeAdded = {
    'DEFAULT': this.isCondoCertificateFeeAdded,
    'AB': this.isCondoDocumentFeeAdded,
    'MB': this.isCondoDocumentFeeAdded,
    'SK': this.isCondoDocumentFeeAdded,
    'NB': this.isCondoEstoppelCertificateFeeAdded,
    'NS': this.isCondoEstoppelCertificateFeeAdded
  };

  isRetailTaxChattelsAdded(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.RETAIL_SALES_TAX_CHATTELS ]);
  }

  isERegServiceCharge(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.E_REG_SERVICE_CHARGE_KEY ]);
  }

  isCompliance(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.COMPLIANCE_ITEMS ]);

  }

  isNrst(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.NRST ]);

  }

  isEMP(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.ELECTRONIC_MORTGAGE_FEE_KEY ]);
  }

  isTitleInsurance(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.INSURANCE_PREMIUM_KEY ]);
  }

  isTitleRegistrationFee(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.TITLE_REGISTRATION_FEE ]);
  }

  isMortgageRegistrationFee(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.MORTGAGE_REGISTRATION_FEE ]);
  }

  isCondoCorporation(): boolean {
    return (
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.CONDO_STATUS_CERTIFICATE_FEE ] ||
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.CONDOMINIUM_DOCUMENTS ] ||
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.STATUS_CERTIFICATE ] ||
      this.itemKey == AccountingItemKeys[ AccountingItemKeys.ESTOPPEL_CERTIFICATE ]);
  }

  isSoftwareTransactionCharge(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.SOFTWARE_TRANSACTION_CHARGE ]);
  }

  isLandTitleSearches(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.LAND_TITLE_SEARCHES ]);
  }

  isTitleSearch(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.TITLE_SEARCH ]);
  }

  // used for allowing groups of fees that would not allow other types to be interspersed with other types
  isPartOfGroupedFees(matterType: MatterType, provinceCode: ProvinceCode, customMatterTypeName ?: string): boolean {
    return (this.isTransactionFees(matterType, provinceCode, customMatterTypeName)) ||
      (this.isMortgage() && matterType == MatterTypesValue.PURCHASE) ||
      (this.isAdditionalMortgage() && matterType == MatterTypesValue.MORTGAGE) ||
      (provinceCode == 'NS' && this.isNsOurFeesForTransaction(matterType, customMatterTypeName)) ||
      this.isParalegal();
  }

  isTeranetConnectCharges(): boolean {
    return (this.itemKey == AccountingItemKeys[ AccountingItemKeys.TERANET_CONNECT_USAGE_CHARGE_KEY ]);
  }

}
